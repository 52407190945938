import { axiosInstance } from "@/plugins/axios";
import {
  PoolAccountPDF,
  BankTransferPDF,
  SameMarketTransferPDF,
  DifferentMarketTransferPDF,
} from "models";

const AccountTransfersPDF = () => {
  const baseUrl = "/api/AccountTransfersPDF";

  const getPoolAccountPDF = async (
    poolAccount: PoolAccountPDF[]
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + `/getPoolAccountPDF`,
      poolAccount,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const getBankTransferPDF = async (bank: BankTransferPDF[]): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + `/getBankTransferPDF`,
      bank,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const getSameMarketTransferPDF = async (
    market: SameMarketTransferPDF[]
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + `/getSameMarketTransferPDF`,
      market,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const getDifferentMarketTransferPDF = async (
    market: DifferentMarketTransferPDF[]
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + `/getDifferentMarketTransferPDF`,
      market,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  return {
    getPoolAccountPDF,
    getBankTransferPDF,
    getSameMarketTransferPDF,
    getDifferentMarketTransferPDF,
  };
};
export default AccountTransfersPDF;
