
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { PostalAddress } from "models";

@Component({})
export default class PostalAddressComponent extends Vue {
  @PropSync("postalAddress", { type: String, required: false })
  postalAddressSync!: string;
  @PropSync("postalAddress2", { type: String, required: false })
  postalAddress2Sync!: string;
  @PropSync("postalCity", { type: String, required: false })
  postalCitySync!: string;
  @PropSync("postalSuburb", { type: String, required: false })
  postalSuburbSync!: string;
  @PropSync("postalCode", { type: String, required: false })
  postalCodeSync!: string;

  @Prop() sameAsStreetAddress!: boolean;

  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
  };
}
