
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import dayjs from "dayjs";
import { ClosedCorpEntityDetail, ClientDetail_CREATE } from "models";
import Hooks from "@/hooks";
import JSEClient from "@/store/modules/JSEClient";

const { PrivateDetails, PrivateAccount } = Hooks;
const privateHook = PrivateDetails();
const privateAccountHook = PrivateAccount();

@Component({})
export default class AccountContactDetails extends Vue {
  @PropSync("valid") formValid!: boolean;
  @PropSync("nextTab") tab!: boolean;
  @PropSync("next") accountNext!: boolean;
  @Prop() type!: string;
  loading = false;
  dataCheck = false;
  clientDetails: ClientDetail_CREATE = {
    titleId: null,
    name: null,
    surname: null,
    dateOfBirth: null,
    email: null,
    cellNumber: null,
    isCompany: false,
    companyAddress: null,
    postalAddress: null,
    streetAddress: null,
    streetAddress2: null,
    suburb: null,
    city: null,
    code: null,
    province: null,
    entityId: null,
    postalAddress2: null,
    postalCity: null,
    postalCode: null,
    postalSuburb: null,
  };
  title = [
    { id: 1, title: "Ms" },
    { id: 2, title: "Mrs" },
    { id: 3, title: "Mr" },
  ];
  account: ClosedCorpEntityDetail | undefined;
  dobMenu = false;
  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
    email: (value: string): boolean | string => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },
    cell: (value: string | null = null): boolean | string =>
      value != null ? value.length >= 10 : "" || "Enter a valid Contact Number",
  };

  @Watch("type", { immediate: true })
  async modalTypeChanged(): Promise<void> {
    console.log("Modal Details Type: ", this.type);

    if (this.type == "edit") {
      await this.getStoredData();
    }

    if (this.type == "add") {
      await this.getAccount();
    }
  }

  @Watch("accountNext", {
    immediate: true,
  })
  async onNextChanged(): Promise<void> {
    console.log("AccountNext", this.accountNext);
    if (this.accountNext == true) {
      if (this.type == "add") {
        await this.addNewClientDetails();
      }
      if (this.type == "edit") {
        if (this.dataCheck == true) {
          await this.editClientDetails();
        } else {
          await this.addNewClientDetails();
        }
      }
    }
  }

  async getAccount(): Promise<void> {
    var entityId: string | null = null;
    const key = localStorage.getItem("ClientFile/entityId");
    if (key != null) {
      entityId = key;
    }
    try {
      if (entityId != null) {
        const res = await privateAccountHook.getPrivateCompAccountByCode(
          entityId
        );

        console.log("Account Details: ", res);
        this.account = res;
        if (this.account != undefined) {
          this.clientDetails.cellNumber = this.account.contactNumber;
          this.clientDetails.email = this.account.contactPersonEmail;
        }

        return Promise.resolve();
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  }

  async getStoredData(): Promise<void> {
    try {
      this.loading = true;
      var entityId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        entityId = key;
      }

      if (entityId != null) {
        const res = await privateHook.getPrivateCompDetailByCode(entityId);

        console.log("Get Stored Data res: ", res);
        if (res != undefined && (res as any) != "") {
          this.dataCheck = true;
          this.clientDetails = res as any;
          this.clientDetails.dateOfBirth = dayjs(res.dateOfBirth)
            .format("YYYY-MM-DD")
            .toString();
        } else {
          this.dataCheck = false;
        }
        return Promise.resolve();
      }
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async addNewClientDetails(): Promise<void> {
    try {
      this.loading = true;
      const key = localStorage.getItem("ClientFile/entityId");

      if (key != null) {
        this.clientDetails.entityId = key;
      }

      console.log("Account Contact Details: ", this.clientDetails);

      this.clientDetails.isCompany == null
        ? (this.clientDetails.isCompany = false)
        : this.clientDetails.isCompany;
      const res = await privateHook.addNewPrivateCompDetails(
        this.clientDetails
      );

      JSEClient.setAccountContact({
        cellNumber: this.clientDetails.cellNumber,
        city: this.clientDetails.city,
        code: this.clientDetails.code,
        companyAddress: this.clientDetails.companyAddress,
        dateOfBirth: this.clientDetails.dateOfBirth,
        email: this.clientDetails.email,
        isCompany: this.clientDetails.isCompany,
        name: this.clientDetails.name,
        postalAddress: this.clientDetails.postalAddress,
        province: this.clientDetails.province,
        streetAddress: this.clientDetails.streetAddress,
        streetAddress2: this.clientDetails.streetAddress2,
        suburb: this.clientDetails.suburb,
        surname: this.clientDetails.surname,
        postalAddress2: this.clientDetails.postalAddress2,
        postalCity: this.clientDetails.postalCity,
        postalCode: this.clientDetails.postalCode,
        postalSuburb: this.clientDetails.postalSuburb,
      });

      this.$notificationCreator.createSuccessNotification(
        "Account Contact Details Saved"
      );
      this.tab = true;
      return Promise.resolve();
    } catch (err: any) {
      this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async editClientDetails(): Promise<void> {
    try {
      this.loading = true;
      console.log("Account Contact Details: ", this.clientDetails);

      this.clientDetails.isCompany == null
        ? (this.clientDetails.isCompany = false)
        : this.clientDetails.isCompany;
      const res = await privateHook.updatePrivateCompDetails(
        this.clientDetails as any
      );

      JSEClient.setAccountContact({
        cellNumber: this.clientDetails.cellNumber,
        city: this.clientDetails.city,
        code: this.clientDetails.code,
        companyAddress: this.clientDetails.companyAddress,
        dateOfBirth: this.clientDetails.dateOfBirth,
        email: this.clientDetails.email,
        isCompany: this.clientDetails.isCompany,
        name: this.clientDetails.name,
        postalAddress: this.clientDetails.postalAddress,
        province: this.clientDetails.province,
        streetAddress: this.clientDetails.streetAddress,
        streetAddress2: this.clientDetails.streetAddress2,
        suburb: this.clientDetails.suburb,
        surname: this.clientDetails.surname,
        postalAddress2: this.clientDetails.postalAddress2,
        postalCity: this.clientDetails.postalCity,
        postalCode: this.clientDetails.postalCode,
        postalSuburb: this.clientDetails.postalSuburb,
      });

      this.$notificationCreator.createSuccessNotification(
        "Account Contact Details Updated."
      );
      //this.tab = true;
      return Promise.resolve();
    } catch (err: any) {
      //this.accountNext = false;
      //this.tab = false;
      return Promise.reject(err);
    } finally {
      this.accountNext = false;
      this.loading = false;
    }
  }
}
