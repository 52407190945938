import { axiosAuth, axiosInstance } from "@/plugins/axios";
import { UserSignature, UserSignatureUpdate } from "models";

const UserSignatures = () => {
  const baseUrl = "/api/UserSignature";

  const UploadUserSignatureDocuments = async (
    userSignature: UserSignature
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + `/UploadUserSignatureDocuments`,
      {
        userProfileId: userSignature.userProfileId,
        customFile: userSignature.customFile,
      }
    );
    return Promise.resolve(res.data);
  };

  const UpdateUserSignatureDocuments = async (
    userSignature: UserSignatureUpdate
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/UpdateUserSignatureDocuments",
      {
        userProfileId: userSignature.userProfileId,
        customFileId: userSignature.customFileId,
        customFile: userSignature.customFile,
      }
    );
    return Promise.resolve(res.data);
  };

  const GetUserSignatureDocumentsByUser = async (
    userId: string
  ): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/GetUserSignatureDocumentsByUser?userId=${userId}`
    );
    return Promise.resolve(res.data);
  };

  const DownloadUserSignatureDocuments = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadUserSignatureDocuments?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  return {
    UploadUserSignatureDocuments,
    UpdateUserSignatureDocuments,
    GetUserSignatureDocumentsByUser,
    DownloadUserSignatureDocuments,
  };
};
export default UserSignatures;
