import { axiosInstance } from "@/plugins/axios";
import {
  PoolAccount,
  PoolName,
  MarketTransfer,
  BankTransfer,
  BankTransferData,
  AmendModel,
  AmendModelPDF,
} from "models";

const Excel = () => {
  const baseUrl = "/api/Excel";

  const DownloadPoolAccountsExcel = async (
    account: PoolAccount[]
  ): Promise<File> => {
    const res = await axiosInstance.post(
      baseUrl + `/DownloadPoolAccountsExcel`,
      account,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadMarketTransferSameExcel = async (
    account: MarketTransfer[]
  ): Promise<File> => {
    const res = await axiosInstance.post(
      baseUrl + `/DownloadMarketTransferSameExcel`,
      account,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadMarketTransferExcel = async (
    account: MarketTransfer[]
  ): Promise<File> => {
    const res = await axiosInstance.post(
      baseUrl + `/DownloadMarketTransferExcel`,
      account,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadBankAccountTransferExcel = async (
    account: BankTransfer[]
  ): Promise<File> => {
    const res = await axiosInstance.post(
      baseUrl + `/DownloadBankAccountTransferExcel`,
      account,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadAmendAccount = async (amend: AmendModelPDF): Promise<File> => {
    const res = await axiosInstance.post(
      baseUrl + `/DownloadAmendAccount`,
      amend,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadAmendFee = async (amend: {
    ratingCode: string;
    marketId: number;
    fee: number;
  }): Promise<File> => {
    const res = await axiosInstance.post(baseUrl + `/DownloadAmendFee`, amend, {
      headers: {
        "Content-Type": "application/json",
        Accept: "image/jpeg" + "application/pdf" + "image/png",
      },
      responseType: "blob",
    });
    return Promise.resolve(res.data);
  };

  const getSafexData = async (safexCode: string): Promise<PoolName> => {
    const res = await axiosInstance.get(
      baseUrl + `/getSafexData?safexCode=${safexCode}`
    );
    return Promise.resolve(res.data);
  };

  const getSafexBankData = async (
    safexCode: string
  ): Promise<BankTransferData> => {
    const res = await axiosInstance.get(
      baseUrl + `/getSafexBankData?safexCode=${safexCode}`
    );
    return Promise.resolve(res.data);
  };

  const getClientName = async (safexCode: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClientName?safexCode=${safexCode}`
    );
    return Promise.resolve(res.data);
  };

  return {
    DownloadPoolAccountsExcel,
    DownloadMarketTransferExcel,
    DownloadMarketTransferSameExcel,
    DownloadBankAccountTransferExcel,
    DownloadAmendAccount,
    DownloadAmendFee,
    getSafexData,
    getSafexBankData,
    getClientName,
  };
};
export default Excel;
