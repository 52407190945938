
import { Component, Vue, Watch } from "vue-property-decorator";
import AddEditMarket from "@/components/Manage/Markets/AddEditMarket.vue";
import { Market, UserAccessModel } from "models";
import Hooks from "@/hooks";
import User from "@/store/modules/user";

const { Markets } = Hooks;
const marketHook = Markets();

@Component({
  components: {
    AddEditMarket,
  },
})
export default class TradersView extends Vue {
  items: Market[] = [];
  loading = false;
  search = "";
  sortDesc = false;
  sortBy = "marketName";
  modal = false;
  deleteModal = false;
  deleteItem = {} as Market;
  modalData = {
    type: "",
    fields: {},
  };
  refresh = false;
  headers = [
    { text: "Name", value: "marketName" },
    { text: "Code", value: "marketCode" },
    { text: "", value: "actions", sortable: false, align: "end" },
  ];

  @Watch("refresh", { immediate: true })
  async modalDataChanged(): Promise<void> {
    if (this.refresh == true) {
      console.log("Refresh Triggered");
      await this.init();
    }
  }

  get getUserAccess(): UserAccessModel | null {
    console.log("UA: ", User.getUserAccess);
    return User.getUserAccess;
  }

  openEdit(i: Market): void {
    this.modalData.type = "edit";
    this.modalData.fields = {
      marketName: i.marketName,
      marketCode: i.marketCode,
      id: i.id,
      createdBy: i.createdBy,
      createdDate: i.createdDate,
      updatedBy: i.updatedBy,
      updatedDate: i.updatedDate,
      _id: i._id,
    };
    this.modal = true;
  }

  openAdd(): void {
    this.modalData.type = "add";
    this.modalData.fields = {};
    this.modal = true;
  }

  openDelete(i: Market): void {
    this.deleteItem = i;
    this.deleteModal = true;
  }

  async deleteTrader(): Promise<void> {
    try {
      const res = await marketHook.deleteMarket(this.deleteItem);
      this.$notificationCreator.createSuccessNotification("Trader Deleted.");
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.deleteModal = false;
      this.init();
    }
  }

  async mounted(): Promise<void> {
    await this.init();
  }

  async init(): Promise<void> {
    try {
      this.loading = true;
      const res = await marketHook.getAllMarkets();
      this.items = res;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
