
import dayjs from "dayjs";
import {
  RiskReporting_ABSA_Resp,
  RiskReporting_Balance_Resp,
  RiskReporting_Brokes,
  RiskReporting_Brokes_Resp,
  RiskReporting_HQ_Resp,
  RiskReporting_SubAccount_Resp,
} from "models";
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import User from "@/store/modules/user";
import Hooks from "@/hooks";

const { ExcelFiles } = Hooks;
const excelFileHook = ExcelFiles();

@Component({
  filters: {
    numberWCommas: function (value: number) {
      if (value != null) {
        return value.toLocaleString("en-US", { minimumFractionDigits: 2 });
      } else {
        return value;
      }
    },
  },
})
export default class Brokes extends Vue {
  @PropSync("loading", { default: false }) load!: boolean;
  @Prop() readonly importResult: {
    balanceSheet: RiskReporting_Balance_Resp[];
    brokesSheet: RiskReporting_Brokes_Resp[];
    absa: RiskReporting_ABSA_Resp[];
    absaDaily: RiskReporting_ABSA_Resp[];
    hq: RiskReporting_HQ_Resp[];
    subAccount: RiskReporting_SubAccount_Resp[];
  } | null = null;
  items: RiskReporting_Brokes_Resp[] = [];
  date = dayjs().format("YYYY-MM-DD");
  dateMenu = false;
  search = "";
  sortDesc = false;
  headers = [
    { text: "Agribase Code", value: "agribaseCode" },
    { text: "Agribase Exchange Fees", value: "agribaseExchangeFee" },
    { text: "Agribase Clearing Fees", value: "agribaseClearingFee" },
    { text: "Agribase Member Fees", value: "agribaseMemberFee" },
    { text: "ABSA Code", value: "absaCode" },
    { text: "ABSA Exchange Fees", value: "absaExchangeFee" },
    { text: "ABSA Clearing Fees", value: "absaClearingFee" },
    { text: "ABSA Member Fees", value: "absaMemberFee" },
    { text: "Exchange Fees Difference", value: "exchangeDifference" },
    { text: "Clearing Fees Difference", value: "clearingDifference" },
    { text: "Member Fees Difference", value: "memberDifference" },
    { text: "Notes", value: "note", style: "width: 350px" },
    { text: "", value: "actions", sortable: false },
  ];

  @Watch("importResult", { immediate: true })
  importResultChanged(): void {
    this.load = true;
    console.log("importResult Brokes: ", this.importResult);
    if (this.importResult != null) {
      var filteredBranches: RiskReporting_Brokes_Resp[] = [];
      this.importResult.brokesSheet.forEach((elem)=> {
        if (elem.agribaseCode != null){
          if (elem.agribaseCode.length > 4){
            filteredBranches.push(elem);
          }
        }
      })

      this.items = filteredBranches;
      if (this.importResult.absa[0].reportDate) {
        this.date = dayjs(this.importResult.absa[0].reportDate).format(
          "YYYY-MM-DD"
        );
      } else {
        this.date = this.getPreviousWorkDay;
      }
    }
    this.load = false;
  }

  get getPreviousWorkDay(): string {
    var date = dayjs().day();
    if (date == 6) {
      /* 6 = saturday */
      return dayjs().subtract(1, "day").format("YYYY-MM-DD");
    } else if (date == 0) {
      /* 0 = sunday */
      return dayjs().subtract(2, "day").format("YYYY-MM-DD");
    } else if (date == 1) {
      /* 1 = monday */
      return dayjs().subtract(3, "day").format("YYYY-MM-DD");
    } else {
      return dayjs().subtract(1, "day").format("YYYY-MM-DD");
    }
  }

  get Username(): string | null {
    return User.getLoggedInUser;
  }

  async SaveBrokesSheet(): Promise<void> {
    try {
      this.load = true;
      const brokeSheet: RiskReporting_Brokes[] = [];
      this.items.forEach((elem) => {
        brokeSheet.push({
          absaExchangeFee: elem.absaExchangeFee,
          absaClearingFee: elem.absaClearingFee,
          absaMemberFee: elem.absaMemberFee,
          absaCode: elem.absaCode,
          agribaseExchangeFee: elem.agribaseClearingFee,
          agribaseClearingFee: elem.agribaseClearingFee,
          agribaseMemberFee: elem.agribaseMemberFee,
          agribaseCode: elem.agribaseCode,
          exchangeDifference: elem.exchangeDifference,
          clearingDifference: elem.clearingDifference,
          memberDifference: elem.memberDifference,
          note: elem.note,
          reportDate: this.date,
          createdBy: this.Username,
          createdDate: dayjs().format("YYYY-MM-DD"),
          updatedBy: this.Username,
          updateDate: dayjs().format("YYYY-MM-DD"),
        });
      });
      const resp = await excelFileHook.SaveOrUpdateBrokes(brokeSheet);
      this.$notificationCreator.createSuccessNotification(
        "Brokes Control Sheet Saved Successfully."
      );
      await this.getBrokesSheetPDF(brokeSheet);
    } catch (err: any) {
      console.log(err.response);
      return Promise.reject(err);
    } finally {
      this.load = false;
    }
  }

  async getBrokesSheetPDF(brokesSheet: RiskReporting_Brokes[]): Promise<void> {
    try {
      this.load = true;
      const resp = await excelFileHook
        .getBrokesSheetPDF(brokesSheet)
        .then((response: any) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Brokes Control Sheet ${this.date}.pdf`
          );
          document.body.appendChild(link);
          link.click();
        });

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.load = false;
    }
  }
}
