import { axiosInstance } from "@/plugins/axios";
import { AmendModelPDF } from "models";

const AmendAccountPDF = () => {
  const baseUrl = "/api/AmendAccountPDF";

  const getReinstateAmendAccountPDF = async (
    account: AmendModelPDF
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + `/getReinstateAmendAccountPDF`,
      account,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const getClosedAmendAccountPDF = async (
    account: AmendModelPDF
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + `/getClosedAmendAccountPDF`,
      account,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const getEmailAmendAccountPDF = async (
    account: AmendModelPDF
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + `/getEmailAmendAccountPDF`,
      account,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const getFeeAmendAccountPDF = async (
    account: AmendModelPDF
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + `/getFeeAmendAccountPDF`,
      account,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const getAmendFeeStructurePDF = async (
    account: {
      commodity: string | null;
      fee: number | null;
      ratingCode: string | null;
      tonnes: number | null;
    }[]
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + `/getAmendFeeStructurePDF`,
      account,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  return {
    getClosedAmendAccountPDF,
    getReinstateAmendAccountPDF,
    getEmailAmendAccountPDF,
    getFeeAmendAccountPDF,
    getAmendFeeStructurePDF,
  };
};
export default AmendAccountPDF;
