import { axiosInstance } from "@/plugins/axios";
import { AccountBanking, ClientBanking, ClientBanking_CREATE } from "models";

const PrivateCompanyBankings = () => {
  const baseUrl = "/api/PrivateCompany";

  const getAllPrivateCompBanking = async (): Promise<ClientBanking[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAllPrivateCompBanking");
    return Promise.resolve(res.data);
  };

  const getPrivateCompBankingByCode = async (
    code: string
  ): Promise<ClientBanking> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPrivateCompBankingByCode?code=${code}`
    );
    return Promise.resolve(res.data);
  };

  const addNewPrivateCompBanking = async (
    clientBanking: ClientBanking_CREATE
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/addNewPrivateCompBanking",
      {
        bankId: clientBanking.bankId,
        accountType: clientBanking.accountType,
        branchName: clientBanking.branchName,
        accountHolder: clientBanking.accountHolder,
        branchCode: clientBanking.branchCode,
        bankSwiftNumber: clientBanking.bankSwiftNumber,
        accountNumber: clientBanking.accountNumber,
        bankIBANNumber: clientBanking.bankIBANNumber,
        entityId: clientBanking.entityId,
      }
    );
    return Promise.resolve(res.data);
  };

  const updatePrivateCompBanking = async (
    clientBanking: AccountBanking
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/updatePrivateCompBanking",
      {
        bankId: clientBanking.bankId,
        accountType: clientBanking.accountType,
        branchName: clientBanking.branchName,
        accountHolder: clientBanking.accountHolder,
        branchCode: clientBanking.branchCode,
        bankSwiftNumber: clientBanking.bankSwiftNumber,
        accountNumber: clientBanking.accountNumber,
        bankIBANNumber: clientBanking.bankIBANNumber,
        entityId: clientBanking.entityId,
        createdBy: clientBanking.createdBy,
        createdDate: clientBanking.createdDate,
        clientBankId: clientBanking.clientBankId,
        updatedBy: clientBanking.updatedBy,
        updatedDate: clientBanking.updatedDate,
        _id: clientBanking._id,
      }
    );
    return Promise.resolve(res.data);
  };

  const deletePrivateCompBanking = async (
    clientBanking: AccountBanking
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/deletePrivateCompBanking",
      {
        bankId: clientBanking.bankId,
        accountType: clientBanking.accountType,
        branchName: clientBanking.branchName,
        accountHolder: clientBanking.accountHolder,
        branchCode: clientBanking.branchCode,
        bankSwiftNumber: clientBanking.bankSwiftNumber,
        accountNumber: clientBanking.accountNumber,
        bankIBANNumber: clientBanking.bankIBANNumber,
        entityId: clientBanking.entityId,
        createdBy: clientBanking.createdBy,
        createdDate: clientBanking.createdDate,
        clientBankId: clientBanking.clientBankId,
        updatedBy: clientBanking.updatedBy,
        updatedDate: clientBanking.updatedDate,
        _id: clientBanking._id,
      }
    );
    return Promise.resolve(res.data);
  };

  return {
    getAllPrivateCompBanking,
    getPrivateCompBankingByCode,
    addNewPrivateCompBanking,
    updatePrivateCompBanking,
    deletePrivateCompBanking,
  };
};
export default PrivateCompanyBankings;
