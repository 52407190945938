
import { Component, Vue, Watch } from "vue-property-decorator";
import AddEdittraders from "@/components/Manage/Banks/AddEditBanks.vue";
import { Bank, UserAccessModel } from "models";
import Hooks from "@/hooks";
import User from "@/store/modules/user";

const { Banks } = Hooks;
const bankHook = Banks();

@Component({
  components: {
    AddEdittraders,
  },
})
export default class BanksView extends Vue {
  items: Bank[] = [];
  loading = false;
  search = "";
  sortDesc = false;
  sortBy = "bankName";
  modal = false;
  deleteModal = false;
  deleteItem = {} as Bank;
  modalData = {
    type: "",
    fields: {},
  };
  refresh = false;
  headers = [
    { text: "Name", value: "bankName" },
    { text: "Branch Code", value: "branchCode" },
    { text: "", value: "actions", sortable: false },
  ];

  @Watch("refresh", { immediate: true })
  async modalDataChanged(): Promise<void> {
    if (this.refresh == true) {
      console.log("Refresh Triggered");
      await this.init();
    }
  }

  get getUserAccess(): UserAccessModel | null {
    console.log("UA: ", User.getUserAccess);
    return User.getUserAccess;
  }

  openEdit(i: Bank): void {
    this.modalData.type = "edit";
    this.modalData.fields = i;
    this.modal = true;
  }

  openAdd(): void {
    this.modalData.type = "add";
    this.modalData.fields = {};
    this.modal = true;
  }

  openDelete(i: Bank): void {
    this.deleteItem = i;
    this.deleteModal = true;
  }

  async deleteBank(): Promise<void> {
    try {
      const res = await bankHook.deleteBank(this.deleteItem);
      this.$notificationCreator.createSuccessNotification("Trader Deleted.");
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.deleteModal = false;
      this.init();
    }
  }

  async mounted(): Promise<void> {
    await this.init();
  }

  async init(): Promise<void> {
    try {
      this.loading = true;
      const res = await bankHook.getAllBanks();
      this.items = res;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
