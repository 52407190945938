import { axiosInstance } from "@/plugins/axios";
import { BreakdownOfFee, BreakdownOfFee_CREATE } from "models";

const FeeStructures = () => {
  const baseUrl = "/api/FeeStructure";

  const getAllFeeStructures = async (): Promise<BreakdownOfFee[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAllFeeStructures");
    return Promise.resolve(res.data);
  };

  const getFeeStructure = async (id: number): Promise<BreakdownOfFee> => {
    const res = await axiosInstance.get(baseUrl + `/getFeeStructure?id=${id}`);
    return Promise.resolve(res.data);
  };

  const getFeeStructuresByClient = async (
    clientId: string,
    safexDivisionId: number | null
  ): Promise<BreakdownOfFee> => {
    const res = await axiosInstance.get(
      baseUrl +
        `/getFeeStructuresByClient?clientId=${clientId}&safexDivisionId=${safexDivisionId}`
    );
    return Promise.resolve(res.data);
  };

  const getSafexFeeCheck = async (
    safexDivisionId: number | null
  ): Promise<boolean> => {
    const res = await axiosInstance.get(
      baseUrl + `/getSafexFeeCheck?safexDivisionId=${safexDivisionId}`
    );
    return Promise.resolve(res.data);
  };

  const addFeeStructure = async (
    feeStructure: BreakdownOfFee_CREATE
  ): Promise<BreakdownOfFee> => {
    const res = await axiosInstance.post(baseUrl + "/addFeeStructure", {
      clientAccountId: feeStructure.clientAccountId,
      ratingCodeId: feeStructure.ratingCodeId,
      member: feeStructure.member,
      memberAmount: feeStructure.memberAmount,
      broker: feeStructure.broker,
      brokerAmount: feeStructure.brokerAmount,
      thirdParty: feeStructure.tenthParty,
      thirdPartyAmount: feeStructure.thirdPartyAmount,
      fourthParty: feeStructure.fourthParty,
      fourthPartyAmount: feeStructure.fourthPartyAmount,
      fifthParty: feeStructure.fifthParty,
      fifthPartyAmount: feeStructure.fifthPartyAmount,
      sixthParty: feeStructure.sixthParty,
      sixthPartyAmount: feeStructure.seventhPartyAmount,
      seventhParty: feeStructure.seventhParty,
      seventhPartyAmount: feeStructure.seventhPartyAmount,
      eighthParty: feeStructure.eighthParty,
      eighthPartyAmount: feeStructure.eighthPartyAmount,
      ninethParty: feeStructure.ninethParty,
      ninethPartyAmount: feeStructure.ninethPartyAmount,
      tenthParty: feeStructure.tenthParty,
      total: feeStructure.total,
      tenthPartyAmount: feeStructure.tenthPartyAmount,
      safexDivisionId: feeStructure.safexDivisionId,
    });
    return Promise.resolve(res.data);
  };

  const updateFeeStructure = async (
    feeStructure: BreakdownOfFee
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/updateFeeStructure", {
      id: feeStructure.id,
      clientAccountId: feeStructure.clientAccountId,
      ratingCodeId: feeStructure.ratingCodeId,
      member: feeStructure.member,
      memberAmount: feeStructure.memberAmount,
      broker: feeStructure.broker,
      brokerAmount: feeStructure.brokerAmount,
      thirdParty: feeStructure.tenthParty,
      thirdPartyAmount: feeStructure.thirdPartyAmount,
      fourthParty: feeStructure.fourthParty,
      fourthPartyAmount: feeStructure.fourthPartyAmount,
      fifthParty: feeStructure.fifthParty,
      fifthPartyAmount: feeStructure.fifthPartyAmount,
      sixthParty: feeStructure.sixthParty,
      sixthPartyAmount: feeStructure.seventhPartyAmount,
      seventhParty: feeStructure.seventhParty,
      seventhPartyAmount: feeStructure.seventhPartyAmount,
      eighthParty: feeStructure.eighthParty,
      eighthPartyAmount: feeStructure.eighthPartyAmount,
      ninethParty: feeStructure.ninethParty,
      ninethPartyAmount: feeStructure.ninethPartyAmount,
      tenthParty: feeStructure.tenthParty,
      total: feeStructure.total,
      tenthPartyAmount: feeStructure.tenthPartyAmount,
      createdBy: feeStructure.createdBy,
      createdDate: feeStructure.createdDate,
      updatedBy: feeStructure.updatedBy,
      updatedDate: feeStructure.updatedDate,
      safexDivisionId: feeStructure.safexDivisionId,
      _id: {
        creationTime: feeStructure._id.creationTime,
        increment: feeStructure._id.increment,
        machine: feeStructure._id.machine,
        pid: feeStructure._id.pid,
        timestamp: feeStructure._id.timestamp,
      },
    });
    return Promise.resolve(res.data);
  };

  /* const deleteFeeStructure = async (
    feeStructure: FeeStructure
  ): Promise<void> => {
    const res = await axiosInstance
      .post(baseUrl + "/deleteFeeStructure", {
        id: feeStructure.id,
        ratingCodeId: feeStructure.ratingCodeId,
        feeAmount: feeStructure.feeAmount,
        createdBy: feeStructure.createdBy,
        createdDate: feeStructure.createdDate,
        updatedBy: feeStructure.updatedBy,
        updatedDate: feeStructure.updatedDate,
        _id: {
          creationTime: feeStructure._id.creationTime,
          increment: feeStructure._id.increment,
          machine: feeStructure._id.machine,
          pid: feeStructure._id.pid,
          timestamp: feeStructure._id.timestamp,
        },
      })
      .catch((err) => {
        if (err.response.data) {
          return Promise.reject(err.response.data);
        }
        return Promise.reject(err);
      });
    return Promise.resolve(res.data);
  }; */

  return {
    getAllFeeStructures,
    getFeeStructure,
    getFeeStructuresByClient,
    getSafexFeeCheck,
    addFeeStructure,
    updateFeeStructure,
  };
};
export default FeeStructures;
