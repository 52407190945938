import { axiosInstance } from "@/plugins/axios";
import { ClientAccount, ClientAccount_CREATE, ClientOverview } from "models";

const ClientAccounts = () => {
  const baseUrl = "/api/ClientAccount";

  const getAllClientAccount = async (): Promise<ClientAccount[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAllClientAccount");
    return Promise.resolve(res.data);
  };

  const getOverview = async (): Promise<ClientOverview[]> => {
    const res = await axiosInstance.get(baseUrl + "/getOverview");
    return Promise.resolve(res.data);
  };

  const getClientAccountByClientId = async (
    clientId: string
  ): Promise<ClientAccount> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClientAccount?clientAccountId=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const addNewClientAccount = async (
    clientAccount: ClientAccount_CREATE
  ): Promise<any> => {
    const res = await axiosInstance.post(baseUrl + "/addNewClientAccount", {
      accountName: clientAccount.accountName,
      contactNumber: clientAccount.contactNumber,
      contactPerson: clientAccount.contactPerson,
      contactPersonEmail: clientAccount.contactPersonEmail,
      dateOfRegistration: clientAccount.dateOfRegistration,
      country: clientAccount.country,
      email: clientAccount.email,
      branchTrader: clientAccount.branchTrader,
      enumStatus: clientAccount.enumStatus,
      deleted_At: clientAccount.deleted_At,
      staffAccount: clientAccount.staffAccount,
    });
    return Promise.resolve(res.data);
  };

  const updateClientAccount = async (
    clientAccount: ClientAccount
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/updateClientAccount", {
      accountName: clientAccount.accountName,
      contactNumber: clientAccount.contactNumber,
      contactPerson: clientAccount.contactPerson,
      contactPersonEmail: clientAccount.contactPersonEmail,
      dateOfRegistration: clientAccount.dateOfRegistration,
      country: clientAccount.country,
      email: clientAccount.email,
      branchTrader: clientAccount.branchTrader,
      enumStatus: clientAccount.enumStatus,
      id: clientAccount.id,
      entityId: clientAccount.entityId,
      createdBy: clientAccount.createdBy,
      createdDate: clientAccount.createdDate,
      updatedBy: clientAccount.updatedBy,
      updatedDate: clientAccount.updatedDate,
      _id: {
        creationTime: clientAccount._id.creationTime,
        increment: clientAccount._id.increment,
        machine: clientAccount._id.machine,
        pid: clientAccount._id.pid,
        timestamp: clientAccount._id.timestamp,
      },
      deleted_At: clientAccount.deleted_At,
      staffAccount: clientAccount.staffAccount,
    });
    return Promise.resolve(res.data);
  };

  const deleteClientAccount = async (
    clientAccount: ClientAccount
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/deleteChildAuthorised", {
      accountName: clientAccount.accountName,
      contactNumber: clientAccount.contactNumber,
      contactPerson: clientAccount.contactPerson,
      contactPersonEmail: clientAccount.contactPersonEmail,
      dateOfRegistration: clientAccount.dateOfRegistration,
      country: clientAccount.country,
      email: clientAccount.email,
      branchTrader: clientAccount.branchTrader,
      enumStatus: clientAccount.enumStatus,
      id: clientAccount.id,
      entityId: clientAccount.entityId,
      createdBy: clientAccount.createdBy,
      createdDate: clientAccount.createdDate,
      updatedBy: clientAccount.updatedBy,
      updatedDate: clientAccount.updatedDate,
      _id: {
        creationTime: clientAccount._id.creationTime,
        increment: clientAccount._id.increment,
        machine: clientAccount._id.machine,
        pid: clientAccount._id.pid,
        timestamp: clientAccount._id.timestamp,
      },
      deleted_At: clientAccount.deleted_At,
      staffAccount: clientAccount.staffAccount,
    });
    return Promise.resolve(res.data);
  };

  const updateHqAccessClients = async (
    overview: ClientOverview
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/updateHqAccessClients",
      overview
    );
    return Promise.resolve(res.data);
  };

  return {
    getAllClientAccount,
    getOverview,
    getClientAccountByClientId,
    addNewClientAccount,
    updateClientAccount,
    deleteClientAccount,
    updateHqAccessClients,
  };
};
export default ClientAccounts;
