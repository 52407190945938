
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import dayjs from "dayjs";
import User from "@/store/modules/user";
import {
  ClientOverview,
  ClientsPerDealer,
  DealerPerBranch,
  HQAccess as HQAccessM,
  HQAccess_CREATE,
  UserProfilewPass,
} from "models";
import Hooks from "@/hooks";

const { HQAccess, Traders, Markets } = Hooks;
const HQAccesshook = HQAccess();
const Tradershook = Traders();
const marketHook = Markets();

@Component({})
export default class NewHQ extends Vue {
  @PropSync("valid") formValid!: boolean;
  @PropSync("clear") formClear!: boolean;
  @PropSync("next") accountNext!: boolean;
  @Prop() type!: string;
  @Prop() modalData!: UserProfilewPass;
  loading = false;
  selectAllBranchesLoading = false;
  hqAccess: HQAccess_CREATE = {
    _id: null,
    userId: null,
    admin: false,
    branch: null,
    client: null,
    dealer: null,
    defaultCode: "AGCBVG\\",
    masterdealer: false,
    onScreenLimit: null,
    optionLimit: null,
    reportOnlyLimit: null,
    setup: null,
    tradingSystem: null,
    uniqueCode: null,
    username: null,
    viewOnly: false,
    safexCodes: null,
    filteredClients: null,
    algos: false,
    uniqueKey: null,
    numberofContracts: null,
    limitChecking: false,
  };
  setupList = [
    {
      text: "Master Dealer",
      description:
        "A Master Dealer has the ability to see all the traders and/or clients information. A Master Dealer can trade on behalf of any client and/or dealer.",
    },
    {
      text: "Admin",
      description: "",
    },
    {
      text: "Compliance",
      description:
        "Compliance has the ability to see all the traders and/or clients information. The can not execute any trading.",
    },
    {
      text: "Dealer",
      description:
        "A Dealer has access to a specific branch or more than one branch. They can trade and/or view all the information for that branches(s).",
    },
    {
      text: "Retail Client",
      description:
        "A Retail Client is only allowed to trade on specific client codes and/or sub-accounts. They have access to multiple accounts across all branches and can trade and/or view all the information for those branches.",
    },
  ];
  setup: { text: string; description: string } | null = null;
  tradingSystem: string[] | null = null;
  hasHQAccess = false;
  //showAllByDefaultSetup = false;
  //allByDefaultSetupLoading = false;
  traders: any[] = [];
  branches: string[] = [];
  subAccFormValid = false;
  hqDetailsList: {
    branch: string | null;
    dealerCodes: {
      clients: string[] | null;
      code: string | null;
      safex: string[] | null;
    }[];
    selectedDealers: {
      clients: string[] | null;
      code: string | null;
      safex: string[] | null;
    }[];
    clients: {
      dealer: string | null;
      clientCode: string | null;
    }[];
    selectedClients: {
      dealer: string | null;
      clientCode: string | null;
    }[];
    subAccs: {
      dealer: string | null;
      clientCode: string | null;
    }[];
    selectedSubAccs: {
      dealer: string | null;
      clientCode: string | null;
    }[];
    subAccMenu: boolean;
  }[] = [];
  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
    requiredMultiple: (value: string[]): boolean | string =>
      !value || value.length > 0 || "At least one option is required!",
  };

  get getSelectAllBranchesDisabled(): boolean {
    var disabled = false;
    const unique = this.branches.filter(
      (item, i, ar) => ar.indexOf(item) === i
    );

    if (unique.length == this.hqDetailsList.length) {
      disabled = true;
    } else {
      disabled = false;
    }
    return disabled;
  }

  close_clearSubAccMenu(index: number) {
    console.log("close_clearSubAccMenu");
    /*  if (this.hqDetailsList[index].selectedSubAccs.length > 0) {
      this.hqDetailsList[index].subAccs =
        this.hqDetailsList[index].selectedSubAccs;
    } else { */
    this.hqDetailsList[index].subAccs = [{ clientCode: null, dealer: null }];
    this.hqDetailsList[index].selectedSubAccs = [];
    /*  } */
    this.hqDetailsList[index].subAccMenu = false;
  }

  saveAndSelectAll(index: number) {
    console.log("saveAndSelectAll");
    this.hqDetailsList[index].selectedSubAccs =
      this.hqDetailsList[index].subAccs;
    this.hqDetailsList[index].subAccMenu = false;
  }

  checkForExistingBranches(branch: string): boolean {
    console.log("checkForExistingBranches");
    const findExisting = this.hqDetailsList.filter((h) => h.branch == branch);

    if (findExisting != undefined && findExisting.length > 1) {
      return true;
    } else {
      return false;
    }
  }

  /* keep */
  addItemToList() {
    console.log("addItemToList");
    this.hqDetailsList.push({
      branch: null,
      clients: [],
      dealerCodes: [],
      subAccs: [{ clientCode: null, dealer: null }],
      selectedClients: [],
      selectedDealers: [],
      selectedSubAccs: [],
      subAccMenu: false,
    });
  }
  /* keep */
  removeItemFromList(index: number) {
    console.log("removeItemFromList");
    console.log(index);
    this.hqDetailsList.splice(index, 1);
  }

  /* keep */
  addItemToSubAccList(mainIndex: number) {
    console.log("addItemToSubAccList");
    this.hqDetailsList[mainIndex].subAccs.push({
      clientCode: null,
      dealer: null,
    });
  }

  /* keep */
  removeItemFromSubAccList(mainIndex: number, index: number) {
    console.log("removeItemFromSubAccList");
    console.log(index);
    this.hqDetailsList[mainIndex].subAccs.splice(index, 1);
  }
  /* keep */
  filter(item: ClientOverview, queryText: string, itemText: string): boolean {
    if (
      (item.safexCode != null &&
        item.safexCode
          .toLocaleLowerCase()
          .includes(queryText.toLocaleLowerCase())) ||
      (item.physicCode != null &&
        item.physicCode
          .toLocaleLowerCase()
          .includes(queryText.toLocaleLowerCase())) ||
      (item.ircCode != null &&
        item.ircCode
          .toLocaleLowerCase()
          .includes(queryText.toLocaleLowerCase())) ||
      item.clientName
        .toLocaleLowerCase()
        .includes(queryText.toLocaleLowerCase())
    ) {
      return true;
    } else return false;
  }

  async selectAllBranches(): Promise<void> {
    try {
      this.selectAllBranchesLoading = true;
      const unique = this.branches.filter(
        (item, i, ar) => ar.indexOf(item) === i
      );

      await Promise.all(
        unique.map(async (item) => {
          const find = this.hqDetailsList.findIndex((hq) => hq.branch == item);
          if (find == -1) {
            this.hqDetailsList.push({
              branch: item,
              clients: [],
              dealerCodes: [],
              selectedClients: [],
              selectedDealers: [],
              selectedSubAccs: [],
              subAccMenu: false,
              subAccs: [],
            });
          }

          await this.populateDataFromBranch(this.hqDetailsList.length - 1);
        })
      );

      this.hqDetailsList.forEach(async (hqItem, hqItemIndex) => {
        /* hqItem.dealerCodes.map((dealerCode) => {
          hqItem.selectedDealers.push(dealerCode);
        }); */
        if (hqItem.selectedDealers.length > 0) {
          this.toggleAllDealers(hqItemIndex);
          this.toggleAllDealers(hqItemIndex);
        } else {
          this.toggleAllDealers(hqItemIndex);
        }

        if (hqItem.clients.length == 0) {
          this.populateClients(hqItemIndex);
        }

        if (hqItem.selectedClients.length > 0) {
          this.toggleAllClients(hqItemIndex);
          this.toggleAllClients(hqItemIndex);
        } else {
          this.toggleAllClients(hqItemIndex);
        }
      });

      this.hqDetailsList = this.hqDetailsList.sort((a, b) =>
        a.branch!.localeCompare(b.branch!)
      );

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.selectAllBranchesLoading = false;
    }
  }

  processSubAccs(index: number, subIndex: number) {
    if (
      this.hqDetailsList[index].subAccs[subIndex].clientCode &&
      this.hqDetailsList[index].subAccs[subIndex].dealer
    ) {
      if (
        this.hqDetailsList[index].subAccs[subIndex].clientCode!.includes(",")
      ) {
        const subAccs =
          this.hqDetailsList[index].subAccs[subIndex].clientCode!.split(",");

        subAccs.map((item) => {
          this.hqDetailsList[index].subAccs.push({
            clientCode: item,
            dealer: this.hqDetailsList[index].subAccs[subIndex].dealer,
          });
        });

        this.hqDetailsList[index].subAccs.splice(subIndex, 1);
      }
    }
  }

  toggleAllSubAccDealers(
    mainIndex: number,
    dealers: {
      clients: string[] | null;
      code: string | null;
      safex: string[] | null;
    }[]
  ): void {
    console.log("toggleAllSubAccDealers");
    dealers.forEach((dealer) => {
      const find = this.hqDetailsList[mainIndex].subAccs.findIndex(
        (item) => item.dealer == dealer.code
      );

      if (find == -1) {
        this.hqDetailsList[mainIndex].subAccs.push({
          clientCode: null,
          dealer: dealer.code,
        });
      }
    });

    console.log(
      "this.hqDetailsList[mainIndex].subAccs",
      this.hqDetailsList[mainIndex].subAccs
    );

    const findNull = this.hqDetailsList[mainIndex].subAccs.findIndex(
      (item) => item.dealer == null || item.dealer == undefined
    );

    console.log("findNull", findNull);

    if (findNull != -1) {
      this.hqDetailsList[mainIndex].subAccs.splice(findNull, 1);
    }
  }

  /* keep */
  toggleAllClients(index: number): void {
    console.log("toggleAllClients");
    if (this.hqDetailsList[index].selectedClients) {
      if (this.hqDetailsList[index].selectedClients!.length > 0) {
        this.hqDetailsList[index].selectedClients = [];
      } else {
        this.hqDetailsList[index].selectedClients = [];
        this.hqDetailsList[index].clients!.forEach((elem) => {
          if (this.hqDetailsList[index].selectedClients)
            this.hqDetailsList[index].selectedClients!.push(elem);
        });
      }
    } else {
      this.hqDetailsList[index].selectedClients = [];
      this.toggleAllClients(index);
    }
  }
  /* keep */
  toggleAllDealers(index: number): void {
    console.log("toggleAllDealers");
    if (this.hqDetailsList[index].selectedDealers) {
      if (this.hqDetailsList[index].selectedDealers!.length > 0) {
        this.hqDetailsList[index].selectedDealers = [];
      } else {
        this.hqDetailsList[index].selectedDealers = [];
        this.hqDetailsList[index].dealerCodes!.forEach((elem) => {
          if (this.hqDetailsList[index].selectedDealers)
            this.hqDetailsList[index].selectedDealers!.push(elem);
        });
      }
    } else {
      this.hqDetailsList[index].selectedDealers = [];
      this.toggleAllDealers(index);
    }
  }
  /* keep */
  async populateSetup(): Promise<void> {
    console.log("populateSetup");
    if (this.setup) {
      this.hqAccess.safexCodes = null;
      this.hqAccess.branch = null;
      this.hqAccess.dealer = null;

      this.hqDetailsList = [
        {
          branch: null,
          clients: [],
          dealerCodes: [],
          subAccs: [{ clientCode: null, dealer: null }],
          selectedClients: [],
          selectedDealers: [],
          selectedSubAccs: [],
          subAccMenu: false,
        },
      ];

      this.hqAccess.setup = this.setup.text;
      if (this.hqAccess.setup == "Admin") {
        this.hqAccess.admin = true;
        this.hqAccess.masterdealer = false;
      } else if (this.hqAccess.setup == "Master Dealer") {
        this.hqAccess.admin = false;
        this.hqAccess.masterdealer = true;
        /* await this.selectAllByDefault(); */
      } else {
        this.hqAccess.admin = false;
        this.hqAccess.masterdealer = false;
        if (this.hqAccess.setup == "Retail Client") {
          //await this.loadSafexCodes();
        }
      }
    }
  }

  /* async selectAllByDefault(): Promise<void> {
    try {
      this.allByDefaultSetupLoading = true;

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.allByDefaultSetupLoading = false;
    }
  } */
  /* keep */
  populateTradingSystem(): void {
    console.log("populateTradingSystem");
    console.log("TradingSystem: ", this.tradingSystem);

    if (this.tradingSystem) {
      var tradingSystemList = "";
      this.tradingSystem.map((elem) => {
        tradingSystemList = tradingSystemList + elem + ", ";
      });
      this.hqAccess.tradingSystem = tradingSystemList.substring(
        0,
        tradingSystemList.length - 2
      );
      console.log("TradingSystem Final:", this.hqAccess.tradingSystem);
    }
  }

  /* keep */
  async populateFromRes(res: HQAccessM): Promise<void> {
    console.log("populateFromRes");
    this.hqAccess = res;
    //tradingSystem
    const tradingSystemListRes = res.tradingSystem.split(",");
    console.log("Trading System get res: ", tradingSystemListRes);
    if (tradingSystemListRes) this.tradingSystem = tradingSystemListRes;
    //setup
    const setupRes = this.setupList.find((elem) => elem.text == res.setup);
    console.log("Setup get res: ", setupRes);
    if (setupRes) this.setup = setupRes;
    //client
    console.log("res.client", res.client);

    await this.populateHqDetailsFromRes();
  }
  /* keep */
  async populateHqDetailsFromRes(): Promise<void> {
    this.hqDetailsList = [];
    if (
      this.hqAccess.filteredClients &&
      this.hqAccess.filteredClients.length > 0
    ) {
      console.log("populating from filteredClient");
      for (let i = 0; i < this.hqAccess.filteredClients.length; i++) {
        const resHQAuto = await HQAccesshook.getHQAutoPopulate(
          this.hqAccess.filteredClients[i].branch,
          null,
          this.hqAccess.setup == "Retail Client" ? true : false
        );

        this.hqDetailsList.push({
          branch: resHQAuto.branchCode,
          clients: [],
          dealerCodes: [],
          selectedClients: [],
          selectedDealers: [],
          selectedSubAccs: [],
          subAccs: [],
          subAccMenu: false,
        });

        const findprev = this.hqDetailsList.findIndex(
          (item) => item.branch == resHQAuto.branchCode
        );
        if (
          resHQAuto.dealerCodes != null &&
          findprev != undefined &&
          findprev != -1
        ) {
          resHQAuto.dealerCodes.map((elem) => {
            //populate dealer list
            this.hqDetailsList[findprev].dealerCodes.push({
              code: elem.code,
              clients: elem.clients,
              safex: elem.safex,
            });
            //populate client list
            if (elem.clients != null) {
              elem.clients.map((client) => {
                this.hqDetailsList[findprev].clients.push({
                  dealer: elem.code,
                  clientCode: client,
                });
              });
            }
            if (elem.safex != null) {
              elem.safex.map((client) => {
                this.hqDetailsList[findprev].clients.push({
                  dealer: elem.code,
                  clientCode: client,
                });
              });
            }
          });

          if (
            this.hqAccess.filteredClients &&
            this.hqAccess.filteredClients.length > 0
          ) {
            this.hqAccess.filteredClients.map((filteredC) => {
              if (filteredC.dealers)
                filteredC.dealers.map((filteredCD) => {
                  const filterDealer = this.hqDetailsList[
                    findprev
                  ].dealerCodes.filter(
                    (dealer) => dealer.code == filteredCD.dealer
                  );

                  if (filterDealer) {
                    filterDealer.map((fDealer) => {
                      this.hqDetailsList[findprev].selectedDealers.push(
                        fDealer
                      );
                    });
                  }
                  if (filteredCD.clients) {
                    filteredCD.clients.map((filteredCDclient) => {
                      const filterClients = this.hqDetailsList[
                        findprev
                      ].clients.filter(
                        (client) => client.clientCode == filteredCDclient
                      );

                      if (filterClients) {
                        filterClients.map((fClient) => {
                          this.hqDetailsList[findprev].selectedClients.push(
                            fClient
                          );
                        });
                      }
                    });
                  }
                });
            });

            if (this.hqAccess.filteredClients[i].dealers != null) {
              this.hqAccess.filteredClients[i].dealers!.map(
                (filteredDealer) => {
                  if (filteredDealer.clients) {
                    filteredDealer.clients.map((filteredDealerClients) => {
                      const foundSub = this.hqDetailsList[
                        findprev
                      ].clients.find(
                        (c) => c.clientCode == filteredDealerClients
                      );
                      if (foundSub == undefined) {
                        if (filteredDealerClients.length == 5) {
                          this.hqDetailsList[findprev].subAccs.push({
                            clientCode: filteredDealerClients,
                            dealer: filteredDealer.dealer,
                          });

                          this.hqDetailsList[findprev].selectedSubAccs.push({
                            clientCode: filteredDealerClients,
                            dealer: filteredDealer.dealer,
                          });
                        }
                      }
                    });
                  }
                }
              );
            }

            if (
              this.hqDetailsList[findprev].selectedSubAccs == null ||
              this.hqDetailsList[findprev].selectedSubAccs.length == 0
            ) {
              this.hqDetailsList[findprev].selectedSubAccs.push({
                clientCode: null,
                dealer: null,
              });
            }
            if (
              this.hqDetailsList[findprev].subAccs == null ||
              this.hqDetailsList[findprev].subAccs.length == 0
            ) {
              this.hqDetailsList[findprev].subAccs.push({
                clientCode: null,
                dealer: null,
              });
            }
            this.populateClients(findprev);
          }
        }
      }
    } else {
      console.log("populating from old");
      if (this.hqAccess.branch && this.hqAccess.branch.length > 0) {
        //ensuring unique values to be used
        const uniqueArrayB = this.hqAccess.branch.filter(
          (value: any, index: any) => {
            const _value = JSON.stringify(value);
            return (
              index ===
              this.hqAccess.branch!.findIndex((obj: any) => {
                return JSON.stringify(obj) === _value;
              })
            );
          }
        );

        this.hqAccess.branch = uniqueArrayB;

        for (let i = 0; i < this.hqAccess.branch.length; i++) {
          const resHQAuto = await HQAccesshook.getHQAutoPopulate(
            this.hqAccess.branch[i],
            null,
            this.hqAccess.setup == "Retail Client" ? true : false
          );

          this.hqDetailsList.push({
            branch: resHQAuto.branchCode,
            clients: [],
            dealerCodes: [],
            selectedClients: [],
            selectedDealers: [],
            selectedSubAccs: [],
            subAccs: [{ clientCode: null, dealer: null }],
            subAccMenu: false,
          });

          const findprev = this.hqDetailsList.findIndex(
            (item) => item.branch == resHQAuto.branchCode
          );
          if (
            resHQAuto.dealerCodes != null &&
            findprev != undefined &&
            findprev != -1
          ) {
            resHQAuto.dealerCodes.map((elem) => {
              //populate dealer list
              this.hqDetailsList[findprev].dealerCodes.push({
                code: elem.code,
                clients: elem.clients,
                safex: elem.safex,
              });
              //populate client list
              if (elem.clients != null) {
                elem.clients.map((client) => {
                  this.hqDetailsList[findprev].clients.push({
                    dealer: elem.code,
                    clientCode: client,
                  });
                });
              }
              if (elem.safex != null) {
                elem.safex.map((client) => {
                  this.hqDetailsList[findprev].clients.push({
                    dealer: elem.code,
                    clientCode: client,
                  });
                });
              }
            });

            if (this.hqAccess.dealer && this.hqAccess.dealer.length > 0) {
              this.hqAccess.dealer.map((hqADealer) => {
                const filterDealer = this.hqDetailsList[
                  findprev
                ].dealerCodes.filter((dealer) => dealer.code == hqADealer);

                if (filterDealer) {
                  filterDealer.map((fDealer) => {
                    this.hqDetailsList[findprev].selectedDealers.push(fDealer);
                  });
                }
              });
            }

            if (this.hqAccess.client && this.hqAccess.client.length > 0) {
              this.hqAccess.client.map((hqAClient) => {
                const filterClients = this.hqDetailsList[
                  findprev
                ].clients.filter((client) => client.clientCode == hqAClient);

                if (filterClients) {
                  filterClients.map((fClient) => {
                    this.hqDetailsList[findprev].selectedClients.push(fClient);
                  });
                }
              });
            }

            if (
              this.hqAccess.safexCodes &&
              this.hqAccess.safexCodes.length > 0
            ) {
              this.hqAccess.safexCodes.map((hqASafex) => {
                const filterClients = this.hqDetailsList[
                  findprev
                ].clients.filter((client) => client.clientCode == hqASafex);

                if (filterClients) {
                  filterClients.map((fClient) => {
                    this.hqDetailsList[findprev].selectedClients.push(fClient);
                  });
                }
              });
            }

            this.populateClients(findprev);
          }
        }
      }
    }
  }

  populateClients(index: number): void {
    console.log("populateClients");
    this.hqDetailsList[index].clients = [];
    if (
      this.hqDetailsList &&
      this.hqDetailsList.length > 0 &&
      this.hqDetailsList[index].dealerCodes
    ) {
      this.hqDetailsList[index].selectedDealers.map((selectedDealer) => {
        const find = this.hqDetailsList[index].dealerCodes.find(
          (dealer) => dealer.code == selectedDealer.code
        );

        if (find != undefined) {
          if (find.clients != null) {
            find.clients.map((client) => {
              this.hqDetailsList[index].clients.push({
                dealer: find.code,
                clientCode: client,
              });
            });
          }
          if (find.safex != null) {
            find.safex.map((client) => {
              this.hqDetailsList[index].clients.push({
                dealer: find.code,
                clientCode: client,
              });
            });
          }
        }
      });
    }
  }

  /* keep */
  async populateDataFromBranch(index: number): Promise<void> {
    console.log("populateDataFromBranch");
    if (
      this.hqDetailsList &&
      this.hqDetailsList.length > 0 &&
      this.hqDetailsList[index].branch
    ) {
      const resHQAuto = await HQAccesshook.getHQAutoPopulate(
        this.hqDetailsList[index].branch,
        null,
        this.hqAccess.setup == "Retail Client" ? true : false
      );

      /* this.hqDetailsList[index].clientList = resHQAuto.clients.sort(
        (a: { clientName: string }, b: { clientName: string }) => {
          let fa = a.clientName.toLowerCase(),
            fb = b.clientName.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        }
      );
      this.hqDetailsList[index].traderList = resHQAuto.dealerCodes.sort(
        (a: any, b: any) => {
          let fa = a.dealerCode
            ? a.dealerCode.toLowerCase()
            : a.code.toLowerCase();
          let fb = b.dealerCode
            ? b.dealerCode.toLowerCase()
            : b.code.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        }
      ); */
      if (resHQAuto.dealerCodes != null) {
        resHQAuto.dealerCodes.map((elem) => {
          //populate dealer list
          this.hqDetailsList[index].dealerCodes = [];
          this.hqDetailsList[index].dealerCodes.push({
            code: elem.code,
            clients: elem.clients,
            safex: elem.safex,
          });

          //populate client list
          this.hqDetailsList[index].clients = [];
          if (elem.clients != null) {
            elem.clients.map((client) => {
              this.hqDetailsList[index].clients.push({
                dealer: elem.code,
                clientCode: client,
              });
            });
          }
          if (elem.safex != null) {
            elem.safex.map((client) => {
              this.hqDetailsList[index].clients.push({
                dealer: elem.code,
                clientCode: client,
              });
            });
          }
        });

        this.hqDetailsList[index].selectedClients = [];
        this.hqDetailsList[index].selectedDealers = [];
        this.hqDetailsList[index].selectedSubAccs = [];
      }
    }
  }

  /* keep */
  get getUsername(): string | null {
    return User.getLoggedInUser;
  }

  /* keep */
  @Watch("type", { immediate: true })
  async modalTypeChanged(): Promise<void> {
    console.log("Modal Details Type: ", this.type);
    this.loading = true;
    if (this.type) {
      await this.loadTraders();
      this.loadBranches();

      if (this.type == "edit" && this.modalData.userId) {
        const res = await HQAccesshook.getAllHQAccessByUser(
          this.modalData.userId
        );
        console.log("HQ Access res: ", res);
        if (res != null && res != "" && res != undefined) {
          this.hasHQAccess = true;
          this.populateFromRes(res);
        } else {
          this.hasHQAccess = false;
          this.setDefaults();
        }
      }

      if (this.type == "add") {
        this.setDefaults();
      }
    }
    this.loading = false;
  }
  /* keep */
  setDefaults() {
    console.log("setDefaults");
    this.hqDetailsList = [
      {
        branch: null,
        clients: [],
        dealerCodes: [],
        selectedClients: [],
        selectedDealers: [],
        selectedSubAccs: [],
        subAccs: [{ clientCode: null, dealer: null }],
        subAccMenu: false,
      },
    ];
    this.hqAccess = {
      _id: null,
      userId: null,
      admin: false,
      branch: null,
      client: null,
      dealer: null,
      defaultCode: "AGCBVG\\",
      masterdealer: false,
      onScreenLimit: null,
      optionLimit: null,
      reportOnlyLimit: null,
      setup: null,
      tradingSystem: null,
      uniqueCode: null,
      username: null,
      viewOnly: false,
      safexCodes: null,
      filteredClients: null,
      algos: false,
    };
    this.setup = null;
    this.tradingSystem = null;
  }
  /* keep */
  @Watch("accountNext", {
    immediate: true,
  })
  async onNextChanged(): Promise<void> {
    console.log("AccountNext", this.accountNext);
    if (this.accountNext == true) {
      if (this.type == "edit") {
        if (this.hasHQAccess == true) {
          await this.editClientAccountDetails();
        } else {
          await this.addClientAccountDetails();
        }
      }
      if (this.type == "add") {
        await this.addClientAccountDetails();
      }
    }
  }

  /* keep */
  populateHQAccessObject() {
    console.log("populateHQAccessObject");
    if (this.hqDetailsList.length > 0) {
      var branch: string[] = [];
      var client: string[] = [];
      var dealer: string[] = [];
      var safexCodes: string[] = [];
      var filterClients: DealerPerBranch[] = [];
      this.hqAccess.filteredClients = [];

      this.hqDetailsList.forEach((item) => {
        if (item.branch) {
          branch.push(item.branch);
        }

        if (item.selectedClients) {
          item.selectedClients.map((c) => client.push(c.clientCode!));
        }
        var dealerToBeUsed: ClientsPerDealer[] = [];
        if (item.selectedDealers) {
          item.selectedDealers.map((d) => {
            dealer.push(d.code!);
            dealerToBeUsed.push({ dealer: d.code, clients: [] });
          });
        }
        //TODO
        /* if (item.safexCodes) {
          item.safexCodes.map((s) => safexCodes.push(s));
        } */
        item.selectedClients.map((sC) => {
          const find = dealerToBeUsed.findIndex((d) => d.dealer == sC.dealer);
          if (find != undefined && find != -1) {
            dealerToBeUsed[find].clients?.push(sC.clientCode!);
          }
        });

        item.selectedSubAccs.map((sS) => {
          const find = dealerToBeUsed.findIndex((d) => d.dealer == sS.dealer);
          if (find != undefined && find != -1) {
            dealerToBeUsed[find].clients?.push(sS.clientCode!);
          }
        });

        filterClients.push({
          branch: item.branch,
          dealers: dealerToBeUsed,
        });
      });

      //unique dealers per branch with all client codes from said duplicates
      filterClients = this.mergeDuplicateDealers(filterClients);

      //unique client codes
      filterClients.map((filteredC) => {
        if (filteredC.dealers) {
          filteredC.dealers.map((filteredD) => {
            if (filteredD.clients) {
              const uniqueArray = filteredD.clients.filter(
                (value: any, index: any) => {
                  const _value = JSON.stringify(value);
                  return (
                    index ===
                    filteredD.clients!.findIndex((obj: any) => {
                      return JSON.stringify(obj) === _value;
                    })
                  );
                }
              );
              filteredD.clients = uniqueArray;
            }
          });
        }
      });

      //unique older lists
      const uniqueArrayB = branch.filter((value: any, index: any) => {
        const _value = JSON.stringify(value);
        return (
          index ===
          branch.findIndex((obj: any) => {
            return JSON.stringify(obj) === _value;
          })
        );
      });
      branch = uniqueArrayB;
      const uniqueArrayD = dealer.filter((value: any, index: any) => {
        const _value = JSON.stringify(value);
        return (
          index ===
          dealer.findIndex((obj: any) => {
            return JSON.stringify(obj) === _value;
          })
        );
      });
      dealer = uniqueArrayD;
      const uniqueArrayC = client.filter((value: any, index: any) => {
        const _value = JSON.stringify(value);
        return (
          index ===
          client.findIndex((obj: any) => {
            return JSON.stringify(obj) === _value;
          })
        );
      });
      client = uniqueArrayC;
      const uniqueArrayS = safexCodes.filter((value: any, index: any) => {
        const _value = JSON.stringify(value);
        return (
          index ===
          safexCodes.findIndex((obj: any) => {
            return JSON.stringify(obj) === _value;
          })
        );
      });
      safexCodes = uniqueArrayS;

      this.hqAccess.branch = branch.length > 0 ? branch : null;
      this.hqAccess.client = client.length > 0 ? client : null;
      this.hqAccess.dealer = dealer.length > 0 ? dealer : null;
      this.hqAccess.safexCodes = safexCodes.length > 0 ? safexCodes : null;
      this.hqAccess.filteredClients = filterClients;
    }
  }

  mergeDuplicateDealers(arrayModels: DealerPerBranch[]): DealerPerBranch[] {
    return arrayModels.map(this.mergeDuplicateDealersInBranch);
  }

  mergeDuplicateDealersInBranch(
    branchDealers: DealerPerBranch
  ): DealerPerBranch {
    if (!branchDealers.dealers) {
      return branchDealers;
    }

    const dealersMap = new Map<string, string[]>();

    branchDealers.dealers.forEach(({ dealer, clients }) => {
      if (dealer) {
        if (!dealersMap.has(dealer)) {
          dealersMap.set(dealer, clients ?? []);
        } else {
          const existingClients = dealersMap.get(dealer) ?? [];
          dealersMap.set(
            dealer,
            Array.from(new Set([...existingClients, ...(clients ?? [])]))
          );
        }
      }
    });

    const mergedDealers = Array.from(dealersMap.entries()).map(
      ([dealer, clients]) => ({
        dealer,
        clients: clients.length > 0 ? clients : null,
      })
    );

    return { ...branchDealers, dealers: mergedDealers };
  }
  /* keep */
  async editClientAccountDetails(): Promise<void> {
    try {
      this.loading = true;
      this.hqAccess.userId = this.modalData.userId;
      this.populateHQAccessObject();
      /* console.log("hqA edit", this.hqAccess); */
      const res = await HQAccesshook.updateHQAccess(this.hqAccess as HQAccessM);
      this.$notificationCreator.createSuccessNotification(
        "New HQ Details Updated."
      );
      this.$emit("close");
      return Promise.resolve();
    } catch (err: any) {
      this.accountNext = false;
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  /* keep */
  async addClientAccountDetails(): Promise<void> {
    try {
      this.loading = true;
      this.hqAccess.userId = this.modalData.userId;
      this.populateHQAccessObject();
      /*  console.log("hqA add", this.hqAccess); */
      const res = await HQAccesshook.addNewHQAccess(this.hqAccess);
      this.$notificationCreator.createSuccessNotification(
        "New HQ Details Created."
      );
      this.$emit("close");
      return Promise.resolve();
    } catch (err: any) {
      this.accountNext = false;
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  /* keep */
  loadBranches(): void {
    console.log("loadBranches");
    var branchesRes: string[] = [];
    this.traders.map((elem) => {
      branchesRes.push(elem.branch);
    });
    this.branches = branchesRes.sort();
  }
  /* keep */
  async loadTraders(): Promise<void> {
    console.log("loadTraders");
    this.traders = [];
    const resMarkets = await marketHook.getAllMarkets();
    const resTraders = await Tradershook.getAllTraders();
    const apdMarket = resMarkets.find((market) => market.marketCode == "APD");
    //console.log("APD marketId: ", apdMarket?.id);
    resTraders.map((trader) => {
      if (apdMarket && trader.marketId == apdMarket.id) {
        this.traders.push(trader);
      }
    });
    this.traders.sort((a: any, b: any) => {
      let fa = a.dealerCode ? a.dealerCode.toLowerCase() : a.code.toLowerCase();
      let fb = b.dealerCode ? b.dealerCode.toLowerCase() : b.code.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
  }
}
