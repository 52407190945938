
import { Component, Vue } from "vue-property-decorator";
import dayjs from "dayjs";
import Hooks from "@/hooks";
import {
  MarketTransfer,
  SameMarketTransferPDF,
  DifferentMarketTransferPDF,
  UserAccessModel,
} from "models";
import User from "@/store/modules/user";

const { Excel, AccountTransfersPDF } = Hooks;
const excelHook = Excel();
const accountTransfersPDFHook = AccountTransfersPDF();

@Component({})
export default class TransferBetweenClients extends Vue {
  loading = false;
  market = "Same Markets";
  date = dayjs().format("YYYY-MM-DD");
  dateMenu = false;
  saveModal = false;
  saveAsPDF = false;
  saveAsExcel = false;
  client: {
    id: number;
    fromSafexCode: string | null;
    fromName: string | null;
    fromMarket: string | null;
    toSafexCode: string | null;
    toName: string | null;
    toMarket: string | null;
    amount: number | null;
  }[] = [];
  editedIndex = -1;
  editedItem = {
    id: 0,
    fromSafexCode: "",
    fromName: "",
    fromMarket: "",
    toSafexCode: "",
    toName: "",
    toMarket: "",
    amount: null,
  };
  defaultItem = {
    id: 0,
    fromSafexCode: "",
    fromName: "",
    fromMarket: "",
    toSafexCode: "",
    toName: "",
    toMarket: "",
    amount: null,
  };
  headers = [
    {
      text: "#",
      value: "id",
      sortable: false,
    },
    {
      text: "From Safex Code",
      value: "fromSafexCode",
      sortable: false,
      style: "width: 150px",
    },
    {
      text: "Client Name",
      value: "fromName",
      sortable: false,
      style: "width: 300px",
    },
    {
      text: "From Market",
      value: "fromMarket",
      sortable: false,
      style: "width: 150px",
    },
    {
      text: "",
      value: "fromTo",
      sortable: false,
    },
    {
      text: "To Safex Code",
      value: "toSafexCode",
      sortable: false,
      style: "width: 150px",
    },
    /* {
      text: "To Name",
      value: "toName",
      sortable: false,
      style: "width: 300px",
    }, */
    {
      text: "To Market",
      value: "toMarket",
      sortable: false,
      style: "width: 150px",
    },
    {
      text: "",
      value: "colon",
      sortable: false,
    },
    {
      text: "Amount",
      value: "amount",
      sortable: false,
      style: "width: 150px",
    },
    {
      text: "Actions",
      value: "actions",
      sortable: false,
    },
  ];
  headersSameMarkets = [
    {
      text: "#",
      value: "id",
      sortable: false,
    },
    {
      text: "From Safex Code",
      value: "fromSafexCode",
      sortable: false,
      style: "width: 150px",
    },
    {
      text: "From Name",
      value: "fromName",
      sortable: false,
      style: "width: 300px",
    },
    {
      text: "Market",
      value: "fromMarket",
      sortable: false,
      style: "width: 150px",
    },
    {
      text: "",
      value: "fromTo",
      sortable: false,
    },
    {
      text: "To Safex Code",
      value: "toSafexCode",
      sortable: false,
      style: "width: 150px",
    },
    {
      text: "To Name",
      value: "toName",
      sortable: false,
      style: "width: 300px",
    },
    {
      text: "",
      value: "colon",
      sortable: false,
    },
    {
      text: "Amount",
      value: "amount",
      sortable: false,
      style: "width: 150px",
    },
    {
      text: "Actions",
      value: "actions",
      sortable: false,
    },
  ];

  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
  };

  uppercaseFrom(): void {
    this.editedItem.fromSafexCode = this.editedItem.fromSafexCode.toUpperCase();
  }

  get getUserAccess(): UserAccessModel | null {
    console.log("UA: ", User.getUserAccess);
    return User.getUserAccess;
  }

  uppercaseTo(): void {
    this.editedItem.toSafexCode = this.editedItem.toSafexCode.toUpperCase();
  }

  mounted(): void {
    this.addNew();
  }

  editItem(item: any): void {
    this.editedIndex = this.client.indexOf(item);
    this.editedItem = Object.assign({}, item);
  }

  deleteItem(item: any): void {
    const index = this.client.indexOf(item);

    this.client.splice(index, 1);
  }

  close(): void {
    setTimeout(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    }, 100);
  }

  addNew(): void {
    const addObj = Object.assign({}, this.defaultItem);
    addObj.id = this.client.length + 1;
    this.client.unshift(addObj);
    this.editItem(addObj);
  }

  save(): void {
    if (this.editedIndex > -1) {
      Object.assign(this.client[this.editedIndex], this.editedItem);
      console.log("Client: ", this.client);
    }
  }

  clear(): void {
    this.client = [];
    this.addNew();
  }

  async getSafexData(safexCode: string, type: string): Promise<void> {
    try {
      const res = await excelHook.getSafexData(safexCode);
      console.log("Safex Data: ", res);
      if (type == "to") {
        this.editedItem.toName = res.name;
        this.editedItem.toMarket = res.market;
      }

      if (type == "from") {
        this.editedItem.fromName = res.name;
        this.editedItem.fromMarket = res.market;
        if (this.market == "Same Markets") {
          this.editedItem.toMarket = res.market;
        }
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async downloadFile(): Promise<void> {
    if (this.saveAsPDF == true) {
      await this.DownloadPDF();
    }
    if (this.saveAsExcel == true) {
      await this.DownloadExcel();
    }
  }

  async DownloadPDF(): Promise<void> {
    try {
      this.client.sort((a, b) => a.id - b.id);

      if (this.market == "Same Markets") {
        var client: SameMarketTransferPDF[] = [];
        this.client.forEach((elem) => [
          client.push({
            clientName: elem.fromName,
            fromSafexCode: elem.fromSafexCode,
            market: elem.fromMarket,
            amount: elem.amount,
            toSafexCode: elem.toSafexCode,
            depositDate: this.date,
            logoFileId: 1,
            lineNumber: elem.id,
          }),
        ]);
        console.log("Client Same PDF", client);
        const res = await accountTransfersPDFHook
          .getSameMarketTransferPDF(client)
          .then((response: any) => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `TBC(Same Market) ${dayjs().format("YYYY-MM-DD")}.pdf`
            );
            document.body.appendChild(link);
            link.click();
          });

        return Promise.resolve();
      }

      if (this.market == "Different Markets") {
        var clientDiff: DifferentMarketTransferPDF[] = [];
        this.client.forEach((elem) => [
          clientDiff.push({
            clientName: elem.fromName,
            fromSafexCode: elem.fromSafexCode,
            fromMarket: elem.fromMarket,
            toMarket: elem.toMarket,
            amount: elem.amount,
            toSafexCode: elem.toSafexCode,
            depositDate: this.date,
            logoFileId: 1,
            lineNumber: elem.id,
          }),
        ]);
        console.log("Client Diff PDF", clientDiff);
        const res = await accountTransfersPDFHook
          .getDifferentMarketTransferPDF(clientDiff)
          .then((response: any) => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `TBC(Different Market) ${dayjs().format("YYYY-MM-DD")}.pdf`
            );
            document.body.appendChild(link);
            link.click();
          });

        return Promise.resolve();
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async DownloadExcel(): Promise<void> {
    try {
      this.client.sort((a, b) => a.id - b.id);
      var client: MarketTransfer[] = [];
      this.client.forEach((elem) => [
        client.push({
          toSafexCode: elem.toSafexCode,
          toName: elem.toName,
          toMarket: elem.toMarket,
          safexCode: elem.fromSafexCode,
          fromName: elem.fromName,
          fromMarket: elem.fromMarket,
          amount: elem.amount,
          depositDate: this.date,
          lineNumber: elem.id,
        }),
      ]);
      console.log("Client Excel", client);
      if (this.market == "Same Markets") {
        const res = await excelHook
          .DownloadMarketTransferSameExcel(client)
          .then((response: any) => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `TBC(Same Market) ${dayjs().format("YYYY-MM-DD")}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
          });

        return Promise.resolve();
      }

      if (this.market == "Different Markets") {
        const res = await excelHook
          .DownloadMarketTransferExcel(client)
          .then((response: any) => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `TBC(Different Market) ${dayjs().format("YYYY-MM-DD")}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
          });

        return Promise.resolve();
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
}
