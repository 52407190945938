import { axiosInstance } from "@/plugins/axios";
import { SafexDivision } from "models";

const SafexDivision = () => {
  const baseUrl = "/api/SafexDivision";

  const getAllDivisions = async (): Promise<SafexDivision[]> => {
    const res = await axiosInstance.get(baseUrl + `/getAllDivsions`);
    return Promise.resolve(res.data);
  };

  return {
    getAllDivisions,
  };
};
export default SafexDivision;
