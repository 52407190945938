
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import {
  ClosedCorpDocs,
  ClosedCorpPersonalDocs,
  ClosedCorpPersonalDocsUpdate,
  ClosedCorpDocsUpdate,
  CustomDocs,
  CustomDocsUpdate,
  CustomDocsList,
} from "models";
import Hooks from "@/hooks";
import User from "@/store/modules/user";
import JSEClient from "@/store/modules/JSEClient";

const { ClosedCorpDocuments, ClientDetails, CustomFile } = Hooks;
const closedCorpHook = ClosedCorpDocuments();
const clientDetailsHook = ClientDetails();
const customFileHook = CustomFile();

@Component({})
export default class SupportingDocs extends Vue {
  @PropSync("valid") formValid!: boolean;
  @PropSync("nextTab") tab!: boolean;
  @PropSync("next") accountNext!: boolean;
  @Prop() type!: string;
  loading = false;
  regNumber: string | null = null;
  regDocumentsUpload: File[] | null = null;
  idType: string | null = null;
  saIdNumber: string | null = null;
  idUpload: File | null = null;
  proofOfBankUpload: File | null = null;
  proofOfResidenceUpload: File | null = null;
  taxNumber: string | null = null;
  taxNumberUpload: File | null = null;
  incomeTax: string | null = null;
  incomeTaxUpload: File | null = null;
  vatRegNumber: string | null = null;
  vatRegNumberUpload: File | null = null;
  proofCCBusAddressUpload: File | null = null;
  resolution_mandate: File | null = null;
  addFiles: {
    type: string | null;
    info: string | null;
    file: File | null;
  }[] = [];
  storedAddress: {
    streetAddress: string | null;
    streetAddress2: string | null;
    city: string | null;
    suburb: string | null;
    code: string | null;
    province: string | null;
  } = {
    streetAddress: null,
    streetAddress2: null,
    city: null,
    suburb: null,
    code: null,
    province: null,
  };
  storedDocs: {
    idDoc: any | null;
    regDoc: any | null;
    tnDoc: any | null;
    itDoc: any | null;
    pobDoc: any | null;
    proofOfBankDoc: any | null;
    vatDoc: any | null;
    rmDoc: any | null;
    addFiles: any | null;
  } = {
    idDoc: null,
    regDoc: null,
    tnDoc: null,
    itDoc: null,
    proofOfBankDoc: null,
    pobDoc: null,
    vatDoc: null,
    rmDoc: null,
    addFiles: null,
  };
  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
    id: (value: string): boolean | string =>
      value?.length == 13 || "Requires 13 digits",
    fileSize: (value: { size: number }): boolean | string =>
      !value || value.size < 5000000 || "Max File Size 5MB!",
    fileSizeMultiple: (files: { size: number }[]): boolean | string =>
      !files ||
      !files.some((file: { size: number }) => file.size > 5000000) ||
      "Max File Size 5MB!",
  };

  headers = [
    {
      text: "Type",
      value: "docType",
      sortable: false,
      style: "width: 250px",
    },
    {
      text: "Information",
      value: "info",
      sortable: false,
      style: "width: 350px",
    },
    {
      text: "File (s)",
      value: "files",
      sortable: false,
    },
  ];
  headersEdit = [
    {
      text: "Type",
      value: "docType",
      sortable: false,
      style: "width: 250px",
    },
    {
      text: "Information",
      value: "info",
      sortable: false,
      style: "width: 350px",
    },
    {
      text: "File (s)",
      value: "files",
      sortable: false,
    },
    {
      text: "View",
      value: "view",
      sortable: false,
    },
  ];
  items = [
    {
      type: "reg",
    },
    {
      type: "id",
    },
    {
      type: "tax",
    },
    {
      type: "vat",
    },
    {
      type: "proofOfBank",
    },
    {
      type: "pob",
    },
    {
      type: "rm",
    },
  ];

  addCustomFile(): void {
    var checkAdd = this.items.find((elem) => elem.type == "add");

    if (checkAdd != undefined) {
      this.addFiles.push({ type: null, info: null, file: null });
    } else {
      this.items.push({ type: "add" });
      this.addFiles.push({ type: null, info: null, file: null });
    }
  }

  download(type: string): void {
    switch (type) {
      case "id":
        this.downloadId(
          this.storedDocs.idDoc != null ? this.storedDocs.idDoc._id : 0
        );
        break;
      case "reg":
        this.downloadReg(
          this.storedDocs.regDoc != null ? this.storedDocs.regDoc[0]._id : 0
        );
        break;
      case "add":
        this.downloadCustom(
          this.storedDocs.addFiles != null ? this.storedDocs.addFiles[0]._id : 0
        );
        break;
      case "proofOfBank":
        this.downloadProofOfBank(
          this.storedDocs.proofOfBankDoc != null
            ? this.storedDocs.proofOfBankDoc._id
            : 0
        );
        break;
      case "pob":
        this.downloadPOB(
          this.storedDocs.pobDoc != null ? this.storedDocs.pobDoc._id : 0
        );
        break;
      case "tax":
        this.downloadTN(
          this.storedDocs.tnDoc != null ? this.storedDocs.tnDoc._id : 0
        );
        break;
      case "vat":
        this.downloadVAT(
          this.storedDocs.vatDoc != null ? this.storedDocs.vatDoc._id : 0
        );
        break;
      case "rm":
        this.downloadRM(
          this.storedDocs.rmDoc != null ? this.storedDocs.rmDoc._id : 0
        );
        break;
    }
  }

  @Watch("type", { immediate: true })
  async modalTypeChanged(): Promise<void> {
    console.log("Modal Details Type: ", this.type);
    if (this.type == "edit") {
      await this.getStoredData();
      setTimeout(this.asignStoredData(), 10);
    }
  }

  @Watch("accountNext", {
    immediate: true,
  })
  async onNextChanged(): Promise<void> {
    console.log("AccountNext", this.accountNext);
    if (this.accountNext == true) {
      if (this.type == "add") {
        try {
          this.loading = true;
          await this.UploadClosedCorpPersonal();
          await this.UploadClosedCorpCorpoDocuments();
          if (this.addFiles.length > 0) {
            await this.UploadCustomFiles();
          }

          JSEClient.setAccountDocs({
            idNumber: this.idType == "RSA ID" ? this.saIdNumber : null,
            passportNumber: this.idType == "Passport" ? this.saIdNumber : null,
            proofOfRegUploaded: this.regDocumentsUpload ? true : false,
            proofOfResUploaded: this.proofOfResidenceUpload ? true : false,
            regNumber: this.regNumber,
            taxNumber: this.taxNumber,
            vatNumber: this.vatRegNumber,
          });

          this.$notificationCreator.createSuccessNotification(
            "Supporting Documents Saved."
          );

          this.tab = true;
        } finally {
          this.loading = false;
        }
      }
      if (this.type == "edit") {
        try {
          this.loading = true;
          await this.UpdateClosedCorpPersonalDocs();
          await this.UpdateClosedCorpDocs();
          if (this.addFiles.length > 0) {
            await this.UploadCustomFiles();
            await this.UpdateCustomDocs();
          } else if (this.addFiles.length == 0) {
            await this.UpdateCustomDocs();
          }

          JSEClient.setAccountDocs({
            idNumber: this.idType == "RSA ID" ? this.saIdNumber : null,
            passportNumber: this.idType == "Passport" ? this.saIdNumber : null,
            proofOfRegUploaded: this.regDocumentsUpload ? true : false,
            proofOfResUploaded: this.proofOfResidenceUpload ? true : false,
            regNumber: this.regNumber,
            taxNumber: this.taxNumber,
            vatNumber: this.vatRegNumber,
          });

          this.$notificationCreator.createSuccessNotification(
            "Supporting Documents Updated."
          );
          //this.tab = true;
        } finally {
          this.accountNext = false;
          this.loading = false;
        }
      }
    }
  }

  async getBase64(file: Blob | File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  async getClientDetails(): Promise<void> {
    try {
      var clientId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        clientId = key;
      }

      if (clientId != null) {
        const res = await clientDetailsHook.getClientDetailByClient(clientId);

        this.storedAddress.streetAddress = res.streetAddress;
        this.storedAddress.streetAddress2 = res.streetAddress2;
        this.storedAddress.city = res.city;
        this.storedAddress.suburb = res.suburb;
        this.storedAddress.code = res.code;
        this.storedAddress.province = res.province;
        console.log("Get Client Address: ", this.storedAddress);
        return Promise.resolve();
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  }

  asignStoredData(): any {
    if (this.storedDocs.idDoc != null && this.storedDocs.idDoc != "") {
      this.idType = this.storedDocs.idDoc.identificationType;
      this.saIdNumber = this.storedDocs.idDoc.idNumber;
    }
    if (this.storedDocs.tnDoc != null && this.storedDocs.tnDoc != "") {
      this.taxNumber = this.storedDocs.tnDoc.taxNumber;
    }
    if (this.storedDocs.itDoc != null && this.storedDocs.itDoc != "") {
      this.incomeTax = this.storedDocs.itDoc.incomeTaxNumber;
    }

    if (this.storedDocs.regDoc != null) {
      this.regNumber = this.storedDocs.regDoc[0].registrationNumber;
    }

    if (this.storedDocs.vatDoc != null && this.storedDocs.vatDoc != "") {
      this.vatRegNumber = this.storedDocs.vatDoc.vatNumber;
    }
  }

  async getStoredData(): Promise<void> {
    try {
      this.loading = true;
      var closedCorpId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        closedCorpId = key;
      }

      if (closedCorpId != null) {
        const resID = await closedCorpHook.getClosedCorpIdDocumentByCode(
          closedCorpId
        );
        this.idType = resID.identificationType;
        this.saIdNumber = resID.idNumber;
        this.storedDocs.idDoc = resID;

        const resProofOfBank =
          await closedCorpHook.getClosedCorpPOBDocumentByCode(closedCorpId);
        this.storedDocs.proofOfBankDoc = resProofOfBank;

        const resPOB = await closedCorpHook.getClosedCorpBusinessByCode(
          closedCorpId
        );
        this.storedDocs.pobDoc = resPOB;

        const resTN = await closedCorpHook.getClosedCorpTaxByCode(closedCorpId);
        this.taxNumber = resTN.taxNumber;
        this.storedDocs.tnDoc = resTN;

        const resIT = await closedCorpHook.getClosedCorpTaxIncomeByCode(
          closedCorpId
        );
        this.incomeTax = resIT.incomeTaxNumber;
        this.storedDocs.itDoc = resIT;

        const resVAT = await closedCorpHook.getClosedCorpVat(closedCorpId);
        this.vatRegNumber = resVAT.vatNumber;
        this.storedDocs.vatDoc = resVAT;

        const resReg = await closedCorpHook.getClosedCorpReg(closedCorpId);
        if (resReg.length > 0) {
          this.regNumber = resReg[0].registrationNumber;
          this.storedDocs.regDoc = resReg;
          if (this.storedDocs.regDoc != null && this.storedDocs.regDoc != "") {
            this.storedDocs.regDoc.forEach((elem: any) => {
              elem.updateFile = null as File | null;
            });
          }
        } else if (resReg.length == 0) {
          this.storedDocs.regDoc = [{ _id: null, updateFile: null }];
        }

        const resCustom = await customFileHook.getCustomFileByCode(
          closedCorpId
        );
        this.storedDocs.addFiles = resCustom;
        if (
          this.storedDocs.addFiles != null &&
          this.storedDocs.addFiles != ""
        ) {
          this.items.push({ type: "add" });
          this.storedDocs.addFiles.forEach((elem: any) => {
            elem.updateFile = null as File | null;
          });
        }

        const resRM = await closedCorpHook.getClosedCorpMandateByCode(
          closedCorpId
        );
        this.storedDocs.rmDoc = resRM;

        console.log("Stored Closed Corp Docs: ", this.storedDocs);
        return Promise.resolve();
      }
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async UpdateClosedCorpPersonalDocs(): Promise<void> {
    try {
      var closedCorpId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        closedCorpId = key;
      }

      var resultID: string | ArrayBuffer | null = null;
      if (this.idUpload != null) {
        resultID = await this.getBase64(this.idUpload);
      }

      var resultIT: string | ArrayBuffer | null = null;
      if (this.incomeTaxUpload != null) {
        resultIT = await this.getBase64(this.incomeTaxUpload);
      }

      var resultPOB: string | ArrayBuffer | null = null;
      if (this.proofOfBankUpload != null) {
        resultPOB = await this.getBase64(this.proofOfBankUpload);
      }

      var closedCorpDocument: ClosedCorpPersonalDocsUpdate = {
        user: User.getLoggedInUser,
        closedCorpId: closedCorpId,
        identificationType: this.idType,
        authIdentificationType: null,
        authIdFileId: null,
        authIdFile: null,
        authSAIDNumber: null,
        idFileId:
          this.storedDocs.idDoc != undefined && this.idUpload != null
            ? this.storedDocs.idDoc._id
            : null,
        idFile: this.idUpload != null ? resultID : this.idUpload,
        saidNumber: this.saIdNumber,
        incomeTaxId:
          this.storedDocs.itDoc != undefined && this.incomeTaxUpload != null
            ? this.storedDocs.itDoc._id
            : null,
        incomeTax: this.incomeTax,
        incomeTaxUpload:
          this.incomeTaxUpload != null ? resultIT : this.incomeTaxUpload,
        pobId:
          this.storedDocs.proofOfBankDoc != undefined
            ? this.storedDocs.proofOfBankDoc._id
            : null,
        pobUpload:
          this.proofOfBankUpload != null ? resultPOB : this.proofOfBankUpload,
      };

      console.log("Closed Corp Docs: ", closedCorpId);
      const res = await closedCorpHook.UpdateClosedCorpPersonalDocuments(
        closedCorpDocument
      );

      return Promise.resolve();
    } catch (err: any) {
      //this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    }
  }

  async UpdateClosedCorpDocs(): Promise<void> {
    try {
      var closedCorpId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        closedCorpId = key;
      }

      var resultTN: string | ArrayBuffer | null = null;
      if (this.taxNumberUpload != null) {
        resultTN = await this.getBase64(this.taxNumberUpload);
      }
      var resultVR: string | ArrayBuffer | null = null;
      if (this.vatRegNumberUpload != null) {
        resultVR = await this.getBase64(this.vatRegNumberUpload);
      }

      var resultPOBA: string | ArrayBuffer | null = null;
      if (this.proofCCBusAddressUpload != null) {
        resultPOBA = await this.getBase64(this.proofCCBusAddressUpload);
      }

      var resultRM: string | ArrayBuffer | null = null;
      if (this.resolution_mandate != null) {
        resultRM = await this.getBase64(this.resolution_mandate);
      }

      if (this.storedDocs.regDoc.length <= 1) {
        var resultRegDoc: string | ArrayBuffer | null = null;
        if (
          this.storedDocs.regDoc != null &&
          this.storedDocs.regDoc != "" &&
          this.storedDocs.regDoc[0].updateFile != null
        ) {
          resultRegDoc = await this.getBase64(
            new Blob([this.storedDocs.regDoc[0].updateFile])
          );
        }

        var closedCorpDocument: ClosedCorpDocsUpdate = {
          user: User.getLoggedInUser,
          closedCorpId: closedCorpId,
          businessUploadId:
            this.storedDocs.pobDoc != undefined &&
            this.proofCCBusAddressUpload != null
              ? this.storedDocs.pobDoc._id
              : null,
          businessUpload:
            this.proofCCBusAddressUpload != null
              ? resultPOBA
              : this.proofCCBusAddressUpload,
          vatUploadId:
            this.storedDocs.vatDoc != undefined &&
            this.vatRegNumberUpload != null
              ? this.storedDocs.vatDoc._id
              : null,
          vatRegNumber: this.vatRegNumber,
          vatUpload:
            this.vatRegNumberUpload != null
              ? resultVR
              : this.vatRegNumberUpload,
          regNumber: this.regNumber != null ? this.regNumber : "",
          regUploadId:
            resultRegDoc != null && this.storedDocs.regDoc != ""
              ? this.storedDocs.regDoc[0]._id
              : null,
          regUpload:
            resultRegDoc != null && this.storedDocs.regDoc != ""
              ? resultRegDoc
              : null,
          taxNumberUploadId:
            this.storedDocs.tnDoc != undefined && this.taxNumberUpload != null
              ? this.storedDocs.tnDoc._id
              : null,
          taxNumber: this.taxNumber,
          taxNumberUpload:
            this.taxNumberUpload != null ? resultTN : this.taxNumberUpload,
          mandateId:
            this.storedDocs.rmDoc != undefined &&
            this.resolution_mandate != null
              ? this.storedDocs.rmDoc._id
              : null,
          mandate:
            this.resolution_mandate != null
              ? resultRM
              : this.resolution_mandate,
        };

        console.log("Closed Corp Docs: ", closedCorpId);
        const res = await closedCorpHook.UpdateClosedCorpCorpoDocuments(
          closedCorpDocument
        );
      } else if (this.storedDocs.regDoc.length < 1) {
        if (this.storedDocs.regDoc != null && this.storedDocs.regDoc != "") {
          this.storedDocs.regDoc.forEach(async (elem: any) => {
            var resultRegDoc: string | ArrayBuffer | null = null;
            resultRegDoc = await this.getBase64(new Blob([elem.updateFile]));

            var closedCorpDocument: ClosedCorpDocsUpdate = {
              user: User.getLoggedInUser,
              closedCorpId: closedCorpId,
              businessUploadId:
                this.storedDocs.pobDoc != undefined &&
                this.proofCCBusAddressUpload != null
                  ? this.storedDocs.pobDoc._id
                  : null,
              businessUpload:
                this.proofCCBusAddressUpload != null
                  ? resultPOBA
                  : this.proofCCBusAddressUpload,
              vatUploadId:
                this.storedDocs.vatDoc != undefined &&
                this.vatRegNumberUpload != null
                  ? this.storedDocs.vatDoc._id
                  : null,
              vatRegNumber: this.vatRegNumber,
              vatUpload:
                this.vatRegNumberUpload != null
                  ? resultVR
                  : this.vatRegNumberUpload,
              regNumber: this.regNumber != null ? this.regNumber : "",
              regUploadId:
                this.storedDocs.regDoc != undefined && resultRegDoc != null
                  ? elem._id
                  : null,
              regUpload:
                this.storedDocs.regDoc != null && this.storedDocs.regDoc != ""
                  ? resultRegDoc
                  : null,
              taxNumberUploadId:
                this.storedDocs.tnDoc != undefined &&
                this.taxNumberUpload != null
                  ? this.storedDocs.tnDoc._id
                  : null,
              taxNumber: this.taxNumber,
              taxNumberUpload:
                this.taxNumberUpload != null ? resultTN : this.taxNumberUpload,
              mandateId:
                this.storedDocs.rmDoc != undefined &&
                this.resolution_mandate != null
                  ? this.storedDocs.rmDoc._id
                  : null,
              mandate:
                this.resolution_mandate != null
                  ? resultRM
                  : this.resolution_mandate,
            };

            console.log("Closed Corp Docs: ", closedCorpId);
            const res = await closedCorpHook.UpdateClosedCorpCorpoDocuments(
              closedCorpDocument
            );
          });
        }
      }
      return Promise.resolve();
    } catch (err: any) {
      //this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    }
  }

  async UploadClosedCorpPersonal(): Promise<void> {
    try {
      var closedCorpId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        closedCorpId = key;
      }

      var idArray = [];
      const promisesID: Promise<string | ArrayBuffer | null>[] = [];
      idArray.push(this.idUpload);
      idArray.forEach(async (elem: any) => {
        promisesID.push(this.getBase64(new Blob([elem])));
      });
      const resultID = await Promise.all(promisesID);

      var incomeTaxArray = [];
      const promisesIT: Promise<string | ArrayBuffer | null>[] = [];
      incomeTaxArray.push(this.incomeTaxUpload);
      incomeTaxArray.forEach(async (elem: any) => {
        promisesIT.push(this.getBase64(new Blob([elem])));
      });
      const resultIT = await Promise.all(promisesIT);

      var proofOfBankArray = [];
      const promisesPOB: Promise<string | ArrayBuffer | null>[] = [];
      proofOfBankArray.push(this.proofOfBankUpload);
      proofOfBankArray.forEach(async (elem: any) => {
        promisesPOB.push(this.getBase64(new Blob([elem])));
      });
      const resultProofOfBank = await Promise.all(promisesPOB);

      var closedCorpDocument: ClosedCorpPersonalDocs = {
        user: User.getLoggedInUser,
        closedCorpId: closedCorpId,
        identificationType: this.idType,
        authIdentificationType: null,
        authIdFile: null,
        authSAIDNumber: null,
        idFile: this.idUpload != null ? resultID : this.idUpload,
        saidNumber: this.saIdNumber,
        incomeTax: this.incomeTax,
        incomeTaxUpload:
          this.incomeTaxUpload != null ? resultIT : this.incomeTaxUpload,
        pobUpload:
          this.proofOfBankUpload != null
            ? resultProofOfBank
            : this.proofOfBankUpload,
      };

      console.log("Closed Corp Personal Docs: ", ClosedCorpDocuments);
      const res = await closedCorpHook.UploadClosedCorpPersonalDocuments(
        closedCorpDocument
      );

      return Promise.resolve();
    } catch (err: any) {
      this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    }
  }

  async UploadClosedCorpCorpoDocuments(): Promise<void> {
    try {
      var closedCorpId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        closedCorpId = key;
      }
      const promisesRegDoc: Promise<string | ArrayBuffer | null>[] = [];
      if (this.regDocumentsUpload != null) {
        this.regDocumentsUpload.forEach(async (elem: any) => {
          promisesRegDoc.push(this.getBase64(new Blob([elem])));
        });
      }
      const resultRegDoc = await Promise.all(promisesRegDoc);

      var taxNumberArray = [];
      const promisesTN: Promise<string | ArrayBuffer | null>[] = [];
      taxNumberArray.push(this.taxNumberUpload);
      taxNumberArray.forEach(async (elem: any) => {
        promisesTN.push(this.getBase64(new Blob([elem])));
      });
      const resultTN = await Promise.all(promisesTN);

      var vatRegArray = [];
      const promisesVR: Promise<string | ArrayBuffer | null>[] = [];
      vatRegArray.push(this.vatRegNumberUpload);
      vatRegArray.forEach(async (elem: any) => {
        promisesVR.push(this.getBase64(new Blob([elem])));
      });
      const resultVR = await Promise.all(promisesVR);

      var POBAArray = [];
      const promisesPOBA: Promise<string | ArrayBuffer | null>[] = [];
      POBAArray.push(this.proofCCBusAddressUpload);
      POBAArray.forEach(async (elem: any) => {
        promisesPOBA.push(this.getBase64(new Blob([elem])));
      });
      const resultPOBA = await Promise.all(promisesPOBA);

      var RMArray = [];
      const promisesRM: Promise<string | ArrayBuffer | null>[] = [];
      RMArray.push(this.resolution_mandate);
      RMArray.forEach(async (elem: any) => {
        promisesRM.push(this.getBase64(new Blob([elem])));
      });
      const resultRM = await Promise.all(promisesRM);

      var closedCorpDocument: ClosedCorpDocs = {
        user: User.getLoggedInUser,
        closedCorpId: closedCorpId,
        businessUpload:
          this.proofCCBusAddressUpload != null
            ? resultPOBA
            : this.proofCCBusAddressUpload,
        vatRegNumber: this.vatRegNumber,
        vatUpload:
          this.vatRegNumberUpload != null ? resultVR : this.vatRegNumberUpload,
        regNumber: this.regNumber != null ? this.regNumber : "",
        regUpload:
          this.regDocumentsUpload != null
            ? resultRegDoc
            : this.regDocumentsUpload,
        taxNumber: this.taxNumber,
        taxNumberUpload:
          this.taxNumberUpload != null ? resultTN : this.taxNumberUpload,
        mandate:
          this.resolution_mandate != null ? resultRM : this.resolution_mandate,
      };

      console.log("Closed Corp Docs: ", closedCorpDocument);
      const res = await closedCorpHook.UploadClosedCorpCorpoDocuments(
        closedCorpDocument
      );

      return Promise.resolve();
    } catch (err: any) {
      this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    }
  }

  async UploadCustomFiles(): Promise<void> {
    var clientId: string | null = null;
    const key = localStorage.getItem("ClientFile/entityId");
    if (key != null) {
      clientId = key;
    }

    if (this.addFiles.length > 0) {
      var customDoc: CustomDocsList[] = [];

      for (let file of this.addFiles) {
        if (file.file != null) {
          customDoc.push({
            user: User.getLoggedInUser,
            entityId: clientId,
            number: file.info,
            fileType: file.type,
            customFile: await this.getBase64(file.file),
          });
        }
      }

      console.log("Custom Docs: ", customDoc);
      const res = await customFileHook
        .UploadCustomDocumentsList(customDoc)
        .catch((err) => {
          this.accountNext = false;
          this.tab = false;
          return Promise.reject(err);
        });
      return Promise.resolve();
    }
  }

  async UpdateCustomDocs(): Promise<void> {
    try {
      var closedCorpId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        closedCorpId = key;
      }

      if (this.storedDocs.addFiles != null && this.storedDocs.addFiles != "") {
        this.storedDocs.addFiles.forEach(async (elem: any) => {
          if (elem.updateFile != null) {
            var resultCustomFiles: string | ArrayBuffer | null = null;
            resultCustomFiles = await this.getBase64(
              new Blob([elem.updateFile])
            );

            var customDocs: CustomDocsUpdate = {
              user: User.getLoggedInUser,
              entityId: closedCorpId,
              number: elem.number,
              fileType: elem.fileType,
              customFileId:
                this.storedDocs.addFiles != undefined ? elem._id : null,
              customFile:
                this.storedDocs.addFiles != undefined
                  ? resultCustomFiles
                  : null,
            };

            console.log("Custom Docs: ", customDocs);
            const res = await customFileHook.UpdateCustomFile(customDocs);
            return Promise.resolve();
          }
        });
      }
    } catch (err: any) {
      this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    }
  }

  async downloadId(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await closedCorpHook
          .DownloadCCIdDocument(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download ID: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadProofOfBank(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await closedCorpHook
          .DownloadCCPOBDocument(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download POR: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadPOB(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await closedCorpHook
          .DownloadCCBusinessDocument(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download POR: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadTN(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await closedCorpHook
          .DownloadCCTax(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download TN: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadIT(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await closedCorpHook
          .DownloadCCIncomeTax(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download IT: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadVAT(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await closedCorpHook
          .DownloadCCVATDocument(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download IT: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadReg(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await closedCorpHook
          .DownloadReg(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download Reg: ", err);
            return Promise.reject(err);
          });
        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File(s) Has Been Uploaded"
      );
    }
  }

  async downloadRM(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await closedCorpHook
          .DownloadMandate(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download Reg: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadCustom(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await customFileHook
          .DownloadCustomFile(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download Reg: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }
}
