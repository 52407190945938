
import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { Trader_CREATE, Trader, Market } from "models";
import Hooks from "@/hooks";

const { Traders } = Hooks;
const traderHook = Traders();

@Component({})
export default class AddEditTraders extends Vue {
  @PropSync("open") modal!: boolean;
  @Prop() readonly data!: {
    type: string;
    fields: any;
    markets: Market[];
  };
  valid = false;
  rules = {
    required: (value: string): boolean | string =>
      !!value || "This field is Required.",
    email: (value: string): boolean | string => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },
  };

  @Watch("data.fields", { immediate: true })
  modalDataChanged(): void {
    console.log("This modal fields:", this.data.fields);
  }

  marketsFilter(item: Market, queryText: string): boolean {
    // console.log("Searched ", queryText, itemText, item);
    if (
      (item != null &&
        item.marketName
          .toLocaleLowerCase()
          .includes(queryText.toLocaleLowerCase())) ||
      item.marketCode
        .toLocaleLowerCase()
        .includes(queryText.toLocaleLowerCase())
    ) {
      return true;
    } else return false;
  }

  closeModal(): void {
    this.modal = false;
  }

  clearFields(): void {
    const refForm: any = this.$refs.form;
    refForm.reset();
  }

  async addItem(): Promise<void> {
    console.log("Add info: ", this.data.fields);

    try {
      var trader: Trader_CREATE;
      trader = {
        marketId: this.data.fields.marketId,
        branch: this.data.fields.branch,
        organisation: this.data.fields.organisation,
        dealerCode: this.data.fields.dealerCode,
        traderName: this.data.fields.traderName,
        traderEmail: this.data.fields.traderEmail,
      };
      const res = await traderHook.addNewTrader(trader);
      this.$notificationCreator.createSuccessNotification("Trader Added.");

      return Promise.resolve();
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.$emit("refresh");
      this.closeModal();
    }
  }

  async editItem(): Promise<void> {
    console.log("Edit info: ", this.data.fields);

    try {
      var trader: Trader;
      trader = {
        _id: this.data.fields._id,
        marketId: this.data.fields.marketId,
        branch: this.data.fields.branch,
        organisation: this.data.fields.organisation,
        dealerCode: this.data.fields.dealerCode,
        traderName: this.data.fields.traderName,
        traderEmail: this.data.fields.traderEmail,
        createdBy: this.data.fields.createdBy,
        createdDate: this.data.fields.createdDate,
        updatedBy: this.data.fields.updatedBy,
        updatedDate: this.data.fields.updatedDate,
      };
      const res = await traderHook.updateTrader(trader);

      this.$notificationCreator.createSuccessNotification("Trader Updated.");
      return Promise.resolve();
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.$emit("refresh");
      this.closeModal();
    }
  }
}
