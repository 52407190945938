import { axiosInstance } from "@/plugins/axios";
import {
  ClosedCorpEntityDetail,
  ClientAccount_CREATE,
  ClientOverview,
} from "models";

const PartnershipAccounts = () => {
  const baseUrl = "/api/Partnership";

  const getAllPartnershipAccount = async (): Promise<
    ClosedCorpEntityDetail[]
  > => {
    const res = await axiosInstance.get(baseUrl + "/getAllPartnerAccount");
    return Promise.resolve(res.data);
  };

  const getPPOverview = async (): Promise<ClientOverview[]> => {
    const res = await axiosInstance.get(baseUrl + "/getPPOverview");
    return Promise.resolve(res.data);
  };

  const getPartnershipAccountByCode = async (
    code: string
  ): Promise<ClosedCorpEntityDetail> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPartnerAccountByCode?code=${code}`
    );
    return Promise.resolve(res.data);
  };

  const addNewPartnershipAccount = async (
    clientAccount: ClientAccount_CREATE
  ): Promise<any> => {
    const res = await axiosInstance.post(baseUrl + "/addNewPartnerAccount", {
      accountName: clientAccount.accountName,
      contactNumber: clientAccount.contactNumber,
      contactPerson: clientAccount.contactPerson,
      contactPersonEmail: clientAccount.contactPersonEmail,
      dateOfRegistration: clientAccount.dateOfRegistration,
      country: clientAccount.country,
      email: clientAccount.email,
      branchTrader: clientAccount.branchTrader,
      enumStatus: clientAccount.enumStatus,
      deleted_At: clientAccount.deleted_At,
      staffAccount: clientAccount.staffAccount,
    });
    return Promise.resolve(res.data);
  };

  const updatePartnershipAccount = async (
    clientAccount: ClosedCorpEntityDetail
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/updatePartnerAccount", {
      accountName: clientAccount.accountName,
      contactNumber: clientAccount.contactNumber,
      contactPerson: clientAccount.contactPerson,
      contactPersonEmail: clientAccount.contactPersonEmail,
      dateOfRegistration: clientAccount.dateOfRegistration,
      country: clientAccount.country,
      email: clientAccount.email,
      branchTrader: clientAccount.branchTrader,
      enumStatus: clientAccount.enumStatus,
      entityId: clientAccount.entityId,
      createdBy: clientAccount.createdBy,
      createdDate: clientAccount.createdDate,
      updatedBy: clientAccount.updatedBy,
      updatedDate: clientAccount.updatedDate,
      _id: clientAccount._id,
      deleted_At: clientAccount.deleted_At,
      staffAccount: clientAccount.staffAccount,
    });
    return Promise.resolve(res.data);
  };

  const deletePartnershipAccount = async (
    clientAccount: ClosedCorpEntityDetail
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/deletePartnerAccount", {
      accountName: clientAccount.accountName,
      contactNumber: clientAccount.contactNumber,
      contactPerson: clientAccount.contactPerson,
      contactPersonEmail: clientAccount.contactPersonEmail,
      dateOfRegistration: clientAccount.dateOfRegistration,
      country: clientAccount.country,
      email: clientAccount.email,
      branchTrader: clientAccount.branchTrader,
      enumStatus: clientAccount.enumStatus,
      entityId: clientAccount.entityId,
      createdBy: clientAccount.createdBy,
      createdDate: clientAccount.createdDate,
      updatedBy: clientAccount.updatedBy,
      updatedDate: clientAccount.updatedDate,
      _id: clientAccount._id,
      deleted_At: clientAccount.deleted_At,
      staffAccount: clientAccount.staffAccount,
    });
    return Promise.resolve(res.data);
  };

  return {
    getAllPartnershipAccount,
    getPPOverview,
    getPartnershipAccountByCode,
    addNewPartnershipAccount,
    updatePartnershipAccount,
    deletePartnershipAccount,
  };
};
export default PartnershipAccounts;
