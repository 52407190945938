
import dayjs from "dayjs";
import { Component, PropSync, Vue, Watch } from "vue-property-decorator";
import Hooks from "@/hooks";
import {
  BreakdownOfFee,
  RatingCode,
  AmendModel,
  Market,
  AmendModelPDF,
} from "models";

const {
  Amend,
  RatingCodes,
  FeeStructures,
  ClosedCorpEntityDetails,
  ClientAccounts,
  PartnershipAccount,
  PrivateAccount,
  TrustAccount,
  Excel,
  AmendAccountPDF,
  Markets,
} = Hooks;
const amendHook = Amend();
const ratingCodeHook = RatingCodes();
const feeStructureHook = FeeStructures();
const closedCorpHook = ClosedCorpEntityDetails();
const clientHook = ClientAccounts();
const partnershipHook = PartnershipAccount();
const privateHook = PrivateAccount();
const trustHook = TrustAccount();
const excelHook = Excel();
const amendPDFHook = AmendAccountPDF();
const marketsHook = Markets();

@Component({})
export default class AmendAccount extends Vue {
  @PropSync("open") modal!: boolean;
  valid = false;
  client: any | null = null;
  date = dayjs().format("YYYY-MM-DD");
  dateMenu = false;
  safexCode: string | null = null;
  clientName: string | null = null;
  validClient = false;
  clientType: string | null = null;
  showCard = false;
  amendFees = false;
  ratingCodes: RatingCode[] = [];
  oldfeeAmount: number | null = null;
  oldRatingCode: number | null = null;
  feeAmount: number | null = null;
  feeStructure: BreakdownOfFee | null = null;
  email = false;
  currentEmail: string | null = null;
  newEmail: string | null = null;
  closeOffAccount = false;
  closeOffReason: string | null = null;
  reinstateAccount = false;
  saveModal = false;
  saveAsPDF = false;
  saveAsExcel = false;
  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
    email: (value: string): boolean | string => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },
  };

  uppercase(): void {
    this.safexCode =
      this.safexCode != null ? this.safexCode.toUpperCase() : null;
  }

  @Watch("modal", { immediate: true })
  async modalChanged(): Promise<void> {
    if (this.modal == true) {
      this.date = dayjs().format("YYYY-MM-DD");
    }
  }

  @Watch("safexCode", { immediate: true })
  async safexCodeChanged(): Promise<void> {
    if (this.safexCode == null) {
      this.validClient = false;
    }
  }

  @Watch("clientName", { immediate: false })
  async clientNameChanged(): Promise<void> {
    await this.amendFeesChanged();
  }

  @Watch("amendFees", { immediate: false })
  async amendFeesChanged(): Promise<void> {
    if (this.clientName != null) {
      await this.getRatingCodes();
      if (this.client.breakdown != null) {
        this.feeStructure = this.client.breakdown;
        if (this.feeStructure != null || this.feeStructure != undefined) {
          this.feeStructure.ratingCodeId = 0;
        }
        this.getFeeAmountForRatingCodeOld();
        console.log("oldRatingCode", this.oldRatingCode);
      }
    }
  }

  closeModal(): void {
    this.clear();
    this.client = null;
    this.clientType = null;
    this.clientName = null;
    this.feeStructure = null;
    this.oldfeeAmount = null;
    this.oldRatingCode = null;
    this.currentEmail = null;
    this.newEmail = null;
    this.closeOffReason = null;
    this.validClient = false;
    this.saveAsPDF = false;
    this.saveAsExcel = false;
    this.modal = false;
  }

  clear(): void {
    const refForm: any = this.$refs.form;
    refForm.reset();
    this.valid = false;
  }

  validateCheckBoxes(i: string): void {
    this.showCard = true;
    if (
      this.amendFees == false &&
      this.email == false &&
      this.closeOffAccount == false &&
      this.reinstateAccount == false
    ) {
      this.showCard = false;
    }

    switch (i) {
      case "fees":
        this.email = false;
        this.closeOffAccount = false;
        this.reinstateAccount = false;
        this.currentEmail = null;
        this.newEmail = null;
        this.closeOffReason = null;
        this.saveAsPDF = false;
        this.saveAsExcel = false;
        break;
      case "email":
        this.amendFees = false;
        this.closeOffAccount = false;
        this.reinstateAccount = false;
        this.feeStructure = null;
        this.oldfeeAmount = null;
        this.oldRatingCode = null;
        this.closeOffReason = null;
        this.saveAsPDF = false;
        this.saveAsExcel = false;
        break;
      case "closeAccount":
        this.amendFees = false;
        this.email = false;
        this.reinstateAccount = false;
        this.feeStructure = null;
        this.oldfeeAmount = null;
        this.oldRatingCode = null;
        this.currentEmail = null;
        this.newEmail = null;
        this.saveAsPDF = false;
        this.saveAsExcel = false;
        break;
      case "reinstateAccount":
        this.amendFees = false;
        this.email = false;
        this.closeOffAccount = false;
        this.feeStructure = null;
        this.oldfeeAmount = null;
        this.oldRatingCode = null;
        this.currentEmail = null;
        this.newEmail = null;
        this.closeOffReason = null;
        this.saveAsPDF = false;
        this.saveAsExcel = false;
        break;
    }
  }

  getFeeAmountForRatingCode(): void {
    var rCodeId = 0;
    if (this.feeStructure != null) {
      rCodeId =
        this.feeStructure.ratingCodeId != null
          ? this.feeStructure.ratingCodeId
          : 0;
    }
    var result = this.ratingCodes.find((elem) => elem.id == rCodeId);
    if (result != undefined) {
      this.feeAmount = result.amount;
    }
  }

  getRatingCodeForFeeAmount(): void {
    var result = this.ratingCodes.find((elem) => elem.amount == this.feeAmount);
    if (result != undefined && this.feeStructure != null) {
      this.feeStructure.ratingCodeId = result.id;
    }
  }

  getFeeAmountForRatingCodeOld(): void {
    var rCodeId = 0;
    if (this.oldRatingCode != null) {
      rCodeId = this.oldRatingCode;
    }
    var result = this.ratingCodes.find((elem) => elem.id == rCodeId);
    if (result != undefined) {
      this.oldfeeAmount = result.amount;
    }
  }

  getRatingCodeForFeeAmountOld(): void {
    var result = this.ratingCodes.find(
      (elem) => elem.amount == this.oldfeeAmount
    );
    if (result != undefined && this.feeStructure != null) {
      this.oldRatingCode = result.id;
    }
  }

  getTotal(): void {
    if (this.feeStructure != null) {
      this.feeStructure.total = 0;
      var member =
        this.feeStructure.memberAmount != null
          ? this.feeStructure.memberAmount
          : 0;
      var broker =
        this.feeStructure.brokerAmount != null
          ? this.feeStructure.brokerAmount
          : 0;
      var thirdParty =
        this.feeStructure.thirdPartyAmount != null
          ? this.feeStructure.thirdPartyAmount
          : 0;
      var fourthParty =
        this.feeStructure.fourthPartyAmount != null
          ? this.feeStructure.fourthPartyAmount
          : 0;
      this.feeStructure.total =
        Number(member) +
        Number(broker) +
        Number(thirdParty) +
        Number(fourthParty);
    }
  }

  async getSafexData(safexCode: string): Promise<void> {
    try {
      const res = await amendHook.getAmendedAccount(safexCode);
      console.log("Safex Data: ", res);
      if (res != null) {
        this.client = res;
        if (this.client.client != undefined) {
          this.clientType = "CRM";
          this.clientName = this.client.client.accountName;
          this.currentEmail = this.client.client.email;
        } else if (this.client.closedCorp != undefined) {
          this.clientType = "CC";
          this.clientName = this.client.closedCorp.accountName;
          this.currentEmail = this.client.closedCorp.email;
        } else if (this.client.trust != undefined) {
          this.clientType = "TT";
          this.clientName = this.client.trust.accountName;
          this.currentEmail = this.client.trust.email;
        } else if (this.client.privateComp != undefined) {
          this.clientType = "PC";
          this.clientName = this.client.privateComp.accountName;
          this.currentEmail = this.client.privateComp.email;
        } else if (this.client.partner != undefined) {
          this.clientType = "PP";
          this.clientName = this.client.partner.accountName;
          this.currentEmail = this.client.partner.email;
        }
        if (res.breakdown != null) {
          this.oldRatingCode = res.breakdown.ratingCodeId;
          this.validClient = true;
        } else {
          this.$notificationCreator.createErrorNotification(
            `Client Data Associated with Safex Code Entered is Incomplete, Try Again Later.`
          );
        }
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getRatingCodes(): Promise<void> {
    try {
      const res = await ratingCodeHook.getAllRatingCodes();
      console.log("RATING CODES:", this.ratingCodes);
      this.ratingCodes = res;

      console.log(this.ratingCodes);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getMarketById(id: number): Promise<Market> {
    try {
      const res = await marketsHook.getMarketById(id);
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async save(): Promise<void> {
    if (this.amendFees == true) {
      await this.updateFees();
    }
    if (this.email == true) {
      await this.updateEmail();
    }
    if (this.closeOffAccount == true) {
      await this.closeOffAcc();
    }
    if (this.reinstateAccount == true) {
      await this.reinstateAcc();
    }
  }

  async updateFees(): Promise<void> {
    try {
      console.log("Fee Structure Details: ", this.feeStructure);

      const resFeeStructure = await feeStructureHook.updateFeeStructure(
        this.feeStructure as any
      );
      this.$notificationCreator.createSuccessNotification(
        "Fees Structure Amended"
      );
      var result = this.ratingCodes.find(
        (elem) => elem.id == this.feeStructure?.ratingCodeId
      );
      var oldRatingCode = this.ratingCodes.find(
        (elem) => elem.id == this.oldRatingCode
      );
      var market: Market | null = null;
      if (result != undefined) {
        market = await this.getMarketById(result.marketId);
      }
      var toRatingCode = this.ratingCodes.find(
        (elem) => elem.id == this.feeStructure?.ratingCodeId
      );

      var amend: AmendModelPDF = {
        market: market != null ? market.marketCode : null,
        changeEmail: false,
        changeStructure: true,
        safexCode: this.safexCode,
        fromEmail: null,
        toEmail: null,
        fromFee: String(this.oldfeeAmount),
        toFee: String(this.feeAmount),
        toRatingCode: toRatingCode != undefined ? toRatingCode.name : null,
        fromRatingCode: oldRatingCode != undefined ? oldRatingCode.name : null,
        logoFileId: 1,
        closeAccount: false,
      };

      if (this.saveAsExcel == true) {
        await this.DownloadAmendExcel(amend);
      }
      if (this.saveAsPDF == true) {
        await this.DownloadAmendPDF(amend);
      }
      return Promise.resolve();
    } catch (err: any) {
      return Promise.reject(err);
    }
  }

  async updateEmail(): Promise<void> {
    try {
      switch (this.clientType) {
        case "CRM":
          this.client.client.email = this.newEmail;
          await clientHook.updateClientAccount(this.client.client);
          this.$notificationCreator.createSuccessNotification(
            "Email Address Amended."
          );
          break;
        case "CC":
          this.client.closedCorp.email = this.newEmail;
          await closedCorpHook.updateClosedCorp(this.client.closedCorp);
          this.$notificationCreator.createSuccessNotification(
            "Email Address Amended."
          );
          break;
        case "TT":
          this.client.trust.email = this.newEmail;
          await trustHook.updateTrustAccount(this.client.trust);
          this.$notificationCreator.createSuccessNotification(
            "Email Address Amended."
          );
          break;
        case "PC":
          this.client.privateComp.email = this.newEmail;
          await privateHook.updatePrivateCompAccount(this.client.privateComp);
          this.$notificationCreator.createSuccessNotification(
            "Email Address Amended."
          );
          break;
        case "PP":
          this.client.partner.email = this.newEmail;
          await partnershipHook.updatePartnershipAccount(this.client.partner);
          this.$notificationCreator.createSuccessNotification(
            "Email Address Amended."
          );
          break;
      }

      var RatingCodeId: number | null = null;
      if (this.feeStructure != null) {
        if (this.feeStructure.ratingCodeId == 0) {
          RatingCodeId = this.oldRatingCode;
        } else {
          RatingCodeId = this.feeStructure.ratingCodeId;
        }
      }
      var result = this.ratingCodes.find((elem) => elem.id == RatingCodeId);
      var market: Market | null = null;
      if (result != undefined) {
        market = await this.getMarketById(result.marketId);
      }

      var amend: AmendModelPDF = {
        market: market != null ? market.marketCode : null,
        changeEmail: true,
        changeStructure: false,
        safexCode: this.safexCode,
        fromEmail: this.currentEmail,
        toEmail: this.newEmail,
        fromFee: null,
        toFee: null,
        closeAccount: false,
        toRatingCode: null,
        fromRatingCode: null,
        logoFileId: 1,
      };

      if (this.saveAsExcel == true) {
        await this.DownloadAmendExcel(amend);
      }
      if (this.saveAsPDF == true) {
        await this.DownloadAmendPDF(amend);
      }

      return Promise.resolve();
    } catch (err: any) {
      return Promise.reject(err);
    }
  }

  async closeOffAcc(): Promise<void> {
    try {
      switch (this.clientType) {
        case "CRM":
          this.client.client.deleted_At = dayjs().format("YYYY-MM-DD");
          await clientHook.updateClientAccount(this.client.client);
          this.$notificationCreator.createSuccessNotification(
            "Account Closed Off Successfully."
          );
          break;
        case "CC":
          this.client.closedCorp.deleted_At = dayjs().format("YYYY-MM-DD");
          await closedCorpHook.updateClosedCorp(this.client.closedCorp);
          this.$notificationCreator.createSuccessNotification(
            "Account Closed Off Successfully."
          );
          break;
        case "TT":
          this.client.trust.deleted_At = dayjs().format("YYYY-MM-DD");
          await trustHook.updateTrustAccount(this.client.trust);
          this.$notificationCreator.createSuccessNotification(
            "Account Closed Off Successfully."
          );
          break;
        case "PC":
          this.client.privateComp.deleted_At = dayjs().format("YYYY-MM-DD");
          await privateHook.updatePrivateCompAccount(this.client.privateComp);
          this.$notificationCreator.createSuccessNotification(
            "Account Closed Off Successfully."
          );
          break;
        case "PP":
          this.client.partner.deleted_At = dayjs().format("YYYY-MM-DD");
          await partnershipHook.updatePartnershipAccount(this.client.partner);
          this.$notificationCreator.createSuccessNotification(
            "Account Closed Off Successfully."
          );
          break;
      }

      var RatingCodeId: number | null = null;
      if (this.feeStructure != null) {
        if (this.feeStructure.ratingCodeId == 0) {
          RatingCodeId = this.oldRatingCode;
        } else {
          RatingCodeId = this.feeStructure.ratingCodeId;
        }
      }
      var result = this.ratingCodes.find((elem) => elem.id == RatingCodeId);
      var market: Market | null = null;
      if (result != undefined) {
        market = await this.getMarketById(result.marketId);
      }

      var amend: AmendModelPDF = {
        market: market != null ? market.marketCode : null,
        changeEmail: false,
        changeStructure: false,
        safexCode: this.safexCode,
        fromEmail: null,
        toEmail: null,
        fromFee: null,
        toFee: null,
        closeAccount: true,
        toRatingCode: null,
        fromRatingCode: null,
        logoFileId: 1,
      };

      if (this.saveAsExcel == true) {
        await this.DownloadAmendExcel(amend);
      }
      if (this.saveAsPDF == true) {
        await this.DownloadAmendPDF(amend);
      }

      return Promise.resolve();
    } catch (err: any) {
      return Promise.reject(err);
    }
  }

  async reinstateAcc(): Promise<void> {
    try {
      switch (this.clientType) {
        case "CRM":
          this.client.client.deleted_At = null;
          await clientHook.updateClientAccount(this.client.client);
          this.$notificationCreator.createSuccessNotification(
            "Account Reinstated Successfully."
          );
          break;
        case "CC":
          this.client.closedCorp.deleted_At = null;
          await closedCorpHook.updateClosedCorp(this.client.closedCorp);
          this.$notificationCreator.createSuccessNotification(
            "Account Reinstated Successfully."
          );
          break;
        case "TT":
          this.client.trust.deleted_At = null;
          await trustHook.updateTrustAccount(this.client.trust);
          this.$notificationCreator.createSuccessNotification(
            "Account Reinstated Successfully."
          );
          break;
        case "PC":
          this.client.privateComp.deleted_At = null;
          await privateHook.updatePrivateCompAccount(this.client.privateComp);
          this.$notificationCreator.createSuccessNotification(
            "Account Reinstated Successfully."
          );
          break;
        case "PP":
          this.client.partner.deleted_At = null;
          await partnershipHook.updatePartnershipAccount(this.client.partner);
          this.$notificationCreator.createSuccessNotification(
            "Account Reinstated Successfully."
          );
          break;
      }

      var RatingCodeId: number | null = null;
      if (this.feeStructure != null) {
        if (this.feeStructure.ratingCodeId == 0) {
          RatingCodeId = this.oldRatingCode;
        } else {
          RatingCodeId = this.feeStructure.ratingCodeId;
        }
      }
      var result = this.ratingCodes.find((elem) => elem.id == RatingCodeId);
      var market: Market | null = null;
      if (result != undefined) {
        market = await this.getMarketById(result.marketId);
      }

      var amend: AmendModelPDF = {
        market: market != null ? market.marketCode : null,
        changeEmail: false,
        changeStructure: false,
        safexCode: this.safexCode,
        fromEmail: null,
        toEmail: null,
        fromFee: null,
        toRatingCode: null,
        fromRatingCode: null,
        logoFileId: 1,
        toFee: null,
        closeAccount: false,
      };

      if (this.saveAsExcel == true) {
        await this.DownloadAmendExcel(amend);
      }
      if (this.saveAsPDF == true) {
        await this.DownloadAmendPDF(amend);
      }

      return Promise.resolve();
    } catch (err: any) {
      return Promise.reject(err);
    }
  }

  async DownloadAmendExcel(amend: AmendModelPDF): Promise<void> {
    try {
      const res = await excelHook
        .DownloadAmendAccount(amend)
        .then((response: any) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Amend Account ${dayjs().format("YYYY-MM-DD")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
        });

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async DownloadAmendPDF(amend: AmendModel): Promise<void> {
    try {
      var oldRatingCode = this.ratingCodes.find(
        (elem) => elem.id == this.oldRatingCode
      );
      var toRatingCode = this.ratingCodes.find(
        (elem) => elem.id == this.feeStructure?.ratingCodeId
      );
      var account: AmendModelPDF = {
        market: amend.market,
        changeEmail: amend.changeEmail,
        changeStructure: amend.changeStructure,
        safexCode: amend.safexCode,
        fromEmail: amend.fromEmail,
        toEmail: amend.toEmail,
        fromFee: amend.fromFee,
        toFee: amend.toFee,
        toRatingCode: toRatingCode != undefined ? toRatingCode.name : null,
        fromRatingCode: oldRatingCode != undefined ? oldRatingCode.name : null,
        closeAccount: amend.closeAccount,
        logoFileId: 1,
      };
      if (this.closeOffAccount == true) {
        const res = await amendPDFHook
          .getClosedAmendAccountPDF(account)
          .then((response: any) => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `Amend Account ${dayjs().format("YYYY-MM-DD")}.pdf`
            );
            document.body.appendChild(link);
            link.click();
          });
      }
      if (this.reinstateAccount == true) {
        const res = await amendPDFHook
          .getReinstateAmendAccountPDF(account)
          .then((response: any) => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `Amend Account ${dayjs().format("YYYY-MM-DD")}.pdf`
            );
            document.body.appendChild(link);
            link.click();
          });
      }

      if (this.email == true) {
        const res = await amendPDFHook
          .getEmailAmendAccountPDF(account)
          .then((response: any) => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `Amend Account ${dayjs().format("YYYY-MM-DD")}.pdf`
            );
            document.body.appendChild(link);
            link.click();
          });
      }

      if (this.amendFees == true) {
        const res = await amendPDFHook
          .getFeeAmendAccountPDF(account)
          .then((response: any) => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `Amend Account ${dayjs().format("YYYY-MM-DD")}.pdf`
            );
            document.body.appendChild(link);
            link.click();
          });
      }

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
}
