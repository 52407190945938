
import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { Bank, Bank_CREATE } from "models";
import Hooks from "@/hooks";

const { Banks } = Hooks;
const bankHook = Banks();

@Component({})
export default class AddEditBanks extends Vue {
  @PropSync("open") modal!: boolean;
  @Prop() readonly data!: {
    type: string;
    fields: any;
  };
  valid = false;
  rules = {
    required: (value: string): boolean | string =>
      !!value || "This field is Required.",
  };

  @Watch("data.fields", { immediate: true })
  modalDataChanged(): void {
    console.log("This modal fields:", this.data.fields);
  }

  closeModal(): void {
    this.modal = false;
    this.$emit("refresh");
  }

  clearFields(): void {
    const refForm: any = this.$refs.form;
    refForm.reset();
  }

  async addItem(): Promise<void> {
    console.log("Add info: ", this.data.fields);

    try {
      var bank: Bank_CREATE;
      bank = {
        bankName: this.data.fields.bankName,
        branchCode: this.data.fields.branchCode,
      };
      const res = await bankHook.addNewBank(bank);
      this.$notificationCreator.createSuccessNotification("Bank Added.");
      return Promise.resolve();
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.$emit("refresh");
      this.closeModal();
    }
  }

  async editItem(): Promise<void> {
    console.log("Edit info: ", this.data.fields);

    try {
      var bank: Bank;
      bank = {
        bankName: this.data.fields.bankName,
        branchCode: this.data.fields.branchCode,
        createdBy: this.data.fields.createdBy,
        createdDate: this.data.fields.createdDate,
        id: this.data.fields.id,
        updatedBy: this.data.fields.updatedBy,
        updatedDate: this.data.fields.updatedDate,
        _id: {
          creationTime: this.data.fields._id.creationTime,
          increment: this.data.fields._id.increment,
          machine: this.data.fields._id.machine,
          pid: this.data.fields._id.pid,
          timestamp: this.data.fields._id.timestamp,
        },
      };
      const res = await bankHook.updateBank(bank);

      this.$notificationCreator.createSuccessNotification("Bank Updated.");
      return Promise.resolve();
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.$emit("refresh");
      this.closeModal();
    }
  }
}
