import { axiosInstance } from "@/plugins/axios";
import { ClientBanking, ClientBanking_CREATE, AccountBanking } from "models";

const PartnershipBankings = () => {
  const baseUrl = "/api/Partnership";

  const getAllPartnershipBanking = async (): Promise<ClientBanking[]> => {
    const res = await axiosInstance.get(
      baseUrl + "/getAllPartnerBankingDetails"
    );
    return Promise.resolve(res.data);
  };

  const getPartnershipBankingByCode = async (
    code: string
  ): Promise<ClientBanking> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPartnerBankingDetailByCode?code=${code}`
    );
    return Promise.resolve(res.data);
  };

  const addNewPartnershipBanking = async (
    clientBanking: ClientBanking_CREATE
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/addNewPartnerBankingDetails",
      {
        bankId: clientBanking.bankId,
        accountType: clientBanking.accountType,
        branchName: clientBanking.branchName,
        accountHolder: clientBanking.accountHolder,
        branchCode: clientBanking.branchCode,
        bankSwiftNumber: clientBanking.bankSwiftNumber,
        accountNumber: clientBanking.accountNumber,
        bankIBANNumber: clientBanking.bankIBANNumber,
        entityId: clientBanking.entityId,
      }
    );
    return Promise.resolve(res.data);
  };

  const updatePartnershipBanking = async (
    clientBanking: AccountBanking
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/updatePartnerBankingDetails",
      {
        bankId: clientBanking.bankId,
        accountType: clientBanking.accountType,
        branchName: clientBanking.branchName,
        accountHolder: clientBanking.accountHolder,
        branchCode: clientBanking.branchCode,
        bankSwiftNumber: clientBanking.bankSwiftNumber,
        accountNumber: clientBanking.accountNumber,
        bankIBANNumber: clientBanking.bankIBANNumber,
        entityId: clientBanking.entityId,
        createdBy: clientBanking.createdBy,
        createdDate: clientBanking.createdDate,
        clientBankId: clientBanking.clientBankId,
        updatedBy: clientBanking.updatedBy,
        updatedDate: clientBanking.updatedDate,
        _id: clientBanking._id,
      }
    );
    return Promise.resolve(res.data);
  };

  const deletePartnershipBanking = async (
    clientBanking: AccountBanking
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/deletePartnerBankingDetails",
      {
        bankId: clientBanking.bankId,
        accountType: clientBanking.accountType,
        branchName: clientBanking.branchName,
        accountHolder: clientBanking.accountHolder,
        branchCode: clientBanking.branchCode,
        bankSwiftNumber: clientBanking.bankSwiftNumber,
        accountNumber: clientBanking.accountNumber,
        bankIBANNumber: clientBanking.bankIBANNumber,
        entityId: clientBanking.entityId,
        createdBy: clientBanking.createdBy,
        createdDate: clientBanking.createdDate,
        clientBankId: clientBanking.clientBankId,
        updatedBy: clientBanking.updatedBy,
        updatedDate: clientBanking.updatedDate,
        _id: clientBanking._id,
      }
    );
    return Promise.resolve(res.data);
  };

  return {
    getAllPartnershipBanking,
    getPartnershipBankingByCode,
    addNewPartnershipBanking,
    updatePartnershipBanking,
    deletePartnershipBanking,
  };
};
export default PartnershipBankings;
