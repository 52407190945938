import { axiosInstance } from "@/plugins/axios";
import { CustomDocs, CustomDocsList, CustomDocsUpdate } from "models";

const ClientDocuments = () => {
  const baseUrl = "/api/CustomFile";

  const UploadCustomDocuments = async (
    customDoc: CustomDocs
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + `/UploadCustomDocuments`, {
      user: customDoc.user,
      entityId: customDoc.entityId,
      number: customDoc.number,
      fileType: customDoc.fileType,
      customFile: customDoc.customFile,
    });
    return Promise.resolve(res.data);
  };

  const UploadCustomDocumentsList = async (
    customDoc: CustomDocsList[]
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + `/UploadCustomDocumentsList`,
      customDoc
    );
    return Promise.resolve(res.data);
  };

  const UpdateCustomFile = async (
    customDoc: CustomDocsUpdate
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/UpdateCustomFile", {
      user: customDoc.user,
      entityId: customDoc.entityId,
      number: customDoc.number,
      fileType: customDoc.fileType,
      customFileId: customDoc.customFileId,
      customFile: customDoc.customFile,
    });
    return Promise.resolve(res.data);
  };

  const getCustomFileByCode = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getCustomFileByCode?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const DownloadCustomFile = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadCustomFile?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  return {
    UploadCustomDocuments,
    UploadCustomDocumentsList,
    UpdateCustomFile,
    getCustomFileByCode,
    DownloadCustomFile,
  };
};
export default ClientDocuments;
