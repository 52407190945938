import { axiosInstance } from "@/plugins/axios";
import {
  PartnershipCorpDocs,
  PartnershipCorpDocsUpdate,
  PartnershipDocument,
  PartnershipDocumentUpdate,
  PartnershipPersonalDocs,
  PartnershipPersonalDocsUpdate,
} from "models";

const PartnershipDocuments = () => {
  const baseUrl = "/api/PartnershipDoc";

  const UploadPartnershipDocuments = async (
    clientDocuments: PartnershipDocument
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + `/UploadPartnershipDocuments`,
      {
        user: clientDocuments.user,
        entityId: clientDocuments.entityId,
        identificationType: clientDocuments.identificationType,
        idFile: clientDocuments.idFile,
        saidNumber: clientDocuments.saidNumber,
        bankUpload: clientDocuments.bankUpload,
        taxNumber: clientDocuments.taxNumber,
        taxNumberUpload: clientDocuments.taxNumberUpload,
        incomeTax: clientDocuments.incomeTax,
        incomeTaxUpload: clientDocuments.incomeTaxUpload,
        vatRegNumber: clientDocuments.vatRegNumber,
        vatUpload: clientDocuments.vatUpload,
        mandate: clientDocuments.bankUpload,
        regUpload: clientDocuments.regUpload,
        authSAIDNumber: clientDocuments.authSAIDNumber,
        authId: clientDocuments.authId,
        authPOR: clientDocuments.authPOR,
      }
    );
    return Promise.resolve();
  };

  const UploadPartnershipPersonalDocuments = async (
    clientDocuments: PartnershipPersonalDocs
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + `/UploadPartnershipPersonalDocuments`,
      {
        user: clientDocuments.user,
        entityId: clientDocuments.entityId,
        identificationType: clientDocuments.identificationType,
        idFile: clientDocuments.idFile,
        saidNumber: clientDocuments.saidNumber,
        incomeTax: clientDocuments.incomeTax,
        incomeTaxUpload: clientDocuments.incomeTaxUpload,
        authSAIDNumber: clientDocuments.authSAIDNumber,
        authId: clientDocuments.authId,
        authPOR: clientDocuments.authPOR,
      }
    );
    return Promise.resolve();
  };

  const UploadPartnershipCorpoDocuments = async (
    clientDocuments: PartnershipCorpDocs
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + `/UploadPartnershipCorpoDocuments`,
      {
        user: clientDocuments.user,
        entityId: clientDocuments.entityId,
        bankUpload: clientDocuments.bankUpload,
        taxNumber: clientDocuments.taxNumber,
        taxNumberUpload: clientDocuments.taxNumberUpload,
        vatRegNumber: clientDocuments.vatRegNumber,
        vatUpload: clientDocuments.vatUpload,
        mandate: clientDocuments.bankUpload,
        regUpload: clientDocuments.regUpload,
        regNumber: clientDocuments.regNumber,
      }
    );
    return Promise.resolve();
  };

  const UpdatePartnershipDocuments = async (
    clientDocuments: PartnershipDocumentUpdate
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/UpdatePartnershipDocuments",
      {
        user: clientDocuments.user,
        entityId: clientDocuments.entityId,
        identificationType: clientDocuments.identificationType,
        idFileId: clientDocuments.idFileId,
        idFile: clientDocuments.idFile,
        saidNumber: clientDocuments.saidNumber,
        bankUploadId: clientDocuments.bankUploadId,
        bankUpload: clientDocuments.bankUpload,
        taxNumber: clientDocuments.taxNumber,
        taxNumberUploadId: clientDocuments.taxNumberUploadId,
        taxNumberUpload: clientDocuments.taxNumberUpload,
        incomeTax: clientDocuments.incomeTax,
        incomeTaxId: clientDocuments.incomeTaxId,
        incomeTaxUpload: clientDocuments.incomeTaxUpload,
        vatRegNumber: clientDocuments.vatRegNumber,
        vatUploadId: clientDocuments.vatUploadId,
        vatUpload: clientDocuments.vatUpload,
        mandateId: clientDocuments.mandateId,
        mandate: clientDocuments.bankUpload,
        regUploadId: clientDocuments.regUploadId,
        regUpload: clientDocuments.regUpload,
        authSAIDNumber: clientDocuments.authSAIDNumber,
        idAuthId: clientDocuments.idAuthId,
        authIdFileId: clientDocuments.authIdFileId,
        authId: clientDocuments.authId,
        authPORId: clientDocuments.authPORId,
        aUthPOR: clientDocuments.aUthPOR,
      }
    );
    return Promise.resolve(res.data);
  };

  const UpdatePartnershipPersonalDocuments = async (
    clientDocuments: PartnershipPersonalDocsUpdate
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/UpdatePartnershipDocuments",
      {
        user: clientDocuments.user,
        entityId: clientDocuments.entityId,
        identificationType: clientDocuments.identificationType,
        idFileId: clientDocuments.idFileId,
        idFile: clientDocuments.idFile,
        saidNumber: clientDocuments.saidNumber,
        incomeTax: clientDocuments.incomeTax,
        incomeTaxId: clientDocuments.incomeTaxId,
        incomeTaxUpload: clientDocuments.incomeTaxUpload,
        authSAIDNumber: clientDocuments.authSAIDNumber,
        idAuthId: clientDocuments.idAuthId,
        authIdFileId: clientDocuments.authIdFileId,
        authId: clientDocuments.authId,
        authPORId: clientDocuments.authPORId,
        aUthPOR: clientDocuments.aUthPOR,
      }
    );
    return Promise.resolve(res.data);
  };

  const UpdatePartnershipCorpoDocuments = async (
    clientDocuments: PartnershipCorpDocsUpdate
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/UpdatePartnershipCorpoDocuments",
      {
        user: clientDocuments.user,
        entityId: clientDocuments.entityId,
        bankUploadId: clientDocuments.bankUploadId,
        bankUpload: clientDocuments.bankUpload,
        taxNumber: clientDocuments.taxNumber,
        taxNumberUploadId: clientDocuments.taxNumberUploadId,
        taxNumberUpload: clientDocuments.taxNumberUpload,
        vatRegNumber: clientDocuments.vatRegNumber,
        vatUploadId: clientDocuments.vatUploadId,
        vatUpload: clientDocuments.vatUpload,
        mandateId: clientDocuments.mandateId,
        mandate: clientDocuments.bankUpload,
        regUploadId: clientDocuments.regUploadId,
        regUpload: clientDocuments.regUpload,
        regNumber: clientDocuments.regNumber,
      }
    );
    return Promise.resolve(res.data);
  };

  const getPartnerIdDocumentByCode = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPartnerIdDocumentByCode?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getPartnerBankByCode = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPartnerByCode?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getPartnerTaxByCode = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPartnerTaxByCode?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getPartnerTaxIncomeByCode = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPartnerTaxIncomeByCode?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getPartnerVat = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPartnerVat?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getPartnerMandate = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPartnerMandate?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getPartnerReg = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPartnerReg?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getPartnerAuthIdDocumentByCode = async (
    clientId: string
  ): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPartnerAuthIdDocumentByCode?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getPartnerAuthPORDocumentByCode = async (
    clientId: string
  ): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPartnerAuthPORDocumentByCode?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const DownloadPPIdDocument = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadPPIdDocument?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadPPBankingDocument = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadPPBankingDocument?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadPPVATDocument = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadPPVATDocument?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadPPTax = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(baseUrl + `/DownloadPPTax?id=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "image/jpeg" + "application/pdf" + "image/png",
      },
      responseType: "blob",
    });
    return Promise.resolve(res.data);
  };

  const DownloadPPIncomeTax = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadPPIncomeTax?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadPPMandate = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadPPMandate?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadPPRegDocument = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadPPRegDocument?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadPPAuthIdDocument = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadPPAuthIdDocument?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadAuthPORDocument = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadAuthPORDocument?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  return {
    UploadPartnershipDocuments,
    UploadPartnershipCorpoDocuments,
    UploadPartnershipPersonalDocuments,
    UpdatePartnershipDocuments,
    UpdatePartnershipCorpoDocuments,
    UpdatePartnershipPersonalDocuments,
    getPartnerIdDocumentByCode,
    getPartnerBankByCode,
    getPartnerTaxByCode,
    getPartnerTaxIncomeByCode,
    getPartnerVat,
    getPartnerMandate,
    getPartnerReg,
    getPartnerAuthIdDocumentByCode,
    getPartnerAuthPORDocumentByCode,
    DownloadPPIdDocument,
    DownloadPPBankingDocument,
    DownloadPPVATDocument,
    DownloadPPTax,
    DownloadPPIncomeTax,
    DownloadPPMandate,
    DownloadPPRegDocument,
    DownloadAuthPORDocument,
    DownloadPPAuthIdDocument,
  };
};
export default PartnershipDocuments;
