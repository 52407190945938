
import { Component, Vue } from "vue-property-decorator";
import AmendAccount from "@/components/Dashboard/AmendAccount.vue";
import AmendFeeStructure from "@/components/Dashboard/AmendFeeStructure.vue";
import { UserAccessModel } from "models";
import User from "@/store/modules/user";

@Component({
  components: {
    AmendAccount,
    AmendFeeStructure,
  },
})
export default class Landing extends Vue {
  amendAccountModal = false;
  amendFeeStructureModal = false;
  globalFeesModal = false;
  dash: {
    name: string;
    title: string;
    path: string | null;
    access: boolean;
  }[] = [
    {
      name: "commodities",
      title: "Commodity Accounts",
      path: "dash.commodityAccount.overview",
      access:
        this.getUserAccess != null ? this.getUserAccess.viewComodities : true,
    },
    {
      name: "poolAccounts",
      title: "Pool Accounts",
      path: "dash.poolAccount",
      access:
        this.getUserAccess != null ? this.getUserAccess.viewPoolAccounts : true,
    },
    {
      name: "clearing&JSEFees",
      title: "Clearing & JSE Fees",
      path: "dash.clearing",
      access: true,
    },
    {
      name: "transferBanks",
      title: "Transfer Between Bank Accounts",
      path: "dash.transferBetweenBanks",
      access:
        this.getUserAccess != null
          ? this.getUserAccess.viewTransfersBank
          : true,
    },
    {
      name: "transferClients",
      title: "Transfer Between Clients",
      path: "dash.transferBetweenClients",
      access:
        this.getUserAccess != null
          ? this.getUserAccess.viewTransfersClient
          : true,
    },
    {
      name: "traders",
      title: "Traders",
      path: "dash.manage.traders",
      access:
        this.getUserAccess != null ? this.getUserAccess.accessTraders : true,
    },
    {
      name: "ratingCodes",
      title: "Rating Codes",
      path: "dash.manage.ratingCodes",
      access:
        this.getUserAccess != null
          ? this.getUserAccess.accessRatingCodes
          : true,
    },
    {
      name: "banks",
      title: "Banks",
      path: "dash.manage.banks",
      access:
        this.getUserAccess != null ? this.getUserAccess.accessBanks : true,
    },
    {
      name: "market",
      title: "Markets",
      path: "dash.manage.markets",
      access:
        this.getUserAccess != null ? this.getUserAccess.accessMarkets : true,
    },
    {
      name: "amendAccount",
      title: "Amend/Close Account",
      access: true,
      path: null,
    },
    {
      name: "amendFeeStructure",
      title: "New/Amend Fees Structures",
      access: true,
      path: null,
    },
  ];

  get getDashItems(): {
    name: string;
    title: string;
    path: string | null;
    access: boolean;
  }[] {
    var dashItems: {
      name: string;
      title: string;
      path: string | null;
      access: boolean;
    }[] = [];

    dashItems = this.dash.filter((elem) => elem.access == true);

    return dashItems;
  }

  getColor(index: number): string {
    var color = "primary";
    if (index <= 2) {
      color = "primary";
    } else if (index > 2 && index <= 5) {
      color = "secondary";
    } else if (index > 5 && index <= 8) {
      color = "accent";
    } else if (index > 8) {
      color = "accent lighten-2";
    } else {
      color = "primary";
    }
    return color;
  }

  goTo(name: string, path: string | undefined): void {
    console.log("Dash Click: ", name);
    if (path != null) {
      this.$router.push({
        name: path,
      });
    }

    if (name == "amendAccount") {
      this.amendAccountModal = true;
    }
    if (name == "amendFeeStructure") {
      this.amendFeeStructureModal = true;
    }
    if (name == "globalFees") {
      this.globalFeesModal = true;
    }
  }

  get getUserAccess(): UserAccessModel | null {
    console.log("UA: ", User.getUserAccess);
    return User.getUserAccess;
  }
}
