
import dayjs from "dayjs";
import {
  RiskReporting_Matching,
  RiskReporting_SubAccount,
  RiskReporting_SubAccount_Resp,
} from "models";
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import Hooks from "@/hooks";
import User from "@/store/modules/user";

const { ExcelFiles } = Hooks;
const excelFileHook = ExcelFiles();

@Component({
  filters: {
    numberWCommas: function (value: number) {
      if (value != null) {
        return value.toLocaleString("en-US", { minimumFractionDigits: 2 });
      } else {
        return value;
      }
    },
  },
})
export default class SubAccounts extends Vue {
  @PropSync("loading", { default: false }) load!: boolean;
  @Prop() readonly importResultMatching:
    | RiskReporting_SubAccount_Resp[]
    | null = null;
  items: RiskReporting_SubAccount_Resp[] = [];
  date = dayjs().subtract(1, "d").format("YYYY-MM-DD");
  dateMenu = false;
  search = "";
  sortDesc = true;
  headers = [
    { text: "Principal", value: "subAccount" },
    { text: "Prev Margin", value: "prevMargin" },
    { text: "New Margin", value: "newMargin" },
    { text: "Margin Call", value: "marginCall" },
    { text: "MTM", value: "mtm" },
    { text: "Cash Call", value: "cashCall" },
    { text: "Exchange BF", value: "exchangeBF" },
    { text: "Clearing BF", value: "clearingBF" },
    { text: "Broker BF + Member", value: "brokerBFMember" },
    { text: "Total BF", value: "totalBF" },
    { text: "Current Account", value: "currAcct" },
    { text: "Comments", value: "comments", style: "width: 350px" },
    { text: "", value: "actions", sortable: false },
  ];

  @Watch("importResultMatching", { immediate: true })
  async importResultMatchingChanged(): Promise<void> {
    this.load = true;
    console.log("importResult Matching: ", this.importResultMatching);
    if (this.importResultMatching != null) {
      this.items = this.importResultMatching;
      console.log("Items Matching: ", this.items);
      this.date = dayjs(this.importResultMatching[0].reportDate).format(
        "YYYY-MM-DD"
      );
    }

    this.load = false;
  }

  get Username(): string | null {
    return User.getLoggedInUser;
  }

  async SaveMatchingSheet(): Promise<void> {
    try {
      this.load = true;
      const matching: RiskReporting_Matching[] = [];
      this.items.forEach((elem) => {
        matching.push({
          principal: elem.subAccount,
          prevMargin: elem.prevMargin,
          newMargin: elem.newMargin,
          marginCall: elem.marginCall,
          mtm: elem.mtm,
          cashCall: elem.cashCall,
          exchangeBF: elem.exchangeBF,
          clearingBF: elem.clearingBF,
          brokerBFMemeber: elem.brokerBFMember,
          totalBF: elem.totalBF,
          currAcct: elem.currAcct,
          comments: elem.comments,
          reportDate: this.date,
          createdBy: this.Username,
          createdDate: dayjs().format("YYYY-MM-DD"),
          updatedBy: this.Username,
          updateDate: dayjs().format("YYYY-MM-DD"),
        });
      });
      const resp = await excelFileHook.SaveOrUpdateMatching(matching);
      this.$notificationCreator.createSuccessNotification(
        "Matching Sheet Saved Successfully."
      );
      await this.getMatchingPDF(matching);
    } catch (err: any) {
      console.log(err.response);
      return Promise.reject(err);
    } finally {
      this.load = false;
    }
  }

  async getMatchingPDF(matching: RiskReporting_Matching[]): Promise<void> {
    try {
      this.load = true;
      const resp = await excelFileHook
        .getMatchingPDF(matching)
        .then((response: any) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Matching - Post Balancing ${this.date}.pdf`
          );
          document.body.appendChild(link);
          link.click();
        });

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.load = false;
    }
  }
}
