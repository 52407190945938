
import dayjs from "dayjs";
import {
  RiskReporting_ABSA_Resp,
  RiskReporting_Balance,
  RiskReporting_Balance_Resp,
  RiskReporting_Brokes_Resp,
  RiskReporting_HQ_Resp,
  RiskReporting_SubAccount_Resp,
} from "models";
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import User from "@/store/modules/user";
import Hooks from "@/hooks";

const { ExcelFiles } = Hooks;
const excelFileHook = ExcelFiles();

@Component({
  filters: {
    numberWCommas: function (value: number) {
      if (value != null) {
        return value.toLocaleString("en-US", { minimumFractionDigits: 2 });
      } else {
        return value;
      }
    },
  },
})
export default class Balancing extends Vue {
  @PropSync("loading", { default: false }) load!: boolean;
  @Prop() readonly importResult: {
    balanceSheet: RiskReporting_Balance_Resp[];
    brokesSheet: RiskReporting_Brokes_Resp[];
    absa: RiskReporting_ABSA_Resp[];
    absaDaily: RiskReporting_ABSA_Resp[];
    hq: RiskReporting_HQ_Resp[];
    subAccount: RiskReporting_SubAccount_Resp[];
  } | null = null;
  items: RiskReporting_Balance_Resp[] = [];
  date = dayjs().format("YYYY-MM-DD");
  dateMenu = false;
  search = "";
  sortDesc = false;
  headers = [
    { text: "Agribase Code", value: "agribaseCode" },
    { text: "Agribase Balance", value: "agribaseBalance" },
    { text: "ABSA Code", value: "absaCode" },
    { text: "ABSA Balance", value: "absaBalance" },
    { text: "Difference", value: "difference" },
    { text: "Notes", value: "note", style: "width: 350px" },
    { text: "", value: "actions", sortable: false },
  ];

  @Watch("importResult", { immediate: true })
  importResultChanged(): void {
    this.load = true;
    console.log("importResult Balance: ", this.importResult);
    if (this.importResult != null) {
      var filteredBranches: RiskReporting_Balance_Resp[] = [];
      this.importResult.balanceSheet.forEach((elem) => {
        if (elem.agribaseCode != null) {
          if (elem.agribaseCode.length > 4) {
            filteredBranches.push(elem);
          }
        }
      });

      this.items = filteredBranches;
      if (this.importResult.absa[0].reportDate) {
        this.date = dayjs(this.importResult.absa[0].reportDate).format(
          "YYYY-MM-DD"
        );
      } else {
        this.date = this.getPreviousWorkDay;
      }
    }
    this.load = false;
  }

  get getPreviousWorkDay(): string {
    var date = dayjs().day();
    if (date == 6) {
      /* 6 = saturday */
      return dayjs().subtract(1, "day").format("YYYY-MM-DD");
    } else if (date == 0) {
      /* 0 = sunday */
      return dayjs().subtract(2, "day").format("YYYY-MM-DD");
    } else if (date == 1) {
      /* 1 = monday */
      return dayjs().subtract(3, "day").format("YYYY-MM-DD");
    } else {
      return dayjs().subtract(1, "day").format("YYYY-MM-DD");
    }
  }

  get Username(): string | null {
    return User.getLoggedInUser;
  }

  async saveBalanceSheet(): Promise<void> {
    try {
      this.load = true;
      const balanceSheet: RiskReporting_Balance[] = [];
      this.items.forEach((elem) => {
        balanceSheet.push({
          absaBalance: elem.absaBalance,
          absaCode: elem.absaCode,
          agribaseBalance: elem.agribaseBalance,
          agribaseCode: elem.absaCode,
          difference: elem.difference,
          note: elem.note,
          createdBy: this.Username,
          createdDate: dayjs().format("YYYY-MM-DD"),
          updatedBy: this.Username,
          updateDate: dayjs().format("YYYY-MM-DD"),
          reportDate: this.date,
        });
      });
      const resp = await excelFileHook.SaveOrUpdateBalance(balanceSheet);
      this.$notificationCreator.createSuccessNotification(
        "Balancing Control Sheet Saved Successfully."
      );
      await this.getBalanceSheetPDF(balanceSheet);
    } catch (err: any) {
      console.log(err.response);
      return Promise.reject(err);
    }
  }

  async getBalanceSheetPDF(
    balanceSheet: RiskReporting_Balance[]
  ): Promise<void> {
    try {
      this.load = true;
      const resp = await excelFileHook
        .getBalanceSheetPDF(balanceSheet)
        .then((response: any) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Balance Control Sheet ${this.date}.pdf`
          );
          document.body.appendChild(link);
          link.click();
        });

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.load = false;
    }
  }
}
