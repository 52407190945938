import { axiosInstance } from "@/plugins/axios";
import { RatingCode, RatingCode_CREATE } from "models";

const RatingCodes = () => {
  const baseUrl = "/api/RatingCode";

  const getAllRatingCodes = async (): Promise<RatingCode[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAllRatingCodes");
    return Promise.resolve(res.data);
  };

  const getAllRatingCodesByMarket = async (
    id: number
  ): Promise<RatingCode[]> => {
    const res = await axiosInstance.get(
      baseUrl + `/getAllRatingCodesByMarket?id=${id}`
    );
    return Promise.resolve(res.data);
  };

  const addNewRatingCode = async (
    ratingCode: RatingCode_CREATE
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/addRatingCode", {
      Name: ratingCode.name,
      Amount: ratingCode.amount,
      marketId: ratingCode.marketId,
    });
    return Promise.resolve(res.data);
  };

  const updateRatingCode = async (ratingCode: RatingCode): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/updateRatingCode", {
      marketId: ratingCode.marketId,
      Name: ratingCode.name,
      Amount: ratingCode.amount,
      createdBy: ratingCode.createdBy,
      createdDate: ratingCode.createdDate,
      id: ratingCode.id,
      updatedBy: ratingCode.updatedBy,
      updatedDate: ratingCode.updatedDate,
      _id: {
        creationTime: ratingCode._id.creationTime,
        increment: ratingCode._id.increment,
        machine: ratingCode._id.machine,
        pid: ratingCode._id.pid,
        timestamp: ratingCode._id.timestamp,
      },
    });
    return Promise.resolve(res.data);
  };

  const deleteRatingCode = async (ratingCode: RatingCode): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/deleteRatingCode", {
      Name: ratingCode.name,
      Amount: ratingCode.amount,
      createdBy: ratingCode.createdBy,
      marketId: ratingCode.marketId,
      createdDate: ratingCode.createdDate,
      id: ratingCode.id,
      updatedBy: ratingCode.updatedBy,
      updatedDate: ratingCode.updatedDate,
      _id: {
        creationTime: ratingCode._id.creationTime,
        increment: ratingCode._id.increment,
        machine: ratingCode._id.machine,
        pid: ratingCode._id.pid,
        timestamp: ratingCode._id.timestamp,
      },
    });
    return Promise.resolve(res.data);
  };

  return {
    getAllRatingCodes,
    getAllRatingCodesByMarket,
    addNewRatingCode,
    updateRatingCode,
    deleteRatingCode,
  };
};
export default RatingCodes;
