import { PostalAddress, StreetAddress } from "models";

function copyStreetToPostal(
  streetAddress: StreetAddress,
  postalAddress: PostalAddress
) {
  postalAddress.postalAddress = streetAddress.streetAddress;
  postalAddress.postalAddress2 = streetAddress.streetAddress2;
  postalAddress.postalCity = streetAddress.city;
  postalAddress.postalSuburb = streetAddress.suburb;
  postalAddress.postalCode = streetAddress.code;
}

export default copyStreetToPostal;
