import { axiosInstance } from "@/plugins/axios";
import { ClientDetail, ClientDetail_CREATE } from "models";

const ClientDetails = () => {
  const baseUrl = "/api/ClientDetails";

  const getAllClientDetails = async (): Promise<ClientDetail[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAllClientDetails");
    return Promise.resolve(res.data);
  };

  const getClientDetailByClient = async (
    clientId: string
  ): Promise<ClientDetail> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClientDetailByClient?clientId=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const addNewClientDetails = async (
    clientDetail: ClientDetail_CREATE
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/addNewClientDetails",
      clientDetail
    );
    return Promise.resolve(res.data);
  };

  const updateClientDetails = async (
    clientDetail: ClientDetail
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/updateClientDetails",
      clientDetail
    );
    return Promise.resolve(res.data);
  };

  const deleteClientDetails = async (
    clientDetail: ClientDetail
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/deleteChildAuthorised",
      clientDetail
    );
    return Promise.resolve(res.data);
  };

  return {
    getAllClientDetails,
    addNewClientDetails,
    updateClientDetails,
    deleteClientDetails,
    getClientDetailByClient,
  };
};
export default ClientDetails;
