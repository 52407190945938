import { axiosInstance } from "@/plugins/axios";
import { Bank, Bank_CREATE } from "models";

const Banks = () => {
  const baseUrl = "/api/Bank";

  const getAllBanks = async (): Promise<Bank[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAllBanks");
    return Promise.resolve(res.data);
  };

  const addNewBank = async (bank: Bank_CREATE): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/addNewBank", {
      bankName: bank.bankName,
      branchCode: bank.branchCode,
    });
    return Promise.resolve(res.data);
  };

  const updateBank = async (bank: Bank): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/updateBank", {
      bankName: bank.bankName,
      branchCode: bank.branchCode,
      createdBy: bank.createdBy,
      createdDate: bank.createdDate,
      id: bank.id,
      updatedBy: bank.updatedBy,
      updatedDate: bank.updatedDate,
      _id: {
        creationTime: bank._id.creationTime,
        increment: bank._id.increment,
        machine: bank._id.machine,
        pid: bank._id.pid,
        timestamp: bank._id.timestamp,
      },
    });
    return Promise.resolve(res.data);
  };

  const deleteBank = async (bank: Bank): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/deleteBank", {
      bankName: bank.bankName,
      branchCode: bank.branchCode,
      createdBy: bank.createdBy,
      createdDate: bank.createdDate,
      id: bank.id,
      updatedBy: bank.updatedBy,
      updatedDate: bank.updatedDate,
      _id: {
        creationTime: bank._id.creationTime,
        increment: bank._id.increment,
        machine: bank._id.machine,
        pid: bank._id.pid,
        timestamp: bank._id.timestamp,
      },
    });
    return Promise.resolve(res.data);
  };

  return {
    getAllBanks,
    addNewBank,
    updateBank,
    deleteBank,
  };
};
export default Banks;
