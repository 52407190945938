
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { ClosedCorpBankingDetails_CREATE, Bank } from "models";
import Hooks from "@/hooks";
import JSEClient from "@/store/modules/JSEClient";

const { ClosedCorpBankingDetals, Banks, ClosedCorpEntityDetails } = Hooks;
const closedCorpHook = ClosedCorpBankingDetals();
const bankHook = Banks();
const clientAccountHook = ClosedCorpEntityDetails();

@Component({})
export default class Banking extends Vue {
  @PropSync("valid") formValid!: boolean;
  @PropSync("nextTab") tab!: boolean;
  @PropSync("next") accountNext!: boolean;
  @Prop() type!: string;
  loading = false;
  dataCheck = false;
  closedCorpBank: ClosedCorpBankingDetails_CREATE = {
    bankId: null,
    accountType: null,
    branchName: null,
    accountHolder: null,
    branchCode: null,
    bankSwiftNumber: null,
    accountNumber: null,
    bankIBANNumber: null,
    entityId: null,
  };
  banks: Bank[] = [];
  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
    fileSize: (value: { size: number }): boolean | string =>
      !value || value?.size < 50000000 || "Max File Size is 50MB",
  };

  getBranchCode(): void {
    console.log(this.closedCorpBank.bankId);
    this.banks.forEach((elem) => {
      if (elem.id == this.closedCorpBank.bankId) {
        this.closedCorpBank.branchCode = elem.branchCode;
      }
    });
  }

  async getBanks(): Promise<void> {
    try {
      const res = await bankHook.getAllBanks();
      this.banks = res;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  @Watch("type", { immediate: true })
  async modalTypeChanged(): Promise<void> {
    console.log("Modal Details Type: ", this.type);
    if (this.type == "edit") {
      await this.getStoredData();
    }
    if (this.type == "add") {
      await this.getAccountDetails();
    }
  }

  @Watch("accountNext", {
    immediate: true,
  })
  async onNextChanged(): Promise<void> {
    console.log("AccountNext", this.accountNext);
    if (this.accountNext == true) {
      if (this.type == "add") {
        await this.addNewClientAccountDetails();
      }
      if (this.type == "edit") {
        if (this.dataCheck == true) {
          await this.editClientAccountDetails();
        } else {
          await this.addNewClientAccountDetails();
        }
      }
    }
  }

  async mounted(): Promise<void> {
    await this.getBanks();
    if (this.type == "add") {
      await this.getAccountDetails();
    }
  }

  async getAccountDetails(): Promise<void> {
    try {
      var entityId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        entityId = key;
      }

      if (entityId != null) {
        const res = await clientAccountHook.getClosedCorpByCode(entityId);

        console.log("Get Stored Data res: ", res);
        this.closedCorpBank.accountHolder = res.accountName;
        return Promise.resolve();
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  }

  async getStoredData(): Promise<void> {
    try {
      this.loading = true;
      var entityId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        entityId = key;
      }

      if (entityId != null) {
        const res = await closedCorpHook.getClosedCorpByCodeBank(entityId);

        console.log("Get Stored Data res: ", res);
        if (res != undefined && (res as any) != "") {
          this.dataCheck = true;
          this.closedCorpBank = res as any;
        } else {
          this.dataCheck = false;
        }

        return Promise.resolve();
      }
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async editClientAccountDetails(): Promise<void> {
    try {
      this.loading = true;
      console.log("BA Details: ", this.closedCorpBank);

      const res = await closedCorpHook.updateClosedCorpBank(
        this.closedCorpBank as any
      );

      JSEClient.setAccountBanking({
        accountNumber: this.closedCorpBank.accountNumber,
        bankSwiftNumber: this.closedCorpBank.bankSwiftNumber,
      });

      this.$notificationCreator.createSuccessNotification(
        "Banking Details Updated."
      );
      //this.tab = true;
      return Promise.resolve();
    } catch (err: any) {
      //this.accountNext = false;
      // this.tab = false;
      return Promise.reject(err);
    } finally {
      this.accountNext = false;
      this.loading = false;
    }
  }

  async addNewClientAccountDetails(): Promise<void> {
    try {
      this.loading = true;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        this.closedCorpBank.entityId = key;
      }
      console.log("Banking Details: ", this.closedCorpBank);

      const res = await closedCorpHook.addNewClosedCorpBank(
        this.closedCorpBank
      );

      JSEClient.setAccountBanking({
        accountNumber: this.closedCorpBank.accountNumber,
        bankSwiftNumber: this.closedCorpBank.bankSwiftNumber,
      });

      this.$notificationCreator.createSuccessNotification(
        "Banking Details Saved."
      );
      this.tab = true;
      return Promise.resolve();
    } catch (err: any) {
      this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
