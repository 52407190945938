
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import Account from "@/components/Commodity Accounts/Trusts/tabs/Account.vue";
import Division from "@/components/Commodity Accounts/Trusts/tabs/Division.vue";
import AccountContactDetails from "@/components/Commodity Accounts/Trusts/tabs/AccountContactDetails.vue";
import Banking from "@/components/Commodity Accounts/Trusts/tabs/Banking.vue";
import Fees from "@/components/Commodity Accounts/Trusts/tabs/Fees.vue";
import KycFica from "@/components/Commodity Accounts/Trusts/tabs/KYC.vue";
import Communications from "@/components/Commodity Accounts/Trusts/tabs/Communications.vue";
import SupportingDocs from "@/components/Commodity Accounts/Trusts/tabs/SupportingDocs.vue";
import { ClientOverview } from "models";
import ClientAccounts from "@/hooks/Individual/clientAccount";
import JSEClients from "@/store/modules/JSEClient";
import Hooks from "@/hooks";

const { JSEClient } = Hooks;
const JSEClientHook = JSEClient();

@Component({
  components: {
    Account,
    Division,
    AccountContactDetails,
    Banking,
    Fees,
    KycFica,
    Communications,
    SupportingDocs,
  },
})
export default class ClientRegistration extends Vue {
  @PropSync("open") modal!: boolean;
  @PropSync("summary") summaryModal!: boolean;
  @Prop() readonly type: string | null = null;
  @Prop() divisionToBeEdited!: {
    ircCode: string;
    safexCode: string;
    physicalsCode: string;
    subAccount: string;
  };
  @Prop() activeOverviewAccount!: ClientOverview | null;
  updateTriggered = false;
  tab = "account";
  accountValid = false;
  accountTab = false;
  accountNext = false;

  divisionValid = false;
  divisionTab = false;
  divisionNext = false;

  personalValid = false;
  personalTab = false;
  personalNext = false;

  bankingValid = false;
  bankingTab = false;
  bankingNext = false;

  feesValid = false;
  feesTab = false;
  feesNext = false;

  kycficaValid = false;
  kycNext = false;

  communicationsValid = false;
  communicationsTab = false;
  communicationsNext = false;

  supportingDocsValid = false;
  supportingDocsTab = false;
  supportingDocsNext = false;

  modalType: string | null = null;

  resetValidations(): void {
    this.accountValid = false;
    this.accountTab = false;
    this.accountNext = false;

    this.divisionValid = false;
    this.divisionTab = false;
    this.divisionNext = false;

    this.personalValid = false;
    this.personalTab = false;
    this.personalNext = false;

    this.bankingValid = false;
    this.bankingTab = false;
    this.bankingNext = false;

    this.feesValid = false;
    this.feesTab = false;
    this.feesNext = false;

    this.kycficaValid = false;
    this.kycNext = false;

    this.communicationsValid = false;
    this.communicationsTab = false;
    this.communicationsNext = false;

    this.supportingDocsValid = false;
    this.supportingDocsTab = false;
    this.supportingDocsNext = false;
  }

  closeModal(): void {
    this.resetValidations();
    localStorage.removeItem("ClientFile/marketId");
    this.tab = "account";
    this.modalType = null;
    this.modal = false;

    JSEClients.resetStore();

    this.$emit("refresh");
  }

  @Watch("modal", { immediate: true })
  async modalChanged(): Promise<void> {
    console.log("modal: ", this.modal);
    if (this.modal == true) {
      JSEClients.setAddClient(this.type != "edit");
      JSEClients.setDivisionsToBeEdited(this.divisionToBeEdited);
      const resp = await JSEClientHook.getCountryCodes();
      JSEClients.setCountries(resp);
    }
  }

  async closeWithoutWindowRefresh(): Promise<void> {
    if (this.type != "edit") await JSEClients.sendToJSE();
    JSEClients.resetStore();

    this.$emit("close");
  }

  openSummary(): void {
    if (this.type == "add") {
      this.summaryModal = true;
    }
  }

  async sendToJSE(): Promise<void> {
    await JSEClients.sendToJSE();
    this.updateTriggered = false;
  }

  @Watch("type", { immediate: true })
  async modalTypeChanged(): Promise<void> {
    console.log("Modal Type: ", this.type);
    this.modalType = this.type;
    this.tab = "account";
  }

  @Watch("accountTab", { immediate: true })
  async accountTabChanged(): Promise<void> {
    console.log("accountTab: ", this.accountTab);
    if (this.accountTab == true) {
      this.tab = "division";
    }
  }

  @Watch("divisionTab", { immediate: true })
  async divisionTabChanged(): Promise<void> {
    console.log("divisionTab: ", this.divisionTab);
    if (this.divisionTab == true) {
      this.tab = "personal";
    }
  }

  @Watch("personalTab", { immediate: true })
  async personalTabTabChanged(): Promise<void> {
    console.log("personalTab: ", this.personalTab);
    if (this.personalTab == true) {
      this.tab = "banking";
    }
  }

  @Watch("bankingTab", { immediate: true })
  async bankingTabChanged(): Promise<void> {
    console.log("bankingTab: ", this.bankingTab);
    if (this.bankingTab == true) {
      this.tab = "fees";
    }
  }

  @Watch("feesTab", { immediate: true })
  async feesTabChanged(): Promise<void> {
    console.log("feesTab: ", this.feesTab);
    if (this.feesTab == true) {
      this.tab = "communications";
    }
  }

  @Watch("communicationsTab", { immediate: true })
  async communicationsTabChanged(): Promise<void> {
    console.log("communicationsTab: ", this.communicationsTab);
    if (this.communicationsTab == true) {
      this.tab = "supportingDocs";
    }
  }

  @Watch("supportingDocsTab", { immediate: true })
  async supportingDocsTabChanged(): Promise<void> {
    console.log("supportingDocsTab: ", this.supportingDocsTab);
    if (this.supportingDocsTab == true) {
      this.tab = "kycfica";
    }
  }

  disabled(): boolean {
    if (this.tab == "account") {
      if (this.accountValid == true) {
        return false;
      } else {
        return true;
      }
    } else if (this.tab == "division") {
      if (this.divisionValid == true) {
        return false;
      } else {
        return true;
      }
    } else if (this.tab == "personal") {
      if (this.personalValid == true) {
        return false;
      } else {
        return true;
      }
    } else if (this.tab == "banking") {
      if (this.bankingValid == true) {
        return false;
      } else {
        return true;
      }
    } else if (this.tab == "fees") {
      if (this.feesValid == true) {
        return false;
      } else {
        return true;
      }
    } else if (this.tab == "communications") {
      if (this.communicationsValid == true) {
        return false;
      } else {
        return true;
      }
    } else if (this.tab == "supportingDocs") {
      if (this.supportingDocsValid == true) {
        return false;
      } else {
        return true;
      }
    } else if (this.tab == "kycfica") {
      if (this.kycficaValid == true) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  editNext(): void {
    switch (this.tab) {
      case "account":
        this.tab = "division";
        break;
      case "division":
        this.tab = "personal";
        break;
      case "personal":
        this.tab = "banking";
        break;
      case "banking":
        this.tab = "fees";
        break;
      case "fees":
        this.tab = "communications";
        break;
      case "communications":
        this.tab = "supportingDocs";
        break;
      case "supportingDocs":
        this.tab = "kycfica";
        break;
      default:
    }
  }

  next(): void {
    switch (this.tab) {
      case "account":
        if (this.type == "add") {
          this.accountNext = true;
        }
        break;
      case "division":
        if (this.type == "add") {
          this.divisionNext = true;
        }
        break;
      case "personal":
        if (this.type == "add") {
          this.personalNext = true;
        }
        break;
      case "banking":
        if (this.type == "add") {
          this.bankingNext = true;
        }
        break;
      case "fees":
        if (this.type == "add") {
          this.feesNext = true;
        }
        break;
      case "communications":
        if (this.type == "add") {
          this.communicationsNext = true;
        }
        break;
      case "supportingDocs":
        if (this.type == "add") {
          this.supportingDocsNext = true;
        }
        break;
      case "kycfica":
        if (this.type == "add") {
          this.kycNext = true;
        }
        break;
      default:
        this.tab = "account";
    }
  }

  triggerUpdate(): void {
    this.updateTriggered = true;
    switch (this.tab) {
      case "account":
        this.accountNext = true;
        break;
      case "division":
        this.divisionNext = true;
        break;
      case "personal":
        this.personalNext = true;
        break;
      case "banking":
        this.bankingNext = true;
        break;
      case "fees":
        this.feesNext = true;
        break;
      case "communications":
        this.communicationsNext = true;
        break;
      case "supportingDocs":
        this.supportingDocsNext = true;
        break;
      case "kycfica":
        this.kycNext = true;
        break;
      default:
        this.tab = "account";
    }
  }

  back(): void {
    switch (this.tab) {
      case "kycfica":
        this.tab = "supportingDocs";
        break;
      case "supportingDocs":
        this.tab = "communications";
        break;
      case "communications":
        this.tab = "fees";
        break;
      case "fees":
        this.tab = "banking";
        break;
      case "banking":
        this.tab = "personal";
        break;
      case "personal":
        this.tab = "division";
        break;
      case "division":
        this.tab = "account";
        break;
      default:
        this.tab = "account";
    }
  }

  async updateHQ(): Promise<void> {
    try {
      if (this.activeOverviewAccount != null) {
        this.activeOverviewAccount.enumStatus = 2;
        console.log(
          "Overview obj to be submitted: ",
          this.activeOverviewAccount
        );

        const clientAccountHook = ClientAccounts();
        const res = await clientAccountHook.updateHqAccessClients(
          this.activeOverviewAccount!
        );
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
}
