import { axiosInstance } from "@/plugins/axios";
import { ClientDivisionModel, ClientDivision_CREATE } from "models";

const PartnershipDivisions = () => {
  const baseUrl = "/api/Partnership";

  const getAllPartnershipDivision = async (): Promise<
    ClientDivisionModel[]
  > => {
    const res = await axiosInstance.get(baseUrl + "/getAllPartnerDivision");
    return Promise.resolve(res.data);
  };

  const getAllPartnerDivisionByCode = async (
    code: string
  ): Promise<ClientDivisionModel[]> => {
    const res = await axiosInstance.get(
      baseUrl + `/getAllPartnerDivisionByCode?code=${code}`
    );
    return Promise.resolve(res.data);
  };

  const getPartnershipDivisionByCode = async (
    code: string
  ): Promise<ClientDivisionModel> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPartnerDivisionByCode?code=${code}`
    );
    return Promise.resolve(res.data);
  };

  const addNewPartnershipDivisons = async (
    clientDivision: ClientDivision_CREATE
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/addNewPartnerDivisons", {
      safexCode: clientDivision.safexCode,
      accountingCode: clientDivision.accountingCode,
      physicalCode: clientDivision.physicalCode,
      safexSubAccount: clientDivision.safexSubAccount,
      accountingSubAccount: clientDivision.accountingSubAccount,
      safexMarket: clientDivision.safexMarket,
      jseMarket: clientDivision.jseMarket,
      physicalsMarket: clientDivision.physicalsMarket,
      safexTrader: clientDivision.safexTrader,
      physicalsTrader: clientDivision.physicalsTrader,
      safexTraderId: clientDivision.safexTraderId,
      physicalsTraderId: clientDivision.physicalsTraderId,
      jseTraderId: clientDivision.jseTraderId,
      jseTrader: clientDivision.jseTrader,
      entityId: clientDivision.entityId,
      discretionaryManaged: clientDivision.discretionaryManaged,
      electronicAccNumber: clientDivision.electronicAccNumber,
      isBeneficial: clientDivision.isBeneficial,
    });
    return Promise.resolve(res.data);
  };

  const updatePartnershipDivisions = async (
    clientDivision: ClientDivisionModel
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/updatePartnerDivisions", {
      safexCode: clientDivision.safexCode,
      accountingCode: clientDivision.accountingCode,
      physicalCode: clientDivision.physicalCode,
      safexSubAccount: clientDivision.safexSubAccount,
      accountingSubAccount: clientDivision.accountingSubAccount,
      safexMarket: clientDivision.safexMarket,
      jseMarket: clientDivision.jseMarket,
      physicalsMarket: clientDivision.physicalsMarket,
      safexTrader: clientDivision.safexTrader,
      physicalsTrader: clientDivision.physicalsTrader,
      jseTrader: clientDivision.jseTrader,
      safexTraderId: clientDivision.safexTraderId,
      physicalsTraderId: clientDivision.physicalsTraderId,
      jseTraderId: clientDivision.jseTraderId,
      entityId: clientDivision.entityId,
      id: clientDivision.id,
      createdBy: clientDivision.createdBy,
      createdDate: clientDivision.createdDate,
      updatedBy: clientDivision.updatedBy,
      updatedDate: clientDivision.updateDate,
      _id: clientDivision._id,
      discretionaryManaged: clientDivision.discretionaryManaged,
      electronicAccNumber: clientDivision.electronicAccNumber,
      isBeneficial: clientDivision.isBeneficial,
    });
    return Promise.resolve(res.data);
  };

  const deletePartnershipDivisions = async (
    clientDivision: ClientDivisionModel
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/deletePartnerDivisions", {
      safexCode: clientDivision.safexCode,
      accountingCode: clientDivision.accountingCode,
      physicalCode: clientDivision.physicalCode,
      safexSubAccount: clientDivision.safexSubAccount,
      accountingSubAccount: clientDivision.accountingSubAccount,
      safexMarket: clientDivision.safexMarket,
      jseMarket: clientDivision.jseMarket,
      physicalsMarket: clientDivision.physicalsMarket,
      safexTrader: clientDivision.safexTrader,
      physicalsTrader: clientDivision.physicalsTrader,
      jseTrader: clientDivision.jseTrader,
      entityId: clientDivision.entityId,
      safexTraderId: clientDivision.safexTraderId,
      physicalsTraderId: clientDivision.physicalsTraderId,
      jseTraderId: clientDivision.jseTraderId,
      id: clientDivision.id,
      createdBy: clientDivision.createdBy,
      createdDate: clientDivision.createdDate,
      updatedBy: clientDivision.updatedBy,
      updatedDate: clientDivision.updateDate,
      _id: clientDivision._id,
      discretionaryManaged: clientDivision.discretionaryManaged,
      electronicAccNumber: clientDivision.electronicAccNumber,
      isBeneficial: clientDivision.isBeneficial,
    });
    return Promise.resolve(res.data);
  };

  return {
    getAllPartnershipDivision,
    getAllPartnerDivisionByCode,
    getPartnershipDivisionByCode,
    addNewPartnershipDivisons,
    updatePartnershipDivisions,
    deletePartnershipDivisions,
  };
};
export default PartnershipDivisions;
