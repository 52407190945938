
import { loginResponse } from "models";
import { Component, Vue } from "vue-property-decorator";
import User from "@/store/modules/user";
import Recaptcha2 from "@/components/Recaptcha2.vue";

import Hooks from "@/hooks";

const { Users } = Hooks;
const userHook = Users();

@Component({
  components: {
    Recaptcha2,
  },
})
export default class Register extends Vue {
  name = "";
  email = "";
  password = "";
  firstName = "";
  lastName = "";
  cellNumber = "";
  organisation = "";
  occupation = "";
  province = "";
  passwordConfirm = "";
  terms = false;
  checkbox = false;
  showPassword = false;
  showPasswordConfirm = false;
  captcha = false;
  loading = false;
  valid = false;
  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
    counter: (value: string): boolean | string =>
      value.length <= 20 || "Max 20 characters",

    uppercase: (value: string): boolean | string => {
      const pattern = /^(?=.*[A-Z])/;
      return pattern.test(value) || "Requires atleast 1 uppercase letter";
    },
    lowercase: (value: string): boolean | string => {
      const pattern = /^(?=.*[a-z])/;
      return pattern.test(value) || "Requires atleast 1 lowercase letter";
    },
    matchingPasswords: (value: string): boolean | string =>
      this.checkPasswordsMatch(value) ||
      `Passwords do not match ${this.password}`,
    digit: (value: string): boolean | string => {
      const pattern = /^(?=.*\d)/;
      return pattern.test(value) || "Requires atleast 1 digit";
    },
    special: (value: string): boolean | string => {
      const pattern = /^(?=.*\W)/;
      return pattern.test(value) || "Requires atleast 1 special character";
    },
    email: (value: string): boolean | string => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },

    cell: (value: string | null = null): boolean | string =>
      value != null ? value.length >= 10 : "" || "Enter a valid Contact Number",
  };

  checkPasswordsMatch(pass: string): boolean {
    if (pass == this.password) {
      return true;
    } else {
      return false;
    }
  }

  get getCaptcha(): boolean {
    return this.captcha;
  }

  async register(): Promise<void> {
    try {
      this.loading = true;
      const response = await userHook
        .addUser(
          this.email,
          this.password,
          this.firstName,
          this.lastName,
          this.cellNumber,
          this.organisation,
          this.occupation,
          this.province
        )
        .catch((err) => {
          console.error("Error-", err);
          this.$notificationCreator.createErrorNotification(
            `Unable to Register User: ${err}`
          );
          return Promise.reject(err);
        });
      if (response != undefined) {
        console.log("Register response: ", response);
      }

      try {
        const response = await this.$axiosAuth
          .post(
            `/api/Account/Login?email=${this.email}&password=${this.password}`
          )
          .catch((err) => {
            console.log("Login error rejected");
            return Promise.reject(err);
          });
        if (response != undefined) {
          console.log("Login Response: ", response.data, typeof response.data);
        }
        const data = response.data as loginResponse;
        const notifText = "Successfully Registered and Logged In";
        +(data.token.length > 20
          ? data.token.substring(0, 20) + "..."
          : data.token);
        this.$notificationCreator.createSuccessNotification(notifText);
        User.setLoggedIn({
          accessToken: data.token,
          expiration: data.expiration,
          username: this.email,
        });

        localStorage.setItem(
          "ClientFile/login",
          JSON.stringify({
            accessToken: data.token,
            expiration: data.expiration,
            authenticated: true,
            username: this.email,
          })
        );

        this.$router.push({
          name: "dash.landing",
        });
        console.log("Login Return");
        return Promise.resolve();
      } catch (err: any) {
        console.log("Login catch");
        if (err.response) {
          console.log("Check ", err.response);
          if (err.response.data) {
            console.log("Data ", err.response.data);
            this.$notificationCreator.createErrorNotification(
              `LOGIN ERROR: ${err.response.data.status} ${err.response.data.title}`
            );
            return Promise.resolve();
          }
        }
        this.$notificationCreator.createErrorNotification(err);
      } finally {
        this.loading = false;
      }

      return Promise.resolve();
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async registerSuperUser(): Promise<void> {
    try {
      this.loading = true;
      const response = await userHook
        .addSuperUser(
          this.email,
          this.password,
          this.firstName,
          this.lastName,
          this.cellNumber,
          this.organisation,
          this.occupation,
          this.province
        )
        .catch((err) => {
          console.error("Error-", err);
          return Promise.reject(err);
        });
      if (response != undefined) {
        console.log("Register response: ", response);
      }

      try {
        const response = await this.$axiosAuth
          .post(
            `/api/Account/Login?email=${this.email}&password=${this.password}`
          )
          .catch((err) => {
            console.log("Login error rejected");
            return Promise.reject(err);
          });
        if (response != undefined) {
          console.log("Login Response: ", response.data, typeof response.data);
        }
        const data = response.data as loginResponse;
        const notifText = "Successfully Registered and Logged In";
        +(data.token.length > 20
          ? data.token.substring(0, 20) + "..."
          : data.token);
        this.$notificationCreator.createSuccessNotification(notifText);
        User.setLoggedIn({
          accessToken: data.token,
          expiration: data.expiration,
          username: this.email,
        });

        localStorage.setItem(
          "ClientFile/login",
          JSON.stringify({
            accessToken: data.token,
            expiration: data.expiration,
            authenticated: true,
            username: this.email,
          })
        );

        this.$router.push({
          name: "dash.landing",
        });
        console.log("Login Return");
        return Promise.resolve();
      } catch (err: any) {
        console.log("Login catch");
        if (err.response) {
          console.log("Check ", err.response);
          if (err.response.data) {
            console.log("Data ", err.response.data);
            this.$notificationCreator.createErrorNotification(
              `LOGIN ERROR: ${err.response.data.status} ${err.response.data.title}`
            );
            return Promise.resolve();
          }
        }
        this.$notificationCreator.createErrorNotification(err);
      } finally {
        this.loading = false;
      }

      return Promise.resolve();
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
