import { axiosInstance } from "@/plugins/axios";
import { TrustCommunication, TrustCommunication_CREATE } from "models";

const TrustCommunications = () => {
  const baseUrl = "/api/Trust";

  const getAllTrustCommunications = async (): Promise<TrustCommunication[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAllTrustCommunications");
    return Promise.resolve(res.data);
  };

  const getTrustCommunicationByCode = async (
    code: string
  ): Promise<TrustCommunication> => {
    const res = await axiosInstance.get(
      baseUrl + `/getTrustCommunicationByCode?code=${code}`
    );
    return Promise.resolve(res.data);
  };

  const addNewTrustCommunications = async (
    clientCommunication: TrustCommunication_CREATE
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/addNewTrustCommunications",
      {
        contactName: clientCommunication.contactName,
        emailAddress: clientCommunication.emailAddress,
        mobileNumber: clientCommunication.mobileNumber,
        entityId: clientCommunication.entityId,
      }
    );
    return Promise.resolve(res.data);
  };

  const updateTrustCommunications = async (
    clientCommunication: TrustCommunication
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/updateTrustCommunications",
      {
        contactName: clientCommunication.contactName,
        emailAddress: clientCommunication.emailAddress,
        mobileNumber: clientCommunication.mobileNumber,
        entityId: clientCommunication.entityId,
        createdBy: clientCommunication.createdBy,
        createdDate: clientCommunication.createdDate,
        updatedBy: clientCommunication.updatedBy,
        updatedDate: clientCommunication.updatedDate,
        _id: clientCommunication._id,
      }
    );
    return Promise.resolve(res.data);
  };

  const deleteTrustCommunications = async (
    clientCommunication: TrustCommunication
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/deleteTrustCommunications",
      {
        contactName: clientCommunication.contactName,
        emailAddress: clientCommunication.emailAddress,
        mobileNumber: clientCommunication.mobileNumber,
        entityId: clientCommunication.entityId,
        createdBy: clientCommunication.createdBy,
        createdDate: clientCommunication.createdDate,
        updatedBy: clientCommunication.updatedBy,
        updatedDate: clientCommunication.updatedDate,
        _id: clientCommunication._id,
      }
    );
    return Promise.resolve(res.data);
  };

  return {
    getAllTrustCommunications,
    addNewTrustCommunications,
    updateTrustCommunications,
    deleteTrustCommunications,
    getTrustCommunicationByCode,
  };
};
export default TrustCommunications;
