var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Street Address","clearable":"","rules":[_vm.rules.required]},model:{value:(_vm.streetAddressSync),callback:function ($$v) {_vm.streetAddressSync=$$v},expression:"streetAddressSync"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Street Address 2 (optional)","clearable":""},model:{value:(_vm.streetAddress2Sync),callback:function ($$v) {_vm.streetAddress2Sync=$$v},expression:"streetAddress2Sync"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Suburb","clearable":""},model:{value:(_vm.suburbSync),callback:function ($$v) {_vm.suburbSync=$$v},expression:"suburbSync"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"City","clearable":"","rules":[_vm.rules.required]},model:{value:(_vm.citySync),callback:function ($$v) {_vm.citySync=$$v},expression:"citySync"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"type":"number","label":"Code","clearable":"","rules":[_vm.rules.required]},model:{value:(_vm.codeSync),callback:function ($$v) {_vm.codeSync=$$v},expression:"codeSync"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Province","clearable":"","items":[
          'Eastern Cape',
          'Free State',
          'Gauteng',
          'KwaZulu-Natal',
          'Limpopo',
          'Mpumalanga',
          'Northen Cape',
          'North West',
          'Western Cape',
        ],"rules":[_vm.rules.required]},model:{value:(_vm.provinceSync),callback:function ($$v) {_vm.provinceSync=$$v},expression:"provinceSync"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }