
import { Vue, Component, PropSync, Prop, Ref } from "vue-property-decorator";

@Component
export default class ClientDetailsComponent extends Vue {
  @PropSync("titleId", { type: Number, required: false }) titleIdSync!: number;
  @PropSync("name", { type: String, required: false }) nameSync!: string;
  @PropSync("surname", { type: String, required: false }) surnameSync!: string;
  @PropSync("dateOfBirth", { type: String, required: false })
  dateOfBirthSync!: string;
  @PropSync("email", { type: String, required: false }) emailSync!: string;
  @PropSync("cellNumber", { type: String, required: false })
  cellNumberSync!: string;

  titles = [
    { id: 1, title: "Ms" },
    { id: 2, title: "Mrs" },
    { id: 3, title: "Mr" },
  ];
  dobMenu = false;

  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
    email: (value: string): boolean | string => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },
    cell: (value: string | null = null): boolean | string =>
      value != null ? value.length >= 10 : "" || "Enter a valid Contact Number",
  };

  saveDate(date: string) {
    (this.$refs.dobMenuRef as any).save(date);
    this.dobMenu = false;
    debugger;
  }
  saveAndClose(date: string) {
    debugger;
    this.saveDate(date);
    this.dobMenu = false;
  }
}
