import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Auth from "@/views/Auth.vue";
import User from "@/store/modules/user";
import landing from "@/components/Dashboard/Landing.vue";
import Dashboard from "@/views/Dashboard.vue";
import NavDrawer from "@/components/NavDrawer.vue";
import ForgotPassword from "@/components/Login&Register/ForgotPassword.vue";

//Commodity Acoounts
import Overview from "@/components/Commodity Accounts/Overview.vue";

//Appbar
import UserProfile from "@/components/Appbar/UserProfile.vue";
import AppBar from "../components/Appbar/Appbar.vue";
import Admin from "@/components/Appbar/Admin/Admin.vue";

// Manage
import Traders from "@/components/Manage/Traders/Traders.vue";
import Banks from "@/components/Manage/Banks/Banks.vue";
import RatingCodes from "@/components/Manage/RatingCodes/RatingCodes.vue";
import Markets from "@/components/Manage/Markets/Markets.vue";
import SyncClients from "@/components/Manage/SyncClients/SyncClients.vue";
/* import ChildBank from "@/components/Manage/ChildBank/ChildBank.vue";
import ChildAddress from "@/components/Manage/ChildAddress/ChildAddress.vue";
import FeeStructure from "@/components/Manage/FeeStructures/FeeStructures.vue"; */

//Transfer
import PoolAccount from "@/components/Transfers/PoolAccount.vue";
import TBC from "@/components/Transfers/TransferBetweenClients.vue";
import TBB from "@/components/Transfers/TransferBetweenBanks.vue";

//Audit Trail
import AuditTrail from "@/components/AuditTrail.vue";

//Other
import Clearing_JSEFees from "@/components/Clearing&JSEFees.vue";
import ImportRiskReport from "@/components/RiskReporting/Import/Import.vue";
import AuditRiskReport from "@/components/RiskReporting/Audit/Audit.vue";

import Hooks from "@/hooks";
const { UserProfiles, UserAccess } = Hooks;
const UserProfilesHook = UserProfiles();
const UserAccessHook = UserAccess();

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: {
      name: "Auth",
    },
  },
  {
    path: "",
    name: "Auth",
    components: {
      main: Auth,
    },
  },
  {
    path: "/forgot-password*",
    name: "ResetPassword",
    components: {
      main: ForgotPassword,
    },
  },
  {
    path: "/dashboard",
    components: {
      main: Dashboard,
      nav: NavDrawer,
      appBar: AppBar,
    },
    children: [
      //logged in pages
      {
        path: "/landing",
        component: landing,
        name: "dash.landing",
      },
      {
        path: "/overview",
        name: "dash.commodityAccount.overview",
        component: Overview,
      },
      {
        path: "/user-profile",
        name: "dash.profile.user",
        component: UserProfile,
      },

      {
        path: "/admin",
        name: "dash.admin",
        component: Admin,
      },
      {
        path: "/audit-trail",
        name: "dash.auditTrail",
        component: AuditTrail,
      },
      {
        path: "/traders",
        name: "dash.manage.traders",
        component: Traders,
      },
      {
        path: "/banks",
        name: "dash.manage.banks",
        component: Banks,
      },
      {
        path: "/ratingCodes",
        name: "dash.manage.ratingCodes",
        component: RatingCodes,
      },
      {
        path: "/markets",
        name: "dash.manage.markets",
        component: Markets,
      },
      {
        path: "/sync-clients",
        name: "dash.manage.sync-clients",
        component: SyncClients,
      },
      /* {
        path: "feeStructures",
        name: "dash.manage.feeStructures",
        component: FeeStructure,
      },
      {
        path: "child-bank",
        name: "dash.manage.childBank",
        component: ChildBank,
      },
      {
        path: "child-address",
        name: "dash.manage.childAddress",
        component: ChildAddress,
      }, */

      {
        path: "/pool-account",
        component: PoolAccount,
        name: "dash.poolAccount",
      },
      {
        path: "/transfer-between-clients",
        component: TBC,
        name: "dash.transferBetweenClients",
      },
      {
        path: "/transfer-between-banks",
        component: TBB,
        name: "dash.transferBetweenBanks",
      },
      {
        path: "/clearing-and-jse-fees",
        component: Clearing_JSEFees,
        name: "dash.clearing",
      },
      {
        path: "/riskReporting-import",
        component: ImportRiskReport,
        name: "dash.riskReporting.import",
      },
      {
        path: "/riskReporting-audit",
        component: AuditRiskReport,
        name: "dash.riskReporting.audit",
      },
    ],
  },
];

const router = new VueRouter({
  /* mode: "history", */
  base: process.env.BASE_URL,
  routes,
});

const key = localStorage.getItem("ClientFile/login");
if (key != null) {
  User.setLoggedIn(JSON.parse(key));
} else {
  User.removeLoggedIn();
}

router.beforeEach(async (to, from, next) => {
  document.title = process.env.VUE_APP_TITLE;

  if (to.name == "ResetPassword") {
    next();
    return;
  }

  if (User.isLoggedIn == false) {
    if (to.name == "Auth") {
      next();
    } else {
      next({
        name: "Auth",
      });
    }
  } else {
    if (User.getLoggedInUser != null) {
      const res = await UserAccessHook.getUserAccessByEmail(
        User.getLoggedInUser
      ).catch((err) => {
        console.log(err.response);
        if (err.response.status == 401) {
          User.removeLoggedIn();
          next({
            name: "Auth",
          });
        }
        return Promise.reject(err);
      });

      console.log("User Acces: ", res);

      if (res != null && res != undefined && res.length != 0) {
        User.setUserAccess({ userAccess: res[0] });
      }
      if (res.length == 0 || res == null || res == undefined) {
        User.setUserAccess({
          userAccess: {
            _id: 0,
            accessBanks: true,
            accessMarkets: true,
            accessRatingCodes: true,
            accessRoles: true,
            accessTraders: true,
            add: true,
            canEditProfileEmail: true,
            createdBy: "",
            createdDate: "",
            delete: true,
            edit: true,
            email: "",
            get: true,
            roleName: "FE",
            updatedBy: "",
            updatedDate: "",
            viewClosedCorp: true,
            viewComodities: true,
            viewFields: true,
            viewGenericCodes: true,
            viewIndividuals: true,
            viewPartnership: true,
            viewPoolAccounts: true,
            viewPrivateCompany: true,
            viewTransfers: true,
            viewTransfersBank: true,
            viewTransfersClient: true,
            viewTrusts: true,
          },
        });
      }
    }
    if (to.name == "Auth") {
      next({
        name: "dash.landing",
      });
    } else {
      next();
    }
  }
});

export default router;
