import { axiosInstance } from "@/plugins/axios";
import { PartnerCommunication, PartnerCommunication_CREATE } from "models";

const PartnershipCommunications = () => {
  const baseUrl = "/api/Partnership";

  const getAllPartnershipCommunications = async (): Promise<
    PartnerCommunication[]
  > => {
    const res = await axiosInstance.get(
      baseUrl + "/getAllPartnerCommunications"
    );
    return Promise.resolve(res.data);
  };

  const getPartnershipCommunicationByCode = async (
    code: string
  ): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPartnerCommunicationByCode?code=${code}`
    );
    return Promise.resolve(res.data);
  };

  const addNewPartnershipCommunications = async (
    clientCommunication: PartnerCommunication_CREATE
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/addNewPartnerCommunications",
      {
        contactName: clientCommunication.contactName,
        contactEmailAddress: clientCommunication.contactEmailAddress,
        contactMobileNumber: clientCommunication.contactMobileNumber,
        entityId: clientCommunication.entityId,
      }
    );
    return Promise.resolve(res.data);
  };

  const updatePartnershipCommunications = async (
    clientCommunication: PartnerCommunication
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/updatePartnerCommunications",
      {
        contactName: clientCommunication.contactName,
        contactEmailAddress: clientCommunication.contactEmailAddress,
        contactMobileNumber: clientCommunication.contactMobileNumber,
        entityId: clientCommunication.entityId,
        createdBy: clientCommunication.createdBy,
        createdDate: clientCommunication.createdDate,
        updatedBy: clientCommunication.updatedBy,
        updatedDate: clientCommunication.updatedDate,
        _id: clientCommunication._id,
      }
    );
    return Promise.resolve(res.data);
  };

  const deletePartnershipCommunications = async (
    clientCommunication: PartnerCommunication
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/deletePartnerCommunications",
      {
        contactName: clientCommunication.contactName,
        contactEmailAddress: clientCommunication.contactEmailAddress,
        contactMobileNumber: clientCommunication.contactMobileNumber,
        entityId: clientCommunication.entityId,
        createdBy: clientCommunication.createdBy,
        createdDate: clientCommunication.createdDate,
        updatedBy: clientCommunication.updatedBy,
        updatedDate: clientCommunication.updatedDate,
        _id: clientCommunication._id,
      }
    );
    return Promise.resolve(res.data);
  };

  return {
    getAllPartnershipCommunications,
    addNewPartnershipCommunications,
    updatePartnershipCommunications,
    deletePartnershipCommunications,
    getPartnershipCommunicationByCode,
  };
};
export default PartnershipCommunications;
