import { axiosInstance } from "@/plugins/axios";
import { PartnerCommunication, PartnerCommunication_CREATE } from "models";

const PrivateCompanyCommunications = () => {
  const baseUrl = "/api/PrivateCompany";

  const getAllPrivateCompCommunications = async (): Promise<
    PartnerCommunication[]
  > => {
    const res = await axiosInstance.get(
      baseUrl + "/getAllPrivateCompCommunications"
    );
    return Promise.resolve(res.data);
  };

  const getPrivateCompCommunicationByCode = async (
    code: string
  ): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPrivateCompCommunicationByCode?code=${code}`
    );
    return Promise.resolve(res.data);
  };

  const addNewPrivateCompCommunications = async (
    clientCommunication: PartnerCommunication_CREATE
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/addNewPrivateCompCommunications",
      {
        contactName: clientCommunication.contactName,
        contactEmailAddress: clientCommunication.contactEmailAddress,
        contactMobileNumber: clientCommunication.contactMobileNumber,
        entityId: clientCommunication.entityId,
      }
    );
    return Promise.resolve(res.data);
  };

  const updatePrivateCompCommunications = async (
    clientCommunication: PartnerCommunication
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/updatePrivateCompCommunications",
      {
        contactName: clientCommunication.contactName,
        contactEmailAddress: clientCommunication.contactEmailAddress,
        contactMobileNumber: clientCommunication.contactMobileNumber,
        entityId: clientCommunication.entityId,
        createdBy: clientCommunication.createdBy,
        createdDate: clientCommunication.createdDate,
        updatedBy: clientCommunication.updatedBy,
        updatedDate: clientCommunication.updatedDate,
        _id: clientCommunication._id,
      }
    );
    return Promise.resolve(res.data);
  };

  const deletePrivateCompCommunications = async (
    clientCommunication: PartnerCommunication
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/deletePrivateCompCommunications",
      {
        contactName: clientCommunication.contactName,
        contactEmailAddress: clientCommunication.contactEmailAddress,
        contactMobileNumber: clientCommunication.contactMobileNumber,
        entityId: clientCommunication.entityId,
        createdBy: clientCommunication.createdBy,
        createdDate: clientCommunication.createdDate,
        updatedBy: clientCommunication.updatedBy,
        updatedDate: clientCommunication.updatedDate,
        _id: clientCommunication._id,
      }
    );
    return Promise.resolve(res.data);
  };

  return {
    getAllPrivateCompCommunications,
    addNewPrivateCompCommunications,
    updatePrivateCompCommunications,
    deletePrivateCompCommunications,
    getPrivateCompCommunicationByCode,
  };
};
export default PrivateCompanyCommunications;
