
import { Component, Vue } from "vue-property-decorator";
import Login from "@/components/Login&Register/Login.vue";
import Register from "@/components/Login&Register/Register.vue";

@Component({
  components: {
    Login,
    Register,
  },
})
export default class Auth extends Vue {
  registerCard = false;
  account = "Don't have an account?";
  btnDesc = "Sign Up Now";
  btn2Desc = "Sign Up";

  register(): void {
    if ((this, this.registerCard == false)) {
      this.registerCard = true;
    } else this.registerCard = false;
    if (this.registerCard == false) {
      this.account = "Don't have an account?";
      this.btnDesc = "Sign Up Now";
      this.btn2Desc = "Sign Up";
    } else if (this.registerCard == true) {
      this.account = "Already have an account?";
      this.btnDesc = "Sign In";
      this.btn2Desc = "Sign In";
    }
  }
}
