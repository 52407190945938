import { axiosAuth, axiosInstance } from "@/plugins/axios";
import { UserProfile, UserProfilewPass } from "models";

const UserProfiles = () => {
  const baseUrl = "/api/UserProfile";

  const getUserProfileByEmail = async (email: string): Promise<UserProfile> => {
    const res = await axiosInstance.get(
      baseUrl + `/getUserProfileByEmail?email=${email}`
    );
    return Promise.resolve(res.data);
  };

  const getAllUsers = async (): Promise<UserProfile[]> => {
    const res = await axiosInstance.get(baseUrl + `/getAllUsers`);
    return Promise.resolve(res.data);
  };

  const addNewUserProfile = async (
    profile: UserProfilewPass
  ): Promise<void> => {
    const res = await axiosAuth.post(baseUrl + "/addNewUserProfile", {
      _id: profile._id,
      userId: profile.userId,
      password: profile.password,
      firstName: profile.firstName,
      lastName: profile.lastName,
      email: profile.email,
      roleName: profile.roleName,
      alternativeEmail: profile.alternativeEmail,
      title: profile.title,
      cellNumber: profile.cellNumber,
      organisation: profile.organisation,
      occupation: profile.occupation,
      branchCode: profile.branchCode,
      saId: profile.saId,
      passportNo: profile.passportNo,
      dateOfBirth: profile.dateOfBirth,
      gender: profile.gender,
      homeTel: profile.homeTel,
      workTel: profile.workTel,
      maritalStatus: profile.maritalStatus,
      language: profile.language,
      province: profile.province,
      street: profile.street,
      city: profile.city,
      createdBy: profile.createdBy,
      createdDate: profile.createdDate,
      updatedBy: profile.updatedBy,
      updatedDate: profile.updatedDate,
    });
    return Promise.resolve(res.data);
  };

  const updateUserProfile = async (
    profile: UserProfilewPass
  ): Promise<void> => {
    const res = await axiosAuth.post(baseUrl + `/updateUserProfile`, {
      _id: profile._id,
      userId: profile.userId,
      password: profile.password,
      firstName: profile.firstName,
      lastName: profile.lastName,
      email: profile.email,
      roleName: profile.roleName,
      alternativeEmail: profile.alternativeEmail,
      title: profile.title,
      cellNumber: profile.cellNumber,
      organisation: profile.organisation,
      occupation: profile.occupation,
      branchCode: profile.branchCode,
      saId: profile.saId,
      passportNo: profile.passportNo,
      dateOfBirth: profile.dateOfBirth,
      gender: profile.gender,
      homeTel: profile.homeTel,
      workTel: profile.workTel,
      maritalStatus: profile.maritalStatus,
      language: profile.language,
      province: profile.province,
      street: profile.street,
      city: profile.city,
      createdBy: profile.createdBy,
      createdDate: profile.createdDate,
      updatedBy: profile.updatedBy,
      updatedDate: profile.updatedDate,
    });
    return Promise.resolve(res.data);
  };

  return {
    getUserProfileByEmail,
    getAllUsers,
    addNewUserProfile,
    updateUserProfile,
  };
};
export default UserProfiles;
