
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import Profile from "@/components/Appbar/Admin/Tabs/Users/Tabs/Profile.vue";
import Roles from "@/components/Appbar/Admin/Tabs/Users/Tabs/Roles.vue";
import New from "@/components/Appbar/Admin/Tabs/Users/Tabs/New.vue";
import { UserProfile, UserProfilewPass } from "models";
import Hooks from "@/hooks";

const { UserProfiles } = Hooks;
const UserProfileHook = UserProfiles();
@Component({
  components: {
    Profile,
    Roles,
    New,
  },
})
export default class ClientRegistration extends Vue {
  @PropSync("open") modal!: boolean;
  @Prop() readonly type: string | null = null;
  @Prop() modalData!: UserProfilewPass;
  tab = "profile";
  modalType: string | null = null;
  profileValid = false;
  profileClear = false;
  profileNext = false;

  rolesValid = false;
  rolesClear = false;
  rolesNext = false;
  rolesDisabled = false;

  newHQValid = false;
  newHQClear = false;
  newHQNext = false;
  newHQDisabled = false;

  async updateModalData(email: string | null): Promise<void> {
    try {
      if (email != null) {
        const res = await UserProfileHook.getUserProfileByEmail(email);
        console.log("Modal Data Updated getProfile: ", res);
        if (res) {
          this.modalData = res as UserProfilewPass;
          this.rolesDisabled = false;
          this.newHQDisabled = false;

          console.log("Updated ModalData: ", this.modalData);
        }
        return Promise.resolve();
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  }

  resetValidations(): void {
    this.profileValid = false;
    this.profileClear = true;
    this.profileNext = false;

    this.rolesValid = false;
    this.rolesClear = true;
    this.rolesNext = false;

    this.newHQValid = false;
    this.newHQClear = true;
    this.newHQNext = false;
  }

  closeModal(): void {
    this.resetValidations();
    this.tab = "profile";
    this.modalType = null;
    this.modal = false;
    this.$emit("refresh");
  }
  @Watch("type", { immediate: true })
  async modalTypeChanged(): Promise<void> {
    console.log("Modal Type (AEU): ", this.type);
    this.modalType = this.type;
    if (this.modalType == "add") {
      this.rolesDisabled = true;
      this.newHQDisabled = true;
    }
    if (this.modalType == "edit") {
      this.rolesDisabled = false;
      this.newHQDisabled = false;
    }
  }

  @Watch("modalData", { immediate: true })
  async modalDataChanged(): Promise<void> {
    console.log("Modal Data (AEU): ", this.modalData);
  }

  @Watch("tab", { immediate: true })
  async tabChanged(): Promise<void> {
    console.log("Active Tab: ", this.tab);
  }

  clear(): void {
    switch (this.tab) {
      case "profile":
        this.profileClear = true;
        break;
      case "roles":
        this.rolesClear = true;
        break;
      case "newHQ":
        this.newHQClear = true;
        break;
    }
  }

  disabled(): boolean {
    if (this.tab == "profile") {
      if (this.profileValid == true) {
        return false;
      } else {
        return true;
      }
    } else if (this.tab == "roles") {
      if (this.rolesValid == true) {
        return false;
      } else {
        return true;
      }
    } else if (this.tab == "newHQ") {
      if (this.newHQValid == true) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  next(): void {
    switch (this.tab) {
      case "profile":
        this.profileNext = true;
        this.tab = "roles";
        break;
      case "roles":
        this.rolesNext = true;
        this.tab = "newHQ";
        break;
      case "newHQ":
        this.newHQNext = true;
        break;
    }
  }

  back(): void {
    switch (this.tab) {
      case "roles":
        this.tab = "profile";
        break;
      case "newHQ":
        this.tab = "roles";
        break;
    }
  }
}
