
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import dayjs from "dayjs";
import {
  TrustDocument,
  TrustDocumentUpdate,
  TrustPersonalDocs,
  TrustPersonalDocsUpdate,
  TrustCorpDocs,
  TrustCorpDocsUpdate,
  CustomDocs,
  CustomDocsUpdate,
  CustomDocsList,
} from "models";
import Hooks from "@/hooks";
import User from "@/store/modules/user";
import JSEClient from "@/store/modules/JSEClient";

const { TrustDocuments, CustomFile } = Hooks;
const docsHook = TrustDocuments();
const customFileHook = CustomFile();

@Component({})
export default class SupportingDocs extends Vue {
  @PropSync("valid") formValid!: boolean;
  @PropSync("nextTab") tab!: boolean;
  @PropSync("next") accountNext!: boolean;
  @Prop() type!: string;
  loading = false;
  taxNumber: string | null = null;
  taxNumberupload: File | null = null;
  vatNumber: string | null = null;
  vatUpload: File | null = null;
  proofOfBankUpload: File | null = null;
  trustNumber: string | null = null;
  deed: File | null = null;
  resolution_mandate: File | null = null;
  authIdNumber: string | null = null;
  authId: File | null = null;
  authPOR: File | null = null;
  addFiles: {
    type: string | null;
    info: string | null;
    file: File | null;
  }[] = [];
  storedAddress: {
    streetAddress: string | null;
    streetAddress2: string | null;
    city: string | null;
    suburb: string | null;
    code: string | null;
    province: string | null;
  } = {
    streetAddress: null,
    streetAddress2: null,
    city: null,
    suburb: null,
    code: null,
    province: null,
  };
  storedDocs: {
    trustDoc: any | null;
    tnDoc: any | null;
    vatDoc: any | null;
    pobDoc: any | null;
    rmDoc: any | null;
    authID: any | null;
    authPOR: any | null;
    addFiles: any | null;
  } = {
    trustDoc: null,
    tnDoc: null,
    vatDoc: null,
    pobDoc: null,
    rmDoc: null,
    authID: null,
    authPOR: null,
    addFiles: null,
  };
  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
    id: (value: string): boolean | string =>
      value?.length == 13 || "Requires 13 digits",
    fileSize: (value: { size: number }): boolean | string =>
      !value || value.size < 5000000 || "Max File Size 5MB!",
  };
  headers = [
    {
      text: "Type",
      value: "docType",
      sortable: false,
      style: "width: 200px",
    },
    {
      text: "Information",
      value: "info",
      sortable: false,
      style: "width: 350px",
    },
    {
      text: "File (s)",
      value: "files",
      sortable: false,
    },
  ];
  headersEdit = [
    {
      text: "Type",
      value: "docType",
      sortable: false,
      style: "width: 200px",
    },
    {
      text: "Information",
      value: "info",
      sortable: false,
      style: "width: 350px",
    },
    {
      text: "File (s)",
      value: "files",
      sortable: false,
    },
    {
      text: "View",
      value: "view",
      sortable: false,
    },
  ];
  items = [
    {
      type: "trust",
    },
    {
      type: "rm",
    },
    {
      type: "pob",
    },
    {
      type: "tax",
    },
    {
      type: "vat",
    },
    {
      type: "authID",
    },
    {
      type: "authPOR",
    },
  ];

  addCustomFile(): void {
    var checkAdd = this.items.find((elem) => elem.type == "add");

    if (checkAdd != undefined) {
      this.addFiles.push({ type: null, info: null, file: null });
    } else {
      this.items.push({ type: "add" });
      this.addFiles.push({ type: null, info: null, file: null });
    }
  }

  download(type: string): void {
    switch (type) {
      case "trust":
        this.downloadTrust(
          this.storedDocs.trustDoc != null ? this.storedDocs.trustDoc._id : 0
        );
        break;
      case "rm":
        this.downloadRM(
          this.storedDocs.rmDoc != null ? this.storedDocs.rmDoc._id : 0
        );
        break;
      case "pob":
        this.downloadBank(
          this.storedDocs.pobDoc != null ? this.storedDocs.pobDoc._id : 0
        );
        break;
      case "tax":
        this.downloadTN(
          this.storedDocs.tnDoc != null ? this.storedDocs.tnDoc._id : 0
        );
        break;
      case "vat":
        this.downloadVAT(
          this.storedDocs.vatDoc != null ? this.storedDocs.vatDoc._id : 0
        );
        break;
      case "authID":
        this.downloadAID(
          this.storedDocs.authID != null ? this.storedDocs.authID._id : 0
        );
        break;
      case "authPOR":
        this.downloadAPOR(
          this.storedDocs.authPOR != null ? this.storedDocs.authPOR._id : 0
        );
        break;
      case "add":
        this.downloadCustom(
          this.storedDocs.addFiles != null ? this.storedDocs.addFiles[0]._id : 0
        );
        break;
    }
  }

  @Watch("type", { immediate: true })
  async modalTypeChanged(): Promise<void> {
    console.log("Modal Details Type: ", this.type);
    if (this.type == "edit") {
      await this.getStoredData();
      setTimeout(this.asignStoredData(), 10);
    }
  }

  @Watch("accountNext", {
    immediate: true,
  })
  async onNextChanged(): Promise<void> {
    console.log("AccountNext", this.accountNext);
    if (this.accountNext == true) {
      if (this.type == "add") {
        try {
          this.loading = true;
          await this.UploadTrustPersonalDocuments();
          await this.UploadTrustCorpDocuments();
          if (this.addFiles.length > 0) {
            await this.UploadCustomFiles();
          }

          JSEClient.setAccountDocs({
            idNumber: this.authIdNumber ? this.authIdNumber : null,
            passportNumber: null,
            proofOfRegUploaded: this.deed ? true : false,
            proofOfResUploaded: this.authPOR ? true : false,
            regNumber: this.trustNumber ? this.trustNumber : null,
            taxNumber: this.taxNumber,
            vatNumber: this.vatNumber,
          });

          this.$notificationCreator.createSuccessNotification(
            "Supporting Documents Saved."
          );
          this.tab = true;
        } finally {
          this.loading = false;
        }
      }
      if (this.type == "edit") {
        try {
          this.loading = true;
          await this.UpdateTrustPersonalDocuments();
          await this.UpdateTrustCorpDocuments();
          if (this.addFiles.length > 0) {
            await this.UploadCustomFiles();
            await this.UpdateCustomDocs();
          } else if (this.addFiles.length == 0) {
            await this.UpdateCustomDocs();
          }

          JSEClient.setAccountDocs({
            idNumber: this.authIdNumber ? this.authIdNumber : null,
            passportNumber: null,
            proofOfRegUploaded: this.deed ? true : false,
            proofOfResUploaded: this.authPOR ? true : false,
            regNumber: this.trustNumber ? this.trustNumber : null,
            taxNumber: this.taxNumber,
            vatNumber: this.vatNumber,
          });

          this.$notificationCreator.createSuccessNotification(
            "Supporting Documents Updated."
          );
          //this.tab = true;
        } finally {
          this.accountNext = false;
          this.loading = false;
        }
      }
    }
  }

  async getBase64(file: Blob | File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  asignStoredData(): any {
    if (this.storedDocs.trustDoc != null) {
      this.trustNumber = this.storedDocs.trustDoc.trustNumber;
    }
    if (this.storedDocs.tnDoc != null) {
      this.taxNumber = this.storedDocs.tnDoc.taxNumber;
    }
    if (this.storedDocs.vatDoc != null) {
      this.vatNumber = this.storedDocs.vatDoc.vatNumber;
    }

    if (this.storedDocs.authID != null) {
      this.authIdNumber = this.storedDocs.authID.idNumber;
    }
  }

  async getStoredData(): Promise<void> {
    try {
      this.loading = true;
      var clientId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        clientId = key;
      }

      if (clientId != null) {
        const resTrust = await docsHook.getTrustDeed(clientId);
        this.trustNumber = resTrust.trustNumber;
        this.storedDocs.trustDoc = resTrust;

        const resTN = await docsHook.getTrustTaxByCode(clientId);
        this.taxNumber = resTN.taxNumber;
        this.storedDocs.tnDoc = resTN;

        const resVAT = await docsHook.getTrustVat(clientId);
        this.vatNumber = resVAT.vatNumber;
        this.storedDocs.vatDoc = resVAT;

        const resPOB = await docsHook.getTrustBankingByCode(clientId);
        this.storedDocs.pobDoc = resPOB;

        const resRM = await docsHook.getTrustMandate(clientId);
        this.storedDocs.rmDoc = resRM;

        const resAID = await docsHook.getTrustAuthIdDocumentByCode(clientId);
        this.authIdNumber = resAID.idNumber;
        this.storedDocs.authID = resAID;

        const resAPOR = await docsHook.getTrustPORDocumentByCode(clientId);
        this.storedDocs.authPOR = resAPOR;

        const resCustom = await customFileHook.getCustomFileByCode(clientId);
        this.storedDocs.addFiles = resCustom;
        if (
          this.storedDocs.addFiles != null &&
          this.storedDocs.addFiles != ""
        ) {
          this.items.push({ type: "add" });
          this.storedDocs.addFiles.forEach((elem: any) => {
            elem.updateFile = null as File | null;
          });
        }

        console.log("Stored Docs: ", this.storedDocs);
        return Promise.resolve();
      }
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async UpdateTrustPersonalDocuments(): Promise<void> {
    try {
      var clientId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        clientId = key;
      }

      var resultAID: string | ArrayBuffer | null = null;
      if (this.authId != null) {
        resultAID = await this.getBase64(this.authId);
      }

      var resultAPOR: string | ArrayBuffer | null = null;
      if (this.authPOR != null) {
        resultAPOR = await this.getBase64(this.authPOR);
      }

      var clientDocument: TrustPersonalDocsUpdate = {
        user: User.getLoggedInUser,
        entityId: clientId,
        identificationType: null,
        idFileId: null,
        idFile: null,
        saidNumber: null,
        incomeTax: null,
        incomeTaxId: null,
        incomeTaxUpload: null,
        authSAIDNumber: this.authIdNumber,
        idAuthId:
          this.storedDocs.authID != undefined
            ? this.storedDocs.authID._id
            : null,
        authIdUpload: this.authId != null ? resultAID : this.authId,
        authPORId:
          this.storedDocs.authPOR != undefined
            ? this.storedDocs.authPOR._id
            : null,
        authPOR: this.authPOR != null ? resultAPOR : this.authPOR,
      };

      console.log("Account Details: ", clientDocument);
      if (clientDocument != null) {
        const res = await docsHook.UpdateTrustPersonalDocuments(clientDocument);
      }

      return Promise.resolve();
    } catch (err: any) {
      //this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    }
  }

  async UpdateTrustCorpDocuments(): Promise<void> {
    try {
      var clientId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        clientId = key;
      }

      var resultTrust: string | ArrayBuffer | null = null;
      if (this.deed != null) {
        resultTrust = await this.getBase64(this.deed);
      }

      var resultTN: string | ArrayBuffer | null = null;
      if (this.taxNumberupload != null) {
        resultTN = await this.getBase64(this.taxNumberupload);
      }

      var resultVAT: string | ArrayBuffer | null = null;
      if (this.vatUpload != null) {
        resultVAT = await this.getBase64(this.vatUpload);
      }

      var resultPOB: string | ArrayBuffer | null = null;
      if (this.proofOfBankUpload != null) {
        resultPOB = await this.getBase64(this.proofOfBankUpload);
      }

      var resultRM: string | ArrayBuffer | null = null;
      if (this.resolution_mandate != null) {
        resultRM = await this.getBase64(this.resolution_mandate);
      }

      var clientDocument: TrustCorpDocsUpdate = {
        user: User.getLoggedInUser,
        entityId: clientId,
        taxNumber: this.taxNumber,
        taxNumberUploadId:
          this.storedDocs.tnDoc != undefined ? this.storedDocs.tnDoc._id : null,
        taxNumberUpload:
          this.taxNumberupload != null ? resultTN : this.taxNumberupload,
        bankUploadId:
          this.storedDocs.pobDoc != undefined
            ? this.storedDocs.pobDoc._id
            : null,
        bankUpload:
          this.proofOfBankUpload != null ? resultPOB : this.proofOfBankUpload,
        vatRegNumber: this.vatNumber,
        vatUploadId:
          this.storedDocs.vatDoc != undefined
            ? this.storedDocs.vatDoc._id
            : null,
        vatUpload: this.vatUpload != null ? resultVAT : this.vatUpload,
        mandateId:
          this.storedDocs.rmDoc != undefined ? this.storedDocs.rmDoc._id : null,
        mandate:
          this.resolution_mandate != null ? resultRM : this.resolution_mandate,
        regUploadId: null,
        regUpload: null,
        deedId:
          this.storedDocs.trustDoc != undefined
            ? this.storedDocs.trustDoc._id
            : null,
        deed: this.deed != null ? resultTrust : this.deed,
        trustNumber: this.trustNumber,
      };

      console.log("Account Details: ", clientDocument);
      if (clientDocument != null) {
        const res = await docsHook.UpdateTrustCorpDocuments(clientDocument);
      }
      return Promise.resolve();
    } catch (err: any) {
      //this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    }
  }

  async UploadTrustPersonalDocuments(): Promise<void> {
    try {
      var clientId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        clientId = key;
      }

      var AIDArray = [];
      const promisesAID: Promise<string | ArrayBuffer | null>[] = [];
      AIDArray.push(this.authId);
      AIDArray.forEach(async (elem: any) => {
        promisesAID.push(this.getBase64(new Blob([elem])));
      });
      const resultAID = await Promise.all(promisesAID);

      var clientDocument: TrustPersonalDocs = {
        user: User.getLoggedInUser,
        entityId: clientId,
        identificationType: null,
        idFile: null,
        saidNumber: null,
        incomeTax: null,
        incomeTaxUpload: null,
        authSAIDNumber: this.authIdNumber,
        authId: this.authId != null ? resultAID : this.authId,
      };

      console.log("Account Details: ", clientDocument);
      if (clientDocument != null) {
        const res = await docsHook.UploadTrustPersonalDocuments(clientDocument);
      }
      return Promise.resolve();
    } catch (err: any) {
      this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    }
  }

  async UploadTrustCorpDocuments(): Promise<void> {
    try {
      var clientId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        clientId = key;
      }

      var trustArray = [];
      const promisesTrust: Promise<string | ArrayBuffer | null>[] = [];
      trustArray.push(this.deed);
      trustArray.forEach(async (elem: any) => {
        promisesTrust.push(this.getBase64(new Blob([elem])));
      });
      const resultTrust = await Promise.all(promisesTrust);

      var taxNumberArray = [];
      const promisesTN: Promise<string | ArrayBuffer | null>[] = [];
      taxNumberArray.push(this.taxNumberupload);
      taxNumberArray.forEach(async (elem: any) => {
        promisesTN.push(this.getBase64(new Blob([elem])));
      });
      const resultTN = await Promise.all(promisesTN);

      var vatArray = [];
      const promisesVAT: Promise<string | ArrayBuffer | null>[] = [];
      vatArray.push(this.vatUpload);
      vatArray.forEach(async (elem: any) => {
        promisesVAT.push(this.getBase64(new Blob([elem])));
      });
      const resultVAT = await Promise.all(promisesVAT);

      var pobArray = [];
      const promisesPOB: Promise<string | ArrayBuffer | null>[] = [];
      pobArray.push(this.proofOfBankUpload);
      pobArray.forEach(async (elem: any) => {
        promisesPOB.push(this.getBase64(new Blob([elem])));
      });
      const resultPOB = await Promise.all(promisesPOB);

      var RMArray = [];
      const promisesRM: Promise<string | ArrayBuffer | null>[] = [];
      RMArray.push(this.resolution_mandate);
      RMArray.forEach(async (elem: any) => {
        promisesRM.push(this.getBase64(new Blob([elem])));
      });
      const resultRM = await Promise.all(promisesRM);

      var APORArray = [];
      const promisesAPOR: Promise<string | ArrayBuffer | null>[] = [];
      APORArray.push(this.authPOR);
      APORArray.forEach(async (elem: any) => {
        promisesAPOR.push(this.getBase64(new Blob([elem])));
      });
      const resultAPOR = await Promise.all(promisesAPOR);

      var clientDocument: TrustCorpDocs = {
        user: User.getLoggedInUser,
        entityId: clientId,
        taxNumber: this.taxNumber,
        taxNumberUpload:
          this.taxNumberupload != null ? resultTN : this.taxNumberupload,
        bankUpload:
          this.proofOfBankUpload != null ? resultPOB : this.proofOfBankUpload,
        vatRegNumber: this.vatNumber,
        vatUpload: this.vatUpload != null ? resultVAT : this.vatUpload,
        mandate:
          this.resolution_mandate != null ? resultRM : this.resolution_mandate,
        regUpload: null,
        deed: this.deed != null ? resultTrust : this.deed,
        trustNumber: this.trustNumber,
        authPOR: this.authPOR != null ? resultAPOR : this.authPOR,
      };

      console.log("Account Details: ", clientDocument);
      if (clientDocument != null) {
        const res = await docsHook.UploadTrustCorpDocuments(clientDocument);
      }
      return Promise.resolve();
    } catch (err: any) {
      this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    }
  }

  async UploadCustomFiles(): Promise<void> {
    var clientId: string | null = null;
    const key = localStorage.getItem("ClientFile/entityId");
    if (key != null) {
      clientId = key;
    }

    if (this.addFiles.length > 0) {
      var customDoc: CustomDocsList[] = [];

      for (let file of this.addFiles) {
        if (file.file != null) {
          customDoc.push({
            user: User.getLoggedInUser,
            entityId: clientId,
            number: file.info,
            fileType: file.type,
            customFile: await this.getBase64(file.file),
          });
        }
      }

      console.log("Custom Docs: ", customDoc);
      const res = await customFileHook
        .UploadCustomDocumentsList(customDoc)
        .catch((err) => {
          this.accountNext = false;
          this.tab = false;
          return Promise.reject(err);
        });
      return Promise.resolve();
    }
  }

  async UpdateCustomDocs(): Promise<void> {
    try {
      var closedCorpId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        closedCorpId = key;
      }

      if (this.storedDocs.addFiles != null && this.storedDocs.addFiles != "") {
        this.storedDocs.addFiles.forEach(async (elem: any) => {
          if (elem.updateFile != null) {
            var resultCustomFiles: string | ArrayBuffer | null = null;
            resultCustomFiles = await this.getBase64(
              new Blob([elem.updateFile])
            );

            var customDocs: CustomDocsUpdate = {
              user: User.getLoggedInUser,
              entityId: closedCorpId,
              number: elem.number,
              fileType: elem.fileType,
              customFileId:
                this.storedDocs.addFiles != undefined ? elem._id : null,
              customFile:
                this.storedDocs.addFiles != undefined
                  ? resultCustomFiles
                  : null,
            };

            console.log("Custom Docs: ", customDocs);
            const res = await customFileHook.UpdateCustomFile(customDocs);

            return Promise.resolve();
          }
        });
      }
    } catch (err: any) {
      //this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    }
  }

  async downloadTrust(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await docsHook
          .DownloadDeed(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download ID: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadBank(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await docsHook
          .DownloadTBankingDocument(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download POR: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadTN(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await docsHook
          .DownloadTTax(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download TN: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadVAT(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await docsHook
          .DownloadTVATDocument(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download IT: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadRM(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await docsHook
          .DownloadMandate(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download IT: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadAID(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await docsHook
          .DownloadAuthId(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download IT: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadAPOR(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await docsHook
          .DownloadPOR(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download IT: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadCustom(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await customFileHook
          .DownloadCustomFile(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download Reg: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }
}
