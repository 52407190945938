
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import dayjs from "dayjs";
import Hooks from "@/hooks";
import {
  Market,
  UserAccessModel,
  ClearingJSEFee,
  ClearingJSEFee_CREATE,
} from "models";
import User from "@/store/modules/user";

const { Markets, Clearing_JSE_Fees } = Hooks;
const marketsHook = Markets();
const clearingHook = Clearing_JSE_Fees();

@Component({})
export default class CLearing_JSEFees extends Vue {
  loading = false;
  markets: Market[] = [];
  market: number | null = null;
  date = dayjs().format("YYYY-MM-DD");
  dateMenu = false;
  saveModal = false;
  saveAsPDF = false;
  saveAsExcel = false;
  client: {
    id: number;
    marketId: number | null;
    name: string | null;
    absaFuture: number | null;
    absaOption: number | null;
    absaDelivery: number | null;
    jseFuture: number | null;
    jseOption: number | null;
    jseDelivery: number | null;
    bookoversJSEFuture: number | null;
    bookoversJSEOption: number | null;
    tonnes: number | null;
    createdBy: string | null;
    createdDate: string | null;
    updatedBy: string | null;
    updatedDate: string | null;
    type: string;
  }[] = [];
  clientNew: {
    id: number;
    marketId: number | null;
    name: string | null;
    absaFuture: number | null;
    absaOption: number | null;
    absaDelivery: number | null;
    jseFuture: number | null;
    jseOption: number | null;
    jseDelivery: number | null;
    bookoversJSEFuture: number | null;
    bookoversJSEOption: number | null;
    tonnes: number | null;
    createdBy: string | null;
    createdDate: string | null;
    updatedBy: string | null;
    updatedDate: string | null;
    type: string;
  }[] = [];
  editedIndex = -1;
  editedItem = {
    id: 0,
    marketId: 0,
    name: "",
    absaFuture: 0,
    absaOption: 0,
    absaDelivery: 0,
    jseFuture: 0,
    jseOption: 0,
    jseDelivery: 0,
    bookoversJSEFuture: 0,
    bookoversJSEOption: 0,
    tonnes: 0,
    createdBy: "",
    createdDate: "",
    updatedBy: "",
    updatedDate: "",
    type: "",
  };
  defaultItem = {
    id: 0,
    marketId: 0,
    name: "",
    absaFuture: 0,
    absaOption: 0,
    absaDelivery: 0,
    jseFuture: 0,
    jseOption: 0,
    jseDelivery: 0,
    bookoversJSEFuture: 0,
    bookoversJSEOption: 0,
    tonnes: 100,
    createdBy: "",
    createdDate: "",
    updatedBy: "",
    updatedDate: "",
    type: "",
  };
  headers = [
    {
      text: "Name",
      value: "name",
      sortable: false,
      style: "width: 200px",
    },
    {
      text: "Tonnes",
      value: "tonnes",
      sortable: false,
      style: "width: 200px",
    },
    {
      text: "ABSA Future",
      value: "absaFuture",
      sortable: false,
      style: "width: 200px",
    },
    {
      text: "ABSA Option",
      value: "absaOption",
      sortable: false,
      style: "width: 200px",
    },
    {
      text: "ABSA Delivery",
      value: "absaDelivery",
      sortable: false,
      style: "width: 200px",
    },
    {
      text: "JSE Future",
      value: "jseFuture",
      sortable: false,
      style: "width: 200px",
    },
    {
      text: "JSE Option",
      value: "jseOption",
      sortable: false,
      style: "width: 200px",
    },
    {
      text: "JSE Delivery",
      value: "jseDelivery",
      sortable: false,
      style: "width: 200px",
    },
    {
      text: "Bookovers (JSE Future)",
      value: "bookoversJSEFuture",
      sortable: false,
      style: "width: 200px",
    },
    {
      text: "Bookovers (JSE Option)",
      value: "bookoversJSEOption",
      sortable: false,
      style: "width: 200px",
    },
    {
      text: "Actions",
      value: "actions",
    },
  ];
  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
    email: (value: string): boolean | string => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },
    cell: (value: string | null = null): boolean | string =>
      value != null ? value.length == 10 : "" || "Enter a valid Contact Number",
  };

  async mounted(): Promise<void> {
    await this.getMarkets();
    await this.init();
  }

  get getUserAccess(): UserAccessModel | null {
    console.log("UA: ", User.getUserAccess);
    return User.getUserAccess;
  }

  editItem(item: any): void {
    if (item.type == "new") {
      this.editedIndex = this.clientNew.indexOf(item);
      this.editedItem = Object.assign({}, item);
    }
    if (item.type == "existing") {
      this.editedIndex = this.client.indexOf(item);
      this.editedItem = Object.assign({}, item);
    }
  }

  deleteItem(item: any): void {
    const index = this.clientNew.indexOf(item);

    this.clientNew.splice(index, 1);
  }

  close(): void {
    setTimeout(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    }, 100);
  }

  addNew(): void {
    const addObj = Object.assign({}, this.defaultItem);
    addObj.type = "new";
    if (this.getItems.length > 0) {
      addObj.id = this.getItems[this.getItems.length - 1].id + 1;
    }
    if (this.getItems.length == 0) {
      addObj.id = this.getItems.length + 1;
    }

    this.clientNew.push(addObj);
    this.editItem(addObj);
  }

  save(): void {
    if (this.editedIndex > -1) {
      if (this.editedItem.type == "new") {
        Object.assign(this.clientNew[this.editedIndex], this.editedItem);
        console.log("Client New: ", this.clientNew);
      }
      if (this.editedItem.type == "existing") {
        Object.assign(this.client[this.editedIndex], this.editedItem);
        console.log("Client: ", this.client);
      }
    }
  }

  clear(): void {
    this.clientNew = [];
  }

  get getItems(): {
    id: number;
    name: any | null;
    absaFuture: any | null;
    absaOption: any | null;
    absaDelivery: any | null;
    jseFuture: any | null;
    jseOption: any | null;
    jseDelivery: any | null;
    bookoversJSEFuture: any | null;
    bookoversJSEOption: any | null;
    tonnes: number | null;
    createdBy: string | null;
    createdDate: string | null;
    updatedBy: string | null;
    updatedDate: string | null;
  }[] {
    var items: {
      id: number;
      name: any | null;
      absaFuture: any | null;
      absaOption: any | null;
      absaDelivery: any | null;
      jseFuture: any | null;
      jseOption: any | null;
      jseDelivery: any | null;
      bookoversJSEFuture: any | null;
      bookoversJSEOption: any | null;
      tonnes: number | null;
      createdBy: string | null;
      createdDate: string | null;
      updatedBy: string | null;
      updatedDate: string | null;
    }[] = [];

    if (this.market != null) {
      if (this.client.length > 0) {
        this.client.forEach((elem) => {
          items.push(elem);
        });
      }

      if (this.clientNew.length > 0) {
        this.clientNew.forEach((elem) => {
          items.push(elem);
        });
      }
      console.log("Items: ", items);
      return items;
    } else {
      return items;
    }
  }

  async saveClearing(): Promise<void> {
    try {
      this.loading = true;
      if (this.client.length > 0) {
        await this.updateClearing();
      }
      if (this.clientNew.length > 0) {
        await this.addNewClearing();
      }
      this.$notificationCreator.createSuccessNotification(
        "Clearing & JSE Fees Saved."
      );
    } finally {
      this.saveModal = false;
      this.loading = false;
      await this.init();
    }
  }

  async getMarkets(): Promise<void> {
    try {
      const res = await marketsHook.getAllMarkets();
      this.markets = res;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async init(): Promise<void> {
    try {
      this.loading = true;
      this.client = [];
      this.clientNew = [];
      const res = await clearingHook.getAllClearing();
      console.log("getAllClearing: ", res);
      if (res != null && res != undefined && res.length > 0) {
        res.forEach((elem) => {
          if (elem.marketId == this.market) {
            this.client.push({
              id: elem._id,
              marketId: elem.marketId,
              name: elem.instrument,
              absaFuture: elem.absaFuture,
              absaOption: elem.absaOption,
              absaDelivery: elem.absaDelivery,
              jseFuture: elem.jseFuture,
              jseOption: elem.jseOption,
              jseDelivery: elem.jseDelivery,
              bookoversJSEFuture: elem.bookoversJSEFuture,
              bookoversJSEOption: elem.bookoversJSEOption,
              tonnes: elem.tonnes,
              createdBy: elem.createdBy,
              createdDate: elem.createdDate,
              updatedBy: elem.updatedBy,
              updatedDate: elem.updatedDate,
              type: "existing",
            });
          }
        });
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async addNewClearing(): Promise<void> {
    try {
      for (let elem of this.clientNew) {
        const clearing: ClearingJSEFee_CREATE = {
          instrument: elem.name,
          marketId: this.market,
          absaFuture: elem.absaFuture,
          absaOption: elem.absaOption,
          absaDelivery: elem.absaDelivery,
          jseFuture: elem.jseFuture,
          jseOption: elem.jseOption,
          jseDelivery: elem.jseDelivery,
          bookoversJSEFuture: elem.bookoversJSEFuture,
          bookoversJSEOption: elem.bookoversJSEOption,
          tonnes: elem.tonnes,
        };
        const res = await clearingHook.addClearing(clearing);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async updateClearing(): Promise<void> {
    try {
      for (let elem of this.client) {
        const clearing: ClearingJSEFee = {
          instrument: elem.name,
          marketId: this.market,
          absaFuture: elem.absaFuture,
          absaOption: elem.absaOption,
          absaDelivery: elem.absaDelivery,
          jseFuture: elem.jseFuture,
          jseOption: elem.jseOption,
          jseDelivery: elem.jseDelivery,
          bookoversJSEFuture: elem.bookoversJSEFuture,
          bookoversJSEOption: elem.bookoversJSEOption,
          tonnes: elem.tonnes,
          _id: elem.id,
          createdBy: elem.createdBy,
          createdDate: elem.createdDate,
          updatedBy: elem.updatedBy,
          updatedDate: elem.updatedDate,
        };
        const res = await clearingHook.updateClearing(clearing);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async Download(): Promise<void> {
    try {
      /*  */
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.saveModal = false;
    }
  }
}
