
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import dayjs from "dayjs";
import {
  UserProfile,
  UserProfilewPass,
  UserProfile_CREATE,
  UserProfile_CREATEwPass,
} from "models";
import Hooks from "@/hooks";

const { UserProfiles } = Hooks;
const UserProfileHook = UserProfiles();

@Component({})
export default class Profile extends Vue {
  @PropSync("valid") formValid!: boolean;
  @PropSync("clear") formClear!: boolean;
  @PropSync("next") accountNext!: boolean;
  @Prop() type!: string;
  @Prop() modalData!: UserProfilewPass;
  loading = false;
  password = "";
  passwordConfirm = "";
  showPassword = false;
  showPasswordConfirm = false;
  profile: UserProfile_CREATEwPass = {
    _id: 0,
    password: null,
    userId: null,
    firstName: null,
    lastName: null,
    email: null,
    alternativeEmail: null,
    title: null,
    cellNumber: null,
    organisation: null,
    occupation: null,
    branchCode: null,
    saId: null,
    passportNo: null,
    dateOfBirth: null,
    gender: null,
    roleName: null,
    homeTel: null,
    workTel: null,
    maritalStatus: null,
    language: null,
    province: null,
    street: null,
    city: null,
    createdBy: null,
    createdDate: dayjs().format("YYYY-MM-DD"),
  };
  dobMenu = false;
  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
    email: (value: string): boolean | string => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },
    id: (value: string): boolean | string =>
      value?.length == 13 || "Requires 13 digits",
    cell: (value: string | null = null): boolean | string =>
      value != null ? value.length >= 10 : "" || "Enter a valid Contact Number",
    counter: (value: string): boolean | string =>
      value.length <= 20 || "Max 20 characters",

    uppercase: (value: string): boolean | string => {
      const pattern = /^(?=.*[A-Z])/;
      return pattern.test(value) || "Requires atleast 1 uppercase letter";
    },
    lowercase: (value: string): boolean | string => {
      const pattern = /^(?=.*[a-z])/;
      return pattern.test(value) || "Requires atleast 1 lowercase letter";
    },
    matchingPasswords: (value: string): boolean | string =>
      this.checkPasswordsMatch(value) || `Passwords do not match`,
    digit: (value: string): boolean | string => {
      const pattern = /^(?=.*\d)/;
      return pattern.test(value) || "Requires atleast 1 digit";
    },
    special: (value: string): boolean | string => {
      const pattern = /^(?=.*\W)/;
      return pattern.test(value) || "Requires atleast 1 special character";
    },
  };

  checkPasswordsMatch(pass: string): boolean {
    if (pass == this.profile.password) {
      return true;
    } else {
      return false;
    }
  }

  validate(): void {
    //   console.log("Outside validate");
    if (this.$refs.form) {
      //   console.log("Outside inside");
      (this.$refs.form as any).validate();
    }
  }

  @Watch("type", { immediate: true })
  async modalTypeChanged(): Promise<void> {
    console.log("Modal Type (P): ", this.type);
    console.log("Modal Data (P): ", this.modalData);

    if (this.type == "edit") {
      await this.getUserProfilebyEmail();
    }
    if (this.type == "add") {
      this.profile = {
        _id: 0,
        password: null,
        userId: null,
        firstName: null,
        lastName: null,
        email: null,
        alternativeEmail: null,
        title: null,
        cellNumber: null,
        organisation: null,
        occupation: null,
        branchCode: null,
        saId: null,
        passportNo: null,
        dateOfBirth: null,
        gender: null,
        roleName: null,
        homeTel: null,
        workTel: null,
        maritalStatus: null,
        language: null,
        province: null,
        street: null,
        city: null,
        createdBy: null,
        createdDate: dayjs().format("YYYY-MM-DD"),
      };
      this.passwordConfirm = "";
    }
  }

  async getUserProfilebyEmail(): Promise<void> {
    try {
      this.loading = true;
      if (this.modalData != null) {
        const res = await UserProfileHook.getUserProfileByEmail(
          this.modalData.email
        );
        console.log("Modal Data (Ppp) res: ", res);
        if (res) {
          this.profile = res as UserProfilewPass;

          console.log("Modal Data (Ppp): ", this.profile);
          if (this.profile.dateOfBirth != null) {
            this.profile.dateOfBirth = dayjs(res.dateOfBirth).format(
              "YYYY-MM-DD"
            );
          }
        }
        return Promise.resolve();
      }
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  /* @Watch("formClear", {
    immediate: true,
  })
  clear(): void {
    const refForm: any = this.$refs.form;
    refForm.reset();
    this.formClear = false;
  } */

  @Watch("accountNext", {
    immediate: true,
  })
  async onNextChanged(): Promise<void> {
    console.log("AccountNext", this.accountNext);
    if (this.accountNext == true) {
      if (this.type == "edit") {
        await this.editClientAccountDetails();
      }
      if (this.type == "add") {
        await this.addClientAccountDetails();
      }
    }
  }

  async editClientAccountDetails(): Promise<void> {
    try {
      this.loading = true;
      if (this.profile != null) {
        console.log(this.profile.dateOfBirth);
        if (this.profile.dateOfBirth) {
          this.profile.dateOfBirth = dayjs(this.profile.dateOfBirth).format(
            "YYYY-MM-DD"
          );
        } else {
          this.profile.dateOfBirth = null;
        }
        const res = await UserProfileHook.updateUserProfile(
          this.profile as UserProfilewPass
        );
        this.$notificationCreator.createSuccessNotification(
          "User Profile Updated."
        );

        return Promise.resolve();
      }
    } catch (err: any) {
      this.accountNext = false;
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async addClientAccountDetails(): Promise<void> {
    try {
      this.loading = true;
      if (this.profile != null) {
        if (this.profile.dateOfBirth) {
          this.profile.dateOfBirth = dayjs(this.profile.dateOfBirth).format(
            "YYYY-MM-DD"
          );
        } else {
          this.profile.dateOfBirth = null;
        }
        const res = await UserProfileHook.addNewUserProfile(
          this.profile as UserProfilewPass
        );
        this.$emit("updateModalData", this.profile.email);
        this.$notificationCreator.createSuccessNotification(
          "User Profile Created."
        );

        return Promise.resolve();
      }
    } catch (err: any) {
      this.accountNext = false;
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
