
import { Component, Vue } from "vue-property-decorator";
import User from "@/store/modules/user";
import Dash from "@/store/modules/Dash";
import { axiosInstance } from "@/plugins/axios";
import { UserAccessModel } from "models";

@Component
export default class NavDrawer extends Vue {
  logoModal = false;
  logo: File | null = null;
  loading = false;
  logoForm = false;
  success = false;
  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
  };

  get checkManage(): boolean {
    if (this.getUserAccess != null) {
      if (
        this.getUserAccess.accessRatingCodes == false &&
        this.getUserAccess.accessBanks == false &&
        this.getUserAccess.accessMarkets == false &&
        this.getUserAccess.accessTraders == false
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  async getBase64(file: Blob | File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  async UploadLogo(): Promise<void> {
    try {
      this.loading = true;

      var logoArray = [];
      const promiseslogo: Promise<string | ArrayBuffer | null>[] = [];
      logoArray.push(this.logo);
      logoArray.forEach(async (elem: any) => {
        promiseslogo.push(this.getBase64(new Blob([elem])));
      });
      const resultlogo = await Promise.all(promiseslogo);

      const res = await axiosInstance.post(`/api/Logo/UploadLogo`, {
        user: User.getLoggedInUser,
        logoUpload: resultlogo,
      });
      Promise.resolve();

      this.success = true;
    } catch (err) {
      Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  get getUserAccess(): UserAccessModel | null {
    console.log("UA: ", User.getUserAccess);
    return User.getUserAccess;
  }

  get getUser(): string | null {
    console.log("Logged In: ", User.getLoggedInUser);
    return User.getLoggedInUser;
  }

  get appDrawer(): boolean {
    return Dash.getAppDrawer;
  }

  flipDrawer(trust: boolean): void {
    Dash.setAppDrawer(trust);
  }

  logout(): void {
    User.removeLoggedIn();
    this.$router.push({
      name: "Auth",
    });
  }
}
