
import { Component, Vue, Watch } from "vue-property-decorator";
import AddEdittraders from "@/components/Manage/Traders/AddEditTraders.vue";
import { Market, Trader, UserAccessModel } from "models";
import Hooks from "@/hooks";
import User from "@/store/modules/user";

const { Traders, Markets } = Hooks;
const traderHook = Traders();
const marketHook = Markets();

@Component({
  components: {
    AddEdittraders,
  },
})
export default class TradersView extends Vue {
  items: Trader[] = [];
  markets: Market[] = [];
  filter: number | null = null;
  loading = false;
  search = "";
  sortDesc = false;
  sortBy = "branch";
  modal = false;
  deleteModal = false;
  deleteItem = {} as Trader;
  modalData = {
    type: "",
    fields: {},
    markets: this.markets,
  };
  refresh = false;
  headers = [
    { text: "Market Code", value: "marketId" },
    { text: "Dealer Code", value: "dealerCode" },
    { text: "Branch", value: "branch" },
    { text: "FullName", value: "traderName" },
    { text: "Email", value: "traderEmail" },
    { text: "Organisation", value: "organisation" },
    { text: "", value: "actions", sortable: false },
  ];

  @Watch("refresh", { immediate: true })
  async modalDataChanged(): Promise<void> {
    if (this.refresh == true) {
      console.log("Refresh Triggered");
      await this.init();
    }
  }

  async filterByMarket(): Promise<void> {
    await this.init();
    if (this.filter != null) {
      this.items = this.items.filter((elem) => elem.marketId == this.filter);
    }
  }

  get getUserAccess(): UserAccessModel | null {
    console.log("UA: ", User.getUserAccess);
    return User.getUserAccess;
  }

  openEdit(i: Trader): void {
    this.modalData.type = "edit";
    this.modalData.fields = {
      branch: i.branch,
      createdBy: i.createdBy,
      createdDate: i.createdDate,
      dealerCode: i.dealerCode,
      _id: i._id,
      marketId: i.marketId,
      organisation: i.organisation,
      traderEmail: i.traderEmail,
      traderName: i.traderName,
      updatedBy: i.updatedBy,
      updatedDate: i.updatedDate,
    };
    this.modalData.markets = this.markets;
    this.modal = true;
  }

  openAdd(): void {
    this.modalData.type = "add";
    this.modalData.fields = {};
    this.modalData.markets = this.markets;
    this.modal = true;
  }

  openDelete(i: Trader): void {
    this.deleteItem = i;
    this.deleteModal = true;
  }

  async deleteTrader(): Promise<void> {
    try {
      const res = await traderHook.deleteTrader(this.deleteItem);
      this.$notificationCreator.createSuccessNotification("Trader Deleted.");
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.deleteModal = false;
      this.init();
    }
  }

  async mounted(): Promise<void> {
    await this.init();
    await this.getMarkets();
  }

  async getMarkets(): Promise<void> {
    try {
      const res = await marketHook.getAllMarkets();
      this.markets = res;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  getMarketCode(id: number): string | number {
    var result = this.markets.find((market) => market.id == id);
    if (result != undefined) {
      return result.marketCode;
    } else {
      return id;
    }
  }

  async init(): Promise<void> {
    try {
      this.loading = true;
      const res = await traderHook.getAllTraders();
      this.items = res;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
      this.getMarkets();
    }
  }
}
