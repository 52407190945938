import { axiosInstance } from "@/plugins/axios";
import { ClientKYC, ClientKYC_CREATE, AccountKYC } from "models";

const PartnershipKYCs = () => {
  const baseUrl = "/api/Partnership";

  const getAllPartnershipKYC = async (): Promise<ClientKYC[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAllPartnerKYC");
    return Promise.resolve(res.data);
  };

  const getPartnershipKYCByCode = async (code: string): Promise<ClientKYC> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPartnerKYCByCode?code=${code}`
    );
    return Promise.resolve(res.data);
  };

  const addNewPartnershipKYC = async (
    clientKYC: ClientKYC_CREATE
  ): Promise<any> => {
    const res = await axiosInstance.post(baseUrl + "/addNewPartnerKYC", {
      entityId: clientKYC.entityId,
      sourceOfIncome: clientKYC.sourceOFIncome,
      currentEarningsSector: clientKYC.currentEarningsSector,
      experienceLevel: clientKYC.experienceLevel,
      accountFunds: clientKYC.accountFunds,
      incomeBand: clientKYC.incomeBand,
      riskAppetite: clientKYC.riskAppetite,
      dpip: clientKYC.dpip,
      job: clientKYC.job,
      geoLocation: clientKYC.geoLocation,
      influentialPerson: clientKYC.influentialPerson,
    });
    return Promise.resolve(res.data);
  };

  const updatePartnershipKYC = async (clientKYC: ClientKYC): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/updatePartnerKYC", {
      entityId: clientKYC.entityId,
      sourceOfIncome: clientKYC.sourceOFIncome,
      currentEarningsSector: clientKYC.currentEarningsSector,
      investmentExperience: clientKYC.investmentExperience,
      accountFunds: clientKYC.accountFunds,
      incomeBand: clientKYC.incomeBand,
      riskAppetite: clientKYC.riskAppetite,
      dpip: clientKYC.dpip,
      job: clientKYC.job,
      geoLocation: clientKYC.geoLocation,
      influentialPerson: clientKYC.influentialPerson,
      createdBy: clientKYC.createdBy,
      createdDate: clientKYC.createdDate,
      id: clientKYC.id,
      updatedBy: clientKYC.updatedBy,
      updatedDate: clientKYC.updatedDate,
      _id: clientKYC._id,
    });
    return Promise.resolve(res.data);
  };

  const deletePartnershipKYC = async (clientKYC: ClientKYC): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/deletePartnerKYC", {
      entityId: clientKYC.entityId,
      sourceOfIncome: clientKYC.sourceOFIncome,
      currentEarningsSector: clientKYC.currentEarningsSector,
      investmentExperience: clientKYC.investmentExperience,
      accountFunds: clientKYC.accountFunds,
      incomeBand: clientKYC.incomeBand,
      riskAppetite: clientKYC.riskAppetite,
      dpip: clientKYC.dpip,
      job: clientKYC.job,
      geoLocation: clientKYC.geoLocation,
      influentialPerson: clientKYC.influentialPerson,
      createdBy: clientKYC.createdBy,
      createdDate: clientKYC.createdDate,
      id: clientKYC.id,
      updatedBy: clientKYC.updatedBy,
      updatedDate: clientKYC.updatedDate,
      _id: clientKYC._id,
    });
    return Promise.resolve(res.data);
  };

  return {
    getAllPartnershipKYC,
    getPartnershipKYCByCode,
    addNewPartnershipKYC,
    updatePartnershipKYC,
    deletePartnershipKYC,
  };
};
export default PartnershipKYCs;
