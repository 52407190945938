
import { Component, Vue } from "vue-property-decorator";
import dayjs from "dayjs";
import Hooks from "@/hooks";
import { BankTransfer, BankTransferPDF, UserAccessModel } from "models";
import User from "@/store/modules/user";

const { Excel, AccountTransfersPDF } = Hooks;
const excelHook = Excel();
const accountTransfersPDFHook = AccountTransfersPDF();

@Component({})
export default class TransferBetweenBanks extends Vue {
  loading = false;
  date = dayjs().format("YYYY-MM-DD");
  dateMenu = false;
  saveModal = false;
  saveAsPDF = false;
  saveAsExcel = false;
  bank: {
    id: number;
    safexCode: string | null;
    name: string | null;
    market: string | null;
    bankName: string | null;
    accountName: string | null;
    accountNumber: string | null;
    branchName: string | null;
    branchCode: string | null;
    amount: number | null;
  }[] = [];
  editedIndex = -1;
  editedItem = {
    id: 0,
    safexCode: "",
    name: "",
    market: "",
    bankName: "",
    accountName: "",
    accountNumber: "",
    branchName: "",
    branchCode: "",
    amount: null,
  };
  defaultItem = {
    id: 0,
    safexCode: "",
    name: "",
    market: "",
    bankName: "",
    accountName: "",
    accountNumber: "",
    branchName: "",
    branchCode: "",
    amount: null,
  };
  headers = [
    {
      text: "#",
      value: "id",
      sortable: false,
    },
    {
      text: "Safex Code",
      value: "safexCode",
      sortable: false,
    },
    {
      text: "Name",
      value: "name",
      sortable: false,
      style: "width: 12%",
    },
    {
      text: "Market",
      value: "market",
      sortable: false,
    },
    {
      text: "Bank Name",
      value: "bankName",
      sortable: false,
      style: "width: 12%",
    },
    {
      text: "Account Name",
      value: "accountName",
      sortable: false,
      style: "width: 12%",
    },
    {
      text: "Account Number",
      value: "accountNumber",
      sortable: false,
    },
    {
      text: "Branch Name",
      value: "branchName",
      sortable: false,
      style: "width: 12%",
    },
    {
      text: "Branch Code",
      value: "branchCode",
      sortable: false,
    },
    {
      text: "Amount",
      value: "amount",
      sortable: false,
      style: "width: 10%",
    },
    {
      text: "Actions",
      value: "actions",
      sortable: false,
    },
  ];
  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
  };

  uppercase(): void {
    this.editedItem.safexCode = this.editedItem.safexCode.toUpperCase();
  }

  get getUserAccess(): UserAccessModel | null {
    console.log("UA: ", User.getUserAccess);
    return User.getUserAccess;
  }

  mounted(): void {
    this.addNew();
  }

  editItem(item: any): void {
    this.editedIndex = this.bank.indexOf(item);
    this.editedItem = Object.assign({}, item);
  }

  deleteItem(item: any): void {
    const index = this.bank.indexOf(item);

    this.bank.splice(index, 1);
  }

  close(): void {
    setTimeout(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    }, 100);
  }

  addNew(): void {
    const addObj = Object.assign({}, this.defaultItem);
    addObj.id = this.bank.length + 1;
    this.bank.unshift(addObj);
    this.editItem(addObj);
  }

  save(): void {
    if (this.editedIndex > -1) {
      Object.assign(this.bank[this.editedIndex], this.editedItem);
      console.log("Client: ", this.bank);
    }
  }

  clear(): void {
    this.bank = [];
    this.addNew();
  }

  async getSafexData(safexCode: string): Promise<void> {
    try {
      const res = await excelHook.getSafexBankData(safexCode);
      console.log("Safex Bank Data: ", res);

      if (this.bank.length > 1) {
        console.log(this.bank[this.bank.length - 1].market);
        if (this.bank[this.bank.length - 1].market == res.market) {
          this.editedItem.name = res.name;
          this.editedItem.market = res.market;
          this.editedItem.bankName = res.bankName;
          this.editedItem.accountName = res.accountName;
          this.editedItem.accountNumber = res.accountNumber;
          this.editedItem.branchName = res.branchName;
          this.editedItem.branchCode = res.branchCode;

          return Promise.resolve();
        } else {
          this.$notificationCreator.createErrorNotification(
            "Safex Code/ JSE Code entered can't be used. The Market is not the same as the first entry's Market."
          );
          return Promise.reject();
        }
      } else {
        this.editedItem.name = res.name;
        this.editedItem.market = res.market;
        this.editedItem.bankName = res.bankName;
        this.editedItem.accountName = res.accountName;
        this.editedItem.accountNumber = res.accountNumber;
        this.editedItem.branchName = res.branchName;
        this.editedItem.branchCode = res.branchCode;
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async downloadFile(): Promise<void> {
    if (this.saveAsPDF == true) {
      await this.DownloadPDF();
    }
    if (this.saveAsExcel == true) {
      await this.DownloadExcel();
    }
  }

  async DownloadPDF(): Promise<void> {
    try {
      this.bank.sort((a, b) => a.id - b.id);
      var bank: BankTransferPDF[] = [];
      this.bank.forEach((elem) => [
        bank.push({
          market: elem.market,
          branchName: elem.branchName,
          amount: elem.amount,
          lineNumber: elem.id,
          clientName: elem.name,
          fromSafexCode: elem.safexCode,
          toBankName: elem.bankName,
          toMarket: elem.market,
          dateOfTransfer: this.date,
          branchNumber: elem.branchCode,
          reference: elem.safexCode,
          logoFileId: 1,
          accountNumber: elem.accountNumber,
        }),
      ]);
      console.log("Bank PDF", bank);
      const res = await accountTransfersPDFHook
        .getBankTransferPDF(bank)
        .then((response: any) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `TB ${dayjs().format("YYYY-MM-DD")}.pdf`
          );
          document.body.appendChild(link);
          link.click();
        });

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async DownloadExcel(): Promise<void> {
    try {
      this.bank.sort((a, b) => a.id - b.id);
      var bank: BankTransfer[] = [];
      this.bank.forEach((elem) => [
        bank.push({
          safexCode: elem.safexCode,
          name: elem.name,
          market: elem.market,
          bankName: elem.bankName,
          accountName: elem.accountName,
          accountNumber: elem.accountNumber,
          branchName: elem.branchName,
          branchCode: elem.branchCode,
          amount: elem.amount,
          depositDate: this.date,
          lineNumber: elem.id,
        }),
      ]);
      console.log("Bank Excel", bank);
      const res = await excelHook
        .DownloadBankAccountTransferExcel(bank)
        .then((response: any) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `TB ${dayjs().format("YYYY-MM-DD")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
        });

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
}
