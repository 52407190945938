import { notification } from "models";
import { NOTIFICATION_TYPES } from "@/types";
import colors from "vuetify/lib/util/colors";
import Notifications from "@/store/modules/notifications";
import _Vue from "vue";

export class NotificationCreator {
  createErrorNotification(text: string): notification {
    const obj = {
      id: Date.now(),
      text: text,
      color: colors.red.base,
      type: NOTIFICATION_TYPES.ERROR,
      timeout: -1,
      showing: true,
    } as notification;
    Notifications.addSnackbar(obj);
    return obj;
  }
  createSuccessNotification(text: string): notification {
    const obj = {
      id: Date.now(),
      text: text,
      color: colors.green.base,
      type: NOTIFICATION_TYPES.SUCCESS,
      timeout: 2000,
      showing: true,
    } as notification;
    Notifications.addSnackbar(obj);
    return obj;
  }
  createWarningNotification(text: string): notification {
    const obj = {
      id: Date.now(),
      text: text,
      color: colors.orange.base,
      type: NOTIFICATION_TYPES.WARNING,
      timeout: 5000,
      showing: true,
    } as notification;
    Notifications.addSnackbar(obj);

    return obj;
  }
}
const notificationCreator = new NotificationCreator();

export default {
  install(Vue: typeof _Vue, options?: any) {
    Vue.prototype.$notificationCreator = notificationCreator;
  },
};
