
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { ClientBanking_CREATE, Bank } from "models";
import Hooks from "@/hooks";
import JSEClient from "@/store/modules/JSEClient";

const { PrivateBanking, Banks, PrivateAccount } = Hooks;
const privateHook = PrivateBanking();
const bankHook = Banks();
const privateAccountHook = PrivateAccount();

@Component({})
export default class Banking extends Vue {
  @PropSync("valid") formValid!: boolean;
  @PropSync("nextTab") tab!: boolean;
  @PropSync("next") accountNext!: boolean;
  @Prop() type!: string;
  loading = false;
  dataCheck = false;
  clientBank: ClientBanking_CREATE = {
    bankId: null,
    accountType: null,
    branchName: null,
    accountHolder: null,
    branchCode: null,
    bankSwiftNumber: null,
    accountNumber: null,
    bankIBANNumber: null,
    entityId: null,
  };
  banks: Bank[] = [];
  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
  };

  getBranchCode(): void {
    console.log(this.clientBank.bankId);
    this.banks.forEach((elem) => {
      if (elem.id == this.clientBank.bankId) {
        this.clientBank.branchCode = elem.branchCode;
      }
    });
  }

  @Watch("type", { immediate: true })
  async modalTypeChanged(): Promise<void> {
    console.log("Modal Details Type: ", this.type);
    if (this.type == "edit") {
      await this.getStoredData();
    }
    if (this.type == "add") {
      await this.getAccountDetails();
    }
  }

  async getAccountDetails(): Promise<void> {
    try {
      var entityId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        entityId = key;
      }

      if (entityId != null) {
        const res = await privateAccountHook.getPrivateCompAccountByCode(
          entityId
        );

        console.log("Get Stored Data res: ", res);
        this.clientBank.accountHolder = res.accountName;
        return Promise.resolve();
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  }

  async getStoredData(): Promise<void> {
    try {
      this.loading = false;
      var entityId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        entityId = key;
      }

      if (entityId != null) {
        const res = await privateHook.getPrivateCompBankingByCode(entityId);
        if (res != undefined && (res as any) != "") {
          this.dataCheck = true;
          console.log("Get Stored Data res: ", res);
          this.clientBank = res as any;
        } else {
          this.dataCheck = false;
        }
        return Promise.resolve();
      }
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async mounted(): Promise<void> {
    await this.getBanks();
    if (this.type == "add") {
      await this.getAccountDetails();
    }
  }

  @Watch("accountNext", {
    immediate: true,
  })
  async onNextChanged(): Promise<void> {
    console.log("AccountNext", this.accountNext);
    if (this.accountNext == true) {
      if (this.type == "add") {
        await this.addNewClientDetails();
      }
      if (this.type == "edit") {
        if (this.dataCheck == true) {
          await this.editClientDetails();
        } else {
          await this.addNewClientDetails();
        }
      }
    }
  }

  async addNewClientDetails(): Promise<void> {
    try {
      this.loading = true;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        this.clientBank.entityId = key;
      }
      console.log("Banking Details: ", this.clientBank);

      const res = await privateHook.addNewPrivateCompBanking(this.clientBank);

      JSEClient.setAccountBanking({
        accountNumber: this.clientBank.accountNumber,
        bankSwiftNumber: this.clientBank.bankSwiftNumber,
      });

      this.$notificationCreator.createSuccessNotification(
        "Banking Details Saved"
      );
      this.tab = true;
      return Promise.resolve();
    } catch (err: any) {
      this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async editClientDetails(): Promise<void> {
    try {
      this.loading = true;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        this.clientBank.entityId = key;
      }
      console.log("Banking Details: ", this.clientBank);

      const res = await privateHook.updatePrivateCompBanking(
        this.clientBank as any
      );

      JSEClient.setAccountBanking({
        accountNumber: this.clientBank.accountNumber,
        bankSwiftNumber: this.clientBank.bankSwiftNumber,
      });

      this.$notificationCreator.createSuccessNotification(
        "Bank Details Updated."
      );
      //this.tab = true;
      return Promise.resolve();
    } catch (err: any) {
      //this.accountNext = false;
      //this.tab = false;
      return Promise.reject(err);
    } finally {
      this.accountNext = false;
      this.loading = false;
    }
  }

  async getBanks(): Promise<void> {
    try {
      const res = await bankHook.getAllBanks();
      this.banks = res;
    } catch (err) {
      return Promise.reject(err);
    }
  }
}
