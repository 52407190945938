
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { ClientDivision_CREATE, Market, Trader } from "models";
import Hooks from "@/hooks";
import JSEClient from "@/store/modules/JSEClient";

const { PartnershipDivision, Markets, Traders } = Hooks;
const partnershipHook = PartnershipDivision();
const marketsHook = Markets();
const traderHook = Traders();

@Component({})
export default class Division extends Vue {
  @PropSync("valid") formValid!: boolean;
  @PropSync("nextTab") tab!: boolean;
  @PropSync("next") accountNext!: boolean;
  @Prop() type!: string;
  @Prop() divisionToBeEdited!: {
    ircCode: string;
    safexCode: string;
    physicalsCode: string;
    subAccount: string | null;
  };
  loading = false;
  markets: Market[] = [];
  safexTraders: Trader[] = [];
  jseTraders: Trader[] = [];
  physicalTraders: Trader[] = [];
  safexTradersEdit: Trader[] = [];
  jseTradersEdit: Trader[] = [];
  physicalTradersEdit: Trader[] = [];
  clientDivision: ClientDivision_CREATE = {
    safexCode: null,
    accountingCode: null,
    physicalCode: null,
    safexSubAccount: null,
    accountingSubAccount: null,
    safexMarket: null,
    physicalsMarket: null,
    jseMarket: null,
    safexTrader: null,
    physicalsTrader: null,
    jseTrader: null,
    safexTraderId: null,
    physicalsTraderId: null,
    jseTraderId: null,
    entityId: null,
    discretionaryManaged: false,
    electronicAccNumber: null,
    isBeneficial: false,
    sendToJSE: false,
  };
  division = "";
  safexSwitch = false;
  ircSwitch = false;
  divisions: {
    clientDivision: ClientDivision_CREATE;
    division: string | null;
    safexSwitch: boolean;
    ircSwitch: boolean;
  }[] = [
    {
      clientDivision: {
        safexCode: null,
        accountingCode: null,
        physicalCode: null,
        safexSubAccount: null,
        accountingSubAccount: null,
        safexMarket: null,
        physicalsMarket: null,
        jseMarket: null,
        safexTrader: null,
        physicalsTrader: null,
        jseTrader: null,
        safexTraderId: null,
        physicalsTraderId: null,
        jseTraderId: null,
        entityId: null,
        discretionaryManaged: false,
        electronicAccNumber: null,
        isBeneficial: false,
        sendToJSE: false,
      },
      division: null,
      safexSwitch: false,
      ircSwitch: false,
    },
  ];
  divisionLength = 0;
  divisionCode: string | null = null;
  divisionEditCode: string | null = null;
  subAccountEditCode: string | null = null;
  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
    emptyArr: (value: string[]): boolean | string =>
      value.length > 0 || "At least one item is required",
    uppercase: (value: string): boolean | string => {
      const pattern = /^[A-Z0-9]*$/;
      return pattern.test(value) || "Values Entered Must All Be Uppercase.";
    },
  };

  @Watch("type", { immediate: true })
  async modalTypeChanged(): Promise<void> {
    console.log("Modal Details Type: ", this.type);
    await this.getMarkets();
    if (this.type == "edit") {
      this.divisions = [];
      this.division = "";
      await this.getStoredData();

      /* const foundTrader = this.safexTraders.find(
        (trader) => trader._id == this.clientDivision.safexTraderId
      );
      if (foundTrader) {
        JSEClient.setDivisions([
          {
            branch: foundTrader.branch,
            dealer: foundTrader.dealerCode,
            ...this.clientDivision,
          },
        ]);
      } */
    }
  }

  @Watch("accountNext", {
    immediate: true,
  })
  async onNextChanged(): Promise<void> {
    console.log("AccountNext", this.accountNext);
    if (this.accountNext == true) {
      if (this.type == "add") {
        await this.addNewClientDetails();
      }
      if (this.type == "edit") {
        await this.editClientDetails();
      }
    }
  }

  marketChanged(marketCode: string): void {
    var result = this.markets.find((elem) => elem.marketCode == marketCode);
    if (result != undefined) {
      localStorage.setItem("ClientFile/marketId", String(result.id));
    }
  }

  divisionCodeChanged(dCode: string | null): void {
    if (dCode != null) {
      this.divisionCode = dCode;
    }
    console.log("dCode: ", this.divisionCode);
  }

  divisionEditCodeChanged(dCode: string | null): void {
    if (dCode != null) {
      this.divisionEditCode = dCode;
    }
    console.log("dEditCode: ", this.divisionEditCode);
  }

  subAccountEditChanged(subCode: string | null): void {
    if (subCode != null) {
      this.subAccountEditCode = subCode;
    }
    console.log("subAccountChanged: ", this.subAccountEditCode);
  }

  divisionEditChanged(division: string): void {
    switch (division) {
      case "Safex Client":
        this.clientDivision.safexCode = this.divisionEditCode;

        this.clientDivision.physicalsMarket = null;
        this.clientDivision.physicalsTrader = null;
        this.clientDivision.physicalCode = null;

        this.clientDivision.jseMarket = null;
        this.clientDivision.jseMarket = null;
        this.clientDivision.accountingCode = null;
        this.ircSwitch = false;
        this.clientDivision.accountingSubAccount = null;
        break;
      case "Physicals Client":
        this.clientDivision.physicalCode = this.divisionEditCode;

        this.clientDivision.safexMarket = null;
        this.clientDivision.safexTrader = null;
        this.clientDivision.safexCode = null;
        this.safexSwitch = false;
        this.clientDivision.safexSubAccount = null;

        this.clientDivision.jseMarket = null;
        this.clientDivision.jseMarket = null;
        this.clientDivision.accountingCode = null;
        this.ircSwitch = false;
        this.clientDivision.accountingSubAccount = null;
        break;
      case "JSE Client":
        this.clientDivision.accountingCode = this.divisionEditCode;

        this.clientDivision.physicalsMarket = null;
        this.clientDivision.physicalsTrader = null;
        this.clientDivision.physicalCode = null;

        this.clientDivision.safexMarket = null;
        this.clientDivision.safexTrader = null;
        this.clientDivision.safexCode = null;
        this.safexSwitch = false;
        this.clientDivision.safexSubAccount = null;
        break;
    }
    console.log("Divisions Edit Changed: ", this.clientDivision);
  }

  async divisionChanged(division: string, index: number): Promise<void> {
    switch (division) {
      case "Safex Client":
        if (this.divisionCode != null) {
          this.divisions[index].clientDivision.safexCode = this.divisionCode;
        } else {
          this.divisionCode = this.divisions[index].clientDivision.safexCode;
        }
        if (this.subAccountEditCode != null) {
          this.divisions[index].clientDivision.safexSubAccount =
            this.subAccountEditCode;
          this.divisions[index].safexSwitch = true;
        }

        if (this.clientDivision.safexMarket) {
          this.divisions[index].clientDivision.safexMarket =
            this.clientDivision.safexMarket;
          await this.getTraders(
            this.divisions[index].clientDivision.safexMarket,
            "safexTraders"
          );
          this.marketChanged(this.divisions[index].clientDivision.safexMarket!);
        }

        if (this.clientDivision.safexTraderId) {
          this.divisions[index].clientDivision.safexTraderId =
            this.clientDivision.safexTraderId;
        }

        this.divisions[index].clientDivision.physicalsMarket = null;
        this.divisions[index].clientDivision.physicalsTrader = null;
        this.divisions[index].clientDivision.physicalCode = null;

        this.divisions[index].clientDivision.jseMarket = null;
        this.divisions[index].clientDivision.jseMarket = null;
        this.divisions[index].clientDivision.accountingCode = null;
        this.divisions[index].ircSwitch = false;
        this.divisions[index].clientDivision.accountingSubAccount = null;
        break;
      case "Physicals Client":
        if (this.divisionCode != null) {
          this.divisions[index].clientDivision.physicalCode = this.divisionCode;
        } else {
          this.divisionCode = this.divisions[index].clientDivision.physicalCode;
        }

        if (this.clientDivision.physicalsMarket) {
          this.divisions[index].clientDivision.physicalsMarket =
            this.clientDivision.physicalsMarket;
          await this.getTraders(
            this.divisions[index].clientDivision.physicalsMarket,
            "physicalTraders"
          );
          this.marketChanged(
            this.divisions[index].clientDivision.physicalsMarket!
          );
        }

        if (this.clientDivision.physicalsTraderId) {
          this.divisions[index].clientDivision.physicalsTraderId =
            this.clientDivision.physicalsTraderId;
        }

        this.divisions[index].clientDivision.safexMarket = null;
        this.divisions[index].clientDivision.safexTrader = null;
        this.divisions[index].clientDivision.safexCode = null;
        this.divisions[index].safexSwitch = false;
        this.divisions[index].clientDivision.safexSubAccount = null;

        this.divisions[index].clientDivision.jseMarket = null;
        this.divisions[index].clientDivision.jseMarket = null;
        this.divisions[index].clientDivision.accountingCode = null;
        this.divisions[index].ircSwitch = false;
        this.divisions[index].clientDivision.accountingSubAccount = null;
        break;
      case "JSE Client":
        if (this.divisionCode != null) {
          this.divisions[index].clientDivision.accountingCode =
            this.divisionCode;
        } else {
          this.divisionCode =
            this.divisions[index].clientDivision.accountingCode;
        }
        if (this.subAccountEditCode != null) {
          this.divisions[index].clientDivision.accountingSubAccount =
            this.subAccountEditCode;
          this.divisions[index].ircSwitch = true;
        }

        if (this.clientDivision.jseMarket) {
          this.divisions[index].clientDivision.jseMarket =
            this.clientDivision.jseMarket;
          await this.getTraders(
            this.divisions[index].clientDivision.jseMarket,
            "jseTraders"
          );
          this.marketChanged(this.divisions[index].clientDivision.jseMarket!);
        }

        if (this.clientDivision.jseTraderId) {
          this.divisions[index].clientDivision.jseTraderId =
            this.clientDivision.jseTraderId;
        }

        this.divisions[index].clientDivision.physicalsMarket = null;
        this.divisions[index].clientDivision.physicalsTrader = null;
        this.divisions[index].clientDivision.physicalCode = null;

        this.divisions[index].clientDivision.safexMarket = null;
        this.divisions[index].clientDivision.safexTrader = null;
        this.divisions[index].clientDivision.safexCode = null;
        this.divisions[index].safexSwitch = false;
        this.divisions[index].clientDivision.safexSubAccount = null;
        break;
    }
    console.log("Divisions Changed: ", this.divisions[index]);
    return Promise.resolve();
  }

  async getTraders(marketName: string | null, division: string): Promise<void> {
    try {
      console.log("SelectedMarket", marketName);
      var selectedMarket = this.markets.find(
        (elem) => elem.marketCode == marketName
      );

      console.log("SelectedMarketFind", selectedMarket);
      if (selectedMarket != undefined) {
        const res = await traderHook.getAllTradersByMarket(selectedMarket.id);
        switch (division) {
          case "safexTraders":
            this.safexTraders = res;
            break;
          case "jseTraders":
            this.jseTraders = res;
            break;
          case "physicalTraders":
            this.physicalTraders = res;
            break;
          case "safexTradersEdit":
            this.safexTradersEdit = res;
            break;
          case "jseTradersEdit":
            this.jseTradersEdit = res;
            break;
          case "physicalTradersEdit":
            this.physicalTradersEdit = res;
            break;
        }
        return Promise.resolve();
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getMarkets(): Promise<void> {
    try {
      const res = await marketsHook.getAllMarkets();
      this.markets = res;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  addDivision(): void {
    if (this.clientDivision.safexSubAccount != null) {
      this.subAccountEditCode = this.clientDivision.safexSubAccount;
    } else if (this.clientDivision.accountingSubAccount != null) {
      this.subAccountEditCode = this.clientDivision.accountingSubAccount;
    }

    const key = localStorage.getItem("ClientFile/entityId");
    if (key != null) {
      this.divisions.push({
        clientDivision: {
          safexCode: /* 
            this.clientDivision.safexCode != null
              ? this.clientDivision.safexCode
              : */ null,
          accountingCode: /* 
            this.clientDivision.accountingCode != null
              ? this.clientDivision.accountingCode
              : */ null,
          physicalCode: /* 
            this.clientDivision.physicalCode != null
              ? this.clientDivision.safexCode
              : */ null,
          safexSubAccount: null,
          accountingSubAccount: null,
          safexMarket: null,
          physicalsMarket: null,
          jseMarket: null,
          safexTrader: null,
          physicalsTrader: null,
          jseTrader: null,
          safexTraderId: null,
          physicalsTraderId: null,
          jseTraderId: null,
          entityId: key,
          discretionaryManaged: false,
          electronicAccNumber: null,
          isBeneficial: false,
          sendToJSE: false,
        },
        division: null,
        safexSwitch: false,
        ircSwitch: false,
      });
    }
    console.log("divisions: ", this.divisions);
  }

  removeDivision(): void {
    this.divisions.pop();
    console.log("divisions: ", this.divisions);
  }

  async getStoredData(): Promise<void> {
    try {
      this.loading = true;
      var entityId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        entityId = key;
      }

      if (entityId != null) {
        const res = await partnershipHook.getAllPartnerDivisionByCode(entityId);
        console.log("Get Stored Data res: ", res);
        console.log("Selected Division: ", this.divisionToBeEdited);
        var division = res.find(
          (elem) =>
            (elem.safexCode == this.divisionToBeEdited.safexCode &&
              elem.safexCode != null &&
              (elem.accountingSubAccount ==
                this.divisionToBeEdited.subAccount ||
                elem.safexSubAccount == this.divisionToBeEdited.subAccount)) ||
            (elem.accountingCode == this.divisionToBeEdited.ircCode &&
              elem.accountingCode != null &&
              (elem.accountingSubAccount ==
                this.divisionToBeEdited.subAccount ||
                elem.safexSubAccount == this.divisionToBeEdited.subAccount)) ||
            (elem.physicalCode == this.divisionToBeEdited.physicalsCode &&
              elem.physicalCode != null &&
              (elem.accountingSubAccount ==
                this.divisionToBeEdited.subAccount ||
                elem.safexSubAccount == this.divisionToBeEdited.subAccount))
        );
        console.log("Division to be Edited", division);
        if (division != undefined) {
          this.clientDivision = { sendToJSE: false, ...division };
          if (this.clientDivision.safexCode != null) {
            this.division = "Safex Client";
            if (this.clientDivision.safexSubAccount != null) {
              this.safexSwitch = true;
            }
            await this.getTraders(
              this.clientDivision.safexMarket,
              "safexTradersEdit"
            );
            this.divisionEditCodeChanged(this.clientDivision.safexCode);
          }
          if (this.clientDivision.physicalCode != null) {
            this.division = "Physicals Client";

            await this.getTraders(
              this.clientDivision.physicalsMarket,
              "physicalTradersEdit"
            );
            this.divisionEditCodeChanged(this.clientDivision.physicalCode);
          }
          if (this.clientDivision.accountingCode != null) {
            this.division = "JSE Client";
            if (this.clientDivision.accountingSubAccount != null) {
              this.ircSwitch = true;
            }
            await this.getTraders(
              this.clientDivision.jseMarket,
              "jseTradersEdit"
            );
            this.divisionEditCodeChanged(this.clientDivision.accountingCode);
          }

          if (
            this.clientDivision.safexCode == null &&
            this.clientDivision.accountingCode == null &&
            this.clientDivision.accountingCode == null
          ) {
            this.division = "Safex Client";
          }
        }
        return Promise.resolve();
      }
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async addNewClientDetails(): Promise<void> {
    try {
      this.loading = true;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        console.log("key: ", key);
        this.clientDivision.entityId = key;
      }

      const divsionsToSend: any[] = [];

      this.divisions.forEach(async (elem) => {
        console.log("Division Details: ", elem.clientDivision);
        elem.clientDivision.entityId = key;

        const foundTrader = this.safexTraders.find(
          (trader) => trader._id == elem.clientDivision.safexTraderId
        );
        if (foundTrader) {
          divsionsToSend.push({
            branch: foundTrader.branch,
            dealer: foundTrader.dealerCode,
            ...elem.clientDivision,
          });
        }

        const res = await partnershipHook
          .addNewPartnershipDivisons(elem.clientDivision)
          .catch((err) => {
            this.accountNext = false;
          });
        this.$notificationCreator.createSuccessNotification(
          "Division Details Saved"
        );
        return Promise.resolve();
      });

      /* const existingD = JSEClient.getDivisions;

      if (existingD.length > 0) {
        existingD.map((div) => {
          divsionsToSend.push(div);
        });
      }
      JSEClient.setDivisions(divsionsToSend);

      this.tab = true; */

      if (this.type != "edit") {
        //add
        JSEClient.setDivisions(divsionsToSend);
        this.tab = true;
      } else {
        //edit
        JSEClient.sendAdditionalClientsToJSE(divsionsToSend);
      }
    } catch (err: any) {
      this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async editClientDetails(): Promise<void> {
    try {
      this.loading = true;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        this.clientDivision.entityId = key;
      }

      console.log("Division Details: ", this.clientDivision);

      const res = await partnershipHook.updatePartnershipDivisions(
        this.clientDivision as any
      );

      const foundTrader = this.safexTraders.find(
        (trader) => trader._id == this.clientDivision.safexTraderId
      );
      if (foundTrader) {
        /*  const existingD = JSEClient.getDivisions;
        if (existingD.length > 0) {
          existingD[0] = {
            branch: foundTrader.branch,
            dealer: foundTrader.dealerCode,
            ...this.clientDivision,
          };
        } */

        JSEClient.setDivisions([
          {
            branch: foundTrader.branch,
            dealer: foundTrader.dealerCode,
            ...this.clientDivision,
          },
        ]);
      }

      this.$notificationCreator.createSuccessNotification(
        "Division Details Updated."
      );
      // this.tab = true;
      return Promise.resolve();
    } catch (err: any) {
      //this.accountNext = false;
      //this.tab = false;
      return Promise.reject(err);
    } finally {
      this.accountNext = false;
      this.loading = false;
    }
  }
}
