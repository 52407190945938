import { axiosInstance } from "@/plugins/axios";
import {
  ClosedCorpBankingDetails,
  ClosedCorpBankingDetails_CREATE,
} from "models";

const ClosedCorpBankingDetail = () => {
  const baseUrl = "/api/ClosedCorpBankingDetails";

  const getAllClosedCorpBank = async (): Promise<
    ClosedCorpBankingDetails[]
  > => {
    const res = await axiosInstance.get(baseUrl + "/getAllClosedCorpBank");
    return Promise.resolve(res.data);
  };

  const getClosedCorpByCodeBank = async (
    code: string
  ): Promise<ClosedCorpBankingDetails> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClosedCorpByCodeBank?code=${code}`
    );
    return Promise.resolve(res.data);
  };

  const addNewClosedCorpBank = async (
    closedCorp: ClosedCorpBankingDetails_CREATE
  ): Promise<any> => {
    const res = await axiosInstance.post(baseUrl + "/addNewClosedCorpBank", {
      bankId: closedCorp.bankId,
      accountType: closedCorp.accountType,
      branchName: closedCorp.branchName,
      accountHolder: closedCorp.accountHolder,
      branchCode: closedCorp.branchCode,
      bankSwiftNumber: closedCorp.bankSwiftNumber,
      accountNumber: closedCorp.accountNumber,
      bankIBANNumber: closedCorp.bankIBANNumber,
      entityId: closedCorp.entityId,
    });
    return Promise.resolve(res.data);
  };

  const updateClosedCorpBank = async (
    closedCorp: ClosedCorpBankingDetails
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/updateClosedCorpBank", {
      _id: closedCorp._id,
      bankId: closedCorp.bankId,
      accountType: closedCorp.accountType,
      branchName: closedCorp.branchName,
      accountHolder: closedCorp.accountHolder,
      branchCode: closedCorp.branchCode,
      bankSwiftNumber: closedCorp.bankSwiftNumber,
      accountNumber: closedCorp.accountNumber,
      bankIBANNumber: closedCorp.bankIBANNumber,
      entityId: closedCorp.entityId,
      createdBy: closedCorp.createdBy,
      createdDate: closedCorp.createdDate,
      updatedBy: closedCorp.updatedBy,
      updatedDate: closedCorp.updatedDate,
    });
    return Promise.resolve(res.data);
  };

  const deleteClosedCorpBank = async (
    closedCorp: ClosedCorpBankingDetails
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/deleteClosedCorpBank", {
      _id: closedCorp._id,
      bankId: closedCorp.bankId,
      accountType: closedCorp.accountType,
      branchName: closedCorp.branchName,
      accountHolder: closedCorp.accountHolder,
      branchCode: closedCorp.branchCode,
      bankSwiftNumber: closedCorp.bankSwiftNumber,
      accountNumber: closedCorp.accountNumber,
      bankIBANNumber: closedCorp.bankIBANNumber,
      entityId: closedCorp.entityId,
      createdBy: closedCorp.createdBy,
      createdDate: closedCorp.createdDate,
      updatedBy: closedCorp.updatedBy,
      updatedDate: closedCorp.updatedDate,
    });
    return Promise.resolve(res.data);
  };

  return {
    getAllClosedCorpBank,
    getClosedCorpByCodeBank,
    addNewClosedCorpBank,
    deleteClosedCorpBank,
    updateClosedCorpBank,
  };
};
export default ClosedCorpBankingDetail;
