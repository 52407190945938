import { axiosInstance } from "@/plugins/axios";
import {
  ClosedCorpCommunications,
  ClosedCorpCommunications_CREATE,
} from "models";

const ClosedCorpCom = () => {
  const baseUrl = "/api/ClosedCorpCom";

  const getAllClosedCorpCom = async (): Promise<ClosedCorpCommunications[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAllClosedCorpCom");
    return Promise.resolve(res.data);
  };

  const getClosedCorpByCodeCom = async (code: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClosedCorpByCodeCom?code=${code}`
    );
    return Promise.resolve(res.data);
  };

  const addNewClosedCorpCom = async (
    closedCorp: ClosedCorpCommunications_CREATE
  ): Promise<any> => {
    const res = await axiosInstance.post(baseUrl + "/addNewClosedCorpCom", {
      contactName: closedCorp.contactName,
      emailAddress: closedCorp.emailAddress,
      mobileNumber: closedCorp.mobileNumber,
      entityId: closedCorp.entityId,
    });
    return Promise.resolve(res.data);
  };

  const updateClosedCorpCom = async (
    closedCorp: ClosedCorpCommunications
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/updateClosedCorpCom", {
      _id: closedCorp._id,
      contactName: closedCorp.contactName,
      emailAddress: closedCorp.emailAddress,
      mobileNumber: closedCorp.mobileNumber,
      entityId: closedCorp.entityId,
      createdBy: closedCorp.createdBy,
      createdDate: closedCorp.createdDate,
      updatedBy: closedCorp.updatedBy,
      updatedDate: closedCorp.updatedDate,
    });
    return Promise.resolve(res.data);
  };

  const deleteClosedCorpCom = async (
    closedCorp: ClosedCorpCommunications
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/deleteClosedCorpCom", {
      _id: closedCorp._id,
      contactName: closedCorp.contactName,
      emailAddress: closedCorp.emailAddress,
      mobileNumber: closedCorp.mobileNumber,
      entityId: closedCorp.entityId,
      createdBy: closedCorp.createdBy,
      createdDate: closedCorp.createdDate,
      updatedBy: closedCorp.updatedBy,
      updatedDate: closedCorp.updatedDate,
    });
    return Promise.resolve(res.data);
  };

  return {
    getAllClosedCorpCom,
    getClosedCorpByCodeCom,
    addNewClosedCorpCom,
    deleteClosedCorpCom,
    updateClosedCorpCom,
  };
};
export default ClosedCorpCom;
