import { axiosInstance } from "@/plugins/axios";
import {
  ClosedCorpDocs,
  ClosedCorpDocsUpdate,
  ClosedCorpDocument,
  ClosedCorpDocumentUpdate,
  ClosedCorpPersonalDocs,
  ClosedCorpPersonalDocsUpdate,
} from "models";
import ClientDocuments from "../customFile";

const ClosedCorpDocuments = () => {
  const baseUrl = "/api/ClosedCorpDocuments";

  const UploadClosedCorpDocuments = async (
    clientDocuments: ClosedCorpDocument
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + `/UploadClosedCorpDocuments`,
      {
        user: clientDocuments.user,
        closedCorpId: clientDocuments.closedCorpId,
        identificationType: clientDocuments.identificationType,
        authIdentificationType: clientDocuments.authIdentificationType,
        authIdFile: clientDocuments.authIdFile,
        authSAIDNumber: clientDocuments.authSAIDNumber,
        saidNumber: clientDocuments.saidNumber,
        idFile: clientDocuments.idFile,
        taxNumber: clientDocuments.taxNumber,
        taxNumberUpload: clientDocuments.taxNumberUpload,
        incomeTax: clientDocuments.incomeTax,
        incomeTaxUpload: clientDocuments.incomeTaxUpload,
        businessUpload: clientDocuments.businessUpload,
        vatRegNumber: clientDocuments.vatRegNumber,
        vatUpload: clientDocuments.vatUpload,
        regUpload: clientDocuments.regUpload,
        mandate: clientDocuments.mandate,
      }
    );
    return Promise.resolve(res.data);
  };

  const UploadClosedCorpPersonalDocuments = async (
    clientDocuments: ClosedCorpPersonalDocs
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + `/UploadClosedCorpPersonalDocuments`,
      {
        user: clientDocuments.user,
        closedCorpId: clientDocuments.closedCorpId,
        identificationType: clientDocuments.identificationType,
        authIdentificationType: clientDocuments.authIdentificationType,
        authIdFile: clientDocuments.authIdFile,
        authSAIDNumber: clientDocuments.authSAIDNumber,
        saidNumber: clientDocuments.saidNumber,
        idFile: clientDocuments.idFile,
        incomeTax: clientDocuments.incomeTax,
        incomeTaxUpload: clientDocuments.incomeTaxUpload,
        pobUpload: clientDocuments.pobUpload,
      }
    );
    return Promise.resolve(res.data);
  };

  const UploadClosedCorpCorpoDocuments = async (
    clientDocuments: ClosedCorpDocs
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + `/UploadClosedCorpCorpoDocuments`,
      {
        user: clientDocuments.user,
        closedCorpId: clientDocuments.closedCorpId,
        taxNumber: clientDocuments.taxNumber,
        taxNumberUpload: clientDocuments.taxNumberUpload,
        businessUpload: clientDocuments.businessUpload,
        vatRegNumber: clientDocuments.vatRegNumber,
        vatUpload: clientDocuments.vatUpload,
        regNumber: clientDocuments.regNumber,
        regUpload: clientDocuments.regUpload,
        mandate: clientDocuments.mandate,
      }
    );
    return Promise.resolve(res.data);
  };

  const updateClosedCorp = async (
    clientDocuments: ClosedCorpDocumentUpdate
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/UpdateClosedCorpDocuments",
      {
        user: clientDocuments.user,
        closedCorpId: clientDocuments.closedCorpId,
        identificationType: clientDocuments.identificationType,
        authIdentificationType: clientDocuments.authIdentificationType,
        authIdFileId: clientDocuments.authIdFileId,
        authIdFile: clientDocuments.authIdFile,
        authSAIDNumber: clientDocuments.authSAIDNumber,
        saidNumber: clientDocuments.saidNumber,
        idFileId: clientDocuments.idFileId,
        idFile: clientDocuments.idFile,
        taxNumber: clientDocuments.taxNumber,
        taxNumberUploadId: clientDocuments.taxNumberUploadId,
        taxNumberUpload: clientDocuments.taxNumberUpload,
        incomeTaxId: clientDocuments.incomeTaxId,
        incomeTax: clientDocuments.incomeTax,
        incomeTaxUpload: clientDocuments.incomeTaxUpload,
        businessUploadId: clientDocuments.businessUploadId,
        businessUpload: clientDocuments.businessUpload,
        vatRegNumber: clientDocuments.vatRegNumber,
        vatUploadId: clientDocuments.vatUploadId,
        vatUpload: clientDocuments.vatUpload,
        regUploadId: clientDocuments.regUploadId,
        regUpload: clientDocuments.regUpload,
        mandateId: clientDocuments.mandateId,
        mandate: clientDocuments.mandate,
      }
    );
    return Promise.resolve(res.data);
  };

  const UpdateClosedCorpPersonalDocuments = async (
    clientDocuments: ClosedCorpPersonalDocsUpdate
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/UpdateClosedCorpPersonalDocuments",
      {
        user: clientDocuments.user,
        closedCorpId: clientDocuments.closedCorpId,
        identificationType: clientDocuments.identificationType,
        authIdentificationType: clientDocuments.authIdentificationType,
        authIdFileId: clientDocuments.authIdFileId,
        authIdFile: clientDocuments.authIdFile,
        authSAIDNumber: clientDocuments.authSAIDNumber,
        saidNumber: clientDocuments.saidNumber,
        idFileId: clientDocuments.idFileId,
        idFile: clientDocuments.idFile,
        incomeTaxId: clientDocuments.incomeTaxId,
        incomeTax: clientDocuments.incomeTax,
        incomeTaxUpload: clientDocuments.incomeTaxUpload,
        pobId: clientDocuments.pobId,
        pobUpload: clientDocuments.pobUpload,
      }
    );
    return Promise.resolve(res.data);
  };

  const UpdateClosedCorpCorpoDocuments = async (
    clientDocuments: ClosedCorpDocsUpdate
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/UpdateClosedCorpCorpoDocuments",
      {
        user: clientDocuments.user,
        closedCorpId: clientDocuments.closedCorpId,
        taxNumber: clientDocuments.taxNumber,
        taxNumberUploadId: clientDocuments.taxNumberUploadId,
        taxNumberUpload: clientDocuments.taxNumberUpload,
        businessUploadId: clientDocuments.businessUploadId,
        businessUpload: clientDocuments.businessUpload,
        vatRegNumber: clientDocuments.vatRegNumber,
        vatUploadId: clientDocuments.vatUploadId,
        vatUpload: clientDocuments.vatUpload,
        regNumber: clientDocuments.regNumber,
        regUploadId: clientDocuments.regUploadId,
        regUpload: clientDocuments.regUpload,
        mandateId: clientDocuments.mandateId,
        mandate: clientDocuments.mandate,
      }
    );
    return Promise.resolve(res.data);
  };

  const getClosedCorpAuthIdDocumentByCode = async (
    clientId: string
  ): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClosedCorpAuthIdDocumentByCode?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getClosedCorpIdDocumentByCode = async (
    clientId: string
  ): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClosedCorpIdDocumentByCode?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getClosedCorpBusinessByCode = async (
    clientId: string
  ): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClosedCorpBusinessByCode?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getClosedCorpTaxByCode = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClosedCorpTaxByCode?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getClosedCorpTaxIncomeByCode = async (
    clientId: string
  ): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClosedCorpTaxIncomeByCode?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getClosedCorpBusiness = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClosedCorpBusiness?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getClosedCorpPOBDocumentByCode = async (
    clientId: string
  ): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClosedCorpPOBDocumentByCode?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getClosedCorpVat = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClosedCorpVat?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getClosedCorpReg = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClosedCorpReg?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getClosedCorpMandateByCode = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClosedCorpMandateByCode?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const DownloadCCIdDocument = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadCCIdDocument?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadCCAuthIdDocument = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadCCAuthIdDocument?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadCCBusinessDocument = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadCCBusinessDocument?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadCCPOBDocument = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadCCPOBDocument?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadCCVATDocument = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadCCVATDocument?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadCCTax = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(baseUrl + `/DownloadCCTax?id=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "image/jpeg" + "application/pdf" + "image/png",
      },
      responseType: "blob",
    });
    return Promise.resolve(res.data);
  };

  const DownloadCCIncomeTax = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadCCIncomeTax?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadReg = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(baseUrl + `/DownloadReg?id=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "image/jpeg" + "application/pdf" + "image/png",
      },
      responseType: "blob",
    });
    return Promise.resolve(res.data);
  };

  const DownloadMandate = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(baseUrl + `/DownloadMandate?id=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "image/jpeg" + "application/pdf" + "image/png",
      },
      responseType: "blob",
    });
    return Promise.resolve(res.data);
  };

  return {
    UploadClosedCorpDocuments,
    UploadClosedCorpPersonalDocuments,
    UploadClosedCorpCorpoDocuments,
    updateClosedCorp,
    UpdateClosedCorpPersonalDocuments,
    UpdateClosedCorpCorpoDocuments,
    getClosedCorpAuthIdDocumentByCode,
    getClosedCorpIdDocumentByCode,
    getClosedCorpPOBDocumentByCode,
    getClosedCorpBusinessByCode,
    getClosedCorpTaxByCode,
    getClosedCorpTaxIncomeByCode,
    getClosedCorpBusiness,
    getClosedCorpVat,
    getClosedCorpReg,
    getClosedCorpMandateByCode,
    DownloadCCIdDocument,
    DownloadCCPOBDocument,
    DownloadCCAuthIdDocument,
    DownloadCCBusinessDocument,
    DownloadCCVATDocument,
    DownloadCCTax,
    DownloadCCIncomeTax,
    DownloadReg,
    DownloadMandate,
  };
};
export default ClosedCorpDocuments;
