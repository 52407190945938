
import dayjs from "dayjs";
import {
  RiskReporting_ABSA_Resp,
  RiskReporting_Balance_Resp,
  RiskReporting_Brokes_Resp,
  RiskReporting_HQ_Resp,
  RiskReporting_SubAccount_Resp,
} from "models";
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";

@Component({
  filters: {
    numberWCommas: function (value: number) {
      if (value != null) {
        return value.toLocaleString("en-US", { minimumFractionDigits: 2 });
      } else {
        return value;
      }
    },
  },
})
export default class ABSADaily extends Vue {
  @PropSync("loading", { default: false }) load!: boolean;
  @Prop() readonly importResult: {
    balanceSheet: RiskReporting_Balance_Resp[];
    brokesSheet: RiskReporting_Brokes_Resp[];
    absa: RiskReporting_ABSA_Resp[];
    absaDaily: RiskReporting_ABSA_Resp[];
    hq: RiskReporting_HQ_Resp[];
    subAccount: RiskReporting_SubAccount_Resp[];
  } | null = null;
  items: RiskReporting_ABSA_Resp[] = [];
  date = dayjs().format("YYYY-MM-DD");
  dateMenu = false;
  search = "";
  sortDesc = false;
  headers = [
    { text: "Principal", value: "principal" },
    { text: "Prev Margin", value: "previousMargin" },
    { text: "New Margin", value: "margin" },
    { text: "Margin Call", value: "marginCall" },
    { text: "MTM", value: "markToMarket" },
    { text: "Cash Call", value: "cashCall" },
    { text: "Clearing Fees", value: "clearingFees" },
    { text: "JSE Fees", value: "jseFees" },
    { text: "Broker Fees", value: "brokerFees" },
    { text: "Member Fees", value: "memberFees" },
    { text: "Third Fees", value: "thirdFees" },
    { text: "Fourth Fees", value: "fourthFees" },
    { text: "Total BF", value: "totalBookingFees" },
    { text: "Deposits", value: "deposits" },
    { text: "With Drawals", value: "withdrawals" },
    { text: "Current Account", value: "currentAccount" },
  ];

  @Watch("importResult", { immediate: true })
  importResultChanged(): void {
    this.load = true;
    console.log("importResult ABSARiskReport: ", this.importResult);
    if (this.importResult != null) {
      this.items = this.importResult.absaDaily;
      if (this.importResult.absa[0].reportDate) {
        this.date = dayjs(this.importResult.absa[0].reportDate).format(
          "YYYY-MM-DD"
        );
      } else {
        this.date = this.getPreviousWorkDay;
      }
    }
    this.load = false;
  }

  get getPreviousWorkDay(): string {
    var date = dayjs().day();
    if (date == 6) {
      /* 6 = saturday */
      return dayjs().subtract(1, "day").format("YYYY-MM-DD");
    } else if (date == 0) {
      /* 0 = sunday */
      return dayjs().subtract(2, "day").format("YYYY-MM-DD");
    } else if (date == 1) {
      /* 1 = monday */
      return dayjs().subtract(3, "day").format("YYYY-MM-DD");
    } else {
      return dayjs().subtract(1, "day").format("YYYY-MM-DD");
    }
  }
}
