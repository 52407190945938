
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import dayjs from "dayjs";
import { AuditModel, UserProfile } from "models";
import Hooks from "@/hooks";
import User from "@/store/modules/user";

const { Audit, UserProfiles } = Hooks;
const AuditHook = Audit();
const UserProfileHook = UserProfiles();

@Component({
  filters: {
    convertDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD HH:mm");
    },
  },
})
export default class AuditTrail extends Vue {
  loading = false;
  items: AuditModel[] = [];
  search = "";
  sortDesc = false;
  toDate = dayjs().format("YYYY-MM-DD");
  toDateMenu = false;
  fromDate = dayjs().format("YYYY-MM-DD");
  fromDateMenu = false;
  toTimeMenu = false;
  toTime = dayjs().format("HH:mm");
  endTimeMenu = false;
  endTime = dayjs().format("HH:mm");
  action: number | null = null;
  actions = [
    { id: 0, text: "Add" },
    { id: 1, text: "Delete" },
    { id: 2, text: "Update" },
    /* { id: 3, text: "Archive" }, */
    { id: 4, text: "View" },
  ];
  user: string | null = null;
  users: UserProfile[] = [];
  actionModal = false;
  oldValues: string | null = null;
  newValues: string | null = null;
  headers = [
    {
      text: "Date & Time",
      value: "dateTime",
      sortable: false,
    },
    {
      text: "Action",
      value: "action",
      sortable: false,
    },
    {
      text: "Description",
      value: "description",
      sortable: false,
    },
    {
      text: "User",
      value: "user",
      sortable: false,
    },

    {
      text: "View Actions",
      value: "viewActions",
      sortable: false,
    },
  ];

  openActionModal(item: AuditModel): void {
    this.actionModal = true;
    this.oldValues = item.oldValues;
    this.newValues = item.newValues;
  }

  closeActionModal(): void {
    this.actionModal = false;
    this.oldValues = null;
    this.newValues = null;
  }

  async mounted(): Promise<void> {
    await this.getUsers();
    this.setDefaults();
    await this.init();
  }

  setDefaults(): void {
    this.action = 0;
    var resUser = this.users.find((elem) => elem.email == User.getLoggedInUser);
    if (resUser != undefined) {
      this.user = resUser.userId;
    }

    this.fromDate = dayjs().add(-1, "day").format("YYYY-MM-DD");
  }

  async getUsers(): Promise<void> {
    try {
      this.loading = true;
      const res = await UserProfileHook.getAllUsers();
      this.users = res;
      return Promise.resolve();
    } catch (err: any) {
      console.log(err.response);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async init(): Promise<void> {
    try {
      this.loading = true;
      const res = await AuditHook.GetAuditSuperSearch(
        this.fromDate,
        this.toDate,
        this.toTime,
        this.endTime,
        this.action != null ? this.action : 0,
        this.user != null ? this.user : ""
      );
      this.items = res;
      this.items.forEach((elem: any) => {
        if (elem.auditType == 0) {
          elem.action = "Add";
        } else if (elem.auditType == 1) {
          elem.action = "Delete";
        } else if (elem.auditType == 2) {
          elem.action = "Update";
        } else if (elem.auditType == 3) {
          elem.action = "Archive";
        }
        if (elem.auditType == 4) {
          elem.action = "View";
        }
      });
      console.log("items: ", this.items);
      return Promise.resolve();
    } catch (err: any) {
      console.log(err.response);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
