
import { Component, Vue, Watch } from "vue-property-decorator";
import Balancing from "@/components/RiskReporting/Audit/Tabs/Balancing.vue";
import Brokes from "@/components/RiskReporting/Audit/Tabs/Brokes.vue";
import SubAccounts from "@/components/RiskReporting/Audit/Tabs/SubAccounts.vue";
import Matching from "@/components/RiskReporting/Audit/Tabs/Matching.vue";

@Component({
  components: {
    Balancing,
    Brokes,
    SubAccounts,
    Matching,
  },
})
export default class RiskReports_Audit extends Vue {
  loading = false;
  mini = true;
  showBalancing = true;
  showBroke = false;
  showSubAccounts = false;
  showMatching = false;
  saveCheckModal = false;
  activeMenuItem = "Balancing Control Sheet";
  menuItem: string | null = null;
  menuItems: { title: string; icon: string }[] = [
    { title: "Balancing Control Sheet", icon: "mdi-scale-balance" },
    { title: "Brokes Control Sheet", icon: "mdi-compare-horizontal" },
    { title: "Sub-Accounts", icon: "mdi-account-multiple" },
    { title: "Matching - After Balancing", icon: "mdi-table-large" },
  ];

  /*  openSaveCheck(title: string): void {
    console.log("Menu Item: ", title);

     if (
      this.showBalancing == true &&
      title != "Balancing Control Sheet"
    ) {
      this.menuItem = title;
      this.saveCheckModal = true;
    } else if (this.showBroke == true && title != "Brokes Control Sheet") {
      this.menuItem = title;
      this.saveCheckModal = true;
    } else if (this.showSubAccounts == true && title != "Sub-Accounts") {
      this.menuItem = title;
      this.saveCheckModal = true;
    } else if (
      this.showMatching == true &&
      title != "Matching - After Balancing"
    ) {
      this.menuItem = title;
      this.saveCheckModal = true;
    } else {
      this.chooseMenuItem(title);
    }
  } */

  chooseMenuItem(title: string): void {
    console.log("Menu Item: ", title);
    switch (title) {
      case "Balancing Control Sheet":
        this.activeMenuItem = "Balancing Control Sheet";
        this.showBalancing = true;
        this.showBroke = false;
        this.showSubAccounts = false;
        this.showMatching = false;
        break;
      case "Brokes Control Sheet":
        this.activeMenuItem = "Brokes Control Sheet";
        this.showBalancing = false;
        this.showBroke = true;
        this.showSubAccounts = false;
        this.showMatching = false;
        break;
      case "Sub-Accounts":
        this.activeMenuItem = "Sub-Accounts";
        this.showBalancing = false;
        this.showBroke = false;
        this.showSubAccounts = true;
        this.showMatching = false;
        break;
      case "Matching - After Balancing":
        this.activeMenuItem = "Matching - After Balancing";
        this.showBalancing = false;
        this.showBroke = false;
        this.showSubAccounts = false;
        this.showMatching = true;
        break;
    }
  }
}
