
import { Component, Vue } from "vue-property-decorator";
import { AssignRole } from "models";
import Hooks from "@/hooks";

const { AssignRoles } = Hooks;
const AssignRolesHook = AssignRoles();

@Component({})
export default class UseAccessView extends Vue {
  items: AssignRole[] = [];
  loading = false;
  search = "";
  sortDesc = false;
  sortBy = "email";
  headers = [
    {
      text: "Role",
      value: "roleName",
      sortable: false,
    },
    {
      text: "Created By",
      value: "createdBy",
      sortable: false,
    },
  ];

  async loadBatch(): Promise<void> {
    try {
      const res = await AssignRolesHook.batchAssignRoles();
      return Promise.resolve();
    } catch (err: any) {
      return Promise.reject(err);
    }
  }

  async mounted(): Promise<void> {
    await this.init();
  }

  async init(): Promise<void> {
    try {
      this.loading = true;
      this.items = [];
      const res = await AssignRolesHook.getAllAssignRole();
      this.items = res;
      console.log("Roles: ", this.items);
      return Promise.resolve();
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
