
import { Component, Vue, Watch } from "vue-property-decorator";
import AddEdittraders from "@/components/Manage/RatingCodes/AddEditRatingCodes.vue";
import { Market, RatingCode, UserAccessModel } from "models";
import Hooks from "@/hooks";
import User from "@/store/modules/user";

const { RatingCodes, Markets } = Hooks;
const ratingCodeHook = RatingCodes();
const marketHook = Markets();

@Component({
  components: {
    AddEdittraders,
  },
})
export default class RatingCodeView extends Vue {
  items: RatingCode[] = [];
  markets: Market[] = [];
  filter: number | null = null;
  loading = false;
  search = "";
  sortDesc = false;
  sortBy = "name";
  modal = false;
  deleteModal = false;
  deleteItem = {} as RatingCode;
  modalData = {
    type: "",
    fields: {},
  };
  refresh = false;
  headers = [
    { text: "Name", value: "name" },
    { text: "Amount", value: "amount" },
    { text: "Market", value: "marketId" },
    { text: "", value: "actions", sortable: false },
  ];

  @Watch("refresh", { immediate: true })
  async modalDataChanged(): Promise<void> {
    if (this.refresh == true) {
      console.log("Refresh Triggered");
      await this.init();
    }
  }

  async filterByMarket(): Promise<void> {
    await this.init();
    if (this.filter != null) {
      this.items = this.items.filter((elem) => elem.marketId == this.filter);
    }
  }

  get getUserAccess(): UserAccessModel | null {
    console.log("UA: ", User.getUserAccess);
    return User.getUserAccess;
  }

  openEdit(i: RatingCode): void {
    this.modalData.type = "edit";
    this.modalData.fields = i;
    this.modal = true;
  }

  openAdd(): void {
    this.modalData.type = "add";
    this.modalData.fields = {};
    this.modal = true;
  }

  openDelete(i: RatingCode): void {
    this.deleteItem = i;
    this.deleteModal = true;
  }

  getMarketCodeById(id: number): string {
    var result = this.markets.find((elem) => elem.id == id);
    if (result != undefined) {
      return result.marketCode;
    } else {
      return "";
    }
  }

  async deleteRatingCode(): Promise<void> {
    try {
      const res = await ratingCodeHook.deleteRatingCode(this.deleteItem);
      this.$notificationCreator.createSuccessNotification(
        "Rating Code Deleted."
      );
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.deleteModal = false;
      this.init();
    }
  }

  async mounted(): Promise<void> {
    await this.init();
    await this.getMarkets();
  }

  async init(): Promise<void> {
    try {
      this.loading = true;
      const res = await ratingCodeHook.getAllRatingCodes();
      this.items = res;
      if (this.filter != null) {
        this.items = this.items.filter((elem) => elem.marketId == this.filter);
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async getMarkets(): Promise<void> {
    try {
      const res = await marketHook.getAllMarkets();
      this.markets = res;
    } catch (err) {
      return Promise.reject(err);
    }
  }
}
