import { axiosInstance } from "@/plugins/axios";
import { AuditModel } from "models";

const Audit = () => {
  const baseUrl = "/api/Audit";

  const GetAuditSuperSearch = async (
    dateFrom: string,
    dateTo: string,
    toTime: string,
    endTime: string,
    action: number,
    userId: string
  ): Promise<AuditModel[]> => {
    const res = await axiosInstance.get(
      baseUrl +
        `/GetAuditSuperSearch?dateFrom=${dateFrom}&dateTo=${dateTo}&toTime=${toTime}&endTime=${endTime}&action=${action}&userId=${userId}`
    );
    return Promise.resolve(res.data);
  };

  return {
    GetAuditSuperSearch,
  };
};
export default Audit;
