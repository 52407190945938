import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "@/plugins/axios";
import vuetify from "./plugins/vuetify";
import notificationCreator from "./plugins/notifications";

Vue.config.productionTip = false;
Vue.use(axios);
Vue.use(notificationCreator);

const vue = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
