import { axiosInstance } from "@/plugins/axios";
import { Market, Market_CREATE } from "models";

const Markets = () => {
  const baseUrl = "/api/Market";

  const getAllMarkets = async (): Promise<Market[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAllMarkets");
    return Promise.resolve(res.data);
  };

  const getMarketById = async (id: number): Promise<Market> => {
    const res = await axiosInstance.get(baseUrl + `/getMarketById?id=${id}`);
    return Promise.resolve(res.data);
  };

  const addNewMarket = async (market: Market_CREATE): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/addNewMarket", {
      marketName: market.marketName,
      marketCode: market.marketCode,
    });
    return Promise.resolve(res.data);
  };

  const updateMarket = async (market: Market): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/updateMarket", {
      marketName: market.marketName,
      marketCode: market.marketCode,
      createdBy: market.createdBy,
      createdDate: market.createdDate,
      id: market.id,
      updatedBy: market.updatedBy,
      updatedDate: market.updatedDate,
      _id: {
        creationTime: market._id.creationTime,
        increment: market._id.increment,
        machine: market._id.machine,
        pid: market._id.pid,
        timestamp: market._id.timestamp,
      },
    });
    return Promise.resolve(res.data);
  };

  const deleteMarket = async (market: Market): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/deleteMarket", {
      marketName: market.marketName,
      marketCode: market.marketCode,
      createdBy: market.createdBy,
      createdDate: market.createdDate,
      id: market.id,
      updatedBy: market.updatedBy,
      updatedDate: market.updatedDate,
      _id: {
        creationTime: market._id?.creationTime,
        increment: market._id?.increment,
        machine: market._id?.machine,
        pid: market._id?.pid,
        timestamp: market._id?.timestamp,
      },
    });
    return Promise.resolve(res.data);
  };

  return {
    getAllMarkets,
    getMarketById,
    addNewMarket,
    updateMarket,
    deleteMarket,
  };
};
export default Markets;
