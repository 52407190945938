import { axiosInstance } from "@/plugins/axios";
import {
  TrustCorpDocs,
  TrustCorpDocsUpdate,
  TrustDocument,
  TrustDocumentUpdate,
  TrustPersonalDocs,
  TrustPersonalDocsUpdate,
} from "models";

const TrustDocuments = () => {
  const baseUrl = "/api/TrustDocuments";

  const UploadTrustDocuments = async (
    clientDocuments: TrustDocument
  ): Promise<TrustDocument> => {
    const res = await axiosInstance.post(baseUrl + `/UploadTrustDocuments`, {
      user: clientDocuments.user,
      entityId: clientDocuments.entityId,
      identificationType: clientDocuments.identificationType,
      idFile: clientDocuments.idFile,
      saidNumber: clientDocuments.saidNumber,
      bankUpload: clientDocuments.bankUpload,
      taxNumber: clientDocuments.taxNumber,
      taxNumberUpload: clientDocuments.taxNumberUpload,
      incomeTax: clientDocuments.incomeTax,
      incomeTaxUpload: clientDocuments.incomeTaxUpload,
      vatRegNumber: clientDocuments.vatRegNumber,
      vatUpload: clientDocuments.vatUpload,
      mandate: clientDocuments.bankUpload,
      regUpload: clientDocuments.regUpload,
      trustNumber: clientDocuments.trustNumber,
      deed: clientDocuments.deed,
      authSAIDNumber: clientDocuments.authSAIDNumber,
      authId: clientDocuments.authId,
      authPOR: clientDocuments.authPOR,
    });
    return Promise.resolve(res.data);
  };

  const UploadTrustPersonalDocuments = async (
    clientDocuments: TrustPersonalDocs
  ): Promise<TrustDocument> => {
    const res = await axiosInstance.post(
      baseUrl + `/UploadTrustPersonalDocuments`,
      {
        user: clientDocuments.user,
        entityId: clientDocuments.entityId,
        identificationType: clientDocuments.identificationType,
        idFile: clientDocuments.idFile,
        saidNumber: clientDocuments.saidNumber,
        incomeTax: clientDocuments.incomeTax,
        incomeTaxUpload: clientDocuments.incomeTaxUpload,
        authSAIDNumber: clientDocuments.authSAIDNumber,
        authId: clientDocuments.authId,
      }
    );
    return Promise.resolve(res.data);
  };

  const UploadTrustCorpDocuments = async (
    clientDocuments: TrustCorpDocs
  ): Promise<TrustDocument> => {
    const res = await axiosInstance.post(baseUrl + `/UploadTrustDocuments`, {
      user: clientDocuments.user,
      entityId: clientDocuments.entityId,
      bankUpload: clientDocuments.bankUpload,
      taxNumber: clientDocuments.taxNumber,
      taxNumberUpload: clientDocuments.taxNumberUpload,
      vatRegNumber: clientDocuments.vatRegNumber,
      vatUpload: clientDocuments.vatUpload,
      mandate: clientDocuments.bankUpload,
      regUpload: clientDocuments.regUpload,
      trustNumber: clientDocuments.trustNumber,
      deed: clientDocuments.deed,
      authPOR: clientDocuments.authPOR,
    });
    return Promise.resolve(res.data);
  };

  const UpdateTrustDocuments = async (
    clientDocuments: TrustDocumentUpdate
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/UpdateTrustDocuments", {
      user: clientDocuments.user,
      entityId: clientDocuments.entityId,
      identificationType: clientDocuments.identificationType,
      idFileId: clientDocuments.idFileId,
      idFile: clientDocuments.idFile,
      saidNumber: clientDocuments.saidNumber,
      bankUploadId: clientDocuments.bankUploadId,
      bankUpload: clientDocuments.bankUpload,
      taxNumber: clientDocuments.taxNumber,
      taxNumberUploadId: clientDocuments.taxNumberUploadId,
      taxNumberUpload: clientDocuments.taxNumberUpload,
      incomeTax: clientDocuments.incomeTax,
      incomeTaxId: clientDocuments.incomeTaxId,
      incomeTaxUpload: clientDocuments.incomeTaxUpload,
      vatRegNumber: clientDocuments.vatRegNumber,
      vatUploadId: clientDocuments.vatUploadId,
      vatUpload: clientDocuments.vatUpload,
      mandateId: clientDocuments.mandateId,
      mandate: clientDocuments.bankUpload,
      regUpload: clientDocuments.regUpload,
      regUploadId: clientDocuments.regUploadId,
      trustNumber: clientDocuments.trustNumber,
      deedId: clientDocuments.deedId,
      deed: clientDocuments.deed,
      authSAIDNumber: clientDocuments.authSAIDNumber,
      authIdUpload: clientDocuments.vatUpload,
      authId: clientDocuments.authId,
      authPORId: clientDocuments.authPORId,
      authPOR: clientDocuments.authPOR,
    });
    return Promise.resolve(res.data);
  };

  const UpdateTrustPersonalDocuments = async (
    clientDocuments: TrustPersonalDocsUpdate
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/UpdateTrustPersonalDocuments",
      {
        user: clientDocuments.user,
        entityId: clientDocuments.entityId,
        identificationType: clientDocuments.identificationType,
        idFileId: clientDocuments.idFileId,
        idFile: clientDocuments.idFile,
        saidNumber: clientDocuments.saidNumber,
        incomeTax: clientDocuments.incomeTax,
        incomeTaxId: clientDocuments.incomeTaxId,
        incomeTaxUpload: clientDocuments.incomeTaxUpload,
        authSAIDNumber: clientDocuments.authSAIDNumber,
        authIdUpload: clientDocuments.authIdUpload,
        idAuthId: clientDocuments.idAuthId,
        authPORId: clientDocuments.authPORId,
        authPOR: clientDocuments.authPOR,
      }
    );
    return Promise.resolve(res.data);
  };

  const UpdateTrustCorpDocuments = async (
    clientDocuments: TrustCorpDocsUpdate
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/UpdateTrustDocuments", {
      user: clientDocuments.user,
      entityId: clientDocuments.entityId,
      bankUploadId: clientDocuments.bankUploadId,
      bankUpload: clientDocuments.bankUpload,
      taxNumber: clientDocuments.taxNumber,
      taxNumberUploadId: clientDocuments.taxNumberUploadId,
      taxNumberUpload: clientDocuments.taxNumberUpload,
      vatRegNumber: clientDocuments.vatRegNumber,
      vatUploadId: clientDocuments.vatUploadId,
      vatUpload: clientDocuments.vatUpload,
      mandateId: clientDocuments.mandateId,
      mandate: clientDocuments.bankUpload,
      regUpload: clientDocuments.regUpload,
      regUploadId: clientDocuments.regUploadId,
      trustNumber: clientDocuments.trustNumber,
      deedId: clientDocuments.deedId,
      deed: clientDocuments.deed,
    });
    return Promise.resolve(res.data);
  };

  const getTrustIdDocumentByCode = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getTrustIdDocumentByCode?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getTrustBankingByCode = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getTrustBankingByCode?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getTrustTaxByCode = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getTrustTaxByCode?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getTrustTaxIncomeByCode = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getTrustTaxIncomeByCode?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getTrustVat = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getTrustVat?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getTrustMandate = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getTrustMandate?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getTrustDeed = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getTrustDeed?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getTrustAuthIdDocumentByCode = async (
    clientId: string
  ): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getTrustAuthIdDocumentByCode?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getTrustPORDocumentByCode = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getTrustPORDocumentByCode?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const DownloadTIdDocument = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadTIdDocument?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadTBankingDocument = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadTBankingDocument?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadTVATDocument = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadTVATDocument?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadTTax = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(baseUrl + `/DownloadTTax?id=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "image/jpeg" + "application/pdf" + "image/png",
      },
      responseType: "blob",
    });
    return Promise.resolve(res.data);
  };

  const DownloadTIncomeTax = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadTIncomeTax?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadMandate = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(baseUrl + `/DownloadMandate?id=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "image/jpeg" + "application/pdf" + "image/png",
      },
      responseType: "blob",
    });
    return Promise.resolve(res.data);
  };

  const DownloadDeed = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(baseUrl + `/DownloadDeed?id=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "image/jpeg" + "application/pdf" + "image/png",
      },
      responseType: "blob",
    });
    return Promise.resolve(res.data);
  };

  const DownloadPOR = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(baseUrl + `/DownloadPOR?id=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "image/jpeg" + "application/pdf" + "image/png",
      },
      responseType: "blob",
    });
    return Promise.resolve(res.data);
  };

  const DownloadAuthId = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(baseUrl + `/DownloadAuthId?id=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "image/jpeg" + "application/pdf" + "image/png",
      },
      responseType: "blob",
    });
    return Promise.resolve(res.data);
  };

  return {
    UploadTrustDocuments,
    UploadTrustCorpDocuments,
    UploadTrustPersonalDocuments,
    UpdateTrustDocuments,
    UpdateTrustCorpDocuments,
    UpdateTrustPersonalDocuments,
    getTrustIdDocumentByCode,
    getTrustBankingByCode,
    getTrustTaxByCode,
    getTrustTaxIncomeByCode,
    getTrustVat,
    getTrustMandate,
    getTrustDeed,
    getTrustAuthIdDocumentByCode,
    getTrustPORDocumentByCode,
    DownloadTIdDocument,
    DownloadTBankingDocument,
    DownloadTVATDocument,
    DownloadTTax,
    DownloadTIncomeTax,
    DownloadMandate,
    DownloadDeed,
    DownloadAuthId,
    DownloadPOR,
  };
};
export default TrustDocuments;
