import { axiosInstance } from "@/plugins/axios";
import { Trader, Trader_CREATE } from "models";

const Traders = () => {
  const baseUrl = "/api/Trader";

  const getAllTraders = async (): Promise<Trader[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAllTraders");
    return Promise.resolve(res.data);
  };

  const getAllTradersByMarket = async (marketId: number): Promise<Trader[]> => {
    const res = await axiosInstance.get(
      baseUrl + `/getAllTradersByMarket?id=${marketId}`
    );
    return Promise.resolve(res.data);
  };

  const addNewTrader = async (trader: Trader_CREATE): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/addNewTrader", {
      marketId: trader.marketId,
      branch: trader.branch,
      organisation: trader.organisation,
      dealerCode: trader.dealerCode,
      traderName: trader.traderName,
      traderEmail: trader.traderEmail,
    });
    return Promise.resolve(res.data);
  };

  const updateTrader = async (trader: Trader): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/updateTrader", {
      _id: trader._id,
      marketId: trader.marketId,
      branch: trader.branch,
      organisation: trader.organisation,
      dealerCode: trader.dealerCode,
      traderName: trader.traderName,
      traderEmail: trader.traderEmail,
      createdBy: trader.createdBy,
      createdDate: trader.createdDate,
      updatedBy: trader.updatedBy,
      updatedDate: trader.updatedDate,
    });
    return Promise.resolve(res.data);
  };

  const deleteTrader = async (trader: Trader): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/deleteTrader", {
      _id: trader._id,
      marketId: trader.marketId,
      branch: trader.branch,
      organisation: trader.organisation,
      dealerCode: trader.dealerCode,
      traderName: trader.traderName,
      traderEmail: trader.traderEmail,
      createdBy: trader.createdBy,
      createdDate: trader.createdDate,
      updatedBy: trader.updatedBy,
      updatedDate: trader.updatedDate,
    });
    return Promise.resolve(res.data);
  };

  return {
    getAllTraders,
    getAllTradersByMarket,
    addNewTrader,
    updateTrader,
    deleteTrader,
  };
};
export default Traders;
