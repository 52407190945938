
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import {
  PrivatePersonalDocs,
  PrivatePersonalDocsUpdate,
  PrivateCorpDocs,
  PrivateCorpDocsUpdate,
  CustomDocs,
  CustomDocsUpdate,
  CustomDocsList,
} from "models";
import Hooks from "@/hooks";
import User from "@/store/modules/user";
import JSEClient from "@/store/modules/JSEClient";

const { PrivateDocuments, CustomFile } = Hooks;
const clientHook = PrivateDocuments();
const customFileHook = CustomFile();

@Component({})
export default class SupportingDocs extends Vue {
  @PropSync("valid") formValid!: boolean;
  @PropSync("nextTab") tab!: boolean;
  @PropSync("next") accountNext!: boolean;
  @Prop() type!: string;
  loading = false;
  regNumber: string | null = null;
  regUpload: File[] | null = null;
  proofOfResidenceUpload: File | null = null;
  taxNumber: string | null = null;
  taxNumberupload: File | null = null;
  vatNumber: string | null = null;
  vatUpload: File | null = null;
  proofOfBankUpload: File | null = null;
  resolution_mandate: File | null = null;
  proofOfShares: File | null = null;
  authIdNumber: string | null = null;
  authId: File | null = null;
  addFiles: {
    type: string | null;
    info: string | null;
    file: File | null;
  }[] = [];
  storedDocs: {
    regDoc: any | null;
    porDoc: any | null;
    tnDoc: any | null;
    vatDoc: any | null;
    pobDoc: any | null;
    rmDoc: any | null;
    posDoc: any | null;
    authID: any | null;
    addFiles: any | null;
  } = {
    regDoc: null,
    porDoc: null,
    tnDoc: null,
    vatDoc: null,
    pobDoc: null,
    rmDoc: null,
    posDoc: null,
    authID: null,
    addFiles: null,
  };
  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
    id: (value: string): boolean | string =>
      value?.length == 13 || "Requires 13 digits",
    fileSize: (value: { size: number }): boolean | string =>
      !value || value.size < 5000000 || "Max File Size 5MB!",
    fileSizeMultiple: (files: { size: number }[]): boolean | string =>
      !files ||
      !files.some((file: { size: number }) => file.size > 5000000) ||
      "Max File Size 5MB!",
  };
  headers = [
    {
      text: "Type",
      value: "docType",
      sortable: false,
      style: "width: 200px",
    },
    {
      text: "Information",
      value: "info",
      sortable: false,
      style: "width: 350px",
    },
    {
      text: "File (s)",
      value: "files",
      sortable: false,
    },
  ];
  headersEdit = [
    {
      text: "Type",
      value: "docType",
      sortable: false,
      style: "width: 200px",
    },
    {
      text: "Information",
      value: "info",
      sortable: false,
      style: "width: 350px",
    },
    {
      text: "File (s)",
      value: "files",
      sortable: false,
    },
    {
      text: "View",
      value: "view",
      sortable: false,
    },
  ];
  items = [
    {
      type: "reg",
    },
    {
      type: "tax",
    },
    {
      type: "vat",
    },
    {
      type: "por",
    },
    {
      type: "pob",
    },
    {
      type: "pos",
    },
    {
      type: "rm",
    },
    {
      type: "authID",
    },
  ];

  addCustomFile(): void {
    var checkAdd = this.items.find((elem) => elem.type == "add");

    if (checkAdd != undefined) {
      this.addFiles.push({ type: null, info: null, file: null });
    } else {
      this.items.push({ type: "add" });
      this.addFiles.push({ type: null, info: null, file: null });
    }
  }

  download(type: string): void {
    switch (type) {
      case "reg":
        this.downloadReg(
          this.storedDocs.regDoc != null ? this.storedDocs.regDoc[0]._id : 0
        );
        break;
      case "pob":
        this.downloadPOB(
          this.storedDocs.pobDoc != null ? this.storedDocs.pobDoc._id : 0
        );
        break;
      case "por":
        this.downloadPOB(
          this.storedDocs.porDoc != null ? this.storedDocs.porDoc._id : 0
        );
        break;
      case "tax":
        this.downloadTN(
          this.storedDocs.tnDoc != null ? this.storedDocs.tnDoc._id : 0
        );
        break;
      case "vat":
        this.downloadVAT(
          this.storedDocs.vatDoc != null ? this.storedDocs.vatDoc._id : 0
        );
        break;
      case "rm":
        this.downloadRM(
          this.storedDocs.vatDoc != null ? this.storedDocs.vatDoc._id : 0
        );
        break;
      case "authID":
        this.downloadAID(
          this.storedDocs.authID != null ? this.storedDocs.authID._id : 0
        );
        break;
      case "pos":
        this.downloadPOS(
          this.storedDocs.posDoc != null ? this.storedDocs.posDoc._id : 0
        );
        break;
      case "add":
        this.downloadCustom(
          this.storedDocs.addFiles != null ? this.storedDocs.addFiles[0]._id : 0
        );
        break;
    }
  }

  @Watch("type", { immediate: true })
  async modalTypeChanged(): Promise<void> {
    console.log("Modal Details Type: ", this.type);
    if (this.type == "edit") {
      await this.getStoredData();
      setTimeout(this.asignStoredData(), 10);
    }
  }

  @Watch("accountNext", {
    immediate: true,
  })
  async onNextChanged(): Promise<void> {
    console.log("AccountNext", this.accountNext);
    if (this.accountNext == true) {
      if (this.type == "add") {
        try {
          this.loading = true;
          await this.UploadPrivateCompPersonalDocuments();
          await this.UploadPrivateCompCorpDocuments();
          if (this.addFiles.length > 0) {
            await this.UploadCustomFiles();
          }

          JSEClient.setAccountDocs({
            idNumber: this.authIdNumber,
            passportNumber: null,
            proofOfRegUploaded: this.regUpload ? true : false,
            proofOfResUploaded: this.proofOfResidenceUpload ? true : false,
            regNumber: this.regNumber,
            taxNumber: this.taxNumber,
            vatNumber: this.vatNumber,
          });

          this.$notificationCreator.createSuccessNotification(
            "Supporting Documents Saved."
          );
          this.tab = true;
        } finally {
          this.loading = false;
        }
      }
      if (this.type == "edit") {
        try {
          this.loading = true;
          await this.UpdatePrivateCompPersonalDocuments();
          await this.UpdatePrivateCompCorpDocuments();
          if (this.addFiles.length > 0) {
            await this.UploadCustomFiles();
            await this.UpdateCustomDocs();
          } else if (this.addFiles.length == 0) {
            await this.UpdateCustomDocs();
          }

          JSEClient.setAccountDocs({
            idNumber: this.authIdNumber,
            passportNumber: null,
            proofOfRegUploaded: this.regUpload ? true : false,
            proofOfResUploaded: this.proofOfResidenceUpload ? true : false,
            regNumber: this.regNumber,
            taxNumber: this.taxNumber,
            vatNumber: this.vatNumber,
          });

          this.$notificationCreator.createSuccessNotification(
            "Supporting Documents Updated."
          );
          //this.tab = true;
        } finally {
          this.accountNext = false;
          this.loading = false;
        }
      }
    }
  }

  async getBase64(file: Blob | File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  asignStoredData(): any {
    if (this.storedDocs.tnDoc != null) {
      this.taxNumber = this.storedDocs.tnDoc.taxNumber;
    }
    if (this.storedDocs.vatDoc != null) {
      this.vatNumber = this.storedDocs.vatDoc.vatNumber;
    }
    if (this.storedDocs.regDoc != null) {
      this.regNumber = this.storedDocs.regDoc[0].regNumber;
    }
    if (this.storedDocs.authID != null) {
      this.authIdNumber = this.storedDocs.authID.idNumber;
    }
  }

  async getStoredData(): Promise<void> {
    try {
      this.loading = true;
      var entityId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        entityId = key;
      }

      if (entityId != null) {
        const resPOR = await clientHook.getPrivateCompPORByCode(entityId);
        this.storedDocs.porDoc = resPOR;

        const resTN = await clientHook.getPrivateCompTaxByCode(entityId);
        this.taxNumber = resTN.taxNumber;
        this.storedDocs.tnDoc = resTN;

        const resVAT = await clientHook.getPrivateCompVAT(entityId);
        this.vatNumber = resVAT.vatNumber;
        this.storedDocs.vatDoc = resVAT;

        const resReg = await clientHook.getPrivateCompRegByCode(entityId);
        if (resReg.length > 0) {
          this.regNumber = resReg[0].regNumber;
          this.storedDocs.regDoc = resReg;
          if (this.storedDocs.regDoc != null && this.storedDocs.regDoc != "") {
            this.storedDocs.regDoc.forEach((elem: any) => {
              elem.updateFile = null as File | null;
            });
          }
        } else if (resReg.length == 0) {
          this.storedDocs.regDoc = [{ _id: null, updateFile: null }];
        }

        const resPOB = await clientHook.getPrivateCompBank(entityId);
        this.storedDocs.pobDoc = resPOB;

        const resRM = await clientHook.getPrivateCompMandate(entityId);
        this.storedDocs.rmDoc = resRM;

        const resPOS = await clientHook.getPrivateCompSHByCode(entityId);
        this.storedDocs.posDoc = resPOS;

        const resAID = await clientHook.getPrivateCompAuthIdByCode(entityId);
        this.authIdNumber = resAID.idNumber;
        this.storedDocs.authID = resAID;

        const resCustom = await customFileHook.getCustomFileByCode(entityId);
        this.storedDocs.addFiles = resCustom;
        if (
          this.storedDocs.addFiles != null &&
          this.storedDocs.addFiles != ""
        ) {
          this.items.push({ type: "add" });
          this.storedDocs.addFiles.forEach((elem: any) => {
            elem.updateFile = null as File | null;
          });
        }

        console.log("Stored Docs: ", this.storedDocs);
        return Promise.resolve();
      }
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async UpdatePrivateCompPersonalDocuments(): Promise<void> {
    try {
      var clientId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        clientId = key;
      }
      var resultAID: string | ArrayBuffer | null = null;
      if (this.authId != null) {
        resultAID = await this.getBase64(this.authId);
      }
      var clientDocument: PrivatePersonalDocsUpdate = {
        user: User.getLoggedInUser,
        entityId: clientId,
        identificationType: null,
        idFileId: null,
        idFile: null,
        saidNumber: null,
        incomeTax: null,
        incomeTaxId: null,
        incomeTaxUpload: null,
        authSAIDNumber: this.authIdNumber,
        authIdFileId:
          this.storedDocs.authID != undefined
            ? this.storedDocs.authID._id
            : null,
        authIdFile: this.authId != null ? resultAID : this.authId,
      };

      console.log("Account Details: ", clientDocument);
      const res = await clientHook.UpdatePrivateCompPersonalDocuments(
        clientDocument
      );

      return Promise.resolve();
    } catch (err: any) {
      //this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    }
  }

  async UpdatePrivateCompCorpDocuments(): Promise<void> {
    try {
      var clientId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        clientId = key;
      }

      var resultPOR: string | ArrayBuffer | null = null;
      if (this.proofOfResidenceUpload != null) {
        resultPOR = await this.getBase64(this.proofOfResidenceUpload);
      }

      var resultPOB: string | ArrayBuffer | null = null;
      if (this.proofOfBankUpload != null) {
        resultPOB = await this.getBase64(this.proofOfBankUpload);
      }

      var resultTN: string | ArrayBuffer | null = null;
      if (this.taxNumberupload != null) {
        resultTN = await this.getBase64(this.taxNumberupload);
      }

      var resultVAT: string | ArrayBuffer | null = null;
      if (this.vatUpload != null) {
        resultVAT = await this.getBase64(this.vatUpload);
      }

      var resultRM: string | ArrayBuffer | null = null;
      if (this.resolution_mandate != null) {
        resultRM = await this.getBase64(this.resolution_mandate);
      }

      var resultPOS: string | ArrayBuffer | null = null;
      if (this.proofOfShares != null) {
        resultPOS = await this.getBase64(this.proofOfShares);
      }

      if (this.storedDocs.regDoc.length <= 1) {
        var resultRegDoc: string | ArrayBuffer | null = null;
        if (
          this.storedDocs.regDoc != null &&
          this.storedDocs.regDoc != "" &&
          this.storedDocs.regDoc[0].updateFile != null
        ) {
          resultRegDoc = await this.getBase64(
            new Blob([this.storedDocs.regDoc[0].updateFile])
          );
        }

        var clientDocument: PrivateCorpDocsUpdate = {
          user: User.getLoggedInUser,
          entityId: clientId,
          proofOfResId:
            this.storedDocs.porDoc != undefined
              ? this.storedDocs.porDoc._id
              : null,
          proofOfRes:
            this.proofOfResidenceUpload != null
              ? resultPOR
              : this.proofOfResidenceUpload,
          taxNumber: this.taxNumber,
          taxNumberUploadId:
            this.storedDocs.tnDoc != undefined
              ? this.storedDocs.tnDoc._id
              : null,
          taxNumberUpload:
            this.taxNumberupload != null ? resultTN : this.taxNumberupload,
          bankUploadId:
            this.storedDocs.pobDoc != undefined
              ? this.storedDocs.pobDoc._id
              : null,
          bankUpload:
            this.proofOfBankUpload != null ? resultPOB : this.proofOfBankUpload,
          vatRegNumber: this.vatNumber,
          vatUploadId:
            this.storedDocs.vatDoc != undefined
              ? this.storedDocs.vatDoc._id
              : null,
          vatUpload: this.vatUpload != null ? resultVAT : this.vatUpload,
          regNumber: this.regNumber,
          regUploadId:
            resultRegDoc != null && this.storedDocs.regDoc != ""
              ? this.storedDocs.regDoc[0]._id
              : null,
          regUpload:
            resultRegDoc != null && this.storedDocs.regDoc != ""
              ? resultRegDoc
              : null,
          mandateId:
            this.storedDocs.rmDoc != undefined
              ? this.storedDocs.rmDoc._id
              : null,
          mandate:
            this.resolution_mandate != null
              ? resultRM
              : this.resolution_mandate,
          shareholderId:
            this.storedDocs.posDoc != undefined
              ? this.storedDocs.posDoc._id
              : null,
          shareholder:
            this.proofOfShares != null ? resultPOS : this.proofOfShares,
        };

        console.log("Account Details: ", clientDocument);
        const res = await clientHook.UpdatePrivateCompCorpDocuments(
          clientDocument
        );
      } else if (this.storedDocs.regDoc.length < 1) {
        if (this.storedDocs.regDoc != null && this.storedDocs.regDoc != "") {
          this.storedDocs.regDoc.forEach(async (elem: any) => {
            var resultRegDoc: string | ArrayBuffer | null = null;
            resultRegDoc = await this.getBase64(new Blob([elem.updateFile]));

            var clientDocument: PrivateCorpDocsUpdate = {
              user: User.getLoggedInUser,
              entityId: clientId,
              proofOfResId:
                this.storedDocs.porDoc != undefined
                  ? this.storedDocs.porDoc._id
                  : null,
              proofOfRes:
                this.proofOfResidenceUpload != null
                  ? resultPOR
                  : this.proofOfResidenceUpload,
              taxNumber: this.taxNumber,
              taxNumberUploadId:
                this.storedDocs.tnDoc != undefined
                  ? this.storedDocs.tnDoc._id
                  : null,
              taxNumberUpload:
                this.taxNumberupload != null ? resultTN : this.taxNumberupload,
              bankUploadId:
                this.storedDocs.pobDoc != undefined
                  ? this.storedDocs.pobDoc._id
                  : null,
              bankUpload:
                this.proofOfBankUpload != null
                  ? resultPOB
                  : this.proofOfBankUpload,
              vatRegNumber: this.vatNumber,
              vatUploadId:
                this.storedDocs.vatDoc != undefined
                  ? this.storedDocs.vatDoc._id
                  : null,
              vatUpload: this.vatUpload != null ? resultVAT : this.vatUpload,
              regNumber: this.regNumber,
              regUploadId:
                this.storedDocs.regDoc != undefined && resultRegDoc != null
                  ? elem._id
                  : null,
              regUpload:
                this.storedDocs.regDoc != null && this.storedDocs.regDoc != ""
                  ? resultRegDoc
                  : null,
              mandateId:
                this.storedDocs.rmDoc != undefined
                  ? this.storedDocs.rmDoc._id
                  : null,
              mandate:
                this.resolution_mandate != null
                  ? resultRM
                  : this.resolution_mandate,
              shareholderId:
                this.storedDocs.posDoc != undefined
                  ? this.storedDocs.posDoc._id
                  : null,
              shareholder:
                this.proofOfShares != null ? resultPOS : this.proofOfShares,
            };

            console.log("Account Details: ", clientDocument);
            const res = await clientHook.UpdatePrivateCompCorpDocuments(
              clientDocument
            );
          });
        }
      }
      return Promise.resolve();
    } catch (err: any) {
      //this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    }
  }
  async UploadPrivateCompCorpDocuments(): Promise<void> {
    try {
      var clientId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        clientId = key;
      }

      const promisesRegDoc: Promise<string | ArrayBuffer | null>[] = [];
      if (this.regUpload != null) {
        this.regUpload.forEach(async (elem: any) => {
          promisesRegDoc.push(this.getBase64(new Blob([elem])));
        });
      }
      const resultRegDoc = await Promise.all(promisesRegDoc);

      var proofOfResidenceArray = [];
      const promisesPOR: Promise<string | ArrayBuffer | null>[] = [];
      proofOfResidenceArray.push(this.proofOfResidenceUpload);
      proofOfResidenceArray.forEach(async (elem: any) => {
        promisesPOR.push(this.getBase64(new Blob([elem])));
      });
      const resultPOR = await Promise.all(promisesPOR);

      var pobArray = [];
      const promisesPOB: Promise<string | ArrayBuffer | null>[] = [];
      pobArray.push(this.proofOfBankUpload);
      pobArray.forEach(async (elem: any) => {
        promisesPOB.push(this.getBase64(new Blob([elem])));
      });
      const resultPOB = await Promise.all(promisesPOB);

      var taxNumberArray = [];
      const promisesTN: Promise<string | ArrayBuffer | null>[] = [];
      taxNumberArray.push(this.taxNumberupload);
      taxNumberArray.forEach(async (elem: any) => {
        promisesTN.push(this.getBase64(new Blob([elem])));
      });
      const resultTN = await Promise.all(promisesTN);

      var vatArray = [];
      const promisesVAT: Promise<string | ArrayBuffer | null>[] = [];
      vatArray.push(this.vatUpload);
      vatArray.forEach(async (elem: any) => {
        promisesVAT.push(this.getBase64(new Blob([elem])));
      });
      const resultVAT = await Promise.all(promisesVAT);

      var RMArray = [];
      const promisesRM: Promise<string | ArrayBuffer | null>[] = [];
      RMArray.push(this.resolution_mandate);
      RMArray.forEach(async (elem: any) => {
        promisesRM.push(this.getBase64(new Blob([elem])));
      });
      const resultRM = await Promise.all(promisesRM);

      var POSArray = [];
      const promisesPOS: Promise<string | ArrayBuffer | null>[] = [];
      POSArray.push(this.proofOfShares);
      POSArray.forEach(async (elem: any) => {
        promisesPOS.push(this.getBase64(new Blob([elem])));
      });
      const resultPOS = await Promise.all(promisesPOS);

      var clientDocument: PrivateCorpDocs = {
        user: User.getLoggedInUser,
        entityId: clientId,
        proofOfRes:
          this.proofOfResidenceUpload != null
            ? resultPOR
            : this.proofOfResidenceUpload,
        taxNumber: this.taxNumber,
        taxNumberUpload:
          this.taxNumberupload != null ? resultTN : this.taxNumberupload,
        bankUpload:
          this.proofOfBankUpload != null ? resultPOB : this.proofOfBankUpload,
        vatRegNumber: this.vatNumber,
        vatUpload: this.vatUpload != null ? resultVAT : this.vatUpload,
        regNumber: this.regNumber,
        regUpload: this.regUpload != null ? resultRegDoc : this.regUpload,
        mandate:
          this.resolution_mandate != null ? resultRM : this.resolution_mandate,
        shareholder:
          this.proofOfShares != null ? resultPOS : this.proofOfShares,
      };

      console.log("Account Details: ", clientDocument);
      if (clientDocument != null) {
        const res = await clientHook.UploadPrivateCompCorpDocuments(
          clientDocument
        );
      }
      return Promise.resolve();
    } catch (err: any) {
      this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    }
  }

  async UploadPrivateCompPersonalDocuments(): Promise<void> {
    try {
      var clientId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        clientId = key;
      }

      var AIDArray = [];
      const promisesAID: Promise<string | ArrayBuffer | null>[] = [];
      AIDArray.push(this.authId);
      AIDArray.forEach(async (elem: any) => {
        promisesAID.push(this.getBase64(new Blob([elem])));
      });
      const resultAID = await Promise.all(promisesAID);

      var clientDocument: PrivatePersonalDocs = {
        user: User.getLoggedInUser,
        entityId: clientId,
        identificationType: null,
        idFile: null,
        saidNumber: null,
        incomeTax: null,
        incomeTaxUpload: null,
        authSAIDNumber: this.authIdNumber,
        authUpload: this.authId != null ? resultAID : this.authId,
      };

      console.log("Account Details: ", clientDocument);
      if (clientDocument != null) {
        const res = await clientHook.UploadPrivateCompPersonalDocuments(
          clientDocument
        );
      }
      return Promise.resolve();
    } catch (err: any) {
      this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    }
  }

  async UploadCustomFiles(): Promise<void> {
    var clientId: string | null = null;
    const key = localStorage.getItem("ClientFile/entityId");
    if (key != null) {
      clientId = key;
    }

    if (this.addFiles.length > 0) {
      var customDoc: CustomDocsList[] = [];

      for (let file of this.addFiles) {
        if (file.file != null) {
          customDoc.push({
            user: User.getLoggedInUser,
            entityId: clientId,
            number: file.info,
            fileType: file.type,
            customFile: await this.getBase64(file.file),
          });
        }
      }

      console.log("Custom Docs: ", customDoc);
      const res = await customFileHook
        .UploadCustomDocumentsList(customDoc)
        .catch((err) => {
          this.accountNext = false;
          this.tab = false;
          return Promise.reject(err);
        });
      return Promise.resolve();
    }
  }

  async UpdateCustomDocs(): Promise<void> {
    try {
      var closedCorpId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        closedCorpId = key;
      }

      if (this.storedDocs.addFiles != null && this.storedDocs.addFiles != "") {
        this.storedDocs.addFiles.forEach(async (elem: any) => {
          if (elem.updateFile != null) {
            var resultCustomFiles: string | ArrayBuffer | null = null;
            resultCustomFiles = await this.getBase64(
              new Blob([elem.updateFile])
            );

            var customDocs: CustomDocsUpdate = {
              user: User.getLoggedInUser,
              entityId: closedCorpId,
              number: elem.number,
              fileType: elem.fileType,
              customFileId:
                this.storedDocs.addFiles != undefined ? elem._id : null,
              customFile:
                this.storedDocs.addFiles != undefined
                  ? resultCustomFiles
                  : null,
            };

            console.log("Custom Docs: ", customDocs);
            const res = await customFileHook.UpdateCustomFile(customDocs);

            return Promise.resolve();
          }
        });
      }
    } catch (err: any) {
      //this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    }
  }

  async downloadId(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await clientHook
          .DownloadIdDocument(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download ID: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadPOR(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await clientHook
          .DownloadPCompPORDocument(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download POR: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadTN(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await clientHook
          .DownloadTax(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download TN: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadVAT(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await clientHook
          .DownloadVATDocument(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download IT: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadReg(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await clientHook
          .DownloadPCompRegDocument(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download IT: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadPOB(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await clientHook
          .DownloadBankDocument(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download IT: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadRM(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await clientHook
          .DownloadPCompMandate(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download Reg: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadPOS(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await clientHook
          .DownloadPCompSHDocument(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download Reg: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadAID(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await clientHook
          .DownloadAuthId(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download IT: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadCustom(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await customFileHook
          .DownloadCustomFile(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download Reg: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }
}
