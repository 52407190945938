import { axiosInstance } from "@/plugins/axios";
/* import {} from "models"; */

const Amend = () => {
  const baseUrl = "/api/Amend";

  const getAmendedAccount = async (safexCode: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getAmendedAccount?safexCode=${safexCode}`
    );
    return Promise.resolve(res.data);
  };

  return {
    getAmendedAccount,
  };
};
export default Amend;
