
import dayjs from "dayjs";
import {
  RiskReporting_ABSA_Resp,
  RiskReporting_Balance_Resp,
  RiskReporting_Brokes_Resp,
  RiskReporting_HQ_Resp,
  RiskReporting_SubAccount,
  RiskReporting_SubAccount_Resp,
  SafexDivision,
} from "models";
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import Hooks from "@/hooks";
import User from "@/store/modules/user";

const { SafexDivisions, ExcelFiles } = Hooks;
const safexDivisionHook = SafexDivisions();
const excelFileHook = ExcelFiles();

@Component({
  filters: {
    numberWCommas: function (value: number) {
      if (value != null) {
        return value.toLocaleString("en-US", { minimumFractionDigits: 2 });
      } else {
        return value;
      }
    },
  },
})
export default class SubAccounts extends Vue {
  @PropSync("loading", { default: false }) loading!: boolean;
  items: RiskReporting_SubAccount[] = [];
  safexDivisions: SafexDivision[] = [];
  mainAccounts: {
    account: string;
    type: string;
    id: number;
  }[] = [];
  mainAccount: {
    account: string;
    type: string;
    id: number;
  } | null = null;
  date = this.getPreviousWorkDay;
  dateMenu = false;
  search = "";
  sortDesc = false;
  totalsModal = false;
  currAcctTotal: string | null = null;
  mtmTotal: string | null = null;
  exchangeFeesTotal: string | null = null;
  clearingFeesTotal: string | null = null;
  memberTotal: string | null = null;
  totalFeesTotal1: string | null = null;
  totalFeesTotal2: string | null = null;
  headers = [
    { text: "Principal", value: "subAccount" },
    { text: "Prev Margin", value: "prevMargin" },
    { text: "New Margin", value: "newMargin" },
    { text: "Margin Call", value: "marginCall" },
    { text: "MTM", value: "mtm" },
    { text: "Cash Call", value: "cashCall" },
    { text: "Exchange BF", value: "exchangeBF" },
    { text: "Clearing BF", value: "clearingBF" },
    { text: "Broker BF + Member", value: "brokerBFMemeber" },
    { text: "Total BF", value: "totalBF" },
    { text: "Current Account", value: "currAcct" },
    { text: "Notes", value: "comments", style: "width: 350px" },
    { text: "", value: "actions", sortable: false },
  ];

  checkMainP(subAcc: string): string {
    var clss = "";
    if (this.mainAccount != null) {
      if (subAcc == this.mainAccount.account) {
        clss = "font-weight-bold";
        return clss;
      } else {
        return clss;
      }
    } else {
      return clss;
    }
  }

  get getPreviousWorkDay(): string {
    var date = dayjs().day();
    if (date == 6) {
      /* 6 = saturday */
      return dayjs().subtract(1, "day").format("YYYY-MM-DD");
    } else if (date == 0) {
      /* 0 = sunday */
      return dayjs().subtract(2, "day").format("YYYY-MM-DD");
    } else if (date == 1) {
      /* 1 = monday */
      return dayjs().subtract(3, "day").format("YYYY-MM-DD");
    } else {
      return dayjs().subtract(1, "day").format("YYYY-MM-DD");
    }
  }

  getTotals(): void {
    const currAcct = this.items.reduce((accumulator, value) => {
      if (this.mainAccount != null) {
        if (
          value.currAcct != null &&
          value.subAccount != this.mainAccount.account
        ) {
          return accumulator + value.currAcct;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    }, 0);

    const mtm = this.items.reduce((accumulator, value) => {
      if (this.mainAccount != null) {
        if (value.mtm != null && value.subAccount != this.mainAccount.account) {
          return accumulator + value.mtm;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    }, 0);

    const exchange = this.items.reduce((accumulator, value) => {
      if (this.mainAccount != null) {
        if (
          value.exchangeBF != null &&
          value.subAccount != this.mainAccount.account
        ) {
          return accumulator + value.exchangeBF;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    }, 0);

    const clearing = this.items.reduce((accumulator, value) => {
      if (this.mainAccount != null) {
        if (
          value.clearingBF != null &&
          value.subAccount != this.mainAccount.account
        ) {
          return accumulator + value.clearingBF;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    }, 0);

    const member = this.items.reduce((accumulator, value) => {
      if (this.mainAccount != null) {
        if (
          value.brokerBFMemeber != null &&
          value.subAccount != this.mainAccount.account
        ) {
          return accumulator + value.brokerBFMemeber;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    }, 0);

    var sum1 = 0;
    /* var sum2 = 0; */
    if (
      this.items[0].exchangeBF != null &&
      this.items[0].clearingBF != null &&
      this.items[0].brokerBFMemeber != null
    ) {
      sum1 =
        this.items[0].exchangeBF +
        this.items[0].clearingBF +
        this.items[0].brokerBFMemeber;
    }
    const sum2 = this.items.reduce((accumulator, value) => {
      if (this.mainAccount != null) {
        if (
          value.totalBF != null &&
          value.subAccount != this.mainAccount.account
        ) {
          return accumulator + value.totalBF;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    }, 0);
    const total1 = sum1 - sum2;
    const total2 = exchange + clearing + member;

    this.currAcctTotal = currAcct.toFixed(2);
    this.mtmTotal = mtm.toFixed(2);
    this.exchangeFeesTotal = exchange.toFixed(2);
    this.clearingFeesTotal = clearing.toFixed(2);
    this.memberTotal = member.toFixed(2);
    this.totalFeesTotal1 = total1.toFixed(2);
    this.totalFeesTotal2 = total2.toFixed(2);

    this.totalsModal = true;
  }

  async divisionFilter(): Promise<void> {
    console.log("mainAccount: ", this.mainAccount);
    await this.init();
    var filtredString: string[] = [];
    var filteredDivision: SafexDivision[] = [];
    var filteredItems: RiskReporting_SubAccount[] = [];
    if (this.items.length > 0 && this.mainAccount != null) {
      filteredDivision = this.safexDivisions.filter(
        (elem) =>
          elem.safexSubAccount == this.mainAccount?.account ||
          elem.accountingSubAccount == this.mainAccount?.account ||
          elem.safexCode == this.mainAccount?.account ||
          elem.accountingCode == this.mainAccount?.account
      );

      console.log("filteredDivision", filteredDivision);

      filteredDivision.forEach((elem) => {
        if (elem.safexCode != null) {
          filtredString.push(elem.safexCode);
        } else if (elem.safexSubAccount != null) {
          filtredString.push(elem.safexSubAccount);
        } else if (elem.accountingCode != null) {
          filtredString.push(elem.accountingCode);
        } else if (elem.accountingSubAccount != null) {
          filtredString.push(elem.accountingSubAccount);
        }
      });

      console.log("filtredString", filtredString);

      filteredItems = this.items.filter((elem) => {
        if (elem.subAccount != null) {
          return filtredString.includes(elem.subAccount);
        }
      });

      this.items = filteredItems;

      var first = this.mainAccount.account;
      const foundIdx = this.items.findIndex((el) => el.subAccount == first);
      const foundItem = this.items[foundIdx];
      this.items.splice(foundIdx, 1);
      this.items.unshift(foundItem);
      console.log("newArr: ", this.items);
      if (this.items[0] == undefined) {
        this.items = [];
      }
    }
  }

  async getSafexDivisions(): Promise<void> {
    try {
      const res = await safexDivisionHook.getAllDivisions();
      console.log("SD", res);
      this.safexDivisions = res;
      this.safexDivisions.forEach((elem) => {
        if (elem.safexCode != null && elem.safexSubAccount == null) {
          this.mainAccounts.push({
            account: elem.safexCode,
            type: "Safex",
            id: elem._id,
          });
        } else if (
          elem.accountingCode != null &&
          elem.accountingSubAccount == null
        ) {
          this.mainAccounts.push({
            account: elem.accountingCode,
            type: "JSE",
            id: elem._id,
          });
        }
      });
      this.mainAccounts.sort(function (a, b) {
        if (a.account < b.account) {
          return -1;
        }
        if (a.account > b.account) {
          return 1;
        }
        return 0;
      });
      console.log("mainAccounts", this.mainAccounts);
    } catch (err) {
      this.loading = false;
      return Promise.reject(err);
    }
  }

  async mounted(): Promise<void> {
    await this.init();
    await this.getSafexDivisions();
  }

  async init(): Promise<void> {
    try {
      this.loading = true;
      const resp = await excelFileHook.viewSubByDateSheet(this.date);
      this.items = resp;
    } catch (err: any) {
      console.log(err.response);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async SaveSubAccountSheet(): Promise<void> {
    try {
      this.loading = true;
      const subAccount: RiskReporting_SubAccount[] = [];
      this.items.forEach((elem) => {
        subAccount.push({
          subAccount: elem.subAccount,
          prevMargin: elem.prevMargin,
          newMargin: elem.newMargin,
          marginCall: elem.marginCall,
          mtm: elem.mtm,
          cashCall: elem.cashCall,
          exchangeBF: elem.exchangeBF,
          clearingBF: elem.clearingBF,
          brokerBFMemeber: elem.brokerBFMemeber,
          totalBF: elem.totalBF,
          currAcct: elem.currAcct,
          comments: elem.comments,
          reportDate: elem.reportDate,
          createdBy: elem.createdBy,
          createdDate: elem.createdDate,
          updatedBy: elem.updatedBy,
          updateDate: dayjs().format("YYYY-MM-DD"),
        });
      });
      const resp = await excelFileHook.SaveOrUpdateSubAccount(subAccount);
      this.$notificationCreator.createSuccessNotification(
        "Sub Accounts Sheet Updated Successfully."
      );

      await this.init();
      if (this.mainAccount != null) {
        this.divisionFilter();
      }
      this.getTotals();
      this.totalsModal = false;
      await this.getSubAccountPDF(subAccount);
    } catch (err: any) {
      console.log(err.response);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async getSubAccountPDF(
    subAccount: RiskReporting_SubAccount[]
  ): Promise<void> {
    try {
      this.loading = true;
      const resp = await excelFileHook
        .getSubAccountPDF(
          subAccount,
          this.date,
          this.currAcctTotal,
          this.mtmTotal,
          this.exchangeFeesTotal,
          this.clearingFeesTotal,
          this.memberTotal,
          this.totalFeesTotal1,
          this.totalFeesTotal2
        )
        .then((response: any) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Sub-Accounts ${this.date}.pdf`);
          document.body.appendChild(link);
          link.click();
        });

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
