
import { Component, Vue, Watch } from "vue-property-decorator";
import {
  RiskReporting_ABSA_Resp,
  RiskReporting_Balance_Resp,
  RiskReporting_Brokes_Resp,
  RiskReporting_HQ_Resp,
  RiskReporting_SubAccount_Resp,
} from "models";
import Imports from "@/components/RiskReporting/Import/Tabs/Imports.vue";
import Balancing from "@/components/RiskReporting/Import/Tabs/Balancing.vue";
import Brokes from "@/components/RiskReporting/Import/Tabs/Brokes.vue";
import SubAccounts from "@/components/RiskReporting/Import/Tabs/SubAccounts.vue";
import absaDaily from "@/components/RiskReporting/Import/Tabs/ABSADaily.vue";
import absaRiskReport from "@/components/RiskReporting/Import/Tabs/ABSARiskReport.vue";
import hqRiskReport from "@/components/RiskReporting/Import/Tabs/HQRiskReport.vue";
import Matching from "@/components/RiskReporting/Import/Tabs/Matching.vue";

@Component({
  components: {
    Imports,
    Balancing,
    Brokes,
    SubAccounts,
    absaDaily,
    absaRiskReport,
    hqRiskReport,
    Matching,
  },
})
export default class RiskReports_Import extends Vue {
  loading = false;
  mini = true;
  showImport = true;
  showBalancing = false;
  showBroke = false;
  showSubAccounts = false;
  showABSADaily = false;
  showABSARiskReport = false;
  showHQRiskReport = false;
  showMatching = false;
  saveCheckModal = false;
  activeMenuItem = "Import Files";
  menuItem: string | null = null;
  importResult: {
    balanceSheet: RiskReporting_Balance_Resp[];
    brokesSheet: RiskReporting_Brokes_Resp[];
    absa: RiskReporting_ABSA_Resp[];
    absaDaily: RiskReporting_ABSA_Resp[];
    hq: RiskReporting_HQ_Resp[];
    subAccount: RiskReporting_SubAccount_Resp[];
  } | null = null;
  importResultMatching: RiskReporting_SubAccount_Resp[] | null = null;
  menuItems: { title: string; icon: string }[] = [
    { title: "Import Files", icon: "mdi-import" },
    { title: "Balancing Control Sheet", icon: "mdi-scale-balance" },
    { title: "Brokes Control Sheet", icon: "mdi-compare-horizontal" },
    { title: "Sub-Accounts", icon: "mdi-account-multiple" },
    { title: "ABSA Daily ACCT Summary", icon: "mdi-calendar-clock" },
    { title: "ABSA Risk Report", icon: "mdi-bank" },
    { title: "HQ Risk Report", icon: "mdi-quality-high" },
    { title: "Matching - After Balancing", icon: "mdi-table-large" },
  ];

  @Watch("importResult", { immediate: true })
  importResultChanged(): void {
    console.log("importResult: ", this.importResult);
  }

  @Watch("importResultMatching", { immediate: true })
  importResultMatchingChanged(): void {
    console.log("importResultMatching: ", this.importResultMatching);
  }

  openSaveCheck(title: string): void {
    console.log("Menu Item: ", title);
    if (
      this.showBalancing == true &&
      title != "Balancing Control Sheet" &&
      this.importResult != null
    ) {
      this.menuItem = title;
      this.saveCheckModal = true;
    } else if (
      this.showBroke == true &&
      title != "Brokes Control Sheet" &&
      this.importResult != null
    ) {
      this.menuItem = title;
      this.saveCheckModal = true;
    } else if (
      this.showSubAccounts == true &&
      title != "Sub-Accounts" &&
      this.importResult != null
    ) {
      this.menuItem = title;
      this.saveCheckModal = true;
    } else if (
      this.showMatching == true &&
      title != "Matching - After Balancing" &&
      this.importResultMatching != null
    ) {
      this.menuItem = title;
      this.saveCheckModal = true;
    } else {
      this.chooseMenuItem(title);
    }
  }

  chooseMenuItem(title: string): void {
    console.log("Menu Item: ", title);
    switch (title) {
      case "Import Files":
        this.activeMenuItem = "Import Files";
        this.showImport = true;
        this.showBalancing = false;
        this.showBroke = false;
        this.showSubAccounts = false;
        this.showABSADaily = false;
        this.showABSARiskReport = false;
        this.showHQRiskReport = false;
        this.showMatching = false;
        break;
      case "Balancing Control Sheet":
        this.activeMenuItem = "Balancing Control Sheet";
        this.showImport = false;
        this.showBalancing = true;
        this.showBroke = false;
        this.showSubAccounts = false;
        this.showABSADaily = false;
        this.showABSARiskReport = false;
        this.showHQRiskReport = false;
        this.showMatching = false;
        break;
      case "Brokes Control Sheet":
        this.activeMenuItem = "Brokes Control Sheet";
        this.showImport = false;
        this.showBalancing = false;
        this.showBroke = true;
        this.showSubAccounts = false;
        this.showABSADaily = false;
        this.showABSARiskReport = false;
        this.showHQRiskReport = false;
        this.showMatching = false;
        break;
      case "Sub-Accounts":
        this.activeMenuItem = "Sub-Accounts";
        this.showImport = false;
        this.showBalancing = false;
        this.showBroke = false;
        this.showSubAccounts = true;
        this.showABSADaily = false;
        this.showABSARiskReport = false;
        this.showHQRiskReport = false;
        this.showMatching = false;
        break;
      case "ABSA Daily ACCT Summary":
        this.activeMenuItem = "ABSA Daily ACCT Summary";
        this.showImport = false;
        this.showBalancing = false;
        this.showBroke = false;
        this.showSubAccounts = false;
        this.showABSADaily = true;
        this.showABSARiskReport = false;
        this.showHQRiskReport = false;
        this.showMatching = false;
        break;
      case "ABSA Risk Report":
        this.activeMenuItem = "ABSA Risk Report";
        this.showImport = false;
        this.showBalancing = false;
        this.showBroke = false;
        this.showSubAccounts = false;
        this.showABSADaily = false;
        this.showABSARiskReport = true;
        this.showHQRiskReport = false;
        this.showMatching = false;
        break;
      case "HQ Risk Report":
        this.activeMenuItem = "HQ Risk Report";
        this.showImport = false;
        this.showBalancing = false;
        this.showBroke = false;
        this.showSubAccounts = false;
        this.showABSADaily = false;
        this.showABSARiskReport = false;
        this.showHQRiskReport = true;
        this.showMatching = false;
        break;
      case "Matching - After Balancing":
        this.activeMenuItem = "Matching - After Balancing";
        this.showImport = false;
        this.showBalancing = false;
        this.showBroke = false;
        this.showSubAccounts = false;
        this.showABSADaily = false;
        this.showABSARiskReport = false;
        this.showHQRiskReport = false;
        this.showMatching = true;
        break;
    }
  }
}
