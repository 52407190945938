
import { Component, Vue } from "vue-property-decorator";
import Users from "@/components/Appbar/Admin/Tabs/Users/User.vue";
import Roles from "@/components/Appbar/Admin/Tabs/Roles.vue";

@Component({
  components: {
    Users,
    Roles,
  },
})
export default class AdminOverview extends Vue {
  tab = "users";
  mini = true;
  showUsers = true;
  showRoles = false;
  activeMenuItem = "Users";
  menuItem: string | null = null;
  menuItems: { title: string; icon: string }[] = [
    { title: "Users", icon: "mdi-account-multiple" },
    { title: "Roles", icon: "mdi-account-lock" },
  ];

  chooseMenuItem(title: string): void {
    console.log("Menu Item: ", title);
    switch (title) {
      case "Users":
        this.activeMenuItem = "Users";
        this.showUsers = true;
        this.showRoles = false;
        break;
      case "Roles":
        this.activeMenuItem = "Roles";
        this.showUsers = false;
        this.showRoles = true;
        break;
    }
  }
}
