
import { Component, PropSync, Vue } from "vue-property-decorator";
import {
  ExcelFile,
  RiskReporting_Balance_Resp,
  RiskReporting_Brokes_Resp,
  RiskReporting_ABSA_Resp,
  RiskReporting_HQ_Resp,
  RiskReporting_SubAccount_Resp,
} from "models";
import Hooks from "@/hooks";
import dayjs from "dayjs";

const { ExcelFiles } = Hooks;
const excelFileHook = ExcelFiles();

@Component({})
export default class Imports extends Vue {
  @PropSync("loading", { default: false }) load!: boolean;
  @PropSync("importResult") importRes: {
    balanceSheet: RiskReporting_Balance_Resp[];
    brokesSheet: RiskReporting_Brokes_Resp[];
    absa: RiskReporting_ABSA_Resp[];
    absaDaily: RiskReporting_ABSA_Resp[];
    hq: RiskReporting_HQ_Resp[];
    subAccount: RiskReporting_SubAccount_Resp[];
  } | null = null;
  @PropSync("importResultMatching") impResMatching:
    | RiskReporting_SubAccount_Resp[]
    | null = null;

  date = this.getPreviousWorkDay;
  dateMenu = false;
  dateMatching = this.getPreviousWorkDay;
  dateMatchingMenu = false;
  hqRisk: File | null = null;
  hqRisk2: File | null = null;
  absaDaily: File | null = null;
  absaRisk: File | null = null;
  optionsModal = false;
  options: {
    option: string;
    color: string;
    disabled: boolean;
  }[] = [
    {
      option: "Balancing Control Sheet",
      color: "primary",
      disabled: false,
    },
    {
      option: "Brokes Control Sheet",
      color: "secondary",
      disabled: false,
    },
    {
      option: "Sub-Accounts",
      color: "accent",
      disabled: false,
    },
    {
      option: "ABSA Daily ACCT Summary",
      color: "primary",
      disabled: false,
    },
    {
      option: "ABSA Risk Report",
      color: "secondary",
      disabled: false,
    },
    {
      option: "HQ Risk Report",
      color: "accent",
      disabled: false,
    },
    {
      option: "Matching - After Balancing",
      color: "primary",
      disabled: false,
    },
  ];
  rules = {
    fileSize: (value: { size: number }): boolean | string =>
      !value || value.size < 5000000 || "Max File Size 5MB!",
  };

  get getPreviousWorkDay(): string {
    var date = dayjs().day();
    if (date == 6) {
      /* 6 = saturday */
      return dayjs().subtract(1, "day").format("YYYY-MM-DD");
    } else if (date == 0) {
      /* 0 = sunday */
      return dayjs().subtract(2, "day").format("YYYY-MM-DD");
    } else if (date == 1) {
      /* 1 = monday */
      return dayjs().subtract(3, "day").format("YYYY-MM-DD");
    } else {
      return dayjs().subtract(1, "day").format("YYYY-MM-DD");
    }
  }

  openAccount(i: string): void {
    this.optionsModal = false;
    console.log("Modal opened: ", i);
    switch (i) {
      case "Balancing Control Sheet":
        this.$emit("balance");
        break;
      case "Brokes Control Sheet":
        this.$emit("brokes");
        break;
      case "Sub-Accounts":
        this.$emit("subAccounts");
        break;
      case "ABSA Daily ACCT Summary":
        this.$emit("absaDaily");
        break;
      case "ABSA Risk Report":
        this.$emit("absaRisk");
        break;
      case "HQ Risk Report":
        this.$emit("hqRisk");
        break;
      case "Matching - After Balancing":
        this.$emit("matching");
        break;
    }
  }

  async getBase64(file: Blob | File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  async uploadFile(): Promise<void> {
    try {
      this.load = true;
      if (
        this.hqRisk != null &&
        this.absaDaily != null &&
        this.absaRisk != null
      ) {
        const resultFile = await this.getBase64(this.hqRisk);
        const resultABSADaily = await this.getBase64(this.absaDaily);
        const resultABSARisk = await this.getBase64(this.absaRisk);

        const file: ExcelFile = {
          /* entityId: "", */
          fileName: this.hqRisk != null ? this.hqRisk.name : "",
          customFile: this.hqRisk != null ? resultFile : this.hqRisk,
          absaFileName: this.absaRisk != null ? this.absaRisk.name : "",
          absaCustomFile:
            this.absaRisk != null ? resultABSARisk : this.absaRisk,
          absaDailyName: this.absaDaily != null ? this.absaDaily.name : "",
          absaDailyFile:
            this.absaDaily != null ? resultABSADaily : this.absaDaily,
          reportDate: this.date,
        };

        const resp = await excelFileHook.UploadExcelDocuments(file);
        console.log("Import Resp: ", resp);
        this.importRes = resp;
        this.hqRisk = null;
        this.absaDaily = null;
        this.absaRisk = null;
        this.optionsModal = true;
      }
      return Promise.resolve();
    } catch (err: any) {
      console.log(err.response);
      return Promise.reject(err);
    } finally {
      this.load = false;
    }
  }

  async uploadMatching(): Promise<void> {
    try {
      this.load = true;
      if (this.hqRisk2 != null) {
        const resultFile = await this.getBase64(this.hqRisk2);

        const file: ExcelFile = {
          /* entityId: "", */
          fileName: this.hqRisk2 != null ? this.hqRisk2.name : "",
          customFile: this.hqRisk2 != null ? resultFile : this.hqRisk2,
          absaFileName: null,
          absaCustomFile: null,
          absaDailyName: null,
          absaDailyFile: null,
          reportDate: this.dateMatching,
        };

        const resp = await excelFileHook.UploadMatchDocuments(file);

        this.impResMatching= resp.subAccount;
        this.hqRisk2 = null;
        this.optionsModal = true;
      }
      return Promise.resolve();
    } catch (err: any) {
      console.log(err.response);
      return Promise.reject(err);
    } finally {
      this.load = false;
    }
  }

  /*  async download(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await excelFileHook
          .DownloadExcelFile(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download: ", err);

            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Imported"
      );
    }
  } */
}
