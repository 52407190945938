
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import dayjs from "dayjs";
import User from "@/store/modules/user";
import {
  AssignRole,
  UserAccessModel,
  UserAccessModel_CREATE,
  UserProfile,
  UserProfilewPass,
} from "models";
import Hooks from "@/hooks";

const { AssignRoles, UserAccess, UserProfiles } = Hooks;
const AssignRolesHook = AssignRoles();
const UserAccessHook = UserAccess();
const UserProfileHook = UserProfiles();

@Component({})
export default class Roles extends Vue {
  @PropSync("valid") formValid!: boolean;
  @PropSync("clear") formClear!: boolean;
  @PropSync("next") accountNext!: boolean;
  @Prop() type!: string;
  @Prop() modalData!: UserProfilewPass;
  loading = false;
  roles: UserAccessModel_CREATE = {
    _id: null,
    edit: false,
    add: false,
    delete: false,
    get: false,
    accessRatingCodes: false,
    accessMarkets: false,
    accessTraders: false,
    accessRoles: false,
    accessBanks: false,
    viewFields: false,
    viewComodities: false,
    viewGenericCodes: false,
    viewIndividuals: false,
    viewClosedCorp: false,
    viewPartnership: false,
    viewPrivateCompany: false,
    viewTrusts: false,
    viewPoolAccounts: false,
    viewTransfers: false,
    viewTransfersClient: false,
    viewTransfersBank: false,
    canEditProfileEmail: false,
    email: null,
    roleName: null,
    createdBy: null,
    createdDate: null,
  };
  roleList: AssignRole[] = [];
  customRole = false;
  customRoleName: string | null = null;
  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
    email: (value: string): boolean | string => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },
    id: (value: string): boolean | string =>
      value?.length == 13 || "Requires 13 digits",
    cell: (value: string | null = null): boolean | string =>
      value != null ? value.length >= 10 : "" || "Enter a valid Contact Number",
  };

  @Watch("type", { immediate: true })
  async modalTypeChanged(): Promise<void> {
    console.log("Modal Details Type: ", this.type);
    if (this.type) {
      await this.getRoles();
      if (
        this.modalData.roleName != null &&
        (this.modalData.roleName == "Admin" ||
          this.modalData.roleName == "Trader" ||
          this.modalData.roleName == "Client" ||
          this.modalData.roleName == "Admin - Low")
      ) {
        this.roles.roleName = this.modalData.roleName;
        this.customRole = false;
        this.customRoleName = null;
        await this.getUserAccessByRole();
      } else if (this.modalData.roleName == null) {
        this.roles.roleName = null;
        this.customRole = false;
        this.customRoleName = null;
      } else {
        this.customRoleName = this.modalData.roleName;
        this.customRole = true;
        await this.getUserAccessByRole();
      }
    }
  }

  @Watch("modalData", { immediate: false })
  async modalDataChanged(): Promise<void> {
    if (this.type) await this.modalTypeChanged();
  }

  async getUserAccessByRole(): Promise<void> {
    try {
      this.loading = true;
      if (this.roles.roleName != null) {
        const res = await UserAccessHook.getUserAccessByRoleName(
          this.roles.roleName
        );
        this.roles = res[0];

        console.log("getPredifined res: ", res);

        return Promise.resolve();
      } else {
        if (this.modalData.roleName) {
          const res = await UserAccessHook.getUserAccessByRoleName(
            this.modalData.roleName
          );
          if (res) this.roles = res[0];

          console.log("getPredifined res: ", res);

          return Promise.resolve();
        }
      }
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async getPredefined(): Promise<void> {
    try {
      this.loading = true;
      if (this.roles.roleName != null) {
        const res = await UserAccessHook.getPredifined(this.roles.roleName);
        this.roles.accessBanks = res.accessBanks;
        this.roles.accessMarkets = res.accessMarkets;
        this.roles.accessRatingCodes = res.accessRatingCodes;
        this.roles.accessRoles = res.accessRoles;
        this.roles.accessTraders = res.accessTraders;
        this.roles.add = res.add;
        this.roles.canEditProfileEmail = res.canEditProfileEmail;
        this.roles.delete = res.delete;
        this.roles.edit = res.edit;
        this.roles.get = res.get;
        this.roles.viewClosedCorp = res.viewClosedCorp;
        this.roles.viewComodities = res.viewComodities;
        this.roles.viewFields = res.viewFields;
        this.roles.viewGenericCodes = res.viewGenericCodes;
        this.roles.viewIndividuals = res.viewIndividuals;
        this.roles.viewPartnership = res.viewPartnership;
        this.roles.viewPoolAccounts = res.viewPoolAccounts;
        this.roles.viewPrivateCompany = res.viewPrivateCompany;
        this.roles.viewTransfers = res.viewTransfers;
        this.roles.viewTransfersBank = res.viewTransfersBank;
        this.roles.viewTransfersClient = res.viewTransfersClient;
        this.roles.viewTrusts = res.viewTrusts;
        console.log("getPredifined res: ", res);

        return Promise.resolve();
      }
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  get getUsername(): string | null {
    return User.getLoggedInUser;
  }

  async getRoles(): Promise<void> {
    try {
      this.loading = true;
      const res = await AssignRolesHook.getAllAssignRole();
      this.roleList = res;
      console.log("Get Roles: ", res);

      return Promise.resolve();
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  /* @Watch("formClear", {
    immediate: true,
  })
  clear(): void {
    const refForm: any = this.$refs.form;
    refForm.reset();
    this.formClear = false;
  } */

  @Watch("accountNext", {
    immediate: true,
  })
  async onNextChanged(): Promise<void> {
    console.log("AccountNext", this.accountNext);
    if (this.accountNext == true) {
      if (this.type == "edit") {
        await this.editClientAccountDetails();
      }
    }
  }

  async editClientAccountDetails(): Promise<void> {
    try {
      this.loading = true;
      if (this.modalData.roleName == null) {
        this.roles.email = this.modalData.email;
        this.roles._id = 0;
        this.roles.createdDate = dayjs().format("YYYY-MM-DD");
        if (this.customRole == true) {
          this.roles.roleName = this.customRoleName;
        }
        const res = await UserAccessHook.addNewUserAccess(
          this.roles as UserAccessModel
        );
        this.modalData.roleName = this.roles.roleName;
        if (this.modalData.email != null) {
          if (
            this.modalData.dateOfBirth &&
            this.modalData.dateOfBirth != "Invalid Date"
          ) {
            this.modalData.dateOfBirth = dayjs(
              this.modalData.dateOfBirth
            ).format("YYYY-MM-DD");
          } else {
            this.modalData.dateOfBirth = null;
          }
          console.log("dob 1", this.modalData.dateOfBirth);
          const resUserProfile = await UserProfileHook.updateUserProfile(
            this.modalData
          );
        }
        this.$notificationCreator.createSuccessNotification(
          "User Roles Updated."
        );
        return Promise.resolve();
      } else {
        this.roles.email = this.modalData.email;
        if (this.customRole == true) {
          this.roles.roleName = this.customRoleName;
        }
        const res = await UserAccessHook.updateUserAccess(
          this.roles as UserAccessModel
        );
        this.modalData.roleName = this.roles.roleName;
        if (this.modalData.email != null) {
          if (
            this.modalData.dateOfBirth &&
            this.modalData.dateOfBirth != "Invalid Date"
          ) {
            this.modalData.dateOfBirth = dayjs(
              this.modalData.dateOfBirth
            ).format("YYYY-MM-DD");
          } else {
            this.modalData.dateOfBirth = null;
          }
          console.log("dob 2", this.modalData.dateOfBirth);
          const resUserProfile = await UserProfileHook.updateUserProfile(
            this.modalData
          );
        }
        this.$notificationCreator.createSuccessNotification(
          "User Roles Updated."
        );
        return Promise.resolve();
      }
    } catch (err: any) {
      this.accountNext = false;
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
