import { axiosAuth, axiosInstance } from "@/plugins/axios";

const Account = () => {
  const baseUrl = "/api/Account";

  const ForgotPassword = async (email: string): Promise<void> => {
    const res = await axiosAuth.post(
      baseUrl + `/ForgotPassword?email=${email}`
    );
    return Promise.resolve(res.data);
  };

  const ConfirmResetPassword = async (
    email: string | null,
    password: string | null,
    confirmPassword: string | null,
    code: string | null
  ): Promise<void> => {
    const res = await axiosAuth.post(
      baseUrl +
        `/ConfirmResetPassword?email=${email}&password=${password}&confirmPassword=${confirmPassword}&code=${code}`
    );
    return Promise.resolve(res.data);
  };

  const login = async (email: string, password: string): Promise<void> => {
    const res = await axiosAuth.post(
      baseUrl + `/login?email=${email}&password=${password}`
    );
    return Promise.resolve(res.data);
  };

  return {
    login,
    ForgotPassword,
    ConfirmResetPassword,
  };
};
export default Account;
