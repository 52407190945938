import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";
import { notification } from "models";

@Module({
  dynamic: true,
  namespaced: true,
  name: "Notifications",
  store,
})
class Notifications extends VuexModule {
  count = 0;
  snackbars: notification[] = [];

  @Mutation
  addSnackbar(snackbar: notification) {
    this.snackbars = this.snackbars.filter((x) => x.showing === true);
    this.snackbars = this.snackbars.concat(snackbar);
  }
  @Mutation
  removeSnackbar(snackbar: notification) {
    this.snackbars.splice(
      this.snackbars.findIndex((x) => x.id === snackbar.id),
      1
    );
  }
  get getNotifications(): notification[] {
    return this.snackbars;
  }

  @Mutation
  increment(delta: number) {
    this.count += delta;
  }
  @Mutation
  decrement(delta: number) {
    this.count -= delta;
  }

  // action 'incr' commits mutation 'increment' when done with return value as payload
  @Action({ commit: "increment" })
  incr() {
    return 5;
  }
  // action 'decr' commits mutation 'decrement' when done with return value as payload
  @Action({ commit: "decrement" })
  decr() {
    return 5;
  }
}

export default getModule(Notifications);
