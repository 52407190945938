import {
  CountryCode,
  JSENewSafexModelcs,
  UpdateClient,
  VerifyClient,
} from "models";
import { axiosInstance } from "@/plugins/axios";

const JSEClient = () => {
  const baseUrl = "/api/JseClient";

  const addNewClient = async (data: JSENewSafexModelcs): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/addNewClient", data);
    return Promise.resolve(res.data);
  };

  const verifyOrRestrictClient = async (data: VerifyClient): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/verifyOrRestrictClient",
      data
    );
    return Promise.resolve(res.data);
  };

  const updateClientDetails = async (data: UpdateClient): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/updateClientDetails",
      data
    );
    return Promise.resolve(res.data);
  };

  const getCountryCodes = async (): Promise<CountryCode[]> => {
    const res = await axiosInstance.get(baseUrl + `/getCountryCodes`);
    return Promise.resolve(res.data);
  };

  const getClientDetailsForUpdate = async (
    clientCode: string
  ): Promise<UpdateClient> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClientDetailsForUpdate?client=${clientCode}` /* ,
      { data: clientCode } */
    );
    return Promise.resolve(res.data);
  };

  return {
    addNewClient,
    verifyOrRestrictClient,
    updateClientDetails,
    getCountryCodes,
    getClientDetailsForUpdate,
  };
};
export default JSEClient;
