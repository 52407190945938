import { axiosInstance } from "@/plugins/axios";
import { HQAccess as HQAccessM, HQAccess_CREATE } from "models";

const HQAccess = () => {
  const baseUrl = "/api/HQAccess";

  const getAllHQAccess = async (): Promise<HQAccessM[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAllHQAccess");
    return Promise.resolve(res.data);
  };

  const getAllHQAccessByUser = async (userId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getAllHQAccessByUser?id=${userId}`
    );
    return Promise.resolve(res.data);
  };

  const getHQAccessById = async (id: number): Promise<HQAccessM[]> => {
    const res = await axiosInstance.get(baseUrl + `/getHQAccessById?id=${id}`);
    return Promise.resolve(res.data);
  };

  const addNewHQAccess = async (data: HQAccess_CREATE): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/addNewHQAccess", {
      uniqueCode: data.uniqueCode,
      userId: data.userId,
      setup: data.setup,
      branch: data.branch,
      dealer: data.dealer,
      client: data.client,
      tradingSystem: data.tradingSystem,
      defaultCode: data.defaultCode,
      username: data.username,
      masterdealer: data.masterdealer,
      admin: data.admin,
      onScreenLimit: data.onScreenLimit,
      optionLimit: data.optionLimit,
      reportOnlyLimit: data.reportOnlyLimit,
      viewOnly: data.viewOnly,
      safexCodes: data.safexCodes,
      filteredClients: data.filteredClients,
      algos: data.algos,
      uniqueKey: data.uniqueKey,
      numberofContracts: data.numberofContracts,
      limitChecking: data.limitChecking,
    });
    return Promise.resolve(res.data);
  };

  const updateHQAccess = async (data: HQAccessM): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/updateHQAccess", {
      _id: data._id,
      userId: data.userId,
      uniqueCode: data.uniqueCode,
      setup: data.setup,
      branch: data.branch,
      dealer: data.dealer,
      client: data.client,
      tradingSystem: data.tradingSystem,
      defaultCode: data.defaultCode,
      username: data.username,
      masterdealer: data.masterdealer,
      admin: data.admin,
      onScreenLimit: data.onScreenLimit,
      optionLimit: data.optionLimit,
      reportOnlyLimit: data.reportOnlyLimit,
      createdBy: data.createdBy,
      createdDate: data.createdDate,
      updatedBy: data.updatedBy,
      updatedDate: data.updatedDate,
      viewOnly: data.viewOnly,
      safexCodes: data.safexCodes,
      filteredClients: data.filteredClients,
      algos: data.algos,
      uniqueKey: data.uniqueKey,
      numberofContracts: data.numberofContracts,
      limitChecking: data.limitChecking,
    });
    return Promise.resolve(res.data);
  };

  const deleteHQAccess = async (data: HQAccessM): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/deleteUserAccess", {
      _id: data._id,
      userId: data.userId,
      uniqueCode: data.uniqueCode,
      setup: data.setup,
      branch: data.branch,
      dealer: data.dealer,
      client: data.client,
      tradingSystem: data.tradingSystem,
      defaultCode: data.defaultCode,
      username: data.username,
      masterdealer: data.masterdealer,
      admin: data.admin,
      onScreenLimit: data.onScreenLimit,
      optionLimit: data.optionLimit,
      reportOnlyLimit: data.reportOnlyLimit,
      createdBy: data.createdBy,
      createdDate: data.createdDate,
      updatedBy: data.updatedBy,
      updatedDate: data.updatedDate,
      viewOnly: data.viewOnly,
      safexCodes: data.safexCodes,
      filteredClients: data.filteredClients,
      algos: data.algos,
      uniqueKey: data.uniqueKey,
      numberofContracts: data.numberofContracts,
      limitChecking: data.limitChecking,
    });
    return Promise.resolve(res.data);
  };

  const getHQAutoPopulate = async (
    branch: string | null,
    dealer: string | null,
    isRetail: boolean
  ): Promise<{
    branchCode: string | null;
    dealerCodes: {
      code: string | null;
      clients: string[] | null;
      safex: string[] | null;
    }[];
  }> => {
    const res = await axiosInstance.get(
      baseUrl +
        `/getHQAutoPopulate?incomingBranch=${branch}&incomingDealer=${dealer}&isRetail=${isRetail}`
    );
    return Promise.resolve(res.data);
  };

  const getRetailClientDetails = async (code: string | null): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getRetailClientDetails?code=${code}`
    );
    return Promise.resolve(res.data);
  };

  return {
    getAllHQAccess,
    getAllHQAccessByUser,
    getHQAccessById,
    addNewHQAccess,
    updateHQAccess,
    deleteHQAccess,
    getHQAutoPopulate,
    getRetailClientDetails,
  };
};
export default HQAccess;
