
import { Component, Vue } from "vue-property-decorator";
import Hooks from "@/hooks";

const { Account } = Hooks;
const accountHook = Account();
@Component
export default class ResetPassword extends Vue {
  errorMsg = "Error Resetting Password: Please try again...";
  modalForgotPasswordSucces = false;
  alert = false;
  error = false;
  valid = false;
  loading = false;
  email = null;
  pass = null;
  visible = {
    pass: false,
    confirm: false,
  };
  matchingPass = null;
  matchingPassRules = [
    (v: string): boolean | string =>
      (v && v.length >= 1) || "Matching password required",
  ];

  get passwordConfirmationRule(): (v: string) => string | boolean {
    return () =>
      (this.pass === this.matchingPass && this.pass != null) ||
      `Password must match ${this.pass}`;
  }
  rules = {
    required: (v: string): boolean | string =>
      (v && v.length >= 5) || "Password requires min length of 5",
    lowercase: (v: string): boolean | string =>
      /(?=.*[a-z])/.test(v) || "Requires atleast 1 lowercase letter",
    uppercase: (v: string): boolean | string =>
      /(?=.*[A-Z])/.test(v) || "Requires atleast 1 uppercase letter",
    number: (v: string): boolean | string =>
      /(?=.*\d)/.test(v) || "Requires atleast 1 number",
    specialChar: (v: string): boolean | string =>
      /([!@$%])/.test(v) || "Requires atleast 1 special character",
  };

  get confirmPassRules(): ((v: string) => string | boolean)[] {
    const temp = [
      this.rules.required,
      this.rules.lowercase,
      this.rules.uppercase,
      this.rules.number,
      this.rules.specialChar,
      this.passwordConfirmationRule,
    ];
    return temp;
  }
  passRules = [
    this.rules.required,
    this.rules.lowercase,
    this.rules.uppercase,
    this.rules.number,
    this.rules.specialChar,
  ];

  beforeMount(): void {
    console.log("token", this.$route.query.token);
    if (this.$route.query.token === undefined) {
      this.error = true;
    }
  }
  async sendResetPass(): Promise<void> {
    try {
      this.loading = true;
      const resp = await accountHook.ConfirmResetPassword(
        this.email,
        this.pass,
        this.matchingPass,
        this.$route.query.token as string
      );
      this.modalForgotPasswordSucces = true;
    } catch (err) {
      ////console.log("Pushing error: ", err);
      this.alert = true;
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  goToSignIn(): void {
    this.$router.push({ name: "Auth" });
  }
}
