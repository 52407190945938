
import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { Market_CREATE, Market } from "models";
import Hooks from "@/hooks";

const { Markets } = Hooks;
const marketHook = Markets();

@Component({})
export default class AddEditMarkets extends Vue {
  @PropSync("open") modal!: boolean;
  @Prop() readonly data!: {
    type: string;
    fields: any;
  };
  valid = false;
  rules = {
    required: (value: string): boolean | string =>
      !!value || "This field is Required.",
  };

  @Watch("data.fields", { immediate: true })
  modalDataChanged(): void {
    console.log("This modal fields:", this.data.fields);
  }

  closeModal(): void {
    this.modal = false;
  }

  clearFields(): void {
    const refForm: any = this.$refs.form;
    refForm.reset();
  }

  async addItem(): Promise<void> {
    console.log("Add info: ", this.data.fields);

    try {
      var market: Market_CREATE;
      market = {
        marketName: this.data.fields.marketName,
        marketCode: this.data.fields.marketCode,
      };
      const res = await marketHook.addNewMarket(market);
      this.$notificationCreator.createSuccessNotification("Market Added.");

      return Promise.resolve();
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.$emit("refresh");
      this.closeModal();
    }
  }

  async editItem(): Promise<void> {
    console.log("Edit info: ", this.data.fields);

    try {
      var market: Market;
      market = {
        marketName: this.data.fields.marketName,
        marketCode: this.data.fields.marketCode,
        createdBy: this.data.fields.createdBy,
        createdDate: this.data.fields.createdDate,
        id: this.data.fields.id,
        updatedBy: this.data.fields.updatedBy,
        updatedDate: this.data.fields.updatedDate,
        _id: {
          creationTime: this.data.fields._id.creationTime,
          increment: this.data.fields._id.increment,
          machine: this.data.fields._id.machine,
          pid: this.data.fields._id.pid,
          timestamp: this.data.fields._id.timestamp,
        },
      };
      const res = await marketHook.updateMarket(market);

      this.$notificationCreator.createSuccessNotification("Market Updated.");
      return Promise.resolve();
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.$emit("refresh");
      this.closeModal();
    }
  }
}
