
import { Vue, Component, Prop, PropSync } from "vue-property-decorator";
import { StreetAddress } from "models";
@Component
export default class StreetAddressComponent extends Vue {
  @PropSync('streetAddress', { type: String, required: false }) streetAddressSync!: string;
  @PropSync('streetAddress2', { type: String, required: false }) streetAddress2Sync!: string;
  @PropSync('suburb', { type: String, required: false }) suburbSync!: string;
  @PropSync('city', { type: String, required: false }) citySync!: string;
  @PropSync('code', { type: String, required: false }) codeSync!: string;
  @PropSync('province', { type: String, required: false }) provinceSync!: string;
  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
  };
}
