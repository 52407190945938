import { axiosInstance } from "@/plugins/axios";
import { UserAccessModel } from "models";

const UserAccess = () => {
  const baseUrl = "/api/UserAccess";

  const getAllUserAccess = async (): Promise<UserAccessModel[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAllUserAccess");
    return Promise.resolve(res.data);
  };

  const getPredifined = async (name: string): Promise<UserAccessModel> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPredifined?name=${name}`
    );
    return Promise.resolve(res.data);
  };

  const getUserAccessByRoleName = async (
    role: string
  ): Promise<UserAccessModel[]> => {
    const res = await axiosInstance.get(
      baseUrl + `/getUserAccessByRoleName?role=${role}`
    );
    return Promise.resolve(res.data);
  };

  const getUserAccessByEmail = async (
    email: string
  ): Promise<UserAccessModel[]> => {
    const res = await axiosInstance.get(
      baseUrl + `/getUserAccessByEmail?email=${email}`
    );
    return Promise.resolve(res.data);
  };

  const addNewUserAccess = async (
    userAccess: UserAccessModel
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/addNewUserAccess", {
      _id: userAccess._id,
      edit: userAccess.edit,
      add: userAccess.add,
      delete: userAccess.delete,
      get: userAccess.get,
      accessRatingCodes: userAccess.accessRatingCodes,
      accessMarkets: userAccess.accessMarkets,
      accessTraders: userAccess.accessTraders,
      accessRoles: userAccess.accessRoles,
      accessBanks: userAccess.accessBanks,
      viewFields: userAccess.viewFields,
      viewComodities: userAccess.viewComodities,
      viewGenericCodes: userAccess.viewGenericCodes,
      viewIndividuals: userAccess.viewIndividuals,
      viewClosedCorp: userAccess.viewClosedCorp,
      viewPartnership: userAccess.viewPartnership,
      viewPrivateCompany: userAccess.viewPrivateCompany,
      viewTrusts: userAccess.viewTrusts,
      viewPoolAccounts: userAccess.viewPoolAccounts,
      viewTransfers: userAccess.viewTransfers,
      viewTransfersClient: userAccess.viewTransfersClient,
      viewTransfersBank: userAccess.viewTransfersBank,
      canEditProfileEmail: userAccess.canEditProfileEmail,
      email: userAccess.email,
      roleName: userAccess.roleName,
      createdBy: userAccess.createdBy,
      createdDate: userAccess.createdDate,
    });
    return Promise.resolve(res.data);
  };

  const updateUserAccess = async (
    userAccess: UserAccessModel
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/updateUserAccess", {
      _id: userAccess._id,
      edit: userAccess.edit,
      add: userAccess.add,
      delete: userAccess.delete,
      get: userAccess.get,
      accessRatingCodes: userAccess.accessRatingCodes,
      accessMarkets: userAccess.accessMarkets,
      accessTraders: userAccess.accessTraders,
      accessRoles: userAccess.accessRoles,
      accessBanks: userAccess.accessBanks,
      viewFields: userAccess.viewFields,
      viewComodities: userAccess.viewComodities,
      viewGenericCodes: userAccess.viewGenericCodes,
      viewIndividuals: userAccess.viewIndividuals,
      viewClosedCorp: userAccess.viewClosedCorp,
      viewPartnership: userAccess.viewPartnership,
      viewPrivateCompany: userAccess.viewPrivateCompany,
      viewTrusts: userAccess.viewTrusts,
      viewPoolAccounts: userAccess.viewPoolAccounts,
      viewTransfers: userAccess.viewTransfers,
      viewTransfersClient: userAccess.viewTransfersClient,
      viewTransfersBank: userAccess.viewTransfersBank,
      canEditProfileEmail: userAccess.canEditProfileEmail,
      email: userAccess.email,
      roleName: userAccess.roleName,
      createdBy: userAccess.createdBy,
      createdDate: userAccess.createdDate,
      updatedBy: userAccess.updatedBy,
      updatedDate: userAccess.updatedDate,
    });
    return Promise.resolve(res.data);
  };

  const deleteUserAccess = async (
    userAccess: UserAccessModel
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/deleteUserAccess", {
      _id: userAccess._id,
      edit: userAccess.edit,
      add: userAccess.add,
      delete: userAccess.delete,
      get: userAccess.get,
      accessRatingCodes: userAccess.accessRatingCodes,
      accessMarkets: userAccess.accessMarkets,
      accessTraders: userAccess.accessTraders,
      accessRoles: userAccess.accessRoles,
      accessBanks: userAccess.accessBanks,
      viewFields: userAccess.viewFields,
      viewComodities: userAccess.viewComodities,
      viewGenericCodes: userAccess.viewGenericCodes,
      viewIndividuals: userAccess.viewIndividuals,
      viewClosedCorp: userAccess.viewClosedCorp,
      viewPartnership: userAccess.viewPartnership,
      viewPrivateCompany: userAccess.viewPrivateCompany,
      viewTrusts: userAccess.viewTrusts,
      viewPoolAccounts: userAccess.viewPoolAccounts,
      viewTransfers: userAccess.viewTransfers,
      viewTransfersClient: userAccess.viewTransfersClient,
      viewTransfersBank: userAccess.viewTransfersBank,
      canEditProfileEmail: userAccess.canEditProfileEmail,
      email: userAccess.email,
      roleName: userAccess.roleName,
      createdBy: userAccess.createdBy,
      createdDate: userAccess.createdDate,
      updatedBy: userAccess.updatedBy,
      updatedDate: userAccess.updatedDate,
    });
    return Promise.resolve(res.data);
  };

  return {
    addNewUserAccess,
    updateUserAccess,
    deleteUserAccess,
    getAllUserAccess,
    getPredifined,
    getUserAccessByRoleName,
    getUserAccessByEmail,
  };
};
export default UserAccess;
