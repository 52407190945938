
import { loginResponse } from "models";
import { Component, Vue, Watch } from "vue-property-decorator";
import User from "@/store/modules/user";
import Recaptcha2 from "@/components/Recaptcha2.vue";
import Hooks from "@/hooks";

const { Account } = Hooks;
const accountHook = Account();

@Component({
  components: {
    Recaptcha2,
  },
})
export default class Login extends Vue {
  register = false;
  valid = false;
  showPassword = false;
  rememberMeSwitch = true;
  showForgetPassword = false;
  resetSent = false;
  username: string | null = null;
  password: string | null = null;
  captcha = false;
  loading = false;
  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
    email: (value: string): boolean | string => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },
  };
  async login(): Promise<void> {
    try {
      this.loading = true;
      const response = await this.$axiosAuth
        .post(
          `/api/Account/Login?email=${this.username}&password=${this.password}`
        )
        .catch((err) => {
          console.log("Login error rejected");
          return Promise.reject(err);
        });
      if (response != undefined) {
        console.log("Login Response: ", response.data, typeof response.data);
      }
      const data = response.data as loginResponse;
      const notifText = "Welcome " + this.username + " !";
      this.$notificationCreator.createSuccessNotification(notifText);
      User.setLoggedIn({
        accessToken: data.token,
        expiration: data.expiration,
        username: this.username != null ? this.username : "",
      });

      this.$router.push({
        name: "dash.landing",
      });
      console.log("Login Return");
      return Promise.resolve();
    } catch (err: any) {
      console.log("Login catch");
      if (err.response) {
        console.log("Check ", err.response);
        if (err.response.data) {
          console.log("Data ", err.response.data);
          this.$notificationCreator.createErrorNotification(
            `${
              err.response.data.errors == undefined
                ? err.response.data.message
                : err.response.data.errors.email[0]
            }`
          );
          return Promise.reject(err);
        }
      }
      this.$notificationCreator.createErrorNotification(err);
      return Promise.resolve();
    } finally {
      this.loading = false;
    }
  }

  async forgetPassword(): Promise<void> {
    try {
      this.loading = true;
      const response = await accountHook.ForgotPassword(
        this.username != null ? this.username : ""
      );
      this.resetSent = true;
      return Promise.resolve();
    } catch (err: any) {
      this.$notificationCreator.createErrorNotification(
        `Unable to send Reset Password Link: ` + err
      );

      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  get getCaptcha(): boolean {
    return this.captcha;
  }
  get allowCaptcha(): boolean {
    return process.env.VUE_APP_ALLOW_CAPTCHA;
  }

  @Watch("register", {
    immediate: true,
  })
  onRegisteredChange(): void {
    this.$emit("register", this.register);
  }
}
