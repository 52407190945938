
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { ClientAccount } from "models";
import Hooks from "@/hooks";

const { PartnershipCommunication, PartnershipAccount } = Hooks;
const partnershipHook = PartnershipCommunication();
const partnershipAccountHook = PartnershipAccount();

@Component({})
export default class Communications extends Vue {
  @PropSync("valid") formValid!: boolean;
  @PropSync("nextTab") tab!: boolean;
  @PropSync("next") accountNext!: boolean;
  @Prop() type!: string;
  loading = false;
  dataCheck = false;
  contacts: {
    id: number;
    contactName: string | null;
    emailAddress: string | null;
    mobileNumber: string | null;
    entityId: string | null;
  }[] = [];
  accounts: any;
  editedIndex = -1;
  editedItem = {
    id: 0,
    contactName: "",
    emailAddress: "",
    entityId: "",
  };
  defaultItem = {
    id: 0,
    contactName: "",
    emailAddress: "",
    mobileNumber: "",
    entityId: "",
  };
  headers = [
    {
      text: "Name",
      value: "contactName",
      sortable: false,
    },
    {
      text: "Email Address",
      value: "emailAddress",
      sortable: false,
    },
    {
      text: "Mobile Number",
      value: "mobileNumber",
      sortable: false,
    },
    {
      text: "Actions",
      value: "actions",
      sortable: false,
      width: "100px",
    },
  ];
  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
    email: (value: string): boolean | string => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },
    cell: (value: string | null = null): boolean | string =>
      value != null ? value.length >= 10 : "" || "Enter a valid Contact Number",
  };

  editItem(item: any): void {
    this.editedIndex = this.contacts.indexOf(item);
    this.editedItem = Object.assign({}, item);
  }

  deleteItem(item: any): void {
    const index = this.contacts.indexOf(item);

    this.contacts.splice(index, 1);
  }

  close(): void {
    setTimeout(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    }, 100);
  }

  addNew(): void {
    const addObj = Object.assign({}, this.defaultItem);
    addObj.id = this.contacts.length + 1;
    this.contacts.unshift(addObj);
    this.editItem(addObj);
  }

  save(): void {
    if (this.editedIndex > -1) {
      Object.assign(this.contacts[this.editedIndex], this.editedItem);
    }
  }

  @Watch("type", { immediate: true })
  async modalTypeChanged(): Promise<void> {
    console.log("Modal Details Type: ", this.type);
    if (this.type == "edit") {
      await this.getStoredData();
    }
    if (this.type == "add") {
      await this.getAccount();
    }
  }

  @Watch("accountNext", {
    immediate: true,
  })
  async onNextChanged(): Promise<void> {
    console.log("AccountNext", this.accountNext);
    if (this.accountNext == true) {
      if (this.type == "add") {
        await this.addNewClientDetails();
      }
      if (this.type == "edit") {
        if (this.dataCheck == true) {
          await this.editClientDetails();
        } else {
          await this.addNewClientDetails();
        }
      }
    }
  }

  async getAccount(): Promise<void> {
    var entityId: string | null = null;
    const key = localStorage.getItem("ClientFile/entityId");
    if (key != null) {
      entityId = key;
    }
    try {
      if (entityId != null) {
        const res = await partnershipAccountHook.getPartnershipAccountByCode(
          entityId
        );

        this.contacts = [];
        this.contacts.push({
          id: 1,
          contactName: res.accountName,
          emailAddress: res.contactPersonEmail,
          mobileNumber: res.contactNumber,
          entityId: res.entityId,
        });
        console.log(this.contacts);

        return Promise.resolve();
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  }

  async getStoredData(): Promise<void> {
    try {
      this.loading = true;
      var entityId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        entityId = key;
      }

      if (entityId != null) {
        const res = await partnershipHook.getPartnershipCommunicationByCode(
          entityId
        );

        console.log("Get Stored Data res: ", res);
        if (res != undefined && (res as any) != "") {
          this.dataCheck = true;
          this.accounts = res as any;
          this.contacts = [];
          this.contacts.push({
            id: 1,
            contactName: res.contactName,
            emailAddress: res.contactEmailAddress,
            mobileNumber: res.contactMobileNumber,
            entityId: res.entityId,
          });
          console.log(this.contacts);
        } else {
          this.dataCheck = false;
        }
        this.formValid = true;
        return Promise.resolve();
      }
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async editClientDetails(): Promise<void> {
    try {
      this.loading = true;
      var clientId: string;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        clientId = key;
      }

      if (this.contacts.length > 0) {
        this.contacts.forEach(async (elem) => {
          var clientCommunication = {
            contactName: elem.contactName,
            contactEmailAddress: elem.emailAddress,
            contactMobileNumber: elem.mobileNumber,
            entityId: clientId,
            createdBy: this.accounts.createdBy,
            createdDate: this.accounts.createdDate,
            updatedBy: this.accounts.updatedBy,
            updatedDate: this.accounts.updatedDate,
            _id: this.accounts._id,
          };
          const res = await partnershipHook.updatePartnershipCommunications(
            clientCommunication as any
          );
          this.$notificationCreator.createSuccessNotification(
            "Communication Saved"
          );
          Promise.resolve();
        });
      }
      // this.tab = true;
    } catch (err: any) {
      //this.accountNext = false;
      // this.tab = false;
      return Promise.reject(err);
    } finally {
      this.accountNext = false;
      this.loading = false;
    }
  }

  async addNewClientDetails(): Promise<void> {
    try {
      this.loading = true;
      var clientId: string;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        clientId = key;
      }

      if (this.contacts.length > 0) {
        this.contacts.forEach(async (elem) => {
          const res = await partnershipHook.addNewPartnershipCommunications({
            contactName: elem.contactName,
            contactEmailAddress: elem.emailAddress,
            contactMobileNumber: elem.mobileNumber,
            entityId: clientId,
          });
          this.$notificationCreator.createSuccessNotification(
            "Communication Saved"
          );
          Promise.resolve();
        });
      }
      this.tab = true;
    } catch (err: any) {
      this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
