import { axiosInstance } from "@/plugins/axios";
import { AssignRole } from "models";

const AssignRoles = () => {
  const baseUrl = "/api/AssignRole";

  const getAllAssignRole = async (): Promise<AssignRole[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAllAssignRole");
    return Promise.resolve(res.data);
  };

  const getAssignRole = async (id: string): Promise<AssignRole> => {
    const res = await axiosInstance.get(baseUrl + `/getAssignRole?id=${id}`);
    return Promise.resolve(res.data);
  };

  const getAssignRoleById = async (id: string): Promise<AssignRole> => {
    const res = await axiosInstance.get(
      baseUrl + `/getAssignRoleById?id=${id}`
    );
    return Promise.resolve(res.data);
  };

  const addAssignRole = async (role: AssignRole): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/addNewUserAccess", {
      _id: role._id,
      roleName: role.roleName,
      createdBy: role.createdBy,
      createdDate: role.createdDate,
    });
    return Promise.resolve(res.data);
  };

  const updateAssignRoles = async (role: AssignRole): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/updateAssignRoles", {
      _id: role._id,
      roleName: role.roleName,
      createdBy: role.createdBy,
      createdDate: role.createdDate,
      updatedBy: role.updatedBy,
      updatedDate: role.updatedDate,
    });
    return Promise.resolve(res.data);
  };

  const deleteAssignRoles = async (role: AssignRole): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/deleteUserAccess", {
      _id: role._id,
      roleName: role.roleName,
      createdBy: role.createdBy,
      createdDate: role.createdDate,
      updatedBy: role.updatedBy,
      updatedDate: role.updatedDate,
    });
    return Promise.resolve(res.data);
  };

  const batchAssignRoles = async (): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/batchAssignRoles");
    return Promise.resolve(res.data);
  };

  return {
    addAssignRole,
    updateAssignRoles,
    deleteAssignRoles,
    getAllAssignRole,
    getAssignRole,
    getAssignRoleById,
    batchAssignRoles,
  };
};
export default AssignRoles;
