import { axiosInstance } from "@/plugins/axios";
import { ClientDivisionModel, ClientDivision_CREATE } from "models";

const PrivateCompanyDivisions = () => {
  const baseUrl = "/api/PrivateCompany";

  const getAllPrivateCompDivision = async (): Promise<
    ClientDivisionModel[]
  > => {
    const res = await axiosInstance.get(baseUrl + "/getAllPrivateCompDivision");
    return Promise.resolve(res.data);
  };

  const getAllPrivateCompDivisionByCode = async (
    code: string
  ): Promise<ClientDivisionModel[]> => {
    const res = await axiosInstance.get(
      baseUrl + `/getAllPrivateCompDivisionByCode?code=${code}`
    );
    return Promise.resolve(res.data);
  };

  const getPrivateCompDivisionByCode = async (
    code: string
  ): Promise<ClientDivisionModel> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPrivateCompDivisionByCode?code=${code}`
    );
    return Promise.resolve(res.data);
  };

  const addNewPrivateCompDivisons = async (
    clientDivision: ClientDivision_CREATE
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/addNewPrivateCompDivision",
      {
        safexCode: clientDivision.safexCode,
        accountingCode: clientDivision.accountingCode,
        physicalCode: clientDivision.physicalCode,
        safexSubAccount: clientDivision.safexSubAccount,
        accountingSubAccount: clientDivision.accountingSubAccount,
        safexMarket: clientDivision.safexMarket,
        jseMarket: clientDivision.jseMarket,
        physicalsMarket: clientDivision.physicalsMarket,
        safexTrader: clientDivision.safexTrader,
        physicalsTrader: clientDivision.physicalsTrader,
        jseTrader: clientDivision.jseTrader,
        safexTraderId: clientDivision.safexTraderId,
        physicalsTraderId: clientDivision.physicalsTraderId,
        jseTraderId: clientDivision.jseTraderId,
        entityId: clientDivision.entityId,
        discretionaryManaged: clientDivision.discretionaryManaged,
        electronicAccNumber: clientDivision.electronicAccNumber,
        isBeneficial: clientDivision.isBeneficial,
      }
    );
    return Promise.resolve(res.data);
  };

  const updatePrivateCompDivisions = async (
    clientDivision: ClientDivisionModel
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/updatePrivateCompDivision",
      {
        safexCode: clientDivision.safexCode,
        accountingCode: clientDivision.accountingCode,
        physicalCode: clientDivision.physicalCode,
        safexSubAccount: clientDivision.safexSubAccount,
        accountingSubAccount: clientDivision.accountingSubAccount,
        safexMarket: clientDivision.safexMarket,
        jseMarket: clientDivision.jseMarket,
        physicalsMarket: clientDivision.physicalsMarket,
        safexTrader: clientDivision.safexTrader,
        physicalsTrader: clientDivision.physicalsTrader,
        jseTrader: clientDivision.jseTrader,
        safexTraderId: clientDivision.safexTraderId,
        physicalsTraderId: clientDivision.physicalsTraderId,
        jseTraderId: clientDivision.jseTraderId,
        entityId: clientDivision.entityId,
        id: clientDivision.id,
        createdBy: clientDivision.createdBy,
        createdDate: clientDivision.createdDate,
        updatedBy: clientDivision.updatedBy,
        updatedDate: clientDivision.updateDate,
        _id: clientDivision._id,
        discretionaryManaged: clientDivision.discretionaryManaged,
        electronicAccNumber: clientDivision.electronicAccNumber,
        isBeneficial: clientDivision.isBeneficial,
      }
    );
    return Promise.resolve(res.data);
  };

  const deletePrivateCompDivisions = async (
    clientDivision: ClientDivisionModel
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/deletePrivateCompDivision",
      {
        safexCode: clientDivision.safexCode,
        accountingCode: clientDivision.accountingCode,
        physicalCode: clientDivision.physicalCode,
        safexSubAccount: clientDivision.safexSubAccount,
        accountingSubAccount: clientDivision.accountingSubAccount,
        safexMarket: clientDivision.safexMarket,
        jseMarket: clientDivision.jseMarket,
        physicalsMarket: clientDivision.physicalsMarket,
        safexTrader: clientDivision.safexTrader,
        physicalsTrader: clientDivision.physicalsTrader,
        jseTrader: clientDivision.jseTrader,
        safexTraderId: clientDivision.safexTraderId,
        physicalsTraderId: clientDivision.physicalsTraderId,
        jseTraderId: clientDivision.jseTraderId,
        entityId: clientDivision.entityId,
        id: clientDivision.id,
        createdBy: clientDivision.createdBy,
        createdDate: clientDivision.createdDate,
        updatedBy: clientDivision.updatedBy,
        updatedDate: clientDivision.updateDate,
        _id: clientDivision._id,
        discretionaryManaged: clientDivision.discretionaryManaged,
        electronicAccNumber: clientDivision.electronicAccNumber,
        isBeneficial: clientDivision.isBeneficial,
      }
    );
    return Promise.resolve(res.data);
  };

  return {
    getAllPrivateCompDivision,
    getAllPrivateCompDivisionByCode,
    getPrivateCompDivisionByCode,
    addNewPrivateCompDivisons,
    updatePrivateCompDivisions,
    deletePrivateCompDivisions,
  };
};
export default PrivateCompanyDivisions;
