
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { ClientDivision_CREATE, Market, Trader } from "models";
import Hooks from "@/hooks";
import JSEClient from "@/store/modules/JSEClient";

const { ClosedCorpDivision, Markets, Traders } = Hooks;
const clientHook = ClosedCorpDivision();
const marketsHook = Markets();
const traderHook = Traders();

@Component({})
export default class Division extends Vue {
  @PropSync("valid") formValid!: boolean;
  @PropSync("nextTab") tab!: boolean;
  @PropSync("next") accountNext!: boolean;
  @Prop() type!: string;
  @Prop() divisionToBeEdited!: {
    ircCode: string;
    safexCode: string;
    physicalsCode: string;
  };
  loading = false;
  markets: Market[] = [];
  safexTraders: Trader[] = [];
  jseTraders: Trader[] = [];
  physicalTraders: Trader[] = [];
  safexTradersEdit: Trader[] = [];
  jseTradersEdit: Trader[] = [];
  physicalTradersEdit: Trader[] = [];
  closedCorpDivision: ClientDivision_CREATE = {
    safexCode: null,
    accountingCode: null,
    physicalCode: null,
    safexSubAccount: null,
    accountingSubAccount: null,
    safexMarket: null,
    physicalsMarket: null,
    safexTrader: null,
    physicalsTrader: null,
    jseTrader: null,
    jseMarket: null,
    safexTraderId: null,
    physicalsTraderId: null,
    jseTraderId: null,
    entityId: null,
    discretionaryManaged: false,
    electronicAccNumber: null,
    isBeneficial: false,
    sendToJSE: false,
  };
  division = "";
  safexSwitch = false;
  ircSwitch = false;
  divisions: {
    closedCorpDivision: ClientDivision_CREATE;
    division: string | null;
    safexSwitch: boolean;
    ircSwitch: boolean;
  }[] = [
    {
      closedCorpDivision: {
        safexCode: null,
        accountingCode: null,
        physicalCode: null,
        safexSubAccount: null,
        accountingSubAccount: null,
        safexMarket: null,
        physicalsMarket: null,
        jseMarket: null,
        safexTrader: null,
        physicalsTrader: null,
        jseTrader: null,
        safexTraderId: null,
        physicalsTraderId: null,
        jseTraderId: null,
        entityId: null,
        discretionaryManaged: false,
        electronicAccNumber: null,
        isBeneficial: false,
        sendToJSE: false,
      },
      division: null,
      safexSwitch: false,
      ircSwitch: false,
    },
  ];
  divisionLength = 0;
  divisionCode: string | null = null;
  divisionEditCode: string | null = null;
  subAccountEditCode: string | null = null;
  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
    emptyArr: (value: string[]): boolean | string =>
      value.length > 0 || "At least one item is required",
    uppercase: (value: string): boolean | string => {
      const pattern = /^[A-Z0-9]*$/;
      return pattern.test(value) || "Values Entered Must All Be Uppercase.";
    },
  };

  @Watch("type", { immediate: true })
  async modalTypeChanged(): Promise<void> {
    console.log("Modal Details Type: ", this.type);
    await this.getMarkets();
    if (this.type == "edit") {
      this.divisions = [];
      this.division = "";
      await this.getStoredData();

      /* const foundTrader = this.safexTraders.find(
        (trader) => trader._id == this.closedCorpDivision.safexTraderId
      );
      if (foundTrader) {
        JSEClient.setDivisions([
          {
            branch: foundTrader.branch,
            dealer: foundTrader.dealerCode,
            ...this.closedCorpDivision,
          },
        ]);
      } */
    }
  }

  @Watch("accountNext", {
    immediate: true,
  })
  async onNextChanged(): Promise<void> {
    console.log("AccountNext", this.accountNext);
    if (this.accountNext == true) {
      if (this.type == "add") {
        await this.addNewClientDetails();
      }
      if (this.type == "edit") {
        await this.editClientDetails();
      }
    }
  }

  marketChanged(marketCode: string): void {
    var result = this.markets.find((elem) => elem.marketCode == marketCode);
    if (result != undefined) {
      localStorage.setItem("ClientFile/marketId", String(result.id));
    }
  }

  divisionCodeChanged(dCode: string | null): void {
    if (dCode != null) {
      this.divisionCode = dCode;
    }
    console.log("dCode: ", this.divisionCode);
  }

  divisionEditCodeChanged(dCode: string | null): void {
    if (dCode != null) {
      this.divisionEditCode = dCode;
    }
    console.log("dEditCode: ", this.divisionEditCode);
  }

  subAccountEditChanged(subCode: string | null): void {
    if (subCode != null) {
      this.subAccountEditCode = subCode;
    }
    console.log("subAccountChanged: ", this.subAccountEditCode);
  }

  divisionEditChanged(division: string): void {
    switch (division) {
      case "Safex Client":
        this.closedCorpDivision.safexCode = this.divisionEditCode;

        this.closedCorpDivision.physicalsMarket = null;
        this.closedCorpDivision.physicalsTrader = null;
        this.closedCorpDivision.physicalCode = null;

        this.closedCorpDivision.jseMarket = null;
        this.closedCorpDivision.jseMarket = null;
        this.closedCorpDivision.accountingCode = null;
        this.ircSwitch = false;
        this.closedCorpDivision.accountingSubAccount = null;
        break;
      case "Physicals Client":
        this.closedCorpDivision.physicalCode = this.divisionEditCode;

        this.closedCorpDivision.safexMarket = null;
        this.closedCorpDivision.safexTrader = null;
        this.closedCorpDivision.safexCode = null;
        this.safexSwitch = false;
        this.closedCorpDivision.safexSubAccount = null;

        this.closedCorpDivision.jseMarket = null;
        this.closedCorpDivision.jseMarket = null;
        this.closedCorpDivision.accountingCode = null;
        this.ircSwitch = false;
        this.closedCorpDivision.accountingSubAccount = null;
        break;
      case "JSE Client":
        this.closedCorpDivision.accountingCode = this.divisionEditCode;

        this.closedCorpDivision.physicalsMarket = null;
        this.closedCorpDivision.physicalsTrader = null;
        this.closedCorpDivision.physicalCode = null;

        this.closedCorpDivision.safexMarket = null;
        this.closedCorpDivision.safexTrader = null;
        this.closedCorpDivision.safexCode = null;
        this.safexSwitch = false;
        this.closedCorpDivision.safexSubAccount = null;
        break;
    }
    console.log("Divisions Edit Changed: ", this.closedCorpDivision);
  }

  async divisionChanged(division: string, index: number): Promise<void> {
    switch (division) {
      case "Safex Client":
        this.divisions[index].closedCorpDivision.safexCode = this.divisionCode;
        if (this.subAccountEditCode != null) {
          this.divisions[index].closedCorpDivision.safexSubAccount =
            this.subAccountEditCode;
          this.divisions[index].safexSwitch = true;
        }

        if (this.closedCorpDivision.safexMarket) {
          this.divisions[index].closedCorpDivision.safexMarket =
            this.closedCorpDivision.safexMarket;
          await this.getTraders(
            this.divisions[index].closedCorpDivision.safexMarket,
            "safexTraders"
          );
          this.marketChanged(
            this.divisions[index].closedCorpDivision.safexMarket!
          );
        }

        if (this.closedCorpDivision.safexTraderId) {
          this.divisions[index].closedCorpDivision.safexTraderId =
            this.closedCorpDivision.safexTraderId;
        }

        this.divisions[index].closedCorpDivision.physicalsMarket = null;
        this.divisions[index].closedCorpDivision.physicalsTrader = null;
        this.divisions[index].closedCorpDivision.physicalCode = null;

        this.divisions[index].closedCorpDivision.jseMarket = null;
        this.divisions[index].closedCorpDivision.jseTrader = null;
        this.divisions[index].closedCorpDivision.accountingCode = null;
        this.divisions[index].ircSwitch = false;
        this.divisions[index].closedCorpDivision.accountingSubAccount = null;
        break;
      case "Physicals Client":
        this.divisions[index].closedCorpDivision.physicalCode =
          this.divisionCode;

        if (this.closedCorpDivision.physicalsMarket) {
          this.divisions[index].closedCorpDivision.physicalsMarket =
            this.closedCorpDivision.physicalsMarket;
          await this.getTraders(
            this.divisions[index].closedCorpDivision.physicalsMarket,
            "physicalTraders"
          );
          this.marketChanged(
            this.divisions[index].closedCorpDivision.physicalsMarket!
          );
        }

        if (this.closedCorpDivision.physicalsTraderId) {
          this.divisions[index].closedCorpDivision.physicalsTraderId =
            this.closedCorpDivision.physicalsTraderId;
        }

        this.divisions[index].closedCorpDivision.safexMarket = null;
        this.divisions[index].closedCorpDivision.safexTrader = null;
        this.divisions[index].closedCorpDivision.safexCode = null;
        this.divisions[index].safexSwitch = false;
        this.divisions[index].closedCorpDivision.safexSubAccount = null;

        this.divisions[index].closedCorpDivision.jseMarket = null;
        this.divisions[index].closedCorpDivision.jseMarket = null;
        this.divisions[index].closedCorpDivision.accountingCode = null;
        this.divisions[index].ircSwitch = false;
        this.divisions[index].closedCorpDivision.accountingSubAccount = null;
        break;
      case "JSE Client":
        this.divisions[index].closedCorpDivision.accountingCode =
          this.divisionCode;
        if (this.subAccountEditCode != null) {
          this.divisions[index].closedCorpDivision.accountingSubAccount =
            this.subAccountEditCode;
          this.divisions[index].ircSwitch = true;
        }

        if (this.closedCorpDivision.jseMarket) {
          this.divisions[index].closedCorpDivision.jseMarket =
            this.closedCorpDivision.jseMarket;
          await this.getTraders(
            this.divisions[index].closedCorpDivision.jseMarket,
            "jseTraders"
          );
          this.marketChanged(
            this.divisions[index].closedCorpDivision.jseMarket!
          );
        }

        if (this.closedCorpDivision.jseTraderId) {
          this.divisions[index].closedCorpDivision.jseTraderId =
            this.closedCorpDivision.jseTraderId;
        }

        this.divisions[index].closedCorpDivision.physicalsMarket = null;
        this.divisions[index].closedCorpDivision.physicalsTrader = null;
        this.divisions[index].closedCorpDivision.physicalCode = null;

        this.divisions[index].closedCorpDivision.safexMarket = null;
        this.divisions[index].closedCorpDivision.safexTrader = null;
        this.divisions[index].closedCorpDivision.safexCode = null;
        this.divisions[index].safexSwitch = false;
        this.divisions[index].closedCorpDivision.safexSubAccount = null;
        break;
    }
    console.log("Divisions Changed: ", this.divisions[index]);
    return Promise.resolve();
  }

  async getTraders(marketName: string | null, division: string): Promise<void> {
    try {
      console.log("SelectedMarket", marketName);
      var selectedMarket = this.markets.find(
        (elem) => elem.marketCode == marketName
      );

      console.log("SelectedMarketFind", selectedMarket);
      if (selectedMarket != undefined) {
        const res = await traderHook.getAllTradersByMarket(selectedMarket.id);
        switch (division) {
          case "safexTraders":
            this.safexTraders = res;
            break;
          case "jseTraders":
            this.jseTraders = res;
            break;
          case "physicalTraders":
            this.physicalTraders = res;
            break;
          case "safexTradersEdit":
            this.safexTradersEdit = res;
            break;
          case "jseTradersEdit":
            this.jseTradersEdit = res;
            break;
          case "physicalTradersEdit":
            this.physicalTradersEdit = res;
            break;
        }
        return Promise.resolve();
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getMarkets(): Promise<void> {
    try {
      const res = await marketsHook.getAllMarkets();
      this.markets = res;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  addDivision(): void {
    if (this.closedCorpDivision.safexSubAccount != null) {
      this.subAccountEditCode = this.closedCorpDivision.safexSubAccount;
    } else if (this.closedCorpDivision.accountingSubAccount != null) {
      this.subAccountEditCode = this.closedCorpDivision.accountingSubAccount;
    }

    const key = localStorage.getItem("ClientFile/entityId");
    if (key != null) {
      this.divisions.push({
        closedCorpDivision: {
          safexCode: null,
          accountingCode: null,
          physicalCode: null,
          safexSubAccount: null,
          accountingSubAccount: null,
          safexMarket: null,
          physicalsMarket: null,
          jseMarket: null,
          safexTrader: null,
          physicalsTrader: null,
          jseTrader: null,
          entityId: key,
          safexTraderId: null,
          physicalsTraderId: null,
          jseTraderId: null,
          discretionaryManaged: false,
          electronicAccNumber: null,
          isBeneficial: false,
          sendToJSE: false,
        },
        division: null,
        safexSwitch: false,
        ircSwitch: false,
      });
    }
    console.log("divisions: ", this.divisions);
  }

  removeDivision(): void {
    this.divisions.pop();
    console.log("divisions: ", this.divisions);
  }

  async getStoredData(): Promise<void> {
    try {
      this.loading = true;
      var entityId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        entityId = key;
      }

      if (entityId != null) {
        const res = await clientHook.getAllClosedCorpDivisionByCode(entityId);
        console.log("Get Stored Data res: ", res);
        var division = res.find(
          (elem) =>
            (elem.safexCode == this.divisionToBeEdited.safexCode &&
              elem.safexCode != null) ||
            (elem.accountingCode == this.divisionToBeEdited.ircCode &&
              elem.accountingCode != null) ||
            (elem.physicalCode == this.divisionToBeEdited.physicalsCode &&
              elem.physicalCode != null)
        );
        console.log("Division to be Edited", division);
        if (division != undefined) {
          this.closedCorpDivision = { sendToJSE: false, ...division };
          if (this.closedCorpDivision.safexCode != null) {
            this.division = "Safex Client";
            if (this.closedCorpDivision.safexSubAccount != null) {
              this.safexSwitch = true;
            }
            await this.getTraders(
              this.closedCorpDivision.safexMarket,
              "safexTradersEdit"
            );
            this.divisionEditCodeChanged(this.closedCorpDivision.safexCode);
          }
          if (this.closedCorpDivision.physicalCode != null) {
            this.division = "Physicals Client";

            await this.getTraders(
              this.closedCorpDivision.physicalsMarket,
              "physicalTradersEdit"
            );

            this.divisionEditCodeChanged(this.closedCorpDivision.physicalCode);
          }
          if (this.closedCorpDivision.accountingCode != null) {
            this.division = "JSE Client";
            if (this.closedCorpDivision.accountingSubAccount != null) {
              this.ircSwitch = true;
            }
            await this.getTraders(
              this.closedCorpDivision.jseMarket,
              "jseTradersEdit"
            );
            this.divisionEditCodeChanged(
              this.closedCorpDivision.accountingCode
            );
          }

          if (
            this.closedCorpDivision.safexCode == null &&
            this.closedCorpDivision.accountingCode == null &&
            this.closedCorpDivision.accountingCode == null
          ) {
            this.division = "Safex Client";
          }
        }
        return Promise.resolve();
      }
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async addNewClientDetails(): Promise<void> {
    try {
      this.loading = true;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        console.log("key: ", key);
        this.closedCorpDivision.entityId = key;
      }

      const divsionsToSend: any[] = [];

      this.divisions.forEach(async (elem) => {
        console.log("Division Details: ", elem.closedCorpDivision);
        elem.closedCorpDivision.entityId = key;

        const foundTrader = this.safexTraders.find(
          (trader) => trader._id == elem.closedCorpDivision.safexTraderId
        );
        if (foundTrader) {
          divsionsToSend.push({
            branch: foundTrader.branch,
            dealer: foundTrader.dealerCode,
            ...elem.closedCorpDivision,
          });
        }

        const res = await clientHook
          .addNewClosedCorpDivison(elem.closedCorpDivision)
          .catch((err) => {
            this.accountNext = false;
          });
        this.$notificationCreator.createSuccessNotification(
          "Division Details Saved"
        );
        return Promise.resolve();
      });

      /* const existingD = JSEClient.getDivisions;

      if (existingD.length > 0) {
        existingD.map((div) => {
          divsionsToSend.push(div);
        });
      }
      JSEClient.setDivisions(divsionsToSend); */

      if (this.type != "edit") {
        //add
        JSEClient.setDivisions(divsionsToSend);
        this.tab = true;
      } else {
        //edit
        JSEClient.sendAdditionalClientsToJSE(divsionsToSend);
      }
    } catch (err: any) {
      this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async editClientDetails(): Promise<void> {
    try {
      this.loading = true;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        this.closedCorpDivision.entityId = key;
      }

      console.log("Division Details: ", this.closedCorpDivision);

      const res = await clientHook.updateClosedCorpDivision(
        this.closedCorpDivision as any
      );

      const foundTrader = this.safexTraders.find(
        (trader) => trader._id == this.closedCorpDivision.safexTraderId
      );
      if (foundTrader) {
        /* const existingD = JSEClient.getDivisions;
        if (existingD.length > 0) {
          existingD[0] = {
            branch: foundTrader.branch,
            dealer: foundTrader.dealerCode,
            ...this.closedCorpDivision,
          };
        }

        JSEClient.setDivisions(existingD); */

        JSEClient.setDivisions([
          {
            branch: foundTrader.branch,
            dealer: foundTrader.dealerCode,
            ...this.closedCorpDivision,
          },
        ]);
      }
      this.$notificationCreator.createSuccessNotification(
        "Division Details Updated."
      );
      //this.tab = true;
      return Promise.resolve();
    } catch (err: any) {
      //this.accountNext = false;
      //this.tab = false;
      return Promise.reject(err);
    } finally {
      this.accountNext = false;
      this.loading = false;
    }
  }
}
