
import { Component, Vue } from "vue-property-decorator";
import User from "@/store/modules/user";
import Dash from "@/store/modules/Dash";
import { UserAccessModel } from "models";

@Component
export default class AppBar extends Vue {
  flipDrawer(): void {
    Dash.setAppDrawer(!this.appDrawer);
  }

  get appDrawer(): boolean {
    return Dash.getAppDrawer;
  }

  get getTitle(): string {
    switch (this.$route.name) {
      case "dash.landing":
        return "Dashboard";
      case "dash.profile.user":
        return "Profile";
      case "dash.admin":
        return "Admin";
      case "dash.manage.traders":
        return "Manage Traders";
      case "dash.manage.banks":
        return "Manage Banks";
      case "dash.manage.markets":
        return "Manage Markets";
      case "dash.manage.sync-clients":
        return "Sync Clients";
      case "dash.manage.childBank":
        return "Manage Child Bank";
      case "dash.manage.childAddress":
        return "Manage Child Address";
      case "dash.manage.ratingCodes":
        return "Rating Codes";
      case "dash.commodityAccount.overview":
        return "Commodity Accounts";
      case "dash.commodityAccount.individual":
        return "Commodity Accounts - Individual";
      case "dash.commodityAccount.closedCorp":
        return "Commodity Accounts - Closed Corporation (CC)";
      case "dash.commodityAccount.partnership":
        return "Commodity Accounts - Partnership";
      case "dash.poolAccount":
        return "Pool Accounts";
      case "dash.transferBetweenClients":
        return "Transfer Between Clients";
      case "dash.transferBetweenBanks":
        return "Transfer to Banks";
      case "dash.clearing":
        return "Clearing & JSE Fees";
      case "dash.auditTrail":
        return "Audit Trail";
      case "dash.riskReporting.import":
        return "Risk Reporting - Import";
      case "dash.riskReporting.audit":
        return "Risk Reporting - Audit";
      default:
        return "DEFAULT TITLE " + this.$route.name;
    }
  }

  get Username(): string | null {
    return User.getLoggedInUser;
  }

  get getUserAccess(): UserAccessModel | null {
    console.log("UA: ", User.getUserAccess);
    return User.getUserAccess;
  }

  logout(): void {
    User.removeLoggedIn();
    User.removeUserAccess();
    localStorage.removeItem("ClientFile/entityId");
    localStorage.removeItem("ClientFile/tab");
    this.$router.push({
      name: "Auth",
    });
  }
}
