import { axiosInstance } from "@/plugins/axios";

interface ClientFileDetails {
  idAsString: string;
  clientCode: string;
  branch: string;
  _id: {
    timestamp: number;
    machine: number;
    pid: number;
    increment: number;
    creationTime: string;
  };
  titleId: number;
  clientDetailId: number;
  name: string;
  surname: string;
  dateOfBirth: string;
  email: string | null;
  cellNumber: string;
  isCompany: boolean;
  companyAddress: string | null;
  postalAddress: string | null;
  postalAddress2: string | null;
  postalSuburb: string | null;
  postalCity: string | null;
  postalCode: string | null;
  streetAddress: string;
  streetAddress2: string | null;
  suburb: string | null;
  city: string;
  code: string;
  province: string;
  entityId: string;
  createdBy: string | null;
  createdDate: string;
  updatedBy: string | null;
  updateDate: string;
}

export interface SyncClientModel {
  id: string;
  clientFileDetails: ClientFileDetails;
  jseClientFileDetails: ClientFileDetails;
  loading: boolean;
}

const SyncClients = () => {
  const baseUrl = "/api/ClientAccount";

  const GetAllClientDifferences = async (): Promise<SyncClientModel[]> => {
    const res = await axiosInstance.get<{
      [key: string]: {
        clientFileDetails: ClientFileDetails;
        jseClientFileDetails: ClientFileDetails;
      };
    }>(baseUrl + "/GetAllClientDifferences");

    const temp = Object.keys(res.data).map((key) => ({
      id: key,
      clientFileDetails: res.data[key].clientFileDetails,
      jseClientFileDetails: res.data[key].jseClientFileDetails,
      loading: false,
    }));
    return Promise.resolve(temp);
  };

  const SyncClient = async (item: ClientFileDetails): Promise<void> => {
    const res = await axiosInstance.post(
      "/api/JseClient/autoUpdateClient",
      item
    );
    debugger;
    return Promise.resolve();
  };

  return {
    GetAllClientDifferences,
    SyncClient,
  };
};
export default SyncClients;
