import { axiosInstance } from "@/plugins/axios";
import { AccountKYC, ClientKYC, ClientKYC_CREATE } from "models";

const ClosedCorpKYCs = () => {
  const baseUrl = "/api/ClosedCorpKYC";

  const getAllClosedCorpKYC = async (): Promise<ClientKYC[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAllClosedCorpKYC");
    return Promise.resolve(res.data);
  };

  const getClosedCorpByCodeKYC = async (code: string): Promise<ClientKYC> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClosedCorpByCodeKYC?code=${code}`
    );
    return Promise.resolve(res.data);
  };

  const addNewClosedCorpKYC = async (
    clientKYC: ClientKYC_CREATE
  ): Promise<any> => {
    const res = await axiosInstance.post(baseUrl + "/addNewClosedCorpKYC", {
      entityId: clientKYC.entityId,
      sourceOfIncome: clientKYC.sourceOFIncome,
      currentEarningsSector: clientKYC.currentEarningsSector,
      experienceLevel: clientKYC.experienceLevel,
      accountFunds: clientKYC.accountFunds,
      incomeBand: clientKYC.incomeBand,
      riskAppetite: clientKYC.riskAppetite,
      dpip: clientKYC.dpip,
      job: clientKYC.job,
      geoLocation: clientKYC.geoLocation,
      influentialPerson: clientKYC.influentialPerson,
    });
    return Promise.resolve(res.data);
  };

  const updateClosedCorpKYC = async (clientKYC: ClientKYC): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/updateClosedCorpKYC", {
      entityId: clientKYC.entityId,
      sourceOfIncome: clientKYC.sourceOFIncome,
      currentEarningsSector: clientKYC.currentEarningsSector,
      investmentExperience: clientKYC.investmentExperience,
      accountFunds: clientKYC.accountFunds,
      incomeBand: clientKYC.incomeBand,
      riskAppetite: clientKYC.riskAppetite,
      dpip: clientKYC.dpip,
      job: clientKYC.job,
      geoLocation: clientKYC.geoLocation,
      influentialPerson: clientKYC.influentialPerson,
      createdBy: clientKYC.createdBy,
      createdDate: clientKYC.createdDate,
      id: clientKYC.id,
      updatedBy: clientKYC.updatedBy,
      updatedDate: clientKYC.updatedDate,
      _id: clientKYC._id,
    });
    return Promise.resolve(res.data);
  };

  const deleteClosedCorpKYC = async (clientKYC: ClientKYC): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/deleteClosedCorpKYC", {
      entityId: clientKYC.entityId,
      sourceOfIncome: clientKYC.sourceOFIncome,
      currentEarningsSector: clientKYC.currentEarningsSector,
      investmentExperience: clientKYC.investmentExperience,
      accountFunds: clientKYC.accountFunds,
      incomeBand: clientKYC.incomeBand,
      riskAppetite: clientKYC.riskAppetite,
      dpip: clientKYC.dpip,
      job: clientKYC.job,
      geoLocation: clientKYC.geoLocation,
      influentialPerson: clientKYC.influentialPerson,
      createdBy: clientKYC.createdBy,
      createdDate: clientKYC.createdDate,
      id: clientKYC.id,
      updatedBy: clientKYC.updatedBy,
      updatedDate: clientKYC.updatedDate,
      _id: clientKYC._id,
    });
    return Promise.resolve(res.data);
  };

  return {
    getAllClosedCorpKYC,
    getClosedCorpByCodeKYC,
    addNewClosedCorpKYC,
    updateClosedCorpKYC,
    deleteClosedCorpKYC,
  };
};
export default ClosedCorpKYCs;
