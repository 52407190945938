
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import {
  ClientFee_CREATE,
  BreakdownOfFee_CREATE,
  RatingCode,
  ClientDivisionModel,
  Trader,
} from "models";
import Hooks from "@/hooks";

const { RatingCodes, FeeStructures, PrivateDivision, Traders } = Hooks;

const ratingCodeHook = RatingCodes();
const feeStructureHook = FeeStructures();
const closedCorpDivisionHook = PrivateDivision();
const traderHook = Traders();

@Component({})
export default class Fees extends Vue {
  @PropSync("valid") formValid!: boolean;
  @PropSync("nextTab") tab!: boolean;
  @PropSync("next") accountNext!: boolean;
  @Prop() type!: string;
  @Prop() divisionToBeEdited!: {
    ircCode: string;
    safexCode: string;
    physicalsCode: string;
  };
  loading = false;
  dataCheck = false;
  clientFee: ClientFee_CREATE = {
    ficaType: null,
    contractSignatureDate: null,
    contractInFile: null,
    clientAccountId: null,
    breakdownOfFeeId: null,
  };
  feeStructureFromValid = false;
  ratingCodes: RatingCode[] = [];
  feeAmount: number | null = null;
  feeStructure: BreakdownOfFee_CREATE = {
    clientAccountId: null,
    ratingCodeId: null,
    member: null,
    memberAmount: null,
    broker: null,
    brokerAmount: null,
    thirdParty: null,
    thirdPartyAmount: null,
    fourthParty: null,
    fourthPartyAmount: null,
    fifthParty: null,
    fifthPartyAmount: null,
    sixthParty: null,
    sixthPartyAmount: null,
    seventhParty: null,
    seventhPartyAmount: null,
    eighthParty: null,
    eighthPartyAmount: null,
    ninethParty: null,
    ninethPartyAmount: null,
    tenthParty: null,
    tenthPartyAmount: null,
    total: 0,
    safexDivisionId: 0,
  };
  parties: { label: string; name: string | null; amount: number | null }[] = [];
  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
  };

  @Watch("type", { immediate: true })
  async modalTypeChanged(): Promise<void> {
    console.log("Modal Details Type: ", this.type);
    setTimeout(this.setDefaults, 10);

    if (this.type == "edit") {
      await this.getStoredData();
    }
    if (this.type == "add") {
      await this.getRatingCodes(null);
    }
  }

  @Watch("feeStructure.thirdParty", { immediate: true })
  async Changed(): Promise<void> {
    console.log("third: ", this.feeStructure.thirdParty);
  }

  @Watch("accountNext", {
    immediate: true,
  })
  async onNextChanged(): Promise<void> {
    console.log("AccountNext", this.accountNext);
    if (this.accountNext == true) {
      if (this.type == "add") {
        await this.addNewClientDetails();
      }
      if (this.type == "edit") {
        if (this.dataCheck == true) {
          await this.editClientDetails();
        } else {
          await this.addNewClientDetails();
        }
      }
    }
  }

  setDefaults(): void {
    if (this.type == "add") {
      this.clientFee.ficaType = "Individual";
    }
  }

  async mounted(): Promise<void> {
    setTimeout(this.setDefaults, 10);
  }

  getFeeAmountForRatingCode(): void {
    var result = this.ratingCodes.find(
      (elem) => elem.id == this.feeStructure.ratingCodeId
    );
    if (result != undefined) {
      this.feeAmount = result.amount;
    }
  }

  getRatingCodeForFeeAmount(): void {
    var result = this.ratingCodes.find((elem) => elem.amount == this.feeAmount);
    if (result != undefined) {
      this.feeStructure.ratingCodeId = result.id;
    }
  }

  addParty(i: number): void {
    switch (i) {
      case 0:
        this.parties.push({ label: "5th Party", name: null, amount: null });
        break;
      case 1:
        this.parties.push({ label: "6th Party", name: null, amount: null });
        break;
      case 2:
        this.parties.push({ label: "7th Party", name: null, amount: null });
        break;
      case 3:
        this.parties.push({ label: "8th Party", name: null, amount: null });
        break;
      case 4:
        this.parties.push({ label: "9th Party", name: null, amount: null });
        break;
      case 5:
        this.parties.push({ label: "10th Party", name: null, amount: null });
        break;
    }
    console.log("Parties Length: ", this.parties.length);
  }

  removeParty(): void {
    this.parties.pop();
  }

  getTotal(): void {
    this.feeStructure.total = 0;
    var member =
      this.feeStructure.memberAmount != null
        ? this.feeStructure.memberAmount
        : 0;
    var broker =
      this.feeStructure.brokerAmount != null
        ? this.feeStructure.brokerAmount
        : 0;
    var thirdParty =
      this.feeStructure.thirdPartyAmount != null
        ? this.feeStructure.thirdPartyAmount
        : 0;
    var fourthParty =
      this.feeStructure.fourthPartyAmount != null
        ? this.feeStructure.fourthPartyAmount
        : 0;
    var additionalPartiesTotal = 0;
    if (this.parties.length > 0) {
      this.parties.forEach((elem) => {
        if (elem.amount != null) {
          additionalPartiesTotal += Number(elem.amount);
        }
      });
    }
    this.feeStructure.total = Number(
      (
        Number(member) +
        Number(broker) +
        Number(thirdParty) +
        Number(fourthParty) +
        additionalPartiesTotal
      ).toFixed(2)
    );
  }

  async getRatingCodes(traderId: number | null): Promise<void> {
    if (this.type == "add") {
      var marketId: number | null = null;
      const key = localStorage.getItem("ClientFile/marketId");
      if (key != null) {
        marketId = Number(key);
      }
      if (marketId != null) {
        const res = await ratingCodeHook
          .getAllRatingCodesByMarket(marketId)
          .catch((err: any) => {
            return Promise.reject(err);
          });
        this.ratingCodes = res;
        console.log("RATING CODES:", this.ratingCodes);
      } else if (marketId == null || marketId == undefined) {
        const res = await ratingCodeHook
          .getAllRatingCodes()
          .catch((err: any) => {
            return Promise.reject(err);
          });

        this.ratingCodes = res;

        console.log("RATING CODES:", this.ratingCodes);
      }

      console.log(this.ratingCodes);
      return Promise.resolve();
    }
    if (this.type == "edit") {
      var trader: Trader | undefined | null = null;
      if (traderId != null) {
        const allTraders = await traderHook.getAllTraders();
        if (allTraders != undefined || allTraders != null) {
          trader = allTraders.find((elem) => elem._id == traderId);
        }
      }
      if (trader != null || trader != undefined) {
        const res = await ratingCodeHook
          .getAllRatingCodesByMarket(trader.marketId)
          .catch((err: any) => {
            return Promise.reject(err);
          });
        this.ratingCodes = res;
        console.log("RATING CODES (editCheck):", this.ratingCodes);
      } else {
        const res = await ratingCodeHook
          .getAllRatingCodes()
          .catch((err: any) => {
            return Promise.reject(err);
          });
        this.ratingCodes = res;
        console.log("RATING CODES All (editCheck):", this.ratingCodes);
      }
    }
  }

  async getStoredData(): Promise<void> {
    try {
      this.loading = true;
      var clientId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        clientId = key;
      }

      var safexDivisionId: number | null = null;
      const res = await this.getDivision();
      if (res != undefined) {
        safexDivisionId = res._id;
        if (res.safexTraderId != null) {
          await this.getRatingCodes(res.safexTraderId);
        } else if (res.jseTraderId != null) {
          await this.getRatingCodes(res.jseTraderId);
        } else if (res.physicalsTraderId != null) {
          await this.getRatingCodes(res.physicalsTraderId);
        }
      }

      if (clientId != null) {
        const resFeeStructure = await feeStructureHook.getFeeStructuresByClient(
          clientId,
          safexDivisionId
        );
        if (
          resFeeStructure != undefined &&
          (resFeeStructure as any) != "" &&
          resFeeStructure.id != 0
        ) {
          this.dataCheck = true;
          this.feeStructure = resFeeStructure as any;
        } else {
          this.dataCheck = false;
        }
        console.log("dataCheck: ", this.dataCheck);
        this.getFeeAmountForRatingCode();

        return Promise.resolve();
      }
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async getDivision(): Promise<ClientDivisionModel | undefined> {
    var entityId: string | null = null;
    const key = localStorage.getItem("ClientFile/entityId");
    if (key != null) {
      entityId = key;
    }
    if (this.type == "add") {
      try {
        var resAdd: ClientDivisionModel | undefined;
        if (entityId != null) {
          resAdd = await closedCorpDivisionHook.getPrivateCompDivisionByCode(
            entityId
          );

          console.log("Client Division:", resAdd);
        }
        return Promise.resolve(resAdd);
      } catch (err) {
        console.log("Couldn't get Client Division", err);
        return Promise.reject(err);
      }
    } else if (this.type == "edit") {
      try {
        var resEdit: ClientDivisionModel[] | null = null;
        if (entityId != null) {
          resEdit = await closedCorpDivisionHook.getAllPrivateCompDivisionByCode(
            entityId
          );
          console.log("Get Stored Data res: ", resEdit);
          var division = resEdit.find(
            (elem) =>
              (elem.safexCode == this.divisionToBeEdited.safexCode &&
                elem.safexCode != null) ||
              (elem.accountingCode == this.divisionToBeEdited.ircCode &&
                elem.accountingCode != null) ||
              (elem.physicalCode == this.divisionToBeEdited.physicalsCode &&
                elem.physicalCode != null)
          );
          console.log("Division", division);
        }
        return Promise.resolve(division);
      } catch (err) {
        console.log("Couldn't get Client Division", err);
        return Promise.reject(err);
      }
    }
  }

  async addNewClientDetails(): Promise<void> {
    try {
      this.loading = true;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        this.feeStructure.clientAccountId = key;
      }

      const div = await this.getDivision();
      if (div != null) {
        this.feeStructure.safexDivisionId = div._id;
      }

      if (this.parties.length > 0) {
        this.parties.forEach((elem) => {
          switch (elem.label) {
            case "5th Party":
              this.feeStructure.fifthParty = elem.name;
              this.feeStructure.fifthPartyAmount = elem.amount;
              break;
            case "6th Party":
              this.feeStructure.sixthParty = elem.name;
              this.feeStructure.sixthPartyAmount = elem.amount;
              break;
            case "7th Party":
              this.feeStructure.seventhParty = elem.name;
              this.feeStructure.seventhPartyAmount = elem.amount;
              break;
            case "8th Party":
              this.feeStructure.eighthParty = elem.name;
              this.feeStructure.eighthPartyAmount = elem.amount;
              break;
            case "9th Party":
              this.feeStructure.ninethParty = elem.name;
              this.feeStructure.ninethPartyAmount = elem.amount;
              break;
            case "10th Party":
              this.feeStructure.tenthParty = elem.name;
              this.feeStructure.tenthPartyAmount = elem.amount;
              break;
          }
        });
      }

      console.log("Fee Structure Details: ", this.feeStructure);

      const res = await feeStructureHook.addFeeStructure(this.feeStructure);
      this.$notificationCreator.createSuccessNotification(
        "Fees Structure Saved"
      );
      this.tab = true;
      return Promise.resolve();
    } catch (err: any) {
      this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async editClientDetails(): Promise<void> {
    try {
      this.loading = true;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        this.feeStructure.clientAccountId = key;
      }

      const div = await this.getDivision();
      if (div != null) {
        this.feeStructure.safexDivisionId = div._id;
      }

      if (this.parties.length > 0) {
        this.parties.forEach((elem) => {
          switch (elem.label) {
            case "5th Party":
              this.feeStructure.fifthParty = elem.name;
              this.feeStructure.fifthPartyAmount = elem.amount;
              break;
            case "6th Party":
              this.feeStructure.sixthParty = elem.name;
              this.feeStructure.sixthPartyAmount = elem.amount;
              break;
            case "7th Party":
              this.feeStructure.seventhParty = elem.name;
              this.feeStructure.seventhPartyAmount = elem.amount;
              break;
            case "8th Party":
              this.feeStructure.eighthParty = elem.name;
              this.feeStructure.eighthPartyAmount = elem.amount;
              break;
            case "9th Party":
              this.feeStructure.ninethParty = elem.name;
              this.feeStructure.ninethPartyAmount = elem.amount;
              break;
            case "10th Party":
              this.feeStructure.tenthParty = elem.name;
              this.feeStructure.tenthPartyAmount = elem.amount;
              break;
          }
        });
      }

      console.log("Fee Structure Details: ", this.feeStructure);

      const resFeeStructure = await feeStructureHook.updateFeeStructure(
        this.feeStructure as any
      );
      this.$notificationCreator.createSuccessNotification(
        "Fees Structure Updated"
      );
      //this.tab = true;
      return Promise.resolve();
    } catch (err: any) {
      //this.accountNext = false;
      //this.tab = false;
      return Promise.reject(err);
    } finally {
      this.accountNext = false;
      this.loading = false;
    }
  }
}
