import { axiosInstance } from "@/plugins/axios";
import { AccountDetail, ClientDetail, ClientDetail_CREATE } from "models";

const TrustDetails = () => {
  const baseUrl = "/api/Trust";

  const getAllTrustDetailPerson = async (): Promise<ClientDetail[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAllTrustDetailPerson");
    return Promise.resolve(res.data);
  };

  const getTrustDetailByCode = async (code: string): Promise<ClientDetail> => {
    const res = await axiosInstance.get(
      baseUrl + `/getTrustDetailByCode?code=${code}`
    );
    return Promise.resolve(res.data);
  };

  const addNewTrustDetails = async (
    clientDetail: ClientDetail_CREATE
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/addNewTrustDetails",
      clientDetail
    );
    return Promise.resolve(res.data);
  };

  const updateTrustDetails = async (
    clientDetail: AccountDetail
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/updateTrustDetails",
      clientDetail
    );
    return Promise.resolve(res.data);
  };

  const deleteTrustDetails = async (
    clientDetail: AccountDetail
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/deleteTrustDetails",
      clientDetail
    );
    return Promise.resolve(res.data);
  };

  return {
    getAllTrustDetailPerson,
    addNewTrustDetails,
    updateTrustDetails,
    deleteTrustDetails,
    getTrustDetailByCode,
  };
};
export default TrustDetails;
