
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import dayjs from "dayjs";
import { ClientDetail_CREATE, ClosedCorpEntityDetail } from "models";
import Hooks from "@/hooks";
import JSEClient from "@/store/modules/JSEClient";

import StreetAddressComponent from "@/components/StreetAddressDetails.vue";
import PostalAddressComponent from "@/components/PostalAddressDetails.vue";
import ClientDetails from "@/components/ClientDetails.vue";
import copyStreetToPostal from "@/utils/AddressUtil";

const { ClosedCorpDetails, ClosedCorpEntityDetails } = Hooks;
const closedCorpHook = ClosedCorpDetails();
const ClosedCorpAccountstHook = ClosedCorpEntityDetails();

@Component({
  components: {
    StreetAddressComponent,
    PostalAddressComponent,
    ClientDetails,
  },
})
export default class AccountContactDetails extends Vue {
  @PropSync("valid") formValid!: boolean;
  @PropSync("nextTab") tab!: boolean;
  @PropSync("next") accountNext!: boolean;
  @Prop() type!: string;

  sameAsStreetAddress = false;
  loading = false;
  dataCheck = false;
  closedCorpDetails: ClientDetail_CREATE = {
    titleId: null,
    name: null,
    surname: null,
    dateOfBirth: null,
    email: null,
    cellNumber: null,
    isCompany: false,
    companyAddress: null,
    postalAddress: null,
    streetAddress: null,
    streetAddress2: null,
    suburb: null,
    city: null,
    code: null,
    province: null,
    entityId: null,
    postalAddress2: null,
    postalCity: null,
    postalCode: null,
    postalSuburb: null,
  };
  account: ClosedCorpEntityDetail | undefined;

  @Watch("type", { immediate: true })
  async modalTypeChanged(): Promise<void> {
    console.log("Modal Details Type: ", this.type);
    if (this.type == "edit") {
      await this.getStoredData();
    }

    if (this.type == "add") {
      this.getAccount();
    }
  }

  @Watch("accountNext", {
    immediate: true,
  })
  async onNextChanged(): Promise<void> {
    console.log("AccountNext", this.accountNext);
    if (this.accountNext == true) {
      if (this.type == "add") {
        await this.addNewClientDetails();
      }
      if (this.type == "edit") {
        if (this.dataCheck == true) {
          await this.editClientDetails();
        } else {
          await this.addNewClientDetails();
        }
      }
    }
  }

  async getAccount(): Promise<void> {
    var entityId: string | null = null;
    const key = localStorage.getItem("ClientFile/entityId");
    if (key != null) {
      entityId = key;
    }
    try {
      if (entityId != null) {
        const res = await ClosedCorpAccountstHook.getClosedCorpByCode(entityId);

        console.log("Account Details: ", res);
        this.account = res;
        if (this.account != undefined) {
          this.closedCorpDetails.cellNumber = this.account.contactNumber;
          this.closedCorpDetails.email = this.account.contactPersonEmail;
        }

        return Promise.resolve();
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  }

  async getStoredData(): Promise<void> {
    try {
      this.loading = true;
      var entityId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        entityId = key;
      }

      if (entityId != null) {
        const res = await closedCorpHook.getClosedCorpByCodeDetail(entityId);

        console.log("Get Stored Data res: ", res);
        if (res != undefined && (res as any) != "") {
          this.dataCheck = true;
          this.closedCorpDetails = res as any;
          this.closedCorpDetails.dateOfBirth = dayjs(res.dateOfBirth)
            .format("YYYY-MM-DD")
            .toString();
        } else {
          this.dataCheck = false;
        }
        console.log("dataCheck: ", this.dataCheck);
        return Promise.resolve();
      }
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async editClientDetails(): Promise<void> {
    try {
      this.loading = true;
      console.log("BA Details: ", this.closedCorpDetails);

      this.closedCorpDetails.isCompany == null
        ? (this.closedCorpDetails.isCompany = false)
        : this.closedCorpDetails.isCompany;

      const res = await closedCorpHook.updateClosedCorpDetail(
        this.closedCorpDetails as any
      );

      JSEClient.setAccountContact({
        cellNumber: this.closedCorpDetails.cellNumber,
        city: this.closedCorpDetails.city,
        code: this.closedCorpDetails.code,
        companyAddress: this.closedCorpDetails.companyAddress,
        dateOfBirth: this.closedCorpDetails.dateOfBirth,
        email: this.closedCorpDetails.email,
        isCompany: this.closedCorpDetails.isCompany,
        name: this.closedCorpDetails.name,
        postalAddress: this.closedCorpDetails.postalAddress,
        province: this.closedCorpDetails.province,
        streetAddress: this.closedCorpDetails.streetAddress,
        streetAddress2: this.closedCorpDetails.streetAddress2,
        suburb: this.closedCorpDetails.suburb,
        surname: this.closedCorpDetails.surname,
        postalAddress2: this.closedCorpDetails.postalAddress2,
        postalCity: this.closedCorpDetails.postalCity,
        postalCode: this.closedCorpDetails.postalCode,
        postalSuburb: this.closedCorpDetails.postalSuburb,
      });

      this.$notificationCreator.createSuccessNotification(
        "Account Contact Details Updated."
      );

      //this.tab = true;
      return Promise.resolve();
    } catch (err: any) {
      //this.accountNext = false;
      //this.tab = false;
      return Promise.reject(err);
    } finally {
      this.accountNext = false;
      this.loading = false;
    }
  }

  async addNewClientDetails(): Promise<void> {
    try {
      this.loading = true;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        this.closedCorpDetails.entityId = key;
      }
      console.log("Account Contact Details: ", this.closedCorpDetails);

      this.closedCorpDetails.isCompany == null
        ? (this.closedCorpDetails.isCompany = false)
        : this.closedCorpDetails.isCompany;

      const res = await closedCorpHook.addNewClosedCorpDetail(
        this.closedCorpDetails
      );

      JSEClient.setAccountContact({
        cellNumber: this.closedCorpDetails.cellNumber,
        city: this.closedCorpDetails.city,
        code: this.closedCorpDetails.code,
        companyAddress: this.closedCorpDetails.companyAddress,
        dateOfBirth: this.closedCorpDetails.dateOfBirth,
        email: this.closedCorpDetails.email,
        isCompany: this.closedCorpDetails.isCompany,
        name: this.closedCorpDetails.name,
        postalAddress: this.closedCorpDetails.postalAddress,
        province: this.closedCorpDetails.province,
        streetAddress: this.closedCorpDetails.streetAddress,
        streetAddress2: this.closedCorpDetails.streetAddress2,
        suburb: this.closedCorpDetails.suburb,
        surname: this.closedCorpDetails.surname,
        postalAddress2: this.closedCorpDetails.postalAddress2,
        postalCity: this.closedCorpDetails.postalCity,
        postalCode: this.closedCorpDetails.postalCode,
        postalSuburb: this.closedCorpDetails.postalSuburb,
      });

      this.$notificationCreator.createSuccessNotification(
        "Account Contact Details Saved."
      );
      this.tab = true;
      return Promise.resolve();
    } catch (err: any) {
      this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  onSameAsStreetAddressToggle() {
    if (this.sameAsStreetAddress) {
      copyStreetToPostal(this.closedCorpDetails, this.closedCorpDetails);
    }
  }
  @Watch("closedCorpDetails.streetAddress")
  @Watch("closedCorpDetails.streetAddress2")
  @Watch("closedCorpDetails.suburb")
  @Watch("closedCorpDetails.city")
  @Watch("closedCorpDetails.code")
  onStreetAddressChange() {
    if (this.sameAsStreetAddress) {
      copyStreetToPostal(this.closedCorpDetails, this.closedCorpDetails);
    }
  }
}
