import { axiosInstance } from "@/plugins/axios";
import { ClientDivisionModel, ClientDivision_CREATE } from "models";

const ClientDivisions = () => {
  const baseUrl = "/api/ClosedCorpDivision";

  const getAllClosedCorpDivision = async (): Promise<ClientDivisionModel[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAllClosedCorpDivision");
    return Promise.resolve(res.data);
  };

  const getAllClosedCorpDivisionByCode = async (
    code: string
  ): Promise<ClientDivisionModel[]> => {
    const res = await axiosInstance.get(
      baseUrl + `/getAllClosedCorpDivisionByCode?code=${code}`
    );
    return Promise.resolve(res.data);
  };

  const getClosedCorpByCodeDivision = async (
    code: string
  ): Promise<ClientDivisionModel> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClosedCorpByCodeDivision?code=${code}`
    );
    return Promise.resolve(res.data);
  };

  const addNewClosedCorpDivison = async (
    clientDivision: ClientDivision_CREATE
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/addNewClosedCorpDivison", {
      safexCode: clientDivision.safexCode,
      accountingCode: clientDivision.accountingCode,
      physicalCode: clientDivision.physicalCode,
      safexSubAccount: clientDivision.safexSubAccount,
      accountingSubAccount: clientDivision.accountingSubAccount,
      safexMarket: clientDivision.safexMarket,
      jseMarket: clientDivision.jseMarket,
      physicalsMarket: clientDivision.physicalsMarket,
      safexTrader: clientDivision.safexTrader,
      physicalsTrader: clientDivision.physicalsTrader,
      jseTrader: clientDivision.jseTrader,
      safexTraderId: clientDivision.safexTraderId,
      physicalsTraderId: clientDivision.physicalsTraderId,
      jseTraderId: clientDivision.jseTraderId,
      entityId: clientDivision.entityId,
      discretionaryManaged: clientDivision.discretionaryManaged,
      electronicAccNumber: clientDivision.electronicAccNumber,
      isBeneficial: clientDivision.isBeneficial,
    });
    return Promise.resolve(res.data);
  };

  const updateClosedCorpDivision = async (
    clientDivision: ClientDivisionModel
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/updateClosedCorpDivision",
      {
        safexCode: clientDivision.safexCode,
        accountingCode: clientDivision.accountingCode,
        physicalCode: clientDivision.physicalCode,
        safexSubAccount: clientDivision.safexSubAccount,
        accountingSubAccount: clientDivision.accountingSubAccount,
        safexMarket: clientDivision.safexMarket,
        jseMarket: clientDivision.jseMarket,
        physicalsMarket: clientDivision.physicalsMarket,
        safexTrader: clientDivision.safexTrader,
        physicalsTrader: clientDivision.physicalsTrader,
        jseTrader: clientDivision.jseTrader,
        safexTraderId: clientDivision.safexTraderId,
        physicalsTraderId: clientDivision.physicalsTraderId,
        jseTraderId: clientDivision.jseTraderId,
        entityId: clientDivision.entityId,
        createdBy: clientDivision.createdBy,
        createdDate: clientDivision.createdDate,
        updatedBy: clientDivision.updatedBy,
        updatedDate: clientDivision.updateDate,
        _id: clientDivision._id,
        discretionaryManaged: clientDivision.discretionaryManaged,
        electronicAccNumber: clientDivision.electronicAccNumber,
        isBeneficial: clientDivision.isBeneficial,
      }
    );
    return Promise.resolve(res.data);
  };

  const deleteClosedCorpDivision = async (
    clientDivision: ClientDivisionModel
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/deleteClosedCorpDivision",
      {
        safexCode: clientDivision.safexCode,
        accountingCode: clientDivision.accountingCode,
        physicalCode: clientDivision.physicalCode,
        safexSubAccount: clientDivision.safexSubAccount,
        accountingSubAccount: clientDivision.accountingSubAccount,
        safexMarket: clientDivision.safexMarket,
        jseMarket: clientDivision.jseMarket,
        physicalsMarket: clientDivision.physicalsMarket,
        safexTrader: clientDivision.safexTrader,
        physicalsTrader: clientDivision.physicalsTrader,
        jseTrader: clientDivision.jseTrader,
        safexTraderId: clientDivision.safexTraderId,
        physicalsTraderId: clientDivision.physicalsTraderId,
        jseTraderId: clientDivision.jseTraderId,
        entityId: clientDivision.entityId,
        createdBy: clientDivision.createdBy,
        createdDate: clientDivision.createdDate,
        updatedBy: clientDivision.updatedBy,
        updatedDate: clientDivision.updateDate,
        _id: clientDivision._id,
        discretionaryManaged: clientDivision.discretionaryManaged,
        electronicAccNumber: clientDivision.electronicAccNumber,
        isBeneficial: clientDivision.isBeneficial,
      }
    );
    return Promise.resolve(res.data);
  };

  return {
    getAllClosedCorpDivision,
    getAllClosedCorpDivisionByCode,
    getClosedCorpByCodeDivision,
    addNewClosedCorpDivison,
    updateClosedCorpDivision,
    deleteClosedCorpDivision,
  };
};
export default ClientDivisions;
