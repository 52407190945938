import { axiosInstance } from "@/plugins/axios";
import {
  ClosedCorpEntityDetail,
  ClientAccount_CREATE,
  ClientOverview,
} from "models";

const TrustAccounts = () => {
  const baseUrl = "/api/Trust";

  const getAllTrustAccount = async (): Promise<ClosedCorpEntityDetail[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAllTrustAccount");
    return Promise.resolve(res.data);
  };

  const getTTOverview = async (): Promise<ClientOverview[]> => {
    const res = await axiosInstance.get(baseUrl + "/getTTOverview");
    return Promise.resolve(res.data);
  };

  const getTrustAccountByCode = async (
    code: string
  ): Promise<ClosedCorpEntityDetail> => {
    const res = await axiosInstance.get(
      baseUrl + `/getTrustAccountByCode?code=${code}`
    );
    return Promise.resolve(res.data);
  };

  const addNewTrustAccount = async (
    clientAccount: ClientAccount_CREATE
  ): Promise<any> => {
    const res = await axiosInstance.post(baseUrl + "/addNewTrustAccount", {
      accountName: clientAccount.accountName,
      contactNumber: clientAccount.contactNumber,
      contactPerson: clientAccount.contactPerson,
      contactPersonEmail: clientAccount.contactPersonEmail,
      dateOfRegistration: clientAccount.dateOfRegistration,
      country: clientAccount.country,
      email: clientAccount.email,
      branchTrader: clientAccount.branchTrader,
      enumStatus: clientAccount.enumStatus,
      deleted_At: clientAccount.deleted_At,
      staffAccount: clientAccount.staffAccount,
    });
    return Promise.resolve(res.data);
  };

  const updateTrustAccount = async (
    clientAccount: ClosedCorpEntityDetail
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/updateTrustAccount", {
      accountName: clientAccount.accountName,
      contactNumber: clientAccount.contactNumber,
      contactPerson: clientAccount.contactPerson,
      contactPersonEmail: clientAccount.contactPersonEmail,
      dateOfRegistration: clientAccount.dateOfRegistration,
      country: clientAccount.country,
      email: clientAccount.email,
      branchTrader: clientAccount.branchTrader,
      enumStatus: clientAccount.enumStatus,
      entityId: clientAccount.entityId,
      createdBy: clientAccount.createdBy,
      createdDate: clientAccount.createdDate,
      updatedBy: clientAccount.updatedBy,
      updatedDate: clientAccount.updatedDate,
      _id: clientAccount._id,
      deleted_At: clientAccount.deleted_At,
      staffAccount: clientAccount.staffAccount,
    });
    return Promise.resolve(res.data);
  };

  const deleteTrustAccount = async (
    clientAccount: ClosedCorpEntityDetail
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/deleteTrustAccount", {
      accountName: clientAccount.accountName,
      contactNumber: clientAccount.contactNumber,
      contactPerson: clientAccount.contactPerson,
      contactPersonEmail: clientAccount.contactPersonEmail,
      dateOfRegistration: clientAccount.dateOfRegistration,
      country: clientAccount.country,
      email: clientAccount.email,
      branchTrader: clientAccount.branchTrader,
      enumStatus: clientAccount.enumStatus,
      entityId: clientAccount.entityId,
      createdBy: clientAccount.createdBy,
      createdDate: clientAccount.createdDate,
      updatedBy: clientAccount.updatedBy,
      updatedDate: clientAccount.updatedDate,
      _id: clientAccount._id,
      deleted_At: clientAccount.deleted_At,
      staffAccount: clientAccount.staffAccount,
    });
    return Promise.resolve(res.data);
  };

  return {
    getAllTrustAccount,
    getTTOverview,
    getTrustAccountByCode,
    addNewTrustAccount,
    updateTrustAccount,
    deleteTrustAccount,
  };
};
export default TrustAccounts;
