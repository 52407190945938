
import { Component, Vue } from "vue-property-decorator";
import {
  UserAccessModel,
  UserProfile,
  UserSignatureUpdate,
  UserSignature,
  UserProfilewPass,
} from "models";
import User from "@/store/modules/user";
import Hooks from "@/hooks";
import dayjs from "dayjs";

const { UserProfiles, UserSignatures } = Hooks;
const userHook = UserProfiles();
const userSignatureHook = UserSignatures();

@Component({})
export default class UserProfileView extends Vue {
  profile: UserProfilewPass | null = null;
  dobMenu = false;
  valid = false;
  loading = false;
  editProfile = false;
  signatureUpload: File | null = null;
  storedDoc: any | null = null;
  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
    email: (value: string): boolean | string => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },
    id: (value: string): boolean | string =>
      value?.length == 13 || "Requires 13 digits",
    cell: (value: string | null = null): boolean | string =>
      value != null ? value.length >= 10 : "" || "Enter a valid Contact Number",
    fileSize: (value: { size: number }): boolean | string =>
      !value || value.size < 5000000 || "Max File Size 5MB!",
  };

  get getUsername(): string | null {
    return User.getLoggedInUser;
  }

  get getUserAccess(): UserAccessModel | null {
    console.log("UA: ", User.getUserAccess);
    return User.getUserAccess;
  }

  async mounted(): Promise<void> {
    await this.init();
  }

  async init(): Promise<void> {
    try {
      this.loading = true;
      await this.getProfile();
      await this.getStoredData();
    } finally {
      this.loading = false;
    }
  }

  async update(): Promise<void> {
    try {
      this.loading = true;
      await this.updateProfile();
      if (this.storedDoc != null) {
        await this.UpdateSignature();
      } else {
        await this.UploadSignature();
      }
      this.$notificationCreator.createSuccessNotification(
        "User Profile Updated."
      );
    } finally {
      await this.init();
    }
  }

  async getStoredData(): Promise<void> {
    try {
      var userId: string | null = null;
      if (this.profile != null) {
        userId = this.profile.userId;
      }

      if (userId != null) {
        const res = await userSignatureHook.GetUserSignatureDocumentsByUser(
          userId
        );
        if (res != null && res != undefined && res.length > 0) {
          this.storedDoc = res[0];
        }

        console.log("Stored Doc: ", this.storedDoc);
        return Promise.resolve();
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  }

  async getProfile(): Promise<void> {
    try {
      if (this.getUsername != null) {
        const res = await userHook.getUserProfileByEmail(this.getUsername);
        this.profile = res as UserProfilewPass;
        if (this.profile.dateOfBirth != null) {
          this.profile.dateOfBirth = dayjs(res.dateOfBirth).format(
            "YYYY-MM-DD"
          );
        } else {
          this.profile.dateOfBirth = "";
        }
        console.log("User Profile: ", this.profile);
        return Promise.resolve();
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getBase64(file: Blob | File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  async UploadSignature(): Promise<void> {
    try {
      var userProfileId: number | null = null;
      if (this.profile != null) {
        userProfileId = this.profile._id;
      }

      var fileArray = [];
      const promisesFile: Promise<string | ArrayBuffer | null>[] = [];
      fileArray.push(this.signatureUpload);
      fileArray.forEach(async (elem: any) => {
        promisesFile.push(this.getBase64(new Blob([elem])));
      });
      const resultFile = await Promise.all(promisesFile);

      var userSignature: UserSignature = {
        userProfileId: userProfileId,
        customFile:
          this.signatureUpload != null ? resultFile : this.signatureUpload,
      };

      console.log("Signature Upload: ", userSignature);
      const res = await userSignatureHook.UploadUserSignatureDocuments(
        userSignature
      );

      return Promise.resolve();
    } catch (err: any) {
      return Promise.reject(err);
    }
  }

  async UpdateSignature(): Promise<void> {
    try {
      var userProfileId: number | null = null;
      if (this.profile != null) {
        userProfileId = this.profile._id;
      }

      var resultFile: string | ArrayBuffer | null = null;
      if (this.signatureUpload != null) {
        resultFile = await this.getBase64(this.signatureUpload);
      }

      var userSignature: UserSignatureUpdate = {
        userProfileId: userProfileId,
        customFileId: this.storedDoc != null ? this.storedDoc._id : null,
        customFile:
          this.signatureUpload != null ? resultFile : this.signatureUpload,
      };

      console.log("Signature to be updated: ", userSignature);
      const res = await userSignatureHook.UpdateUserSignatureDocuments(
        userSignature
      );

      return Promise.resolve();
    } catch (err: any) {
      return Promise.reject(err);
    }
  }

  async download(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await userSignatureHook
          .DownloadUserSignatureDocuments(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async updateProfile(): Promise<void> {
    try {
      if (this.profile != null) {
        const res = await userHook.updateUserProfile(
          this.profile
        );
        return Promise.resolve();
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
}
