import { axiosInstance } from "@/plugins/axios";

const WelcomeLetter = () => {
  const baseUrl = "/api/WelcomeLetterAPI";

  const getPermitPDF = async (
    clientId: number,
    code: string,
    switchletter: number,
    jseCode: string | null,
    physicalsCode: string | null,
    safexCode: string | null,
    logoId: number
  ): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl +
        `/getWelcomePDF?clientId=${clientId}&code=${code}&switchLetter=${switchletter}&logoId=${logoId}&jseCode=${jseCode}&physicalsCode=${physicalsCode}&safexCode=${safexCode}&barcLogoId=3`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const getWelcomeCCPDF = async (
    clientId: number,
    code: string,
    switchletter: number,
    jseCode: string | null,
    physicalsCode: string | null,
    safexCode: string | null,
    logoId: number
  ): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl +
        `/getWelcomeCCPDF?clientId=${clientId}&code=${code}&switchLetter=${switchletter}&logoId=${logoId}&jseCode=${jseCode}&physicalsCode=${physicalsCode}&safexCode=${safexCode}&barcLogoId=3`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const getWelcomePartnerPDF = async (
    clientId: number,
    code: string,
    switchletter: number,
    jseCode: string | null,
    physicalsCode: string | null,
    safexCode: string | null,
    logoId: number
  ): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl +
        `/getWelcomePartnerPDF?clientId=${clientId}&code=${code}&switchLetter=${switchletter}&logoId=${logoId}&jseCode=${jseCode}&physicalsCode=${physicalsCode}&safexCode=${safexCode}&barcLogoId=3`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const getWelcomePCPDF = async (
    clientId: number,
    code: string,
    switchletter: number,
    jseCode: string | null,
    physicalsCode: string | null,
    safexCode: string | null,
    logoId: number
  ): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl +
        `/getWelcomePCPDF?clientId=${clientId}&code=${code}&switchLetter=${switchletter}&logoId=${logoId}&jseCode=${jseCode}&physicalsCode=${physicalsCode}&safexCode=${safexCode}&barcLogoId=3`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const getWelcomeTrustPDF = async (
    clientId: number,
    code: string,
    switchletter: number,
    jseCode: string | null,
    physicalsCode: string | null,
    safexCode: string | null,
    logoId: number
  ): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl +
        `/getWelcomeTrustPDF?clientId=${clientId}&code=${code}&switchLetter=${switchletter}&logoId=${logoId}&jseCode=${jseCode}&physicalsCode=${physicalsCode}&safexCode=${safexCode}&barcLogoId=3`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  return {
    getPermitPDF,
    getWelcomeCCPDF,
    getWelcomePCPDF,
    getWelcomePartnerPDF,
    getWelcomeTrustPDF,
  };
};
export default WelcomeLetter;
