import { axiosInstance } from "@/plugins/axios";
import { AccountDetail, ClientDetail, ClientDetail_CREATE } from "models";

const PrivateCompanyDetails = () => {
  const baseUrl = "/api/PrivateCompany";

  const getAllPrivateCompDetailPerson = async (): Promise<ClientDetail[]> => {
    const res = await axiosInstance.get(
      baseUrl + "/getAllPrivateCompDetailAsync"
    );
    return Promise.resolve(res.data);
  };

  const getPrivateCompDetailByCode = async (
    code: string
  ): Promise<ClientDetail> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPrivateCompDetailByCode?code=${code}`
    );
    return Promise.resolve(res.data);
  };

  const addNewPrivateCompDetails = async (
    clientDetail: ClientDetail_CREATE
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/addNewPrivateCompDetailAsync",
      clientDetail
    );
    return Promise.resolve(res.data);
  };

  const updatePrivateCompDetails = async (
    clientDetail: AccountDetail
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/updatePrivateCompDetailAsync",
      clientDetail
    );
    return Promise.resolve(res.data);
  };

  const deletePrivateCompDetails = async (
    clientDetail: AccountDetail
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/deletePrivateCompDetailAsync",
      clientDetail
    );
    return Promise.resolve(res.data);
  };

  return {
    getAllPrivateCompDetailPerson,
    addNewPrivateCompDetails,
    updatePrivateCompDetails,
    deletePrivateCompDetails,
    getPrivateCompDetailByCode,
  };
};
export default PrivateCompanyDetails;
