
import { Component, Vue, Watch } from "vue-property-decorator";
import dayjs from "dayjs";
import AddEditUser from "@/components/Appbar/Admin/Tabs/Users/AddEditUser.vue";
import { UserProfile, UserProfilewPass } from "models";
import Hooks from "@/hooks";

const { UserProfiles, HQAccess } = Hooks;
const UserProfilesHook = UserProfiles();
const HQAccesshook = HQAccess();

interface UserTable extends UserProfile {
  setup: string | null;
}

@Component({
  components: {
    AddEditUser,
  },
})
export default class Users extends Vue {
  items: UserTable[] = [];
  loading = false;
  search = "";
  sortDesc = false;
  sortBy = "firstName";
  modal = false;
  modalType: string | null = null;
  modalData: UserProfilewPass | null = null;
  headers = [
    {
      text: "Full Name",
      value: "firstName",
    },
    {
      text: "Email",
      value: "email",
    },
    {
      text: "Cell",
      value: "cellNumber",
    },
    {
      text: "Branch",
      value: "branchCode",
    },
    {
      text: "Role",
      value: "roleName",
    },
    {
      text: "Setup",
      value: "setup",
    },
    {
      text: "",
      value: "actions",
      sortable: false,
    },
  ];
  keys = [
    {
      text: "Full Name",
      value: "firstName",
    },
    {
      text: "Email",
      value: "email",
    },
    {
      text: "Cell",
      value: "cellNumber",
    },
    {
      text: "Branch",
      value: "branchCode",
    },
    {
      text: "Role",
      value: "roleName",
    },
    {
      text: "Setup",
      value: "setup",
      sortable: false,
    },
  ];

  openAdd(): void {
    this.modalType = "add";
    this.modalData = {
      _id: 0,
      password: "",
      userId: "",
      firstName: "",
      lastName: "",
      email: "",
      alternativeEmail: "",
      title: "",
      cellNumber: "",
      organisation: "",
      occupation: "",
      branchCode: "",
      saId: "",
      passportNo: "",
      dateOfBirth: "",
      gender: "",
      roleName: "",
      homeTel: "",
      workTel: "",
      maritalStatus: "",
      language: "",
      province: "",
      street: "",
      city: "",
      createdBy: "",
      createdDate: "",
    } as UserProfilewPass;

    this.modal = true;
  }

  openEdit(i: UserProfilewPass): void {
    console.log("Item to be Edited: ", i);
    let data: UserProfilewPass = {
      _id: i._id,
      password: i.password,
      userId: i.userId,
      firstName: i.firstName,
      lastName: i.lastName,
      email: i.email,
      roleName: i.roleName,
      alternativeEmail: i.alternativeEmail,
      title: i.title,
      cellNumber: i.cellNumber,
      organisation: i.organisation,
      occupation: i.occupation,
      branchCode: i.branchCode,
      saId: i.saId,
      passportNo: i.passportNo,
      dateOfBirth: dayjs(i.dateOfBirth).format("YYYY-MM-DD"),
      gender: i.gender,
      homeTel: i.homeTel,
      workTel: i.workTel,
      maritalStatus: i.maritalStatus,
      language: i.language,
      province: i.province,
      street: i.street,
      city: i.city,
      createdBy: i.createdBy,
      createdDate: i.createdDate,
      updatedBy: i.updatedBy,
      updatedDate: i.updatedDate,
    };
    this.modalData = data;
    this.modalType = "edit";
    this.modal = true;
  }

  @Watch("modal", { immediate: true })
  async modalChanged(): Promise<void> {
    if (this.modal == false) {
      this.modalType = null;
      this.modalData = null;
    }
  }

  async mounted(): Promise<void> {
    await this.init();
  }

  async init(): Promise<void> {
    try {
      this.loading = true;
      this.items = [];
      const res = await UserProfilesHook.getAllUsers();
      this.items = [];
      res.map(async (elem) => {
        const resHQ = await HQAccesshook.getAllHQAccessByUser(elem.userId);
        this.items.push({
          _id: elem._id,
          userId: elem.userId,
          firstName: elem.firstName,
          lastName: elem.lastName,
          email: elem.email,
          alternativeEmail: elem.alternativeEmail,
          title: elem.title,
          cellNumber: elem.cellNumber,
          organisation: elem.organisation,
          occupation: elem.occupation,
          branchCode: elem.branchCode,
          saId: elem.saId,
          passportNo: elem.passportNo,
          dateOfBirth: elem.dateOfBirth,
          gender: elem.gender,
          roleName: elem.roleName,
          homeTel: elem.homeTel,
          workTel: elem.workTel,
          maritalStatus: elem.maritalStatus,
          language: elem.language,
          province: elem.province,
          street: elem.street,
          city: elem.city,
          createdBy: elem.createdBy,
          createdDate: elem.createdDate,
          setup: resHQ.setup,
          updatedBy: elem.updatedBy,
          updatedDate: elem.updatedDate,
        });
      });

      console.log("Users", this.items);
      return Promise.resolve();
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
