import { axiosInstance } from "@/plugins/axios";
import { ClientBanking, ClientBanking_CREATE, AccountBanking } from "models";

const TrustBankings = () => {
  const baseUrl = "/api/Trust";

  const getAllTrustBanking = async (): Promise<ClientBanking[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAllTrustBanking");
    return Promise.resolve(res.data);
  };

  const getTrustBankingByCode = async (
    code: string
  ): Promise<ClientBanking> => {
    const res = await axiosInstance.get(
      baseUrl + `/getTrustBankingByCode?code=${code}`
    );
    return Promise.resolve(res.data);
  };

  const addNewTrustBanking = async (
    clientBanking: ClientBanking_CREATE
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/addNewTrustBanking", {
      bankId: clientBanking.bankId,
      accountType: clientBanking.accountType,
      branchName: clientBanking.branchName,
      accountHolder: clientBanking.accountHolder,
      branchCode: clientBanking.branchCode,
      bankSwiftNumber: clientBanking.bankSwiftNumber,
      accountNumber: clientBanking.accountNumber,
      bankIBANNumber: clientBanking.bankIBANNumber,
      entityId: clientBanking.entityId,
    });
    return Promise.resolve(res.data);
  };

  const updateTrustBanking = async (
    clientBanking: AccountBanking
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/updateTrustBanking", {
      bankId: clientBanking.bankId,
      accountType: clientBanking.accountType,
      branchName: clientBanking.branchName,
      accountHolder: clientBanking.accountHolder,
      branchCode: clientBanking.branchCode,
      bankSwiftNumber: clientBanking.bankSwiftNumber,
      accountNumber: clientBanking.accountNumber,
      bankIBANNumber: clientBanking.bankIBANNumber,
      entityId: clientBanking.entityId,
      createdBy: clientBanking.createdBy,
      createdDate: clientBanking.createdDate,
      clientBankId: clientBanking.clientBankId,
      updatedBy: clientBanking.updatedBy,
      updatedDate: clientBanking.updatedDate,
      _id: clientBanking._id,
    });
    return Promise.resolve(res.data);
  };

  const deleteTrustBanking = async (
    clientBanking: AccountBanking
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/deleteTrustBanking", {
      bankId: clientBanking.bankId,
      accountType: clientBanking.accountType,
      branchName: clientBanking.branchName,
      accountHolder: clientBanking.accountHolder,
      branchCode: clientBanking.branchCode,
      bankSwiftNumber: clientBanking.bankSwiftNumber,
      accountNumber: clientBanking.accountNumber,
      bankIBANNumber: clientBanking.bankIBANNumber,
      entityId: clientBanking.entityId,
      createdBy: clientBanking.createdBy,
      createdDate: clientBanking.createdDate,
      clientBankId: clientBanking.clientBankId,
      updatedBy: clientBanking.updatedBy,
      updatedDate: clientBanking.updatedDate,
      _id: clientBanking._id,
    });
    return Promise.resolve(res.data);
  };

  return {
    getAllTrustBanking,
    getTrustBankingByCode,
    addNewTrustBanking,
    updateTrustBanking,
    deleteTrustBanking,
  };
};
export default TrustBankings;
