import { axiosInstance } from "@/plugins/axios";
import { ClientBanking, ClientBanking_CREATE } from "models";

const ClientBankings = () => {
  const baseUrl = "/api/ClientBank";

  const getAllClientBanks = async (): Promise<ClientBanking[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAllClientBanks");
    return Promise.resolve(res.data);
  };

  const getClientBankByClient = async (
    clientId: string
  ): Promise<ClientBanking> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClientBankByClient?clientId=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const addClientBank = async (
    clientBanking: ClientBanking_CREATE
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/addClientBank", {
      bankId: clientBanking.bankId,
      accountType: clientBanking.accountType,
      branchName: clientBanking.branchName,
      accountHolder: clientBanking.accountHolder,
      branchCode: clientBanking.branchCode,
      bankSwiftNumber: clientBanking.bankSwiftNumber,
      accountNumber: clientBanking.accountNumber,
      bankIBANNumber: clientBanking.bankIBANNumber,
      entityId: clientBanking.entityId,
    });
    return Promise.resolve(res.data);
  };

  const updateClientBank = async (
    clientBanking: ClientBanking
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/updateClientBank", {
      bankId: clientBanking.bankId,
      accountType: clientBanking.accountType,
      branchName: clientBanking.branchName,
      accountHolder: clientBanking.accountHolder,
      branchCode: clientBanking.branchCode,
      bankSwiftNumber: clientBanking.bankSwiftNumber,
      accountNumber: clientBanking.accountNumber,
      bankIBANNumber: clientBanking.bankIBANNumber,
      entityId: clientBanking.entityId,
      createdBy: clientBanking.createdBy,
      createdDate: clientBanking.createdDate,
      clientBankId: clientBanking.clientBankId,
      updatedBy: clientBanking.updatedBy,
      updatedDate: clientBanking.updatedDate,
      _id: {
        creationTime: clientBanking._id.creationTime,
        increment: clientBanking._id.increment,
        machine: clientBanking._id.machine,
        pid: clientBanking._id.pid,
        timestamp: clientBanking._id.timestamp,
      },
    });
    return Promise.resolve(res.data);
  };

  const deleteClientBank = async (
    clientBanking: ClientBanking
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/deleteClientBank", {
      bankId: clientBanking.bankId,
      accountType: clientBanking.accountType,
      branchName: clientBanking.branchName,
      accountHolder: clientBanking.accountHolder,
      branchCode: clientBanking.branchCode,
      bankSwiftNumber: clientBanking.bankSwiftNumber,
      accountNumber: clientBanking.accountNumber,
      bankIBANNumber: clientBanking.bankIBANNumber,
      entityId: clientBanking.entityId,
      createdBy: clientBanking.createdBy,
      createdDate: clientBanking.createdDate,
      clientBankId: clientBanking.clientBankId,
      updatedBy: clientBanking.updatedBy,
      updatedDate: clientBanking.updatedDate,
      _id: {
        creationTime: clientBanking._id.creationTime,
        increment: clientBanking._id.increment,
        machine: clientBanking._id.machine,
        pid: clientBanking._id.pid,
        timestamp: clientBanking._id.timestamp,
      },
    });
    return Promise.resolve(res.data);
  };

  return {
    getAllClientBanks,
    getClientBankByClient,
    addClientBank,
    updateClientBank,
    deleteClientBank,
  };
};
export default ClientBankings;
