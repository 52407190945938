import Users from "./User/user";
import UserProfiles from "./User/userProfile";
import UserAccess from "./User/userAccess";
import UserSignatures from "./User/userSignature";
import HQAccess from "./hqAccess";

import Account from "./account";

import AssignRoles from "./AssignRole";

import Traders from "./traders";
import Banks from "./bank";
import Markets from "./markets";
import SyncClients from "./SyncClients";
import RatingCodes from "./ratingCode";

import FeeStructures from "./feeStructure";
import SafexDivisions from "./safexDivision";

import ClientAccounts from "./Individual/clientAccount";
import ClientDetails from "./Individual/clientDetails";
import ClientBank from "./Individual/clientBanking";
import ClientCommunications from "./Individual/clientCommunication";
import ClientKYCs from "./Individual/clientkyc";
import ClientDocument from "./Individual/clientDocuments";
import ClientDivision from "./Individual/clientDivision";

import ClosedCorpEntityDetails from "./ClosedCorp/closedCorpEntityDetails";
import ClosedCorpBankingDetals from "./ClosedCorp/closedCorpBankingDetails";
import ClosedCorpDetails from "./ClosedCorp/closedCorpDetails";
import ClosedCorpCommunications from "./ClosedCorp/closedCorpCommunications";
import ClosedCorpDivision from "./ClosedCorp/closedCorpDivision";
import ClosedCorpDocuments from "./ClosedCorp/closedCorpDocuments";
import ClosedCorpKYCs from "./ClosedCorp/closedCorpKYC";

import TrustAccount from "./Trusts/trustAccount";
import TrustDetails from "./Trusts/trustDetails";
import TrustBanking from "./Trusts/trustBanking";
import TrustCommunication from "./Trusts/trustCommunication";
import TrustKYC from "./Trusts/trustKYC";
import TrustDocuments from "./Trusts/trustDocuments";
import TrustDivision from "./Trusts/trustDivision";

import PartnershipAccount from "./Partnerships/partnershipAccount";
import PartnershipDetails from "./Partnerships/partnershipDetails";
import PartnershipBanking from "./Partnerships/partnershipBanking";
import PartnershipCommunication from "./Partnerships/partnershipCommunication";
import PartnershipKYC from "./Partnerships/partnershipKYC";
import PartnershipDivision from "./Partnerships/partnershipDivision";
import PartnershipDocuments from "./Partnerships/partnershipDocuments";

import PrivateAccount from "./Private/privateAccount";
import PrivateDetails from "./Private/privateDetails";
import PrivateBanking from "./Private/privateBanking";
import PrivateCommunication from "./Private/privateCommunication";
import PrivateKYC from "./Private/privateKYC";
import PrivateDivision from "./Private/privateDivision";
import PrivateDocuments from "./Private/privateDocuments";

import WelcomeLetter from "./welcomeLetter";

import CustomFile from "./customFile";

import Excel from "./Excel";
import ExcelFiles from "./excelFile";
import AccountTransfersPDF from "./AccountTransfersPDF";
import Amend from "./amend";
import AmendAccountPDF from "./amendAccountPDF";
import Clearing_JSE_Fees from "./clearing&jseFees";

import Audit from "./Audit";

import JSEClient from "./jseClient"

export default {
  Users,
  UserAccess,
  UserProfiles,
  AssignRoles,
  UserSignatures,
  HQAccess,

  Account,

  Traders,
  Banks,
  Markets,
  SyncClients,

  FeeStructures,
  SafexDivisions,

  ClientAccounts,
  ClientDetails,
  ClientBank,
  ClientCommunications,
  RatingCodes,
  ClientKYCs,
  ClientDocument,
  ClientDivision,

  ClosedCorpEntityDetails,
  ClosedCorpBankingDetals,
  ClosedCorpDetails,
  ClosedCorpCommunications,
  ClosedCorpDivision,
  ClosedCorpDocuments,
  ClosedCorpKYCs,

  TrustAccount,
  TrustDetails,
  TrustBanking,
  TrustCommunication,
  TrustKYC,
  TrustDocuments,
  TrustDivision,

  PartnershipAccount,
  PartnershipDetails,
  PartnershipBanking,
  PartnershipCommunication,
  PartnershipKYC,
  PartnershipDivision,
  PartnershipDocuments,

  PrivateAccount,
  PrivateDetails,
  PrivateBanking,
  PrivateCommunication,
  PrivateKYC,
  PrivateDivision,
  PrivateDocuments,

  WelcomeLetter,

  CustomFile,

  Excel,
  ExcelFiles,
  AccountTransfersPDF,
  Amend,
  AmendAccountPDF,
  Clearing_JSE_Fees,

  Audit,

  JSEClient,
};
