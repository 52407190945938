import { axiosInstance } from "@/plugins/axios";
import { ClientKYC, ClientKYC_CREATE } from "models";

const ClientKYCs = () => {
  const baseUrl = "/api/ClientKYC";

  const getAllClientKYC = async (): Promise<ClientKYC[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAllClientKYC");
    return Promise.resolve(res.data);
  };

  const getClientKYCByClient = async (clientId: string): Promise<ClientKYC> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClientKYCByClient?clientId=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const addNewClientKYC = async (clientKYC: ClientKYC_CREATE): Promise<any> => {
    const res = await axiosInstance.post(baseUrl + "/addNewClientKYC", {
      entityId: clientKYC.entityId,
      sourceOfIncome: clientKYC.sourceOFIncome,
      currentEarningsSector: clientKYC.currentEarningsSector,
      experienceLevel: clientKYC.experienceLevel,
      accountFunds: clientKYC.accountFunds,
      incomeBand: clientKYC.incomeBand,
      riskAppetite: clientKYC.riskAppetite,
      dpip: clientKYC.dpip,
      job: clientKYC.job,
      geoLocation: clientKYC.geoLocation,
      influentialPerson: clientKYC.influentialPerson,
    });
    return Promise.resolve(res.data);
  };

  const updateClientKYC = async (clientKYC: ClientKYC): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/updateClientKYC", {
      entityId: clientKYC.entityId,
      sourceOfIncome: clientKYC.sourceOFIncome,
      currentEarningsSector: clientKYC.currentEarningsSector,
      investmentExperience: clientKYC.investmentExperience,
      accountFunds: clientKYC.accountFunds,
      incomeBand: clientKYC.incomeBand,
      riskAppetite: clientKYC.riskAppetite,
      dpip: clientKYC.dpip,
      job: clientKYC.job,
      geoLocation: clientKYC.geoLocation,
      influentialPerson: clientKYC.influentialPerson,
      createdBy: clientKYC.createdBy,
      createdDate: clientKYC.createdDate,
      id: clientKYC.id,
      updatedBy: clientKYC.updatedBy,
      updatedDate: clientKYC.updatedDate,
      _id: {
        creationTime: clientKYC._id.creationTime,
        increment: clientKYC._id.increment,
        machine: clientKYC._id.machine,
        pid: clientKYC._id.pid,
        timestamp: clientKYC._id.timestamp,
      },
    });
    return Promise.resolve(res.data);
  };

  const deleteClientKYC = async (clientKYC: ClientKYC): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/deleteChildAuthorised", {
      entityId: clientKYC.entityId,
      sourceOfIncome: clientKYC.sourceOFIncome,
      currentEarningsSector: clientKYC.currentEarningsSector,
      investmentExperience: clientKYC.investmentExperience,
      accountFunds: clientKYC.accountFunds,
      incomeBand: clientKYC.incomeBand,
      riskAppetite: clientKYC.riskAppetite,
      dpip: clientKYC.dpip,
      job: clientKYC.job,
      geoLocation: clientKYC.geoLocation,
      influentialPerson: clientKYC.influentialPerson,
      createdBy: clientKYC.createdBy,
      createdDate: clientKYC.createdDate,
      id: clientKYC.id,
      updatedBy: clientKYC.updatedBy,
      updatedDate: clientKYC.updatedDate,
      _id: {
        creationTime: clientKYC._id.creationTime,
        increment: clientKYC._id.increment,
        machine: clientKYC._id.machine,
        pid: clientKYC._id.pid,
        timestamp: clientKYC._id.timestamp,
      },
    });
    return Promise.resolve(res.data);
  };

  return {
    getAllClientKYC,
    getClientKYCByClient,
    addNewClientKYC,
    updateClientKYC,
    deleteClientKYC,
  };
};
export default ClientKYCs;
