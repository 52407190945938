import { axiosInstance } from "@/plugins/axios";
import { ClientDivisionModel, ClientDivision_CREATE } from "models";

const ClientDivisions = () => {
  const baseUrl = "/api/ClientDivision";

  const getAllClientDivisions = async (): Promise<ClientDivisionModel[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAllClientDivisions");
    return Promise.resolve(res.data);
  };

  const getAllClientDivisionsByCode = async (
    clientId: string
  ): Promise<ClientDivisionModel[]> => {
    const res = await axiosInstance.get(
      baseUrl + `/getAllClientDivisionsByCode?code=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getClientDivisionByClient = async (
    clientId: string
  ): Promise<ClientDivisionModel> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClientDivisionByClient?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const addClientDivision = async (
    clientDivision: ClientDivision_CREATE
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/addClientDivision", {
      safexCode: clientDivision.safexCode,
      accountingCode: clientDivision.accountingCode,
      physicalCode: clientDivision.physicalCode,
      safexSubAccount: clientDivision.safexSubAccount,
      accountingSubAccount: clientDivision.accountingSubAccount,
      safexMarket: clientDivision.safexMarket,
      jseMarket: clientDivision.jseMarket,
      physicalsMarket: clientDivision.physicalsMarket,
      safexTrader: clientDivision.safexTrader,
      physicalsTrader: clientDivision.physicalsTrader,
      jseTrader: clientDivision.jseTrader,
      safexTraderId: clientDivision.safexTraderId,
      physicalsTraderId: clientDivision.physicalsTraderId,
      jseTraderId: clientDivision.jseTraderId,
      entityId: clientDivision.entityId,
      discretionaryManaged: clientDivision.discretionaryManaged,
      electronicAccNumber: clientDivision.electronicAccNumber,
      isBeneficial: clientDivision.isBeneficial,
    });
    return Promise.resolve(res.data);
  };

  const updateClientDivision = async (
    clientDivision: ClientDivisionModel
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/updateClientDivision", {
      safexCode: clientDivision.safexCode,
      accountingCode: clientDivision.accountingCode,
      physicalCode: clientDivision.physicalCode,
      safexSubAccount: clientDivision.safexSubAccount,
      accountingSubAccount: clientDivision.accountingSubAccount,
      safexMarket: clientDivision.safexMarket,
      jseMarket: clientDivision.jseMarket,
      physicalsMarket: clientDivision.physicalsMarket,
      safexTrader: clientDivision.safexTrader,
      physicalsTrader: clientDivision.physicalsTrader,
      jseTrader: clientDivision.jseTrader,
      safexTraderId: clientDivision.safexTraderId,
      physicalsTraderId: clientDivision.physicalsTraderId,
      jseTraderId: clientDivision.jseTraderId,
      entityId: clientDivision.entityId,
      id: clientDivision.id,
      createdBy: clientDivision.createdBy,
      createdDate: clientDivision.createdDate,
      updatedBy: clientDivision.updatedBy,
      updatedDate: clientDivision.updateDate,
      _id: clientDivision._id,
      discretionaryManaged: clientDivision.discretionaryManaged,
      electronicAccNumber: clientDivision.electronicAccNumber,
      isBeneficial: clientDivision.isBeneficial,
    });
    return Promise.resolve(res.data);
  };

  const deleteClientDivision = async (
    clientDivision: ClientDivisionModel
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/deleteClientDivision", {
      safexCode: clientDivision.safexCode,
      accountingCode: clientDivision.accountingCode,
      physicalCode: clientDivision.physicalCode,
      safexSubAccount: clientDivision.safexSubAccount,
      accountingSubAccount: clientDivision.accountingSubAccount,
      safexMarket: clientDivision.safexMarket,
      jseMarket: clientDivision.jseMarket,
      physicalsMarket: clientDivision.physicalsMarket,
      safexTrader: clientDivision.safexTrader,
      physicalsTrader: clientDivision.physicalsTrader,
      jseTrader: clientDivision.jseTrader,
      safexTraderId: clientDivision.safexTraderId,
      physicalsTraderId: clientDivision.physicalsTraderId,
      jseTraderId: clientDivision.jseTraderId,
      entityId: clientDivision.entityId,
      id: clientDivision.id,
      createdBy: clientDivision.createdBy,
      createdDate: clientDivision.createdDate,
      updatedBy: clientDivision.updatedBy,
      updatedDate: clientDivision.updateDate,
      _id: clientDivision._id,
      discretionaryManaged: clientDivision.discretionaryManaged,
      electronicAccNumber: clientDivision.electronicAccNumber,
      isBeneficial: clientDivision.isBeneficial,
    });
    return Promise.resolve(res.data);
  };

  return {
    getAllClientDivisions,
    getClientDivisionByClient,
    getAllClientDivisionsByCode,
    addClientDivision,
    updateClientDivision,
    deleteClientDivision,
  };
};
export default ClientDivisions;
