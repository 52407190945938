
import { Component, Vue, Ref } from "vue-property-decorator";
import Notifications from "@/store/modules/notifications";
import { notification } from "models";
import dayjs from "dayjs";

@Component
export default class App extends Vue {
  get getNotifications(): notification[] {
    // return [];
    return Notifications.getNotifications;
  }
  @Ref() readonly snackbars!: Vue[];
  removeSnackbar(snackbar: notification): void {
    Notifications.removeSnackbar(snackbar);
  }
  previousHeight(index: number): number {
    console.log("Index ", index, typeof this.snackbars);
    if (this.snackbars) {
      if (this.snackbars.length > 0) {
        let temp = 0;
        const myPadding = 15;
        this.snackbars.forEach((el, i) => {
          if (i >= index) return;
          temp += el.$el.children[0].clientHeight + myPadding;
        });
        return temp;
      }
    }
    return 0;
  }

  routecheck(): boolean {
    const routename = this.$route.name;
    if (routename == "Auth") {
      return true;
    } else return false;
  }

  getYear(): number {
    return dayjs().year();
  }
}
