import { axiosInstance } from "@/plugins/axios";
import {
  ClosedCorpEntityDetail,
  ClosedCorpEntityDetails_CREATE,
  ClientOverview,
} from "models";

const ClosedCorpEntityDetail = () => {
  const baseUrl = "/api/ClosedCorpAccount";

  const getAllClosedCorp = async (): Promise<ClosedCorpEntityDetail[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAllClosedCorp");
    return Promise.resolve(res.data);
  };

  const getClosedCorpByCode = async (
    code: string
  ): Promise<ClosedCorpEntityDetail> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClosedCorpByCode?code=${code}`
    );
    return Promise.resolve(res.data);
  };

  const getOverview = async (): Promise<ClientOverview[]> => {
    const res = await axiosInstance.get(baseUrl + "/getCCOverview");
    return Promise.resolve(res.data);
  };

  const addNewClosedCorp = async (
    closedCorpEntity: ClosedCorpEntityDetails_CREATE
  ): Promise<any> => {
    const res = await axiosInstance.post(baseUrl + "/addNewClosedCorp", {
      accountName: closedCorpEntity.accountName,
      contactNumber: closedCorpEntity.contactNumber,
      contactPerson: closedCorpEntity.contactPerson,
      contactPersonEmail: closedCorpEntity.contactPersonEmail,
      dateOfRegistration: closedCorpEntity.dateOfRegistration,
      country: closedCorpEntity.country,
      email: closedCorpEntity.email,
      branchTrader: closedCorpEntity.branchTrader,
      enumStatus: closedCorpEntity.enumStatus,
      deleted_At: closedCorpEntity.deleted_At,
      staffAccount: closedCorpEntity.staffAccount,
    });
    return Promise.resolve(res.data);
  };

  const updateClosedCorp = async (
    closedCorpEntity: ClosedCorpEntityDetail
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/updateClosedCorp", {
      accountName: closedCorpEntity.accountName,
      contactNumber: closedCorpEntity.contactNumber,
      contactPerson: closedCorpEntity.contactPerson,
      contactPersonEmail: closedCorpEntity.contactPersonEmail,
      dateOfRegistration: closedCorpEntity.dateOfRegistration,
      country: closedCorpEntity.country,
      email: closedCorpEntity.email,
      branchTrader: closedCorpEntity.branchTrader,
      enumStatus: closedCorpEntity.enumStatus,
      entityId: closedCorpEntity.entityId,
      createdBy: closedCorpEntity.createdBy,
      createdDate: closedCorpEntity.createdDate,
      updatedBy: closedCorpEntity.updatedBy,
      updatedDate: closedCorpEntity.updatedDate,
      _id: closedCorpEntity._id,
      deleted_At: closedCorpEntity.deleted_At,
      staffAccount: closedCorpEntity.staffAccount,
    });
    return Promise.resolve(res.data);
  };

  const deleteClosedCorp = async (
    closedCorpEntity: ClosedCorpEntityDetail
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/deleteClosedCorp", {
      accountName: closedCorpEntity.accountName,
      contactNumber: closedCorpEntity.contactNumber,
      contactPerson: closedCorpEntity.contactPerson,
      contactPersonEmail: closedCorpEntity.contactPersonEmail,
      dateOfRegistration: closedCorpEntity.dateOfRegistration,
      country: closedCorpEntity.country,
      email: closedCorpEntity.email,
      branchTrader: closedCorpEntity.branchTrader,
      entityId: closedCorpEntity.entityId,
      createdBy: closedCorpEntity.createdBy,
      createdDate: closedCorpEntity.createdDate,
      enumStatus: closedCorpEntity.enumStatus,
      updatedBy: closedCorpEntity.updatedBy,
      updatedDate: closedCorpEntity.updatedDate,
      _id: closedCorpEntity._id,
      deleted_At: closedCorpEntity.deleted_At,
      staffAccount: closedCorpEntity.staffAccount,
    });
    return Promise.resolve(res.data);
  };

  return {
    getAllClosedCorp,
    getClosedCorpByCode,
    getOverview,
    addNewClosedCorp,
    deleteClosedCorp,
    updateClosedCorp,
  };
};
export default ClosedCorpEntityDetail;
