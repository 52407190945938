
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { ClientKYC_CREATE } from "models";
import Hooks from "@/hooks";

const { ClientKYCs } = Hooks;
const kycHook = ClientKYCs();

@Component({})
export default class KYCFICA extends Vue {
  @PropSync("valid") formValid!: boolean;
  @PropSync("next") accountNext!: boolean;
  @Prop() type!: string;
  loading = false;
  dataCheck = false;
  clientKYC: ClientKYC_CREATE = {
    entityId: null,
    sourceOFIncome: null,
    currentEarningsSector: null,
    experienceLevel: null,
    accountFunds: null,
    incomeBand: null,
    riskAppetite: null,
    dpip: false,
    job: null,
    geoLocation: null,
    influentialPerson: null,
  };
  geoLocationOther: string | null = null;
  jobOther: string | null = null;
  domesticList = [
    "Head of State/Head of Government/Cabinet Minister",
    "Influential Functionary in Government",
    "Member of a Royal Family",
    "Senior Judge",
    "Senior or Influential Representative of Religious Organisation",
    "Senior Political Party Official/Functionary or Military Leader",
    "No affiliation",
  ];
  jobTitleList = [
    "Admin clerk",
    "Accountant",
    "Artist",
    "Auditor",
    "Beautician",
    "Businessman",
    "Businesswoman",
    "Chemist",
    "Company director",
    "Doctor",
    "Farmer",
    "Financial Manager",
    "Housewife",
    "Insurance broker",
    "JSE trader",
    "Lawyer",
    "Musician",
    "Teacher",
    "Veterinarian",
    "Other (insert name of occupation)",
  ];
  geoLocationList = [
    "RSA",
    "Botswana",
    "Namibia",
    "Zimbabwe",
    "Other (insert name of country)",
  ];
  investmentExperienceList = [
    "Entry-Level (Beginner, basic knowledge low interaction)",
    "Intermediate (Limited knowledge mid level interaction)",
    "Mid-level (Broader knowledge)",
    "Senior or executive-level (Expert level)",
  ];
  currentEarningsSectorList = [
    "Accommodation and food service activities",
    "Activities of households as employers; undifferentiated goods-and-service-producing activities of households for own use",
    "Activities of extraterritorial organizations and bodies, not economically active people, unemployed people etc.",
    "Administrative and support service activities",
    "Agriculture, forestry and fishing",
    "Arts, entertainment and recreation",
    "Construction",
    "Education",
    "Electricity, gas, steam and air conditioning supply",
    "Financial and insurance activities",
    "Human health and social work activities",
    "Information and communication",
    "Manufacturing",
    "Mining and quarrying",
    "Professional, scientific and technical activities",
    "Public administration and defense; compulsory social security",
    "Real estate activities",
    "Transportation and storage",
    "Water supply; sewerage, waste management and remediation activities",
    "Wholesale and retail trade; repair of motor vehicles and motorcycles",
    "Other service activities",
  ];
  sourceOfIncomeList = [
    "Allowance",
    "Bonus (salaried employee)",
    "Business income (operating profits)",
    "Capital gain (sale of assets; sale of physical stock)",
    "Commission-based income",
    "Dividends paid (stocks & shares)",
    "Donation/Gift",
    "Farming income",
    "Inheritance",
    "Investment income",
    "Maintenance/Alimony",
    "Pension",
    "Rental income",
    "Retirement annuity",
    "Salary/Wages",
  ];
  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
  };

  @Watch("type", { immediate: true })
  async modalTypeChanged(): Promise<void> {
    console.log("Modal Details Type: ", this.type);

    if (this.type == "edit") {
      await this.getStoredData();
    }
  }

  @Watch("accountNext", {
    immediate: true,
  })
  async onNextChanged(): Promise<void> {
    console.log("AccountNext KYC", this.accountNext);
    if (this.accountNext == true) {
      if (this.type == "add") {
        await this.addNewClientKYC();
      }
      if (this.type == "edit") {
        if (this.dataCheck == true) {
          await this.editClientKYC();
        } else {
          await this.addNewClientKYC();
        }
      }
    }
  }

  async getStoredData(): Promise<void> {
    try {
      this.loading = true;
      var entityId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        entityId = key;
      }

      if (entityId != null) {
        const res = await kycHook.getClientKYCByClient(entityId);

        console.log("Get Stored Data res: ", res);
        if (res != undefined && (res as any) != "") {
          this.dataCheck = true;
          this.clientKYC = res as any;
          if (res.geoLocation != null) {
            var resGeo = this.geoLocationList.find(
              (elem) => elem == res.geoLocation
            );
            console.log("resGeo", resGeo);
            if (resGeo == undefined) {
              this.geoLocationOther = res.geoLocation;
              this.clientKYC.geoLocation = "Other (insert name of country)";
            } else {
              this.clientKYC.geoLocation = res.geoLocation;
            }
          }
          if (res.job != null) {
            var resJob = this.jobTitleList.find((elem) => elem == res.job);
            console.log("resDoresJobmestic", resJob);
            if (resGeo == undefined) {
              this.jobOther = res.job;
              this.clientKYC.job = "Other (insert name of occupation)";
            } else {
              this.clientKYC.job = res.job;
            }
          }
        } else {
          this.dataCheck = false;
        }
        this.formValid = true;
        return Promise.resolve();
      }
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async addNewClientKYC(): Promise<void> {
    try {
      this.loading = true;
      const key = localStorage.getItem("ClientFile/entityId");

      if (key != null) {
        this.clientKYC.entityId = key;
      }

      console.log("KYC Details: ", this.clientKYC);

      this.clientKYC.dpip == null
        ? (this.clientKYC.dpip = false)
        : this.clientKYC.dpip;

      if (this.clientKYC.job == "Other (insert name of occupation)") {
        this.clientKYC.job = this.jobOther;
      }
      if (this.clientKYC.geoLocation == "Other (insert name of country)") {
        this.clientKYC.geoLocation = this.geoLocationOther;
      }

      const res = await kycHook.addNewClientKYC(this.clientKYC);

      this.$notificationCreator.createSuccessNotification("KYC Details Saved");

      this.$emit("close");
      return Promise.resolve();
    } catch (err: any) {
      this.accountNext = false;
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async editClientKYC(): Promise<void> {
    try {
      this.loading = true;
      console.log("Personal Details: ", this.clientKYC);
      if (this.clientKYC.job == "Other (insert name of occupation)") {
        this.clientKYC.job = this.jobOther;
      }
      if (this.clientKYC.geoLocation == "Other (insert name of country)") {
        this.clientKYC.geoLocation = this.geoLocationOther;
      }
      const res = await kycHook.updateClientKYC(this.clientKYC as any);
      this.$notificationCreator.createSuccessNotification(
        "KYC Details Updated."
      );
      return Promise.resolve();
    } catch (err: any) {
      //this.accountNext = false;
      return Promise.reject(err);
    } finally {
      this.accountNext = false;
      this.loading = false;
    }
  }
}
