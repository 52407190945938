import { axiosInstance } from "@/plugins/axios";
import { ClientCommunication, ClientCommunication_CREATE } from "models";

const ClientCommunications = () => {
  const baseUrl = "/api/ClientCommunications";

  const getAllClientCommunication = async (): Promise<
    ClientCommunication[]
  > => {
    const res = await axiosInstance.get(baseUrl + "/getAllClientCommunication");
    return Promise.resolve(res.data);
  };

  const getClientCommunicationByClient = async (
    clientId: string
  ): Promise<ClientCommunication> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClientCommunicationByClient?clientId=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const addNewClientCommunication = async (
    clientCommunication: ClientCommunication_CREATE
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/addNewClientCommunication",
      {
        contactName: clientCommunication.contactName,
        emailAddress: clientCommunication.emailAddress,
        mobileNumber: clientCommunication.mobileNumber,
        entityId: clientCommunication.entityId,
      }
    );
    return Promise.resolve(res.data);
  };

  const updateClientCommunication = async (
    clientCommunication: ClientCommunication
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/updateClientCommunication",
      {
        contactName: clientCommunication.contactName,
        emailAddress: clientCommunication.emailAddress,
        mobileNumber: clientCommunication.mobileNumber,
        entityId: clientCommunication.entityId,
        createdBy: clientCommunication.createdBy,
        createdDate: clientCommunication.createdDate,
        clientComId: clientCommunication.clientComId,
        updatedBy: clientCommunication.updatedBy,
        updatedDate: clientCommunication.updatedDate,
        _id: {
          creationTime: clientCommunication._id.creationTime,
          increment: clientCommunication._id.increment,
          machine: clientCommunication._id.machine,
          pid: clientCommunication._id.pid,
          timestamp: clientCommunication._id.timestamp,
        },
      }
    );
    return Promise.resolve(res.data);
  };

  const deleteClientCommunication = async (
    clientCommunication: ClientCommunication
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/deleteClientCommunication",
      {
        contactName: clientCommunication.contactName,
        emailAddress: clientCommunication.emailAddress,
        mobileNumber: clientCommunication.mobileNumber,
        entityId: clientCommunication.entityId,
        createdBy: clientCommunication.createdBy,
        createdDate: clientCommunication.createdDate,
        clientComId: clientCommunication.clientComId,
        updatedBy: clientCommunication.updatedBy,
        updatedDate: clientCommunication.updatedDate,
        _id: {
          creationTime: clientCommunication._id.creationTime,
          increment: clientCommunication._id.increment,
          machine: clientCommunication._id.machine,
          pid: clientCommunication._id.pid,
          timestamp: clientCommunication._id.timestamp,
        },
      }
    );
    return Promise.resolve(res.data);
  };

  return {
    getAllClientCommunication,
    addNewClientCommunication,
    updateClientCommunication,
    deleteClientCommunication,
    getClientCommunicationByClient,
  };
};
export default ClientCommunications;
