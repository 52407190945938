import { axiosInstance } from "@/plugins/axios";
import {
  ClosedCorpEntityDetail,
  ClientAccount_CREATE,
  ClientOverview,
} from "models";

const PrivateCompanyAccounts = () => {
  const baseUrl = "/api/PrivateCompany";

  const getAllPrivateCompAccount = async (): Promise<
    ClosedCorpEntityDetail[]
  > => {
    const res = await axiosInstance.get(baseUrl + "/getAllPrivateCompAccount");
    return Promise.resolve(res.data);
  };

  const getPCOverview = async (): Promise<ClientOverview[]> => {
    const res = await axiosInstance.get(baseUrl + "/getPCOverview");
    return Promise.resolve(res.data);
  };

  const getPrivateCompAccountByCode = async (code: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPrivateCompAccountByCode?code=${code}`
    );
    return Promise.resolve(res.data);
  };

  const addNewPrivateCompAccount = async (
    clientAccount: ClientAccount_CREATE
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + "/addNewPrivateCompAccount",
      {
        accountName: clientAccount.accountName,
        contactNumber: clientAccount.contactNumber,
        contactPerson: clientAccount.contactPerson,
        contactPersonEmail: clientAccount.contactPersonEmail,
        dateOfRegistration: clientAccount.dateOfRegistration,
        country: clientAccount.country,
        email: clientAccount.email,
        branchTrader: clientAccount.branchTrader,
        enumStatus: clientAccount.enumStatus,
        deleted_At: clientAccount.deleted_At,
        staffAccount: clientAccount.staffAccount,
      }
    );
    return Promise.resolve(res.data);
  };

  const updatePrivateCompAccount = async (
    clientAccount: ClosedCorpEntityDetail
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/updatePrivateCompAccountAsync",
      {
        accountName: clientAccount.accountName,
        contactNumber: clientAccount.contactNumber,
        contactPerson: clientAccount.contactPerson,
        contactPersonEmail: clientAccount.contactPersonEmail,
        dateOfRegistration: clientAccount.dateOfRegistration,
        country: clientAccount.country,
        email: clientAccount.email,
        branchTrader: clientAccount.branchTrader,
        enumStatus: clientAccount.enumStatus,
        entityId: clientAccount.entityId,
        createdBy: clientAccount.createdBy,
        createdDate: clientAccount.createdDate,
        updatedBy: clientAccount.updatedBy,
        updatedDate: clientAccount.updatedDate,
        _id: clientAccount._id,
        deleted_At: clientAccount.deleted_At,
        staffAccount: clientAccount.staffAccount,
      }
    );
    return Promise.resolve(res.data);
  };

  const deletePrivateCompAccount = async (
    clientAccount: ClosedCorpEntityDetail
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/deletePrivateCompAccount",
      {
        accountName: clientAccount.accountName,
        contactNumber: clientAccount.contactNumber,
        contactPerson: clientAccount.contactPerson,
        contactPersonEmail: clientAccount.contactPersonEmail,
        dateOfRegistration: clientAccount.dateOfRegistration,
        country: clientAccount.country,
        email: clientAccount.email,
        branchTrader: clientAccount.branchTrader,
        enumStatus: clientAccount.enumStatus,
        entityId: clientAccount.entityId,
        createdBy: clientAccount.createdBy,
        createdDate: clientAccount.createdDate,
        updatedBy: clientAccount.updatedBy,
        updatedDate: clientAccount.updatedDate,
        _id: clientAccount._id,
        deleted_At: clientAccount.deleted_At,
        staffAccount: clientAccount.staffAccount,
      }
    );
    return Promise.resolve(res.data);
  };

  return {
    getAllPrivateCompAccount,
    getPCOverview,
    getPrivateCompAccountByCode,
    addNewPrivateCompAccount,
    updatePrivateCompAccount,
    deletePrivateCompAccount,
  };
};
export default PrivateCompanyAccounts;
