import { axiosInstance } from "@/plugins/axios";
import { ClientKYC, ClientKYC_CREATE, AccountKYC } from "models";

const TrustKYCs = () => {
  const baseUrl = "/api/Trust";

  const getAllTrustKYC = async (): Promise<ClientKYC[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAllTrustKYC");
    return Promise.resolve(res.data);
  };

  const getTrustKYCByCode = async (code: string): Promise<ClientKYC> => {
    const res = await axiosInstance.get(
      baseUrl + `/getTrustKYCByCode?code=${code}`
    );
    return Promise.resolve(res.data);
  };

  const addNewTrustKYC = async (clientKYC: ClientKYC_CREATE): Promise<any> => {
    const res = await axiosInstance.post(baseUrl + "/addNewTrustKYC", {
      entityId: clientKYC.entityId,
      sourceOfIncome: clientKYC.sourceOFIncome,
      currentEarningsSector: clientKYC.currentEarningsSector,
      experienceLevel: clientKYC.experienceLevel,
      accountFunds: clientKYC.accountFunds,
      incomeBand: clientKYC.incomeBand,
      riskAppetite: clientKYC.riskAppetite,
      dpip: clientKYC.dpip,
      job: clientKYC.job,
      geoLocation: clientKYC.geoLocation,
      influentialPerson: clientKYC.influentialPerson,
    });
    return Promise.resolve(res.data);
  };

  const updateTrustKYC = async (clientKYC: ClientKYC): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/updateTrustKYC", {
      entityId: clientKYC.entityId,
      sourceOfIncome: clientKYC.sourceOFIncome,
      currentEarningsSector: clientKYC.currentEarningsSector,
      investmentExperience: clientKYC.investmentExperience,
      accountFunds: clientKYC.accountFunds,
      incomeBand: clientKYC.incomeBand,
      riskAppetite: clientKYC.riskAppetite,
      dpip: clientKYC.dpip,
      job: clientKYC.job,
      geoLocation: clientKYC.geoLocation,
      influentialPerson: clientKYC.influentialPerson,
      createdBy: clientKYC.createdBy,
      createdDate: clientKYC.createdDate,
      id: clientKYC.id,
      updatedBy: clientKYC.updatedBy,
      updatedDate: clientKYC.updatedDate,
      _id: clientKYC._id,
    });
    return Promise.resolve(res.data);
  };

  const deleteTrustKYC = async (clientKYC: ClientKYC): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/deleteTrustKYC", {
      entityId: clientKYC.entityId,
      sourceOfIncome: clientKYC.sourceOFIncome,
      currentEarningsSector: clientKYC.currentEarningsSector,
      investmentExperience: clientKYC.investmentExperience,
      accountFunds: clientKYC.accountFunds,
      incomeBand: clientKYC.incomeBand,
      riskAppetite: clientKYC.riskAppetite,
      dpip: clientKYC.dpip,
      job: clientKYC.job,
      geoLocation: clientKYC.geoLocation,
      influentialPerson: clientKYC.influentialPerson,
      createdBy: clientKYC.createdBy,
      createdDate: clientKYC.createdDate,
      id: clientKYC.id,
      updatedBy: clientKYC.updatedBy,
      updatedDate: clientKYC.updatedDate,
      _id: clientKYC._id,
    });
    return Promise.resolve(res.data);
  };

  return {
    getAllTrustKYC,
    getTrustKYCByCode,
    addNewTrustKYC,
    updateTrustKYC,
    deleteTrustKYC,
  };
};
export default TrustKYCs;
