import { axiosAuth, axiosInstance } from "@/plugins/axios";
import { UserModel } from "models";

const Users = () => {
  const baseUrl = "/api/User";

  const getUser = async (email: string): Promise<UserModel[]> => {
    const res = await axiosInstance.get(baseUrl + `/GetUser?email=${email}`);
    return Promise.resolve(res.data);
  };

  const addUser = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    cellNumber: string,
    organisation: string,
    occupation: string,
    province: string
  ): Promise<void> => {
    const res = await axiosAuth.post(baseUrl + "/addUser", {
      name: email,
      email: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
      cellNumber: cellNumber,
      organisation: organisation,
      occupation: occupation,
      province: province,
    });
    return Promise.resolve(res.data);
  };

  const addSuperUser = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    cellNumber: string,
    organisation: string,
    occupation: string,
    province: string
  ): Promise<void> => {
    const res = await axiosAuth.post(baseUrl + "/addSuperUser", {
      name: email,
      email: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
      cellNumber: cellNumber,
      organisation: organisation,
      occupation: occupation,
      province: province,
    });
    return Promise.resolve(res.data);
  };

  return {
    getUser,
    addUser,
    addSuperUser,
  };
};
export default Users;
