import { axiosInstance } from "@/plugins/axios";
import {
  ExcelFile,
  RiskReporting_ABSA_Resp,
  RiskReporting_Balance,
  RiskReporting_Balance_Resp,
  RiskReporting_Brokes,
  RiskReporting_Brokes_Resp,
  RiskReporting_HQ_Resp,
  RiskReporting_Matching,
  RiskReporting_SubAccount,
  RiskReporting_SubAccount_Resp,
} from "models";

const ExcelFiles = () => {
  const baseUrl = "/api/ExcelFile";

  const UploadExcelDocuments = async (
    file: ExcelFile
  ): Promise<{
    balanceSheet: RiskReporting_Balance_Resp[];
    brokesSheet: RiskReporting_Brokes_Resp[];
    absa: RiskReporting_ABSA_Resp[];
    absaDaily: RiskReporting_ABSA_Resp[];
    hq: RiskReporting_HQ_Resp[];
    subAccount: RiskReporting_SubAccount_Resp[];
  }> => {
    const res = await axiosInstance.post(baseUrl + `/UploadExcelDocuments`, {
      /* entityId: file.entityId, */
      fileName: file.fileName,
      customFile: file.customFile,
      absaFileName: file.absaFileName,
      absaCustomFile: file.absaCustomFile,
      absaDailyName: file.absaDailyName,
      absaDailyFile: file.absaDailyFile,
      reportDate: file.reportDate,
    });
    return Promise.resolve(res.data);
  };

  const UploadMatchDocuments = async (
    file: ExcelFile
  ): Promise<{
    subAccount: RiskReporting_SubAccount_Resp[];
  }> => {
    const res = await axiosInstance.post(baseUrl + `/UploadMatchDocuments`, {
      /* entityId: file.entityId, */
      fileName: file.fileName,
      customFile: file.customFile,
      absaFileName: file.absaFileName,
      absaCustomFile: file.absaCustomFile,
      absaDailyName: file.absaDailyName,
      absaDailyFile: file.absaDailyFile,
      reportDate: file.reportDate,
    });
    return Promise.resolve(res.data);
  };

  const SaveBalanceSheet = async (
    balanceSheet: RiskReporting_Balance[]
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + `/SaveBalanceSheet`,
      balanceSheet
    );
    return Promise.resolve(res.data);
  };

  const SaveBrokesSheet = async (
    brokeSheet: RiskReporting_Brokes[]
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + `/SaveBrokesSheet`,
      brokeSheet
    );
    return Promise.resolve(res.data);
  };

  const SaveSubAccountSheet = async (
    subAccounts: RiskReporting_SubAccount[]
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + `/SaveSubAccountSheet`,
      subAccounts
    );
    return Promise.resolve(res.data);
  };

  const SaveMatchingSheet = async (
    subAccounts: RiskReporting_Matching[]
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + `/SaveMatchingSheet`,
      subAccounts
    );
    return Promise.resolve(res.data);
  };

  const SaveOrUpdateBalance = async (
    balanceSheet: RiskReporting_Balance[]
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + `/SaveOrUpdateBalance`,
      balanceSheet
    );
    return Promise.resolve(res.data);
  };

  const SaveOrUpdateBrokes = async (
    brokeSheet: RiskReporting_Brokes[]
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + `/SaveOrUpdateBrokes`,
      brokeSheet
    );
    return Promise.resolve(res.data);
  };

  const SaveOrUpdateSubAccount = async (
    subAccounts: RiskReporting_SubAccount[]
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + `/SaveOrUpdateSubAccount`,
      subAccounts
    );
    return Promise.resolve(res.data);
  };

  const SaveOrUpdateMatching = async (
    matchingSheet: RiskReporting_Matching[]
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + `/SaveOrUpdateMatching`,
      matchingSheet
    );
    return Promise.resolve(res.data);
  };

  const viewBalanceByDateSheet = async (
    date: string
  ): Promise<RiskReporting_Balance[]> => {
    const res = await axiosInstance.post(
      baseUrl + `/viewBalanceByDate?dateTime=${date}`
    );
    return Promise.resolve(res.data);
  };

  const viewBrokesByDateSheet = async (
    date: string
  ): Promise<RiskReporting_Brokes[]> => {
    const res = await axiosInstance.post(
      baseUrl + `/viewBrokesByDate?dateTime=${date}`
    );
    return Promise.resolve(res.data);
  };

  const viewSubByDateSheet = async (
    date: string
  ): Promise<RiskReporting_SubAccount[]> => {
    const res = await axiosInstance.post(
      baseUrl + `/viewSubByDate?dateTime=${date}`
    );
    return Promise.resolve(res.data);
  };

  const viewMatchByDateSheet = async (
    date: string
  ): Promise<RiskReporting_Matching[]> => {
    const res = await axiosInstance.post(
      baseUrl + `/viewMatchByDate?dateTime=${date}`
    );
    return Promise.resolve(res.data);
  };

  const DownloadExcelFile = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadExcelFile?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const getBalanceSheetPDF = async (
    balanceSheet: RiskReporting_Balance[]
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + `/getBalanceSheetPDF`,
      balanceSheet,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const getBrokesSheetPDF = async (
    brokeSheet: RiskReporting_Brokes[]
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + `/getBrokesSheetPDF`,
      brokeSheet,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const getSubAccountPDF = async (
    subAccounts: RiskReporting_SubAccount[],
    reportDate: string | null,
    currAccountTotal: string | null,
    mtmTotal: string | null,
    exchangeFeesTotal: string | null,
    clearingFeesTotal: string | null,
    memberTotal: string | null,
    totalFeesTotal1: string | null,
    totalFeesTotal2: string | null
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + `/getSubAccountPDF`,
      {
        subLists: subAccounts,
        reportDate: reportDate,
        currAccountTotal: currAccountTotal,
        mtmTotal: mtmTotal,
        exchangeFeesTotal: exchangeFeesTotal,
        clearingFeesTotal: clearingFeesTotal,
        memberTotal: memberTotal,
        totalFeesTotal1: totalFeesTotal1,
        totalFeesTotal2: totalFeesTotal2,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const getMatchingPDF = async (
    matchingSheet: RiskReporting_Matching[]
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + `/getMatchingPDF`,
      matchingSheet,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  return {
    UploadExcelDocuments,
    UploadMatchDocuments,
    DownloadExcelFile,
    SaveBalanceSheet,
    SaveBrokesSheet,
    SaveSubAccountSheet,
    SaveMatchingSheet,
    SaveOrUpdateBalance,
    SaveOrUpdateBrokes,
    SaveOrUpdateSubAccount,
    SaveOrUpdateMatching,
    viewBalanceByDateSheet,
    viewBrokesByDateSheet,
    viewSubByDateSheet,
    viewMatchByDateSheet,
    getBalanceSheetPDF,
    getBrokesSheetPDF,
    getSubAccountPDF,
    getMatchingPDF,
  };
};
export default ExcelFiles;
