import { axiosInstance } from "@/plugins/axios";
import {
  ClientDocuments,
  ClientIdDocument,
  ClientProofOfResidence,
  ClientTaxDocument,
  ClientIncomeTaxDocument,
  ClientProofOfBank,
  ClientSignature,
  ClientDocumentsUpdate,
  ClientPersonalDocs,
  ClientPersonalDocsUpdate,
  ClientCorpDocs,
  ClientCorpDocsUpdate,
} from "models";

const ClientDocuments = () => {
  const baseUrl = "/api/ClientDocuments";

  const UploadClientDocuments = async (
    clientDocuments: ClientDocuments
  ): Promise<ClientDocuments> => {
    const res = await axiosInstance.post(baseUrl + `/UploadClientDocuments`, {
      user: clientDocuments.user,
      clientId: clientDocuments.clientId,
      identificationType: clientDocuments.identificationType,
      copyOfId: clientDocuments.copyOfId,
      saidNumber: clientDocuments.saidNumber,
      proofOfResidence: clientDocuments.proofOfResidence,
      taxNumber: clientDocuments.taxNumber,
      taxNumberUpload: clientDocuments.taxNumberUpload,
      incomeTax: clientDocuments.incomeTax,
      incomeTaxUpload: clientDocuments.incomeTaxUpload,
      pobUpload: clientDocuments.pobUpload,
      signatureUpload: clientDocuments.signatureUpload,
      vatNumber: clientDocuments.vatNumber,
      vatUpload: clientDocuments.vatUpload,
    });
    return Promise.resolve(res.data);
  };

  const UploadIndividualsPersonalFiles = async (
    clientDocuments: ClientPersonalDocs
  ): Promise<ClientDocuments> => {
    const res = await axiosInstance.post(
      baseUrl + `/UploadIndivdualsPersonalFiles`,
      {
        user: clientDocuments.user,
        clientId: clientDocuments.clientId,
        identificationType: clientDocuments.identificationType,
        copyOfId: clientDocuments.copyOfId,
        saidNumber: clientDocuments.saidNumber,
        proofOfResidence: clientDocuments.proofOfResidence,
        signatureUpload: clientDocuments.signatureUpload,
      }
    );
    return Promise.resolve(res.data);
  };

  const UploadIndividualDocumentFiles = async (
    clientDocuments: ClientCorpDocs
  ): Promise<ClientDocuments> => {
    const res = await axiosInstance.post(
      baseUrl + `/UploadIndividualDocumentFiles`,
      {
        user: clientDocuments.user,
        clientId: clientDocuments.clientId,
        taxNumber: clientDocuments.taxNumber,
        taxNumberUpload: clientDocuments.taxNumberUpload,
        incomeTax: clientDocuments.incomeTax,
        incomeTaxUpload: clientDocuments.incomeTaxUpload,
        pobUpload: clientDocuments.pobUpload,
        vatNumber: clientDocuments.vatNumber,
        vatUpload: clientDocuments.vatUpload,
      }
    );
    return Promise.resolve(res.data);
  };

  const updateClientAccount = async (
    clientDocuments: ClientDocumentsUpdate
  ): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/UpdateVehicleFiles", {
      user: clientDocuments.user,
      clientId: clientDocuments.clientId,
      identificationType: clientDocuments.identificationType,
      idCopyOfId: clientDocuments.idCopyOfId,
      copyOfId: clientDocuments.copyOfId,
      saidNumber: clientDocuments.saidNumber,
      proofOfResidenceId: clientDocuments.proofOfResidenceId,
      proofOfResidence: clientDocuments.proofOfResidence,
      taxNumber: clientDocuments.taxNumber,
      taxNumberUploadId: clientDocuments.taxNumberUploadId,
      taxNumberUpload: clientDocuments.taxNumberUpload,
      incomeTaxUploadId: clientDocuments.incomeTaxUploadId,
      incomeTax: clientDocuments.incomeTax,
      incomeTaxUpload: clientDocuments.incomeTaxUpload,
      pobUploadId: clientDocuments.pobUploadId,
      pobUpload: clientDocuments.pobUpload,
      signatureUploadId: clientDocuments.signatureUploadId,
      signatureUpload: clientDocuments.signatureUpload,
      vatNumber: clientDocuments.vatNumber,
      vatUpload: clientDocuments.vatUpload,
      vatId: clientDocuments.vatId,
    });
    return Promise.resolve(res.data);
  };

  const UpdateIndividualsPersonalFiles = async (
    clientDocuments: ClientPersonalDocsUpdate
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/UpdateIndivdualsPersonalFiles",
      {
        user: clientDocuments.user,
        clientId: clientDocuments.clientId,
        identificationType: clientDocuments.identificationType,
        idCopyOfId: clientDocuments.idCopyOfId,
        copyOfId: clientDocuments.copyOfId,
        saidNumber: clientDocuments.saidNumber,
        proofOfResidenceId: clientDocuments.proofOfResidenceId,
        proofOfResidence: clientDocuments.proofOfResidence,
        signatureUploadId: clientDocuments.signatureUploadId,
        signatureUpload: clientDocuments.signatureUpload,
      }
    );
    return Promise.resolve(res.data);
  };

  const UpdateIndividualDocumentFiles = async (
    clientDocuments: ClientCorpDocsUpdate
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/UpdateIndividualDocumentFiles",
      {
        user: clientDocuments.user,
        clientId: clientDocuments.clientId,
        taxNumber: clientDocuments.taxNumber,
        taxNumberUploadId: clientDocuments.taxNumberUploadId,
        taxNumberUpload: clientDocuments.taxNumberUpload,
        incomeTaxUploadId: clientDocuments.incomeTaxUploadId,
        incomeTax: clientDocuments.incomeTax,
        incomeTaxUpload: clientDocuments.incomeTaxUpload,
        pobUploadId: clientDocuments.pobUploadId,
        pobUpload: clientDocuments.pobUpload,
        vatNumber: clientDocuments.vatNumber,
        vatUpload: clientDocuments.vatUpload,
        vatId: clientDocuments.vatId,
      }
    );
    return Promise.resolve(res.data);
  };

  const getClientIdDocumentByClient = async (
    clientId: string
  ): Promise<ClientIdDocument> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClientIdDocumentByClient?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getClientProofOfResidenceByClient = async (
    clientId: string
  ): Promise<ClientProofOfResidence> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClientProofOfResidenceByClient?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getClientTaxByClient = async (
    clientId: string
  ): Promise<ClientTaxDocument> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClientTaxByClient?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getClientTaxIncomeByClient = async (
    clientId: string
  ): Promise<ClientIncomeTaxDocument> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClientTaxIncomeByClient?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getClientPOB = async (clientId: string): Promise<ClientProofOfBank> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClientPOB?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getClientSignature = async (
    clientId: string
  ): Promise<ClientSignature> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClientSignature?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getClientVAT = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClientVAT?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const DownloadIdDocument = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadIdDocument?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadIncomeTax = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadIncomeTax?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadPOR = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(baseUrl + `/DownloadPOR?id=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "image/jpeg" + "application/pdf" + "image/png",
      },
      responseType: "blob",
    });
    return Promise.resolve(res.data);
  };

  const DownloadTax = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(baseUrl + `/DownloadTax?id=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "image/jpeg" + "application/pdf" + "image/png",
      },
      responseType: "blob",
    });
    return Promise.resolve(res.data);
  };

  const DownloadPOBDocument = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadPOBDocument?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadSignatureDocument = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadSignatureDocument?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadVATDocument = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadVATDocument?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  return {
    UploadClientDocuments,
    updateClientAccount,
    UploadIndividualsPersonalFiles,
    UpdateIndividualsPersonalFiles,
    UploadIndividualDocumentFiles,
    UpdateIndividualDocumentFiles,
    getClientIdDocumentByClient,
    getClientProofOfResidenceByClient,
    getClientTaxByClient,
    getClientTaxIncomeByClient,
    getClientPOB,
    getClientSignature,
    getClientVAT,
    DownloadIdDocument,
    DownloadIncomeTax,
    DownloadPOR,
    DownloadTax,
    DownloadPOBDocument,
    DownloadSignatureDocument,
    DownloadVATDocument,
  };
};
export default ClientDocuments;
