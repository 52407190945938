
import { Component, Vue, Watch } from "vue-property-decorator";
import AddEditMarket from "@/components/Manage/Markets/AddEditMarket.vue";
import { Market, UserAccessModel } from "models";
import Hooks from "@/hooks";
import User from "@/store/modules/user";
import { SyncClientModel } from "@/hooks/SyncClients";

const { SyncClients } = Hooks;
const clientHook = SyncClients();

@Component({
  components: {
    AddEditMarket,
  },
})
export default class SyncClientsView extends Vue {
  api = clientHook;
  items: SyncClientModel[] = [];
  loading = false;
  search = "";
  sortDesc = false;
  sortBy = "";
  refresh = false;
  headers = [
    { text: "", value: "actions" },
    { text: "Client Code", value: "clientCode" },
    { text: "Branch", value: "branch" },
    { text: "Title ID", value: "titleId" },
    { text: "Client Detail ID", value: "clientDetailId" },
    { text: "Name", value: "name" },
    { text: "Surname", value: "surname" },
    { text: "Date of Birth", value: "dateOfBirth" },
    { text: "Email", value: "email" },
    { text: "Cell Number", value: "cellNumber" },
    { text: "Is Company", value: "isCompany" },
    { text: "Company Address", value: "companyAddress" },
    { text: "Postal Address", value: "postalAddress" },
    { text: "Postal Address 2", value: "postalAddress2" },
    { text: "Postal Suburb", value: "postalSuburb" },
    { text: "Postal City", value: "postalCity" },
    { text: "Postal Code", value: "postalCode" },
    { text: "Street Address", value: "streetAddress" },
    { text: "Street Address 2", value: "streetAddress2" },
    { text: "Suburb", value: "suburb" },
    { text: "City", value: "city" },
    { text: "Code", value: "code" },
    { text: "Province", value: "province" },
    { text: "Entity ID", value: "entityId" },
    { text: "Created By", value: "createdBy" },
    { text: "Created Date", value: "createdDate" },
    { text: "Updated By", value: "updatedBy" },
    { text: "Update Date", value: "updateDate" },
  ];

  async syncTrader(item: SyncClientModel): Promise<void> {
    try {
      item.loading = true;
      const res = await clientHook.SyncClient(item.jseClientFileDetails);
      this.$notificationCreator.createSuccessNotification("Trader Synced");
    } catch (err) {
      return Promise.reject(err);
    } finally {
      item.loading = false;
      this.init();
    }
  }

  async mounted(): Promise<void> {
    await this.init();
  }

  async init(): Promise<void> {
    try {
      this.loading = true;
      const res = await clientHook.GetAllClientDifferences();
      this.items = res;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
