import { axiosInstance } from "@/plugins/axios";
import {
  PrivateCorpDocs,
  PrivateCorpDocsUpdate,
  PrivateDocument,
  PrivateDocumentUpdate,
  PrivatePersonalDocs,
  PrivatePersonalDocsUpdate,
} from "models";

const PrivateCompDocuments = () => {
  const baseUrl = "/api/PrivateCompanyDoc";

  const UploadPrivateCompDocuments = async (
    clientDocuments: PrivateDocument
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + `/UploadPrivateCompDocuments`,
      {
        user: clientDocuments.user,
        entityId: clientDocuments.entityId,
        identificationType: clientDocuments.identificationType,
        idFile: clientDocuments.idFile,
        saidNumber: clientDocuments.saidNumber,
        authSAIDNumber: clientDocuments.authSAIDNumber,
        proofOfRes: clientDocuments.proofOfRes,
        taxNumber: clientDocuments.taxNumber,
        taxNumberUpload: clientDocuments.taxNumberUpload,
        incomeTax: clientDocuments.incomeTax,
        incomeTaxUpload: clientDocuments.incomeTaxUpload,
        bankUpload: clientDocuments.bankUpload,
        vatRegNumber: clientDocuments.vatRegNumber,
        vatUpload: clientDocuments.vatUpload,
        regUpload: clientDocuments.regUpload,
        regNumber: clientDocuments.regNumber,
        mandate: clientDocuments.mandate,
        shareholder: clientDocuments.shareholder,
        authUpload: clientDocuments.authUpload,
      }
    );
    return Promise.resolve(res.data);
  };

  const UploadPrivateCompPersonalDocuments = async (
    clientDocuments: PrivatePersonalDocs
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + `/UploadPrivateCompPersonalDocuments`,
      {
        user: clientDocuments.user,
        entityId: clientDocuments.entityId,
        identificationType: clientDocuments.identificationType,
        idFile: clientDocuments.idFile,
        saidNumber: clientDocuments.saidNumber,
        authSAIDNumber: clientDocuments.authSAIDNumber,
        incomeTax: clientDocuments.incomeTax,
        incomeTaxUpload: clientDocuments.incomeTaxUpload,
        authUpload: clientDocuments.authUpload,
      }
    );
    return Promise.resolve(res.data);
  };

  const UploadPrivateCompCorpDocuments = async (
    clientDocuments: PrivateCorpDocs
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + `/UploadPrivateCompDocuments`,
      {
        user: clientDocuments.user,
        entityId: clientDocuments.entityId,
        proofOfRes: clientDocuments.proofOfRes,
        taxNumber: clientDocuments.taxNumber,
        taxNumberUpload: clientDocuments.taxNumberUpload,
        bankUpload: clientDocuments.bankUpload,
        vatRegNumber: clientDocuments.vatRegNumber,
        vatUpload: clientDocuments.vatUpload,
        regUpload: clientDocuments.regUpload,
        regNumber: clientDocuments.regNumber,
        mandate: clientDocuments.mandate,
        shareholder: clientDocuments.shareholder,
      }
    );
    return Promise.resolve(res.data);
  };

  const updatePrivateCompAccount = async (
    clientDocuments: PrivateDocumentUpdate
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/UpdatePrivateCompDocuments",
      {
        user: clientDocuments.user,
        entityId: clientDocuments.entityId,
        identificationType: clientDocuments.identificationType,
        idFileId: clientDocuments.idFileId,
        idFile: clientDocuments.idFile,
        saidNumber: clientDocuments.saidNumber,
        authSAIDNumber: clientDocuments.authSAIDNumber,
        proofOfResId: clientDocuments.proofOfResId,
        proofOfRes: clientDocuments.proofOfRes,
        taxNumberUploadId: clientDocuments.taxNumberUploadId,
        taxNumber: clientDocuments.taxNumber,
        taxNumberUpload: clientDocuments.taxNumberUpload,
        incomeTaxId: clientDocuments.incomeTaxId,
        incomeTax: clientDocuments.incomeTax,
        incomeTaxUpload: clientDocuments.incomeTaxUpload,
        bankUploadId: clientDocuments.bankUploadId,
        bankUpload: clientDocuments.bankUpload,
        vatRegNumber: clientDocuments.vatRegNumber,
        vatUploadId: clientDocuments.vatUploadId,
        vatUpload: clientDocuments.vatUpload,
        regUploadId: clientDocuments.regUploadId,
        regUpload: clientDocuments.regUpload,
        regNumber: clientDocuments.regNumber,
        mandateId: clientDocuments.mandateId,
        mandate: clientDocuments.mandate,
        shareholderId: clientDocuments.shareholderId,
        shareholder: clientDocuments.shareholder,
        authIdFileId: clientDocuments.authIdFileId,
        authIdFile: clientDocuments.authIdFile,
      }
    );
    return Promise.resolve(res.data);
  };

  const UpdatePrivateCompPersonalDocuments = async (
    clientDocuments: PrivatePersonalDocsUpdate
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/UpdatePrivateCompPersonalDocuments",
      {
        user: clientDocuments.user,
        entityId: clientDocuments.entityId,
        identificationType: clientDocuments.identificationType,
        idFileId: clientDocuments.idFileId,
        idFile: clientDocuments.idFile,
        saidNumber: clientDocuments.saidNumber,
        authSAIDNumber: clientDocuments.authSAIDNumber,
        incomeTaxId: clientDocuments.incomeTaxId,
        incomeTax: clientDocuments.incomeTax,
        incomeTaxUpload: clientDocuments.incomeTaxUpload,
        authIdFileId: clientDocuments.authIdFileId,
        authIdFile: clientDocuments.authIdFile,
      }
    );
    return Promise.resolve(res.data);
  };

  const UpdatePrivateCompCorpDocuments = async (
    clientDocuments: PrivateCorpDocsUpdate
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/UpdatePrivateCompDocuments",
      {
        user: clientDocuments.user,
        entityId: clientDocuments.entityId,
        proofOfResId: clientDocuments.proofOfResId,
        proofOfRes: clientDocuments.proofOfRes,
        taxNumberUploadId: clientDocuments.taxNumberUploadId,
        taxNumber: clientDocuments.taxNumber,
        taxNumberUpload: clientDocuments.taxNumberUpload,
        bankUploadId: clientDocuments.bankUploadId,
        bankUpload: clientDocuments.bankUpload,
        vatRegNumber: clientDocuments.vatRegNumber,
        vatUploadId: clientDocuments.vatUploadId,
        vatUpload: clientDocuments.vatUpload,
        regUploadId: clientDocuments.regUploadId,
        regUpload: clientDocuments.regUpload,
        regNumber: clientDocuments.regNumber,
        mandateId: clientDocuments.mandateId,
        mandate: clientDocuments.mandate,
        shareholderId: clientDocuments.shareholderId,
        shareholder: clientDocuments.shareholder,
      }
    );
    return Promise.resolve(res.data);
  };

  const getPrivateCompIdDocumentByCode = async (
    clientId: string
  ): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPrivateCompIdDocumentByCode?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getPrivateCompPORByCode = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPrivateCompPORByCode?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getPrivateCompTaxByCode = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPrivateCompTaxByCode?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getPrivateCompTaxIncomeByCode = async (
    clientId: string
  ): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPrivateCompTaxIncomeByCode?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getPrivateCompMandate = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPrivateCompMandate?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getPrivateCompBank = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPrivateCompByCode?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getPrivateCompVAT = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPrivateCompVat?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getPrivateCompRegByCode = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPrivateCompRegByCode?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getPrivateCompSHByCode = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPrivateCompSHByCode?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const getPrivateCompAuthIdByCode = async (clientId: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPrivateCompAuthIdByCode?clientID=${clientId}`
    );
    return Promise.resolve(res.data);
  };

  const DownloadIdDocument = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadPCompIdDocument?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadIncomeTax = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadPCompIncomeTax?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadPCompPORDocument = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadPCompPORDocument?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadTax = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadPCompTax?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadBankDocument = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadPCompBankingDocument?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadPCompMandate = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadPCompMandate?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadVATDocument = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadPCompVATDocument?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadPCompRegDocument = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadPCompRegDocument?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadPCompSHDocument = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadPCompSHDocument?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const DownloadAuthId = async (id: number): Promise<File> => {
    const res = await axiosInstance.get(
      baseUrl + `/DownloadPCompAuthIdDocument?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  return {
    UploadPrivateCompDocuments,
    UploadPrivateCompCorpDocuments,
    UploadPrivateCompPersonalDocuments,
    updatePrivateCompAccount,
    UpdatePrivateCompCorpDocuments,
    UpdatePrivateCompPersonalDocuments,
    getPrivateCompIdDocumentByCode,
    getPrivateCompMandate,
    getPrivateCompTaxByCode,
    getPrivateCompTaxIncomeByCode,
    getPrivateCompBank,
    getPrivateCompVAT,
    getPrivateCompPORByCode,
    getPrivateCompRegByCode,
    getPrivateCompSHByCode,
    getPrivateCompAuthIdByCode,
    DownloadIdDocument,
    DownloadIncomeTax,
    DownloadTax,
    DownloadBankDocument,
    DownloadPCompMandate,
    DownloadVATDocument,
    DownloadPCompPORDocument,
    DownloadPCompRegDocument,
    DownloadPCompSHDocument,
    DownloadAuthId,
  };
};
export default PrivateCompDocuments;
