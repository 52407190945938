import { axiosInstance } from "@/plugins/axios";
import { ClientKYC, ClientKYC_CREATE, AccountKYC } from "models";

const PrivateCompanyKYCs = () => {
  const baseUrl = "/api/PrivateCompany";

  const getAllPrivateCompKYC = async (): Promise<ClientKYC[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAllPrivateCompKYCAsync");
    return Promise.resolve(res.data);
  };

  const getPrivateCompKYCByCode = async (code: string): Promise<ClientKYC> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPrivateCompKYCByCode?code=${code}`
    );
    return Promise.resolve(res.data);
  };

  const addNewPrivateCompKYC = async (
    clientKYC: ClientKYC_CREATE
  ): Promise<any> => {
    const res = await axiosInstance.post(baseUrl + "/addNewPrivateCompKYC", {
      entityId: clientKYC.entityId,
      sourceOfIncome: clientKYC.sourceOFIncome,
      currentEarningsSector: clientKYC.currentEarningsSector,
      experienceLevel: clientKYC.experienceLevel,
      accountFunds: clientKYC.accountFunds,
      incomeBand: clientKYC.incomeBand,
      riskAppetite: clientKYC.riskAppetite,
      dpip: clientKYC.dpip,
      job: clientKYC.job,
      geoLocation: clientKYC.geoLocation,
      influentialPerson: clientKYC.influentialPerson,
    });
    return Promise.resolve(res.data);
  };

  const updatePrivateCompKYC = async (clientKYC: ClientKYC): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/updatePrivateCompKYC", {
      entityId: clientKYC.entityId,
      sourceOfIncome: clientKYC.sourceOFIncome,
      currentEarningsSector: clientKYC.currentEarningsSector,
      investmentExperience: clientKYC.investmentExperience,
      accountFunds: clientKYC.accountFunds,
      incomeBand: clientKYC.incomeBand,
      riskAppetite: clientKYC.riskAppetite,
      dpip: clientKYC.dpip,
      job: clientKYC.job,
      geoLocation: clientKYC.geoLocation,
      influentialPerson: clientKYC.influentialPerson,
      createdBy: clientKYC.createdBy,
      createdDate: clientKYC.createdDate,
      id: clientKYC.id,
      updatedBy: clientKYC.updatedBy,
      updatedDate: clientKYC.updatedDate,
      _id: clientKYC._id,
    });
    return Promise.resolve(res.data);
  };

  const deletePrivateCompKYC = async (clientKYC: ClientKYC): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/deletePrivateCompKYC", {
      entityId: clientKYC.entityId,
      sourceOfIncome: clientKYC.sourceOFIncome,
      currentEarningsSector: clientKYC.currentEarningsSector,
      investmentExperience: clientKYC.investmentExperience,
      accountFunds: clientKYC.accountFunds,
      incomeBand: clientKYC.incomeBand,
      riskAppetite: clientKYC.riskAppetite,
      dpip: clientKYC.dpip,
      job: clientKYC.job,
      geoLocation: clientKYC.geoLocation,
      influentialPerson: clientKYC.influentialPerson,
      createdBy: clientKYC.createdBy,
      createdDate: clientKYC.createdDate,
      id: clientKYC.id,
      updatedBy: clientKYC.updatedBy,
      updatedDate: clientKYC.updatedDate,
      _id: clientKYC._id,
    });
    return Promise.resolve(res.data);
  };

  return {
    getAllPrivateCompKYC,
    getPrivateCompKYCByCode,
    addNewPrivateCompKYC,
    updatePrivateCompKYC,
    deletePrivateCompKYC,
  };
};
export default PrivateCompanyKYCs;
