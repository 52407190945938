
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import dayjs from "dayjs";
import { ClientAccount_CREATE, Trader } from "models";
import Hooks from "@/hooks";
import JSEClient from "@/store/modules/JSEClient";

const { TrustAccount } = Hooks;
const trustHook = TrustAccount();

@Component({})
export default class Account extends Vue {
  @PropSync("valid") formValid!: boolean;
  @PropSync("nextTab") tab!: boolean;
  @PropSync("next") accountNext!: boolean;
  @Prop() type!: string;
  loading = false;
  dataCheck = false;
  accountDetails: ClientAccount_CREATE = {
    accountName: null,
    email: null,
    contactNumber: null,
    contactPerson: null,
    contactPersonEmail: null,
    country: "South Africa",
    branchTrader: null,
    dateOfRegistration: dayjs().format("YYYY-MM-DD"),
    enumStatus: 1,
    deleted_At: null,
    staffAccount: false,
  };
  enumStatus = [
    { id: 1, status: "Awaiting" },
    { id: 2, status: "Approved" },
    { id: 3, status: "Suspended" },
  ];
  traders: Trader[] = [];
  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
    email: (value: string): boolean | string => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },
    cell: (value: string | null = null): boolean | string =>
      value != null ? value.length >= 10 : "" || "Enter a valid Contact Number",
  };

  @Watch("type", { immediate: true })
  async modalTypeChanged(): Promise<void> {
    this.setDefaults();
    console.log("Modal Details Type TT: ", this.type);
    if (this.type == "edit") {
      await this.getStoredData();
    }

    //JSEClient.setAddClient(this.type != "edit");
  }

  async getStoredData(): Promise<void> {
    try {
      this.loading = true;
      var entityId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        entityId = key;
      }

      if (entityId != null) {
        JSEClient.setEntityId(entityId);

        const res = await trustHook.getTrustAccountByCode(entityId);

        console.log("Get Stored Data res: ", res);
        if (res != undefined && (res as any) != "") {
          this.dataCheck = true;
          this.accountDetails = res as any;
          if (res.dateOfRegistration != undefined) {
            this.accountDetails.dateOfRegistration = dayjs(
              res.dateOfRegistration
            )
              .format("YYYY-MM-DD")
              .toString();
          }
        } else {
          this.dataCheck = false;
        }
        return Promise.resolve();
      }
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  setDefaults(): void {
    if (this.type == "add") {
      this.accountDetails.dateOfRegistration = dayjs().format("YYYY-MM-DD");
      this.accountDetails.country = "South Africa";
    }
  }

  getContactPerson(): void {
    this.accountDetails.contactPerson = this.accountDetails.accountName;
  }

  getContactPersonEmail(): void {
    this.accountDetails.contactPersonEmail = this.accountDetails.email;
  }

  async mounted(): Promise<void> {
    setTimeout(this.setDefaults, 10);
  }

  @Watch("accountNext", {
    immediate: true,
  })
  async onNextChanged(): Promise<void> {
    console.log("AccountNext", this.accountNext);
    if (this.accountNext == true) {
      if (this.type == "add") {
        await this.addNewClientAccountDetails();
      }
      if (this.type == "edit") {
        if (this.dataCheck == true) {
          await this.editClientAccountDetails();
        } else {
          await this.addNewClientAccountDetails();
        }
      }
    }
  }

  async editClientAccountDetails(): Promise<void> {
    try {
      this.loading = true;
      const key = localStorage.getItem("ClientFile/entityId");

      console.log("Account Details: ", this.accountDetails);

      const res = await trustHook.updateTrustAccount(
        this.accountDetails as any
      );

      if ((this.accountDetails.enumStatus as number) == 2) {
        this.$emit("updateHQ");
      }

      JSEClient.setAccountDetails({
        accountName: this.accountDetails.accountName!,
        contactNumber: this.accountDetails.contactNumber!,
        contactPerson: this.accountDetails.contactPerson!,
        contactPersonEmail: this.accountDetails.contactPersonEmail!,
        country: this.accountDetails.country!,
        dateOfRegistration: this.accountDetails.dateOfRegistration!,
        email: this.accountDetails.email!,
        staffAccount: this.accountDetails.staffAccount,
      });

      this.$notificationCreator.createSuccessNotification(
        "Account Details Updated."
      );
      //this.tab = true;
      return Promise.resolve();
    } catch (err: any) {
      //this.accountNext = false;
      //this.tab = false;
      return Promise.reject(err);
    } finally {
      this.accountNext = false;
      this.loading = false;
    }
  }

  async addNewClientAccountDetails(): Promise<void> {
    try {
      this.loading = true;
      console.log("Account Details: ", this.accountDetails);
      this.accountDetails.enumStatus = 1;
      const res = await trustHook.addNewTrustAccount(this.accountDetails);

      JSEClient.setEntityId(res.entityId);

      JSEClient.setAccountDetails({
        accountName: this.accountDetails.accountName!,
        contactNumber: this.accountDetails.contactNumber!,
        contactPerson: this.accountDetails.contactPerson!,
        contactPersonEmail: this.accountDetails.contactPersonEmail!,
        country: this.accountDetails.country!,
        dateOfRegistration: this.accountDetails.dateOfRegistration!,
        email: this.accountDetails.email!,
        staffAccount: this.accountDetails.staffAccount,
      });

      this.$notificationCreator.createSuccessNotification(
        "Account Details Saved."
      );
      console.log("Account Saved Resp entityId: ", res.entityId);
      localStorage.setItem("ClientFile/entityId", res.entityId);
      this.tab = true;
      return Promise.resolve();
    } catch (err: any) {
      this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
