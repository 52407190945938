import { axiosInstance } from "@/plugins/axios";
import { AccountDetail, ClientDetail, ClientDetail_CREATE } from "models";

const PartnershipDetails = () => {
  const baseUrl = "/api/Partnership";

  const getAllPartnershipDetailPerson = async (): Promise<ClientDetail[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAllPartnerDetail");
    return Promise.resolve(res.data);
  };

  const getPartnershipDetailByCode = async (
    code: string
  ): Promise<ClientDetail> => {
    const res = await axiosInstance.get(
      baseUrl + `/getPartnerDetailByCode?code=${code}`
    );
    return Promise.resolve(res.data);
  };

  const addNewPartnershipDetails = async (
    clientDetail: ClientDetail_CREATE
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/addNewPartnerDetail",
      clientDetail
    );
    return Promise.resolve(res.data);
  };

  const updatePartnershipDetails = async (
    clientDetail: AccountDetail
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/updatePartnerDetail",
      clientDetail
    );
    return Promise.resolve(res.data);
  };

  const deletePartnershipDetails = async (
    clientDetail: AccountDetail
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/deletePartnerDetail",
      clientDetail
    );
    return Promise.resolve(res.data);
  };

  return {
    getAllPartnershipDetailPerson,
    addNewPartnershipDetails,
    updatePartnershipDetails,
    deletePartnershipDetails,
    getPartnershipDetailByCode,
  };
};
export default PartnershipDetails;
