import { axiosInstance } from "@/plugins/axios";
import { ClearingJSEFee, ClearingJSEFee_CREATE } from "models";

const Clearing_JSE_Fees = () => {
  const baseUrl = "/api/ClearingJSEFee";

  const getAllClearing = async (): Promise<ClearingJSEFee[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAllClearing");
    return Promise.resolve(res.data);
  };

  const getClearingId = async (id: number): Promise<ClearingJSEFee[]> => {
    const res = await axiosInstance.get(baseUrl + `/getClearingId?id=${id}`);
    return Promise.resolve(res.data);
  };

  const addClearing = async (fee: ClearingJSEFee_CREATE): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/addClearing", {
      instrument: fee.instrument,
      marketId: fee.marketId,
      absaFuture: fee.absaFuture,
      absaOption: fee.absaOption,
      absaDelivery: fee.absaDelivery,
      jseFuture: fee.jseFuture,
      jseOption: fee.jseOption,
      jseDelivery: fee.jseDelivery,
      bookoversJSEFuture: fee.bookoversJSEFuture,
      bookoversJSEOption: fee.bookoversJSEOption,
      tonnes: fee.tonnes,
    });
    return Promise.resolve(res.data);
  };

  const updateClearing = async (fee: ClearingJSEFee): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/updateClearing", {
      _id: fee._id,
      instrument: fee.instrument,
      marketId: fee.marketId,
      absaFuture: fee.absaFuture,
      absaOption: fee.absaOption,
      absaDelivery: fee.absaDelivery,
      jseFuture: fee.jseFuture,
      jseOption: fee.jseOption,
      jseDelivery: fee.jseDelivery,
      bookoversJSEFuture: fee.bookoversJSEFuture,
      bookoversJSEOption: fee.bookoversJSEOption,
      tonnes: fee.tonnes,
      createdBy: fee.createdBy,
      createdDate: fee.createdDate,
      updatedBy: fee.updatedBy,
      updatedDate: fee.updatedDate,
    });
    return Promise.resolve(res.data);
  };

  const deleteClearing = async (fee: ClearingJSEFee): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/deleteClearing", {
      _id: fee._id,
      instrument: fee.instrument,
      marketId: fee.marketId,
      absaFuture: fee.absaFuture,
      absaOption: fee.absaOption,
      absaDelivery: fee.absaDelivery,
      jseFuture: fee.jseFuture,
      jseOption: fee.jseOption,
      jseDelivery: fee.jseDelivery,
      bookoversJSEFuture: fee.bookoversJSEFuture,
      bookoversJSEOption: fee.bookoversJSEOption,
      createdBy: fee.createdBy,
      createdDate: fee.createdDate,
      updatedBy: fee.updatedBy,
      updatedDate: fee.updatedDate,
      tonnes: fee.tonnes,
    });
    return Promise.resolve(res.data);
  };

  return {
    getAllClearing,
    getClearingId,
    addClearing,
    updateClearing,
    deleteClearing,
  };
};
export default Clearing_JSE_Fees;
