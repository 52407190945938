
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import {
  ClientIdDocument,
  ClientProofOfResidence,
  ClientTaxDocument,
  ClientIncomeTaxDocument,
  ClientProofOfBank,
  ClientSignature,
  ClientPersonalDocs,
  ClientPersonalDocsUpdate,
  ClientCorpDocs,
  ClientCorpDocsUpdate,
  CustomDocs,
  CustomDocsUpdate,
  CustomDocsList,
} from "models";
import Hooks from "@/hooks";
import User from "@/store/modules/user";
import JSEClient from "@/store/modules/JSEClient";

const { ClientDocument, ClientDetails, CustomFile } = Hooks;
const clientHook = ClientDocument();
const clientDetailsHook = ClientDetails();
const customFileHook = CustomFile();

@Component({})
export default class SupportingDocs extends Vue {
  @PropSync("valid") formValid!: boolean;
  @PropSync("nextTab") tab!: boolean;
  @PropSync("next") accountNext!: boolean;
  @Prop() type!: string;
  loading = false;
  idType: string | null = null;
  saIdNumber: string | null = null;
  idUpload: File | null = null;
  proofOfResidenceUpload: File | null = null;
  taxNumber: string | null = null;
  taxNumberupload: File | null = null;
  incomeTax: string | null = null;
  incomeTaxUpload: File | null = null;
  vatNumber: string | null = null;
  vatUpload: File | null = null;
  proofOfBankUpload: File | null = null;
  signatureUpload: File | null = null;
  addFiles: {
    type: string | null;
    info: string | null;
    file: File | null;
  }[] = [];
  storedAddress: {
    streetAddress: string | null;
    streetAddress2: string | null;
    city: string | null;
    suburb: string | null;
    code: string | null;
    province: string | null;
  } = {
    streetAddress: null,
    streetAddress2: null,
    city: null,
    suburb: null,
    code: null,
    province: null,
  };
  storedDocs: {
    idDoc: ClientIdDocument | null;
    porDoc: ClientProofOfResidence | null;
    tnDoc: ClientTaxDocument | null;
    itDoc: ClientIncomeTaxDocument | null;
    vatDoc: any | null;
    pobDoc: ClientProofOfBank | null;
    signatureDoc: ClientSignature | null;
    addFiles: any | null;
  } = {
    idDoc: null,
    porDoc: null,
    tnDoc: null,
    itDoc: null,
    vatDoc: null,
    pobDoc: null,
    signatureDoc: null,
    addFiles: null,
  };
  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
    id: (value: string): boolean | string =>
      value?.length == 13 || "Requires 13 digits",
    fileSize: (value: { size: number }): boolean | string =>
      !value || value.size < 5000000 || "Max File Size 5MB!",
  };

  headers = [
    {
      text: "Type",
      value: "docType",
      sortable: false,
      style: "width: 250px",
    },
    {
      text: "Information",
      value: "info",
      sortable: false,
      style: "width: 350px",
    },
    {
      text: "File (s)",
      value: "files",
      sortable: false,
    },
  ];
  headersEdit = [
    {
      text: "Type",
      value: "docType",
      sortable: false,
      style: "width: 250px",
    },
    {
      text: "Information",
      value: "info",
      sortable: false,
      style: "width: 350px",
    },
    {
      text: "File (s)",
      value: "files",
      sortable: false,
    },
    {
      text: "View",
      value: "view",
      sortable: false,
    },
  ];
  items = [
    {
      type: "id",
    },
    {
      type: "por",
    },
    {
      type: "pob",
    },
    {
      type: "tax",
    },
    {
      type: "vat",
    },
  ];

  addCustomFile(): void {
    console.log("add files: ", this.addFiles);
    var checkAdd = this.items.find((elem) => elem.type == "add");

    if (checkAdd != undefined) {
      this.addFiles.push({ type: null, info: null, file: null });
    } else {
      this.items.push({ type: "add" });
      this.addFiles.push({ type: null, info: null, file: null });
    }
  }

  download(type: string): void {
    switch (type) {
      case "id":
        this.downloadId(
          this.storedDocs.idDoc != null ? this.storedDocs.idDoc.id : 0
        );
        break;
      case "por":
        this.downloadPOR(
          this.storedDocs.porDoc != null ? this.storedDocs.porDoc.id : 0
        );
        break;
      case "pob":
        this.downloadPOB(
          this.storedDocs.pobDoc != null ? this.storedDocs.pobDoc.id : 0
        );
        break;
      case "tax":
        this.downloadTN(
          this.storedDocs.tnDoc != null ? this.storedDocs.tnDoc.id : 0
        );
        break;
      case "vat":
        this.downloadVAT(
          this.storedDocs.vatDoc != null ? this.storedDocs.vatDoc._id : 0
        );
        break;
      case "add":
        this.downloadCustom(
          this.storedDocs.addFiles != null ? this.storedDocs.addFiles[0]._id : 0
        );
        break;
    }
  }

  @Watch("type", { immediate: true })
  async modalTypeChanged(): Promise<void> {
    console.log("Modal Details Type: ", this.type);
    if (this.type == "edit") {
      await this.getStoredData();
    }
  }

  @Watch("accountNext", {
    immediate: true,
  })
  async onNextChanged(): Promise<void> {
    console.log("AccountNext", this.accountNext);
    await this.getClientDetails();
    if (this.accountNext == true) {
      if (this.type == "add") {
        try {
          this.loading = true;
          await this.uploadPersonalFiles();
          await this.uploadCorpDocs();
          if (this.addFiles.length > 0) {
            await this.UploadCustomFiles();
          }
          
          JSEClient.setAccountDocs({
            idNumber: this.idType == "RSA ID" ? this.saIdNumber : null,
            passportNumber: this.idType == "Passport" ? this.saIdNumber : null,
            proofOfRegUploaded: false,
            proofOfResUploaded: this.proofOfResidenceUpload ? true : false,
            regNumber: null,
            taxNumber: this.taxNumber,
            vatNumber: this.vatNumber,
          });

          this.$notificationCreator.createSuccessNotification(
            "Supporting Documents Saved."
          );
          this.tab = true;
        } finally {
          this.loading = false;
        }
      }
      if (this.type == "edit") {
        try {
          this.loading = true;
          await this.updatePersonalDocs();
          await this.updateCorpDocs();
          if (this.addFiles.length > 0) {
            await this.UploadCustomFiles();
            await this.UpdateCustomDocs();
          } else if (this.addFiles.length == 0) {
            await this.UpdateCustomDocs();
          }

          JSEClient.setAccountDocs({
            idNumber: this.idType == "RSA ID" ? this.saIdNumber : null,
            passportNumber: this.idType == "Passport" ? this.saIdNumber : null,
            proofOfRegUploaded: false,
            proofOfResUploaded: this.proofOfResidenceUpload ? true : false,
            regNumber: null,
            taxNumber: this.taxNumber,
            vatNumber: this.vatNumber,
          });

          this.$notificationCreator.createSuccessNotification(
            "Supporting Docs Updated."
          );

          //this.tab = true;
        } finally {
          this.accountNext = false;
          this.loading = false;
        }
      }
    }
  }

  async getBase64(file: Blob | File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  async getClientDetails(): Promise<void> {
    try {
      var clientId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        clientId = key;
      }

      if (clientId != null) {
        const res = await clientDetailsHook.getClientDetailByClient(clientId);

        this.storedAddress.streetAddress = res.streetAddress;
        this.storedAddress.streetAddress2 = res.streetAddress2;
        this.storedAddress.city = res.city;
        this.storedAddress.suburb = res.suburb;
        this.storedAddress.code = res.code;
        this.storedAddress.province = res.province;
        console.log("Get Client Address: ", this.storedAddress);
        return Promise.resolve();
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  }

  async getStoredData(): Promise<void> {
    try {
      this.loading = true;
      var clientId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        clientId = key;
      }

      if (clientId != null) {
        const resID = await clientHook.getClientIdDocumentByClient(clientId);
        this.idType = resID.identificationType;
        this.saIdNumber = resID.idNumber;
        this.storedDocs.idDoc = resID;

        const resPOR = await clientHook.getClientProofOfResidenceByClient(
          clientId
        );
        this.storedDocs.porDoc = resPOR;

        const resTN = await clientHook.getClientTaxByClient(clientId);
        this.taxNumber = resTN.taxNumber;
        this.storedDocs.tnDoc = resTN;

        const resIT = await clientHook.getClientTaxIncomeByClient(clientId);
        this.incomeTax = resIT.incomeTaxNumber;
        this.storedDocs.itDoc = resIT;

        const resVAT = await clientHook.getClientVAT(clientId);
        this.vatNumber = resVAT.vatNumber;
        this.storedDocs.vatDoc = resVAT;

        const resPOB = await clientHook.getClientPOB(clientId);
        this.storedDocs.pobDoc = resPOB;

        const resSignature = await clientHook.getClientSignature(clientId);
        this.storedDocs.signatureDoc = resSignature;

        const resCustom = await customFileHook.getCustomFileByCode(clientId);
        this.storedDocs.addFiles = resCustom;
        if (
          this.storedDocs.addFiles != null &&
          this.storedDocs.addFiles != ""
        ) {
          this.items.push({ type: "add" });
          this.storedDocs.addFiles.forEach((elem: any) => {
            elem.updateFile = null as File | null;
          });
        }

        console.log("Stored Docs: ", this.storedDocs);
        return Promise.resolve();
      }
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async updatePersonalDocs(): Promise<void> {
    try {
      var clientId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        clientId = key;
      }

      var resultID: string | ArrayBuffer | null = null;
      if (this.idUpload != null) {
        resultID = await this.getBase64(this.idUpload);
      }

      var resultPOR: string | ArrayBuffer | null = null;
      if (this.proofOfResidenceUpload != null) {
        resultPOR = await this.getBase64(this.proofOfResidenceUpload);
      }

      var resultSignature: string | ArrayBuffer | null = null;
      if (this.signatureUpload != null) {
        resultSignature = await this.getBase64(this.signatureUpload);
      }

      var clientDocument: ClientPersonalDocsUpdate = {
        user: User.getLoggedInUser,
        clientId: clientId,
        identificationType: this.idType,
        idCopyOfId:
          this.storedDocs.idDoc != undefined ? this.storedDocs.idDoc.id : null,
        copyOfId: this.idUpload != null ? resultID : this.idUpload,
        saidNumber: this.saIdNumber,
        proofOfResidenceId:
          this.storedDocs.porDoc != undefined
            ? this.storedDocs.porDoc.id
            : null,
        proofOfResidence:
          this.proofOfResidenceUpload != null
            ? resultPOR
            : this.proofOfResidenceUpload,
        signatureUploadId:
          this.storedDocs.signatureDoc != undefined
            ? this.storedDocs.signatureDoc.id
            : null,
        signatureUpload: this.signatureUpload != null ? resultSignature : null,
      };

      console.log("Account Details: ", clientDocument);
      if (clientDocument != null) {
        const res = await clientHook.UpdateIndividualsPersonalFiles(
          clientDocument
        );
      }

      return Promise.resolve();
    } catch (err: any) {
      //this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    }
  }

  async updateCorpDocs(): Promise<void> {
    try {
      var clientId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        clientId = key;
      }

      var resultTN: string | ArrayBuffer | null = null;
      if (this.taxNumberupload != null) {
        resultTN = await this.getBase64(this.taxNumberupload);
      }

      var resultIT: string | ArrayBuffer | null = null;
      if (this.incomeTaxUpload != null) {
        resultIT = await this.getBase64(this.incomeTaxUpload);
      }

      var resultVAT: string | ArrayBuffer | null = null;
      if (this.vatUpload != null) {
        resultVAT = await this.getBase64(this.vatUpload);
      }

      var resultPOB: string | ArrayBuffer | null = null;
      if (this.proofOfBankUpload != null) {
        resultPOB = await this.getBase64(this.proofOfBankUpload);
      }
      var clientDocument: ClientCorpDocsUpdate = {
        user: User.getLoggedInUser,
        clientId: clientId,

        taxNumberUploadId:
          this.storedDocs.tnDoc != undefined ? this.storedDocs.tnDoc.id : null,
        taxNumber: this.taxNumber,
        taxNumberUpload:
          this.taxNumberupload != null ? resultTN : this.taxNumberupload,
        incomeTax: this.incomeTax,
        incomeTaxUploadId:
          this.storedDocs.itDoc != undefined ? this.storedDocs.itDoc.id : null,
        incomeTaxUpload:
          this.incomeTaxUpload != null ? resultIT : this.incomeTaxUpload,
        pobUploadId:
          this.storedDocs.pobDoc != undefined
            ? this.storedDocs.pobDoc.id
            : null,
        pobUpload:
          this.proofOfBankUpload != null ? resultPOB : this.proofOfBankUpload,
        vatId:
          this.storedDocs.vatDoc != undefined
            ? this.storedDocs.vatDoc._id
            : null,
        vatNumber: this.vatNumber,
        vatUpload: this.vatUpload != null ? resultVAT : this.vatUpload,
      };

      console.log("Account Details: ", clientDocument);
      if (clientDocument != null) {
        const res = await clientHook.UpdateIndividualDocumentFiles(
          clientDocument
        );
      }
      return Promise.resolve();
    } catch (err: any) {
      //this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    }
  }

  async uploadPersonalFiles(): Promise<void> {
    try {
      var clientId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        clientId = key;
      }

      var idArray = [];
      const promisesID: Promise<string | ArrayBuffer | null>[] = [];
      idArray.push(this.idUpload);
      idArray.forEach(async (elem: any) => {
        promisesID.push(this.getBase64(new Blob([elem])));
      });
      const resultID = await Promise.all(promisesID);

      var proofOfResidenceArray = [];
      const promisesPOR: Promise<string | ArrayBuffer | null>[] = [];
      proofOfResidenceArray.push(this.proofOfResidenceUpload);
      proofOfResidenceArray.forEach(async (elem: any) => {
        promisesPOR.push(this.getBase64(new Blob([elem])));
      });
      const resultPOR = await Promise.all(promisesPOR);

      var signatureArray = [];
      const promisesSignature: Promise<string | ArrayBuffer | null>[] = [];

      signatureArray.push(this.signatureUpload);
      signatureArray.forEach(async (elem: any) => {
        promisesSignature.push(this.getBase64(new Blob([elem])));
      });
      const resultSignature = await Promise.all(promisesSignature);

      var clientDocument: ClientPersonalDocs = {
        user: User.getLoggedInUser,
        clientId: clientId,
        identificationType: this.idType,
        copyOfId: this.idUpload != null ? resultID : this.idUpload,
        saidNumber: this.saIdNumber,
        proofOfResidence:
          this.proofOfResidenceUpload != null
            ? resultPOR
            : this.proofOfResidenceUpload,

        signatureUpload: this.signatureUpload != null ? resultSignature : null,
      };

      console.log("Personal Docs: ", clientDocument);
      if (clientDocument != null) {
        const res = await clientHook.UploadIndividualsPersonalFiles(
          clientDocument
        );
      }
      return Promise.resolve();
    } catch (err: any) {
      this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    }
  }

  async uploadCorpDocs(): Promise<void> {
    try {
      var clientId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        clientId = key;
      }

      var taxNumberArray = [];
      const promisesTN: Promise<string | ArrayBuffer | null>[] = [];
      taxNumberArray.push(this.taxNumberupload);
      taxNumberArray.forEach(async (elem: any) => {
        promisesTN.push(this.getBase64(new Blob([elem])));
      });
      const resultTN = await Promise.all(promisesTN);

      var incomeTaxArray = [];
      const promisesIT: Promise<string | ArrayBuffer | null>[] = [];
      incomeTaxArray.push(this.incomeTaxUpload);
      incomeTaxArray.forEach(async (elem: any) => {
        promisesIT.push(this.getBase64(new Blob([elem])));
      });
      const resultIT = await Promise.all(promisesIT);

      var vatArray = [];
      const promisesVAT: Promise<string | ArrayBuffer | null>[] = [];
      vatArray.push(this.vatUpload);
      vatArray.forEach(async (elem: any) => {
        promisesVAT.push(this.getBase64(new Blob([elem])));
      });
      const resultVAT = await Promise.all(promisesVAT);

      var pobArray = [];
      const promisesPOB: Promise<string | ArrayBuffer | null>[] = [];
      pobArray.push(this.proofOfBankUpload);
      pobArray.forEach(async (elem: any) => {
        promisesPOB.push(this.getBase64(new Blob([elem])));
      });
      const resultPOB = await Promise.all(promisesPOB);

      var clientDocument: ClientCorpDocs = {
        user: User.getLoggedInUser,
        clientId: clientId,
        taxNumber: this.taxNumber,
        taxNumberUpload:
          this.taxNumberupload != null ? resultTN : this.taxNumberupload,
        incomeTax: this.incomeTax,
        incomeTaxUpload:
          this.incomeTaxUpload != null ? resultIT : this.incomeTaxUpload,
        pobUpload:
          this.proofOfBankUpload != null ? resultPOB : this.proofOfBankUpload,
        vatNumber: this.vatNumber,
        vatUpload: this.vatUpload != null ? resultVAT : this.vatUpload,
      };

      console.log("Account Details: ", clientDocument);
      if (clientDocument != null) {
        const res = await clientHook.UploadIndividualDocumentFiles(
          clientDocument
        );
      }
      return Promise.resolve();
    } catch (err: any) {
      this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    }
  }

  async UploadCustomFiles(): Promise<void> {
    var clientId: string | null = null;
    const key = localStorage.getItem("ClientFile/entityId");
    if (key != null) {
      clientId = key;
    }

    if (this.addFiles.length > 0) {
      var customDoc: CustomDocsList[] = [];

      for (let file of this.addFiles) {
        if (file.file != null) {
          customDoc.push({
            user: User.getLoggedInUser,
            entityId: clientId,
            number: file.info,
            fileType: file.type,
            customFile: await this.getBase64(file.file),
          });
        }
      }

      console.log("Custom Docs: ", customDoc);
      const res = await customFileHook
        .UploadCustomDocumentsList(customDoc)
        .catch((err) => {
          this.accountNext = false;
          this.tab = false;
          return Promise.reject(err);
        });
      return Promise.resolve();
    }
  }

  async UpdateCustomDocs(): Promise<void> {
    try {
      var closedCorpId: string | null = null;
      const key = localStorage.getItem("ClientFile/entityId");
      if (key != null) {
        closedCorpId = key;
      }

      if (this.storedDocs.addFiles != null && this.storedDocs.addFiles != "") {
        this.storedDocs.addFiles.forEach(async (elem: any) => {
          if (elem.updateFile != null) {
            var resultCustomFiles: string | ArrayBuffer | null = null;
            resultCustomFiles = await this.getBase64(
              new Blob([elem.updateFile])
            );

            var customDocs: CustomDocsUpdate = {
              user: User.getLoggedInUser,
              entityId: closedCorpId,
              number: elem.number,
              fileType: elem.fileType,
              customFileId:
                this.storedDocs.addFiles != undefined ? elem._id : null,
              customFile:
                this.storedDocs.addFiles != undefined
                  ? resultCustomFiles
                  : null,
            };

            console.log("Custom Docs: ", customDocs);
            const res = await customFileHook.UpdateCustomFile(customDocs);

            return Promise.resolve();
          }
        });
      }
    } catch (err: any) {
      //this.accountNext = false;
      this.tab = false;
      return Promise.reject(err);
    }
  }

  async downloadId(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await clientHook
          .DownloadIdDocument(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download ID: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadPOR(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await clientHook
          .DownloadPOR(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download POR: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadTN(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await clientHook
          .DownloadTax(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download TN: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadIT(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await clientHook
          .DownloadIncomeTax(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download IT: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadVAT(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await clientHook
          .DownloadVATDocument(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download IT: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadPOB(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await clientHook
          .DownloadPOBDocument(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download IT: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadSignature(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await clientHook
          .DownloadSignatureDocument(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download IT: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }

  async downloadCustom(id: number | undefined): Promise<void> {
    if (id != undefined) {
      try {
        this.loading = true;
        var res = await customFileHook
          .DownloadCustomFile(id)
          .then((response: any) => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: response.type,
              })
            );
            window.open(url);
            console.log(response);
          })
          .catch((err: any) => {
            console.log("Error Download Reg: ", err);
            return Promise.reject(err);
          });

        return Promise.resolve();
      } catch (err: any) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else {
      this.$notificationCreator.createErrorNotification(
        "No File Has Been Uploaded"
      );
    }
  }
}
