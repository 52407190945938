
import dayjs from "dayjs";
import { Component, PropSync, Vue, Watch } from "vue-property-decorator";
import Hooks from "@/hooks";
import { Market, RatingCode, RatingCode_CREATE, ClearingJSEFee } from "models";

const {
  RatingCodes,
  Markets,
  Clearing_JSE_Fees,
  AmendAccountPDF,
  Excel,
} = Hooks;
const ratingCodeHook = RatingCodes();
const marketHook = Markets();
const clearingHook = Clearing_JSE_Fees();
const amendAccountPDFHook = AmendAccountPDF();
const excelHook = Excel();

@Component({})
export default class AmendFeeStructure extends Vue {
  @PropSync("open") modal!: boolean;
  valid = false;
  date = dayjs().format("YYYY-MM-DD");
  dateMenu = false;
  showCard = false;
  addNewFee = false;
  amendFeeStructure = false;
  markets: Market[] = [];
  ratingCodes: RatingCode[] = [];
  newMarket: number | null = null;
  amendMarket: number | null = null;
  newRatingCode: string | null = null;
  newRatingCodeAmount: number | null = null;
  existingRatingCodeId: number | null = null;
  existingRatingCodeAmount: number | null = null;
  updatedRatingCode: string | null = null;
  updatedRatingCodeAmount: number | null = null;
  saveModal = false;
  saveAsPDF = false;
  saveAsExcel = false;
  commodities: ClearingJSEFee[] = [];
  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
  };

  @Watch("modal", { immediate: true })
  async modalChanged(): Promise<void> {
    if (this.modal == true) {
      this.date = dayjs().format("YYYY-MM-DD");
      await this.getMarkets();
    }
  }

  closeModal(): void {
    this.addNewFee = false;
    this.amendFeeStructure = false;
    this.newMarket = null;
    this.amendMarket = null;
    this.newRatingCode = null;
    this.newRatingCodeAmount = null;
    this.existingRatingCodeId = null;
    this.existingRatingCodeAmount = null;
    this.updatedRatingCode = null;
    this.updatedRatingCodeAmount = null;
    this.modal = false;
  }

  clear(): void {
    const refForm: any = this.$refs.form;
    refForm.reset();
    this.valid = false;
  }

  validateCheckBoxes(i: string): void {
    this.showCard = true;
    if (this.addNewFee == false && this.amendFeeStructure == false) {
      this.showCard = false;
    }

    switch (i) {
      case "new":
        this.amendFeeStructure = false;
        this.amendMarket = null;
        this.existingRatingCodeId = null;
        this.existingRatingCodeAmount = null;
        this.updatedRatingCode = null;
        this.updatedRatingCodeAmount = null;
        break;
      case "amend":
        this.addNewFee = false;
        this.newMarket = null;
        this.newRatingCode = null;
        this.newRatingCodeAmount = null;

        break;
    }
  }

  getFeeAmountForRatingCode(): void {
    var result = this.ratingCodes.find(
      (elem) => elem.id == this.existingRatingCodeId
    );
    if (result != undefined) {
      this.existingRatingCodeAmount = result.amount;
      this.updatedRatingCode = result.name;
    }
  }

  getRatingCodeForFeeAmount(): void {
    var result = this.ratingCodes.find(
      (elem) => elem.amount == this.existingRatingCodeAmount
    );
    if (result != undefined) {
      this.existingRatingCodeId = result.id;
    }
  }

  async getMarkets(): Promise<void> {
    try {
      const res = await marketHook.getAllMarkets();
      this.markets = res;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getRatingCodes(id: number): Promise<void> {
    try {
      const res = await ratingCodeHook.getAllRatingCodesByMarket(id);
      this.ratingCodes = res;

      console.log("RATING CODES:", this.ratingCodes);

      this.getRatingCodeNameSuggestion();
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  getRatingCodeNameSuggestion(): void {
    if (this.ratingCodes.length > 0) {
      this.ratingCodes.sort(function (a, b) {
        var keyA = a.name.match(/[a-zA-Z]+|[0-9]+/g);
        var keyB = b.name.match(/[a-zA-Z]+|[0-9]+/g);
        if (keyA != null && keyB != null) {
          if (keyA[1] < keyB[1]) return -1;
          if (keyA[1] > keyB[1]) return 1;
          return 0;
        } else {
          return 0;
        }
      });
      this.ratingCodes.sort(function (a, b) {
        var keyA = a.name.match(/[a-zA-Z]+|[0-9]+/g);
        var keyB = b.name.match(/[a-zA-Z]+|[0-9]+/g);
        if (keyA != null && keyB != null) {
          if (keyA[0] < keyB[0]) return -1;
          if (keyA[0] > keyB[0]) return 1;
          return 0;
        } else {
          return 0;
        }
      });
      console.log("SR: ", this.ratingCodes);

      if (this.newMarket != null) {
        const lastRatingCode = this.ratingCodes[this.ratingCodes.length - 1];
        const lastRCValueSplit = lastRatingCode.name.match(/[a-zA-Z]+|[0-9]+/g);
        var lastRCValue: string | null = null;
        if (lastRCValueSplit != null) {
          lastRCValue = lastRCValueSplit[1];
        }
        if (lastRCValue != null && lastRCValueSplit != null) {
          var newRatingCode = Number(lastRCValue) + 1;
          if (String(newRatingCode).length < lastRCValueSplit[1].length) {
            this.newRatingCode = lastRCValueSplit[0] + "0" + newRatingCode;
          } else {
            this.newRatingCode = lastRCValueSplit[0] + newRatingCode;
          }
        }
      }
    }
  }

  async save(): Promise<void> {
    if (this.addNewFee == true) {
      await this.addRatingCode();
    }
    if (this.amendFeeStructure == true) {
      await this.updateRatingCode();
    }
  }

  async addRatingCode(): Promise<void> {
    try {
      var ratingCode: RatingCode_CREATE;
      ratingCode = {
        marketId: this.newMarket != null ? this.newMarket : 0,
        name: this.newRatingCode != null ? this.newRatingCode : "",
        amount: this.newRatingCodeAmount,
      };
      const res = await ratingCodeHook.addNewRatingCode(ratingCode);
      this.$notificationCreator.createSuccessNotification(
        "New Rating Code Added."
      );
      if (this.saveAsPDF == true) {
        await this.DownloadAmendPDF();
      }
      if (this.saveAsExcel == true) {
        await this.DownloadAmendExcel({
          ratingCode: this.newRatingCode != null ? this.newRatingCode : "",
          marketId: this.newMarket != null ? this.newMarket : 0,
          fee: this.newRatingCodeAmount != null ? this.newRatingCodeAmount : 0,
        });
      }
      return Promise.resolve();
    } catch (err: any) {
      return Promise.reject(err);
    }
  }

  async updateRatingCode(): Promise<void> {
    try {
      var result = this.ratingCodes.find(
        (elem) => elem.id == this.existingRatingCodeId
      );
      if (result != undefined) {
        var ratingCode: RatingCode = {
          marketId: result.marketId,
          name: this.updatedRatingCode != null ? this.updatedRatingCode : "",
          amount: this.updatedRatingCodeAmount,
          createdBy: result.createdBy,
          createdDate: result.createdDate,
          id: result.id,
          updatedBy: result.updatedBy,
          updatedDate: result.updatedDate,
          _id: {
            creationTime: result._id.creationTime,
            increment: result._id.increment,
            machine: result._id.machine,
            pid: result._id.pid,
            timestamp: result._id.timestamp,
          },
        };
        const res = await ratingCodeHook.updateRatingCode(ratingCode);
        this.$notificationCreator.createSuccessNotification(
          "Rating Code Amended."
        );

        if (this.saveAsPDF == true) {
          await this.DownloadAmendPDF();
        }
        if (this.saveAsExcel == true) {
          await this.DownloadAmendExcel({
            ratingCode:
              this.updatedRatingCode != null ? this.updatedRatingCode : "",
            marketId: result.marketId,
            fee:
              this.updatedRatingCodeAmount != null
                ? this.updatedRatingCodeAmount
                : 0,
          });
        }
        return Promise.resolve();
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  }

  async getCommodities(): Promise<void> {
    try {
      const res = await clearingHook.getAllClearing();
      if (this.addNewFee == true) {
        var commoditiesNew = res.filter(
          (elem) => elem.marketId == this.newMarket
        );
        if (commoditiesNew != undefined) {
          this.commodities = res;
        }
      } else if (this.amendFeeStructure == true) {
        var commoditiesAmend = res.filter(
          (elem) => elem.marketId == this.amendMarket
        );
        if (commoditiesAmend != undefined) {
          this.commodities = res;
        }
      } else {
        this.commodities = res;
      }
      console.log("Commodities:", this.commodities);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async DownloadAmendExcel(amend: {
    ratingCode: string;
    marketId: number;
    fee: number;
  }): Promise<void> {
    if (this.addNewFee == true) {
      try {
        const res = await excelHook
          .DownloadAmendFee(amend)
          .then((response: any) => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `New Fee ${dayjs().format("YYYY-MM-DD")}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
          });

        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    }
    if (this.amendFeeStructure == true) {
      try {
        const res = await excelHook
          .DownloadAmendFee(amend)
          .then((response: any) => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `Amend Fee ${dayjs().format("YYYY-MM-DD")}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
          });

        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    }
  }

  async DownloadAmendPDF(): Promise<void> {
    await this.getCommodities();
    try {
      var account: {
        commodity: string | null;
        fee: number | null;
        ratingCode: string | null;
        tonnes: number | null;
      }[] = [];

      if (this.addNewFee == true) {
        this.commodities.forEach((elem) => {
          account.push({
            commodity: elem.instrument,
            fee: this.newRatingCodeAmount,
            ratingCode: this.newRatingCode,
            tonnes: elem.tonnes,
          });
        });
        console.log("AccountPDF", account);
        const resp = await amendAccountPDFHook
          .getAmendFeeStructurePDF(account)
          .then((response: any) => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `New Fee${dayjs().format("YYYY-MM-DD")}.pdf`
            );
            document.body.appendChild(link);
            link.click();
          });
      }
      if (this.amendFeeStructure == true) {
        this.commodities.forEach((elem) => {
          account.push({
            commodity: elem.instrument,
            fee: this.updatedRatingCodeAmount,
            ratingCode: this.updatedRatingCode,
            tonnes: elem.tonnes,
          });
        });

        console.log("AccountPDF", account);
        const resp = await amendAccountPDFHook
          .getAmendFeeStructurePDF(account)
          .then((response: any) => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `Amend Fee ${dayjs().format("YYYY-MM-DD")}.pdf`
            );
            document.body.appendChild(link);
            link.click();
          });
      }

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
}
