
import { Component, Vue } from "vue-property-decorator";
import dayjs from "dayjs";
import { PoolAccount, PoolAccountPDF, UserAccessModel } from "models";
import Hooks from "@/hooks";
import User from "@/store/modules/user";

const { Excel, AccountTransfersPDF } = Hooks;
const excelHook = Excel();
const accountTransfersPDFHook = AccountTransfersPDF();

@Component({})
export default class PoolAccounts extends Vue {
  loading = false;
  date = this.getPreviousWorkDay;
  dateMenu = false;
  saveModal = false;
  saveAsPDF = false;
  saveAsExcel = false;
  account: {
    id: number;
    safexCode: string | null;
    name: string | null;
    amount: number | null;
    market: string | null;
  }[] = [];
  editedIndex = -1;
  editedItem = {
    id: 0,
    safexCode: "",
    name: "",
    amount: null,
    market: "",
  };
  defaultItem = {
    id: 0,
    safexCode: "",
    name: "",
    amount: null,
    market: "",
  };
  headers = [
    {
      text: "#",
      value: "id",
      sortable: false,
    },
    {
      text: "Safex Code",
      value: "safexCode",
      sortable: false,
    },
    {
      text: "Name",
      value: "name",
      sortable: false,
    },
    {
      text: "Amount",
      value: "amount",
      sortable: false,
    },
    {
      text: "Actions",
      value: "actions",
      sortable: false,
      width: "100px",
    },
  ];
  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
  };

  uppercase(): void {
    this.editedItem.safexCode = this.editedItem.safexCode.toUpperCase();
  }

  get getUserAccess(): UserAccessModel | null {
    console.log("UA: ", User.getUserAccess);
    return User.getUserAccess;
  }

  get getPreviousWorkDay(): string {
    var date = dayjs().day();
    if (date == 6) {
      /* 6 = saturday */
      return dayjs().subtract(1, "day").format("YYYY-MM-DD");
    } else if (date == 0) {
      /* 0 = sunday */
      return dayjs().subtract(2, "day").format("YYYY-MM-DD");
    } else if (date == 1) {
      /* 0 = sunday */
      return dayjs().subtract(3, "day").format("YYYY-MM-DD");
    } else {
      return dayjs().subtract(1, "day").format("YYYY-MM-DD");
    }
  }

  mounted(): void {
    this.addNew();
  }

  editItem(item: any): void {
    this.editedIndex = this.account.indexOf(item);
    this.editedItem = Object.assign({}, item);
  }

  deleteItem(item: any): void {
    const index = this.account.indexOf(item);

    this.account.splice(index, 1);
  }

  close(): void {
    setTimeout(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    }, 100);
  }

  addNew(): void {
    const addObj = Object.assign({}, this.defaultItem);
    addObj.id = this.account.length + 1;
    this.account.unshift(addObj);
    this.editItem(addObj);
  }

  save(): void {
    if (this.editedIndex > -1) {
      Object.assign(this.account[this.editedIndex], this.editedItem);
      console.log("Pool Account: ", this.account);
    }
  }

  clear(): void {
    this.account = [];
    this.addNew();
  }

  async getSafexData(safexCode: string): Promise<void> {
    try {
      const res = await excelHook.getSafexData(safexCode);
      console.log("Safex Data: ", res);
      if (this.account.length > 1) {
        console.log(this.account[this.account.length - 1].market);
        if (this.account[this.account.length - 1].market == res.market) {
          this.editedItem.name = res.name;
          this.editedItem.market = res.market;
          return Promise.resolve();
        } else {
          this.$notificationCreator.createErrorNotification(
            "Safex Code/ JSE Code entered can't be used. The Market is not the same as the first entry's Market."
          );
          return Promise.reject();
        }
      } else {
        this.editedItem.name = res.name;
        this.editedItem.market = res.market;
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async downloadFile(): Promise<void> {
    if (this.saveAsPDF == true) {
      await this.DownloadPoolAccountsPDF();
    }
    if (this.saveAsExcel == true) {
      await this.DownloadPoolAccountsExcel();
    }
  }

  async DownloadPoolAccountsPDF(): Promise<void> {
    try {
      this.account.sort((a, b) => a.id - b.id);
      var poolAccount: PoolAccountPDF[] = [];
      this.account.forEach((elem) => [
        poolAccount.push({
          safexCode: elem.safexCode,
          market: elem.market,
          clientName: elem.name,
          amount: elem.amount,
          depositDate: this.date,
          lineNumber: elem.id,
          logoFileId: 1,
        }),
      ]);
      console.log("Pool Acc", poolAccount);
      const res = await accountTransfersPDFHook
        .getPoolAccountPDF(poolAccount)
        .then((response: any) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `PoolAccount ${dayjs().format("YYYY-MM-DD")}.pdf`
          );
          document.body.appendChild(link);
          link.click();
        });

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async DownloadPoolAccountsExcel(): Promise<void> {
    try {
      this.account.sort((a, b) => a.id - b.id);
      var poolAccount: PoolAccount[] = [];
      this.account.forEach((elem) => [
        poolAccount.push({
          safexCode: elem.safexCode,
          market: elem.market,
          clientName: elem.name,
          amount: elem.amount,
          depositDate: this.date,
          lineNumber: elem.id,
        }),
      ]);
      console.log("Pool Acc", poolAccount);
      const res = await excelHook
        .DownloadPoolAccountsExcel(poolAccount)
        .then((response: any) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `PoolAccount ${dayjs().format("YYYY-MM-DD")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
        });

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
}
