import { axiosInstance } from "@/plugins/axios";
import { AccountDetail, ClientDetail, ClientDetail_CREATE } from "models";

const ClosedCorpDetails = () => {
  const baseUrl = "/api/ClosedCorpDetail";

  const getAllClosedCorpDetail = async (): Promise<ClientDetail[]> => {
    const res = await axiosInstance.get(baseUrl + "/getAllClosedCorpDetail");
    return Promise.resolve(res.data);
  };

  const getClosedCorpByCodeDetail = async (
    code: string
  ): Promise<ClientDetail> => {
    const res = await axiosInstance.get(
      baseUrl + `/getClosedCorpByCodeDetail?code=${code}`
    );
    return Promise.resolve(res.data);
  };

  const addNewClosedCorpDetail = async (
    closedCropDetail: ClientDetail_CREATE
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/addNewClosedCorpDetail",
      closedCropDetail
    );
    return Promise.resolve(res.data);
  };

  const updateClosedCorpDetail = async (
    closedCropDetail: AccountDetail
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/updateClosedCorpDetail",
      closedCropDetail
    );
    return Promise.resolve(res.data);
  };

  const deleteClosedCorpDetail = async (
    closedCropDetail: AccountDetail
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + "/deleteClosedCorpDetail",
      closedCropDetail
    );
    return Promise.resolve(res.data);
  };

  return {
    getAllClosedCorpDetail,
    addNewClosedCorpDetail,
    updateClosedCorpDetail,
    deleteClosedCorpDetail,
    getClosedCorpByCodeDetail,
  };
};
export default ClosedCorpDetails;
